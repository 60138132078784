import React, { useState, Fragment} from 'react';
import ReactDOM from 'react-dom';

const SharedItemModal = ({ sharedItem, isOpen, toggleModal, addSharedItem, dataSources, sharedItems, invitedJourneyMembers}) => {

  if(sharedItem){
    sharedItem.permissions = sharedItem.permissions || {};
    sharedItem.notification_comment = sharedItem.notification_comment || [];
    sharedItem.notification_step_activity = sharedItem.notification_step_activity || [];
  }
  
  const [newShareItem, setNewShareItem] = useState(sharedItem);
  const [loading, setLoading] = useState(false);

  /* 
   * To check an item exist in checkbox options.
   */
  const setAccess = (access_type, key) => {
    let obj = newShareItem[key];
    if (obj[access_type]) {
      delete obj[access_type]
    }else {
      obj[access_type] = true; 
    }
    setNewShareItem({...newShareItem})
  }

  const exists = (notification_type, key) => {
    let list = newShareItem[key];
    return list.indexOf(notification_type) > -1
  }

  const setNotification = (notification_type, key) => {
    let list = newShareItem[key];
    let idx = list.indexOf(notification_type);
     if(idx > -1) {
       list = list.filter(i => i !== notification_type)
       newShareItem[key] = list;
     }else {
       list.push(notification_type)
     }
     setNewShareItem({...newShareItem})
  }

  const setInvitedUser = (e) => {
    let u = invitedJourneyMembers[e.target.selectedIndex-1];
    newShareItem.email = u && u.email;
    setNewShareItem({...newShareItem})
  }

  const OptionsObjList = ({list, id_key, label_key}) => list.map((o, k) =>
    <option key={k} value={o[id_key]}>{o[label_key]}</option>
  );

  return(ReactDOM.createPortal(
    <React.Fragment>
      <div className="ml-modal">
      <div className="ml-modal-content ml-card-4 ml-animate-zoom">
      
      <div className="ml-center bg-black white p-5">
        <h4>{newShareItem.id?'Update':'Add'} User</h4>
        <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal"
        onClick={e=>toggleModal(e)}>×
        </span>
      </div>
    
    {loading ? <div className="spinner"></div> :
    <form className="ml-container">
      <div className="ml-section">

        <label><b>Email</b></label>
        <select onChange={e => setInvitedUser(e)} value={newShareItem.email} className="form-control ml-margin-bottom">
          <option value="null"></option>
          <OptionsObjList list={invitedJourneyMembers} id_key="email" label_key="email"/>
        </select>

        <label><b>Permissions</b></label>
        <div className="m-b-5">Check the box to share your rights</div>
          {Object.keys(dataSources.permissions).map((p)=>
            <Fragment key={p}>
              <span className="checkbox-container" onClick={e => setAccess(p, 'permissions')}>
                <span>{dataSources.permissions[p]}</span>
                <input type="checkbox" checked={newShareItem.permissions[p]!=null} readOnly/>
                <span className="checkbox-checkmark"></span>
              </span>
            </Fragment>
          )}

        <label><b>Notification for Step Activity</b></label>
          {Object.keys(dataSources.notificationsTypes).map((p)=>
            <Fragment key={p}>
              <span className="checkbox-container" onClick={e => setNotification(p, 'notification_step_activity')}>
                <span>{dataSources.notificationsTypes[p]}</span>
                <input type="checkbox" checked={exists(p, 'notification_step_activity')} readOnly/>
                <span className="checkbox-checkmark"></span>
              </span>
            </Fragment>
          )}

        <label><b>Notification for Comments</b></label>
          {Object.keys(dataSources.notificationsTypes).map((p)=>
            <Fragment key={p}>
              <span className="checkbox-container" onClick={e => setNotification(p, 'notification_comment')}>
                <span>{dataSources.notificationsTypes[p]}</span>
                <input type="checkbox" checked={exists(p, 'notification_comment')} readOnly/>
                <span className="checkbox-checkmark"></span>
              </span>
            </Fragment>
          )}

        <div className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=>{addSharedItem(e, newShareItem);setLoading(true)}}>
          <b>{newShareItem.id?'Update':'Create'}</b>
        </div>
        
      </div>
    </form>}
    
  </div>
</div>
    </React.Fragment>, document.body))
};

export default SharedItemModal;