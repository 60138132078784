import React, { Fragment, useState } from 'react';
import FavouritesService from "../../services/FavouritesService";

const AddToFavourite = ({favourite_id, favorable_type, favorable_id, journey_profile_id, class_name_active,class_name_disable}) => {
  
  const [favId, setFavId] = useState(favourite_id)

  const remove = () => {
    FavouritesService.delete(favId).then((res) => {
      if (res.status == 204) {
        setFavId(null)        
      }
    })
  }

  const add = () => {
    let req = {
      favorable_id: favorable_id,
      favorable_type: favorable_type,
      journey_profile_id: journey_profile_id,
    }
    FavouritesService.add(req).then(function (res) {
      if (res.status == 201) {
        setFavId(res.data.favourite.id)
      }
    })
  }

  return (
    <Fragment>
      {favId ? (
        <i onClick={(e) => remove(e)}
          className={`fas fa-thumbtack pull-right pin font-16 ${class_name_active} `}/>
      ) : (
        <i onClick={(e) => add(e)}
          className={`fa fa-thumbtack pull-right pin font-16 ${class_name_disable} `}/>
      )}
    </Fragment>
  )
}

export default AddToFavourite;