import React, {useState, useContext, useEffect, Fragment} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import SharedItem from './SharedItem'
import SharedItemService from "./SharedItemService";
import ConfirmMenu from '../Modals/ConfirmMenu'
import ShareItemModal from './ShareItemModal'
import {Link} from "react-router-dom";
import useModal from '../../hooks/useModal';
import useStyle from '../../hooks/useStyle';
import PreLoadSpinner from "../PreLoadSpinner";
import JourneyTitle from "../Journey/CollegeJourney/JourneyTitle/JourneyTitle";
import SideMenuBar from "../Common/SideMenuBar";
import NotificationPopup from '../Common/NotificationPopup'
import HelpMessageList from '../../constants/HelpMessageList'
import GenericModal from "../Modals/GenericModal";
import ProjectMenu from "../Modals/ProjectMenu";
import CheckAccess from "../Roles/CheckAccess"

let requestParams = {}
let newSharedItems = {};
let selectedSharedItems = {};
let dataSources = {};
let invitedJourneyMembers = [];

const SharedItemsList = (props) => {
  useStyle('card');  
  useStyle('card_table');
  useStyle('add_role_dialog');
  
  let params = props.match.params;
  let {journeyProfile, activity} = props.location.state;
  
  const {state:currentUser, dispatch} = useContext(AuthContext);
  const [sharedItems, setSharedItems] = useState([]);
  const [privacyType, setPrivacyType] = useState(activity.privacy_type);
  const [showTableView, setShowTableView] = useState(false);
  const {isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage} = useModal();
  const {isOpen:isDeletePopupOpen, toggleModal:toggleDeletePopup} = useModal();
  const {isOpen:isNewRolePopupOpen, toggleModal:toggleNewSharedItemPopup} = useModal();
  const {isOpen: isMenuOpen, toggleModal: toggleMenu} = useModal();

  let dataSources = {
    'notificationsTypes' : {
      'hourly':'Hourly Report',
      'daily_digest':'Daily Digest',
      'immediate':'Immediate',
    },
    'permissions':{
      'activity_admin':'Manage Activity',
      'add_review':'Add Comments',
      'add_checklist':'Create Checklists',
      'add_step_activity':'Create Step Activities',
    },
    'roleType':{}
  }

  useEffect(() => {  
    requestParams = {
      'page': 1, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'journey_profile_id':params.journey_profile_id,
      'shareable_item_id': params.shareable_item_id,
      'shareable_item_type': params.shareable_item_type,
    }

    getSharedItemList();
  }, [props.searchString]);

  let getSharedItemList = () => { 
    SharedItemService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       //roles = res.data.roles;
       //dataSources.roleType = roles.reduce((obj, item) => (obj[item.record_id] = item.role, obj) ,{}); 
       invitedJourneyMembers = res.data.members;
       setSharedItems(res.data.shared_items) 
       res.data.shared_items.length == 0 && toggleHelpMessage()
      }
    })
  }

  const deleteSharedItem = (e) => {
    SharedItemService.delete(selectedSharedItems.id).then((res) => {
      if(res.status==204){
        setSharedItems(sharedItems.filter(r => r.record_id !== selectedSharedItems.record_id));
      }
    })    
    toggleDeletePopup(e)
  }

  const setEdit = (e, shared_item) => {
    newSharedItems = shared_item;
    toggleNewSharedItemPopup(e);
  }
  
  const setDelete = (e, shared_item) =>{
    e.preventDefault();
    selectedSharedItems = shared_item;
    toggleDeletePopup(e);
  }

  const addSharedItem = (e) => {
    e.preventDefault();
    if(newSharedItems.id){
      SharedItemService.update(newSharedItems).then((res) => {
        if(res.status==204){
          restModal(e);
        }
      })  
    }else{
      newSharedItems.created_by = currentUser.user.id;
      newSharedItems.journey_profile_id = params.journey_profile_id;
      newSharedItems.shareable_item_id = params.shareable_item_id;
      newSharedItems.shareable_item_type = params.shareable_item_type;

      SharedItemService.create(newSharedItems).then((res) => {
        if(res.status==201){
          newSharedItems.id = res.data.shared_items.id;
          setSharedItems([...sharedItems, newSharedItems])
          restModal(e);
        }
      })  
    }
  }

  const restModal = (e) =>{
    newSharedItems = {};
    toggleNewSharedItemPopup(e);
    //getSharedItemList();
  }

  /*const updateActivityPrivacy = (e) => {
    e.preventDefault();
    let req = {
      'id':activity.id,
      'privacy_type':(activity.privacy_type=='public'?'private':'public'),
      'journey_profile_id':activity.journey_profile_id,
      'education_journey_profile_id':activity.education_journey_profile_id
    }
    let Service = {}
    if(params.shareable_item_type == 'edu_team_journey.activity'){
      Service = TeamActivityService
    }else if(params.shareable_item_type == 'education_journal_entry'){
      Service = ActivityService
    }else if(params.shareable_item_type == 'edu_college_journey.activity'){
      Service = CollegeActivityService
    }else if(params.shareable_item_type == 'academic_courses.course_preparation'){
      Service = CoursePreparationsService
    }

    Service.update(req).then((res)=>{
      if(res.status==204){
        props.location.state.activity.privacy_type = req.privacy_type;
        setPrivacyType(req.privacy_type);
      }
    })
  }*/

  const showMenu = (e, a) => {
    selectedSharedItems = a;
    toggleMenu(e);
  }

  const menuItems = ({ item }) => {
    return (
      <Fragment>
        {CheckAccess.hasEditAccess(currentUser, journeyProfile, item) &&
          <a href="#" onClick={e => setEdit(e, item)}>
            <i className="far fa-edit font-15" aria-hidden="true"></i>
            <span className="font-13">Edit</span>
            <i className="fa fa-angle-right"></i>
          </a>
        }

        {CheckAccess.hasDeleteAccess(currentUser, journeyProfile, item) &&
          <a href="#" onClick={e => setDelete(e, item)}>
            <i className="far fa-trash-alt font-15" aria-hidden="true"></i>
            <span className="font-13">Delete</span>
            <i className="fa fa-angle-right"></i>
          </a>
        }
      </Fragment>
    )
  }

  const Menu = ({ item }) => (
    <i data-tip="Menu" onClick={(e) => showMenu(e, item)} className="fas fa-bars m-r-15"/>
  )

  /*const Menu = ({role}) => (
    <div className="dropup pull-right m-r-15">
    <button className="dropbtn">Menu</button>   
       <div className="dropup-content">
        <a href="#" onClick={e => {setEdit(e, role)}}>Edit</a>
        <a href="#" onClick={e => {toggleDeletePopup(e, role)} }>Delete</a>
      </div>
    </div>
  )*/

 /* if(sharedItems.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/

  return (
    <Fragment>
      <div className="content m-t-15 clearfix">
        <div className="col-xs-8">
          <JourneyTitle title={"Activity Permissions"} project={journeyProfile} />
        </div>
        <div className="col-xs-4 pull-right">
          <a href="#" className="bg-highlight round-btn pull-right m-r-5 m-t-4" onClick={e=>restModal(e)}>  
            <i className="fas fa-plus"></i>
          </a>
          <span href="#" data-tip="Toggle Table" className="bg-highlight round-btn pull-right m-r-5 m-t-4" 
            onClick={e=>setShowTableView(!showTableView)}>
            <i className="fas fa-table"></i>
          </span>
          <span onClick={e=>props.history.goBack()} className="bg-highlight round-btn pull-right m-r-5 m-t-4">
            <i className="fas fa-home"></i>
          </span>
          <div className="clear"></div>
        </div>
      </div>

      <div className="page-content row">
        
        {!showTableView &&
          <Fragment>          
            {sharedItems.map((s, i) => (
              <div className="col-xs-12 col-sm-6 col-md-4 user-list-container" key={i}>
                <SharedItem sharedItem={s} menu={Menu} setEdit={setEdit}
                dataSources={dataSources} roleType={dataSources.roleType} />

                {selectedSharedItems.id == s.id && 
                  <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenu}
                  item={selectedSharedItems} type="shared_item"
                  MenuItems={menuItems} menuHeight="200px"/>
                }
              </div>  
            ))}
          </Fragment>  
        }
      </div>

      <ConfirmMenu isOpen={isDeletePopupOpen} toggleModal={toggleDeletePopup} success={deleteSharedItem}/>

      {isNewRolePopupOpen && <ShareItemModal sharedItem={newSharedItems} isOpen={isNewRolePopupOpen} 
      toggleModal={toggleNewSharedItemPopup} addSharedItem={addSharedItem} 
      dataSources={dataSources} invitedJourneyMembers={invitedJourneyMembers}/>}
    
      {isHelpMessageOpen && <GenericModal component={NotificationPopup} header="Note"
          message={HelpMessageList.team_activity_role} onSuccess={toggleHelpMessage}
          isOpen={isHelpMessageOpen} toggleModal={toggleHelpMessage}/>
      } 
    </Fragment>
  )

}

export default SharedItemsList;

/*
 {showTableView &&
          <div className="col-xs-12">
            <UsersRolesListTable sharedItemsList={sharedItems} menu={Menu} setEdit={setEdit}
            dataSources={dataSources} getSharedItemList={getSharedItemList}
            setDelete={setDelete} requestParams={requestParams} />
          </div>
        }
*/

/*

 {showTableView &&
          <div className="col-xs-12">
            <UsersRolesListTable sharedItemsList={sharedItems} menu={Menu} setEdit={setEdit}
            dataSources={dataSources} getSharedItemList={getSharedItemList}
            setDelete={setDelete} requestParams={requestParams} />
          </div>
        }
        
<div className="col-xs-12">
          <div className="bg-white p-5">
            <label className="radio-container">
              <span>Restrict access to this tile based on the settings below.</span>
              <input type="radio" onClick={e=>updateActivityPrivacy(e)} 
              checked={privacyType=='private'} readOnly/>
              <span className="radio-checkmark"></span>
            </label>

            <label className="radio-container">
              <span>Allow access to this tile and posting of comments by any users with access to this journey ribbon.</span>
              <input type="radio" onClick={e=>updateActivityPrivacy(e)}  
              checked={privacyType=='public'} readOnly/>
              <span className="radio-checkmark"></span>
            </label>
          </div>
        </div>
*/