import React, { Fragment, useState ,useRef, useContext, useEffect} from "react";
import querystringify from "querystringify";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import AuthenticationService from "../../services/AuthService";
import moment from "../../../node_modules/moment/min/moment.min.js";
import useStyle from "../../hooks/useStyle";
//import PushNotification from "../../components/PushNotification/PushNotification";
import { Link } from "react-router-dom";
import HamBar from "./HamBar";
import CommonService from "../../services/CommonService";

export const Login = (props) => {
  useStyle('signin');  

  const { state: authState, dispatch } = useContext(AuthContext);

  /*const getEmail = () => {
    if (localStorage.checkbox && localStorage.email !== "") {
     return localStorage.email
    }else{
      return ""
    }
  };

  const getPassword = () => {
    if (localStorage.checkbox && localStorage.password !== "") {
     return localStorage.password
    }else{
      return ""
    }
  };*/

  /*const getChecked = () => {
    if (localStorage.checkbox && localStorage.checkbox !== "") {
     return localStorage.checkbox
    }else{
      return false
    }
  };*/

  const userInitialState = {
    email: '',
    password: '',
    isChecked: '',
    isSubmitting: false,
    errorMessage: null,
  };

  const [user, setUser] = useState(userInitialState);
  const [count, setCount] = React.useState(Math.random())

  const handleInputChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setUser({
      ...user,
      isSubmitting: true,
      errorMessage: null,
    });
    
    let req = { user: { email: user.email, password: user.password } };
    AuthenticationService.signin(req)
      .then((res) => {
        if (res.status == 201) {
          dispatch({
            type: "LOGIN",
            payload: res.data.data,
          });
          routeUser(res.data.data)
        } else {
          setUser({
            ...user,
            isSubmitting: false,
            errorMessage: res.data.error || res.statusText,
          });
        }
      })
      .catch((error) => {
        setUser({ ...user, errorMessage: error.response.data.error });
      });
  };

  const routeUser = (login) => {
    /*if(login.user.accelerator){
      props.history.push('/dashboard');
    }else{
      props.history.push('/accelerator/profile');
    }*/
    if(!login.user.accelerator){
      props.history.push('/accelerator/profile');
      return null
    }else{
      props.history.push('/home');
    }

    /*if(login.user.accelerator.data.org_type == 1){ //Accelerator
      props.history.push('/dashboard');
    }else if(login.user.accelerator.data.org_type == 2){ //manu
      props.history.push('/companydashboard');
    }else if(login.user.accelerator.data.org_type == 3){ //publisher
      //props.history.push('/companydashboard');
    }else if(login.user.accelerator.data.org_type == 4){ //service provider
      props.history.push('/companydashboard');
    }else{
      props.history.push('/home');
    }*/
  }
  
  useEffect(() => {
    if(authState.isAuthenticated){
      routeUser(authState);
    }
  }, [authState.isAuthenticated]);

  return (
    <Fragment>
    <HamBar className="hidden"/>
    <div className="page-content">
      <div className="page-title-bg bg-20 ht-150" />
      <div className="page-title-bg dark-mode-tint ht-150" />
      <div className="page-title-bg opacity-90 bg-highlight ht-150" />
      <div className="page-title-small color-white bottom-30">
		  <h1>Sign In</h1>
		</div>
    <form className="signform">
      <div className="content-boxed content-boxed-full left-20 right-20 shadow-large">
        <div className="content bottom-20">
          <div className="input-style has-icon input-style-1 input-required">
            <i className="input-icon fa fa-user font-11" />
            <span>Email</span>
            <em>(required)</em>
            <input type="email" placeholder="email" name="email" onChange={handleInputChange}
                value={user.email}/>
        </div>
        <div className="input-style has-icon input-style-1 input-required">
          <i className="input-icon fa fa-lock font-11" />
          <span>Password</span>
          <em>(required)</em>
          <input type="password" placeholder="password" name="password" onChange={handleInputChange}
            value={user.password}/>
        </div>
        {user.errorMessage && (
          <span className="error">{user.errorMessage}</span>
        )}

        <div className="clear" />
        <button
          onClick={handleFormSubmit}
          className="button button-full button-m shadow-large button-round-small bg-green1-dark top-30 bottom-0"
        >
          Sign In
        </button>
        <div className="ht-2 lblack top-30" />
        <div className="display-inline" style={{ width: "100%", textAlign: "center" }}>
          <div className="three-half "></div>
          <div className="three-half">
            <a href="/forget_password"
                className="text-right font-11 color-theme opacity-50">
                    Forgot Password
            </a>
          </div>
        </div>
        <div className="clear" />
      </div>
      </div>
    </form>
    <div className="signinbg">
      <div className="signincon">
        <img
          src={"./../../../images/cueTree_logo.png"}
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            width: "50%",
          }}
        />
        <h1 className="m-t-10">ECN</h1>
        <h3 className="m-t-10">Essential Community Networks</h3>
        <p className="m-t-10 f-16">
          Transformative outcomes from journey collaboration
        </p>
        <p className="m-t-10 f-16">
          Enabling unique connections among organizations, accelerators, investors, services providers and other digital transformers        
        </p>
        <div className="font-18 m-t-10 button button-full button-m shadow-large button-round-small bg-black white top-30 bottom-0">
          {CommonService.displayRandomPhrase(Math.floor(4*count))}
        </div>
      </div>

      <div className="signcopy hidden-xs">
        <div className="content bottom-10 social-sharing">
          <a className="shareToTwitter icon icon-xs icon-round bg-twitter regularbold">
            <i className="fab fa-twitter"></i>
          </a>
          <a className="shareToTwitter icon icon-xs icon-round bg-instagram regularbold">
            <i className="fab fa-instagram"></i>
          </a>
          <a className="shareToFacebook icon icon-xs icon-round bg-facebook regularbold">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a className="shareToWhatsApp icon icon-xs icon-round bg-linkedin regularbold">
            <i className="fab fa-linkedin"></i>
          </a>
          <a className="shareToWhatsApp icon icon-xs icon-round bg-whatsapp regularbold">
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
          <i className="far fa-copyright"></i> {new Date().getFullYear()} IPIPAL INC. All Rights Reserved.
          <label className="m-l-5 text-muted">
            <Link className="font-12 text-muted m-r-10" to="/about_us/terms_of_service">&nbsp;Terms of Service</Link> 
            <Link className="font-12 text-muted" to="/about_us/privacy_policy">&nbsp;Privacy Policy</Link>
          </label>
        </div>
      </div>
        <div className="footer" data-footer-load="menu-footer.html" />
      </div>    </Fragment>
  );
};

export default Login;