import React, { useState } from "react";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import Marketing from "../../../components/Marketing/List/Marketing";
import MarketingService from "../../../components/Marketing/MarketingService";
import Pagination from "../../Pagination";
import { Link } from "react-router-dom";
import ConfirmMenu from "../../Modals/ConfirmMenu";
import useModal from "../../../hooks/useModal";
import FormTemplateId from "../../../constants/FormTemplateList";
import useStyle from "../../../hooks/useStyle";


let requestParams = {};
let selectedOrg = {};


export const MarketingList = (props) => {
  
  //useStyle('card');
  console.log("forums", props);
  const { state: authState, dispatch } = React.useContext(AuthContext);

  const [forumList, setForumList] = useState([]);


  const fn = {}; //common shareable object for all child element

  fn.deleteOrg = (mark) => {
    //Delete
   // deleteOrganization(mark)
    console.log('mark',mark)
  };

  const { isOpen , toggleModal, success } = useModal(fn.deleteOrg);

 /* let deleteOrganization = (organization) => {
    MarketingService.delete(organization).then((res) => {
      if (res.status == 200) {
        console.log("delete company", res);
      
      }
    })
  }*/

  const onDelete = (e, mark) => {
    console.log('delet marketing',e,mark)
    selectedOrg = mark;
    toggleModal(e);
  }


  React.useEffect(() => {
    requestParams = {
      content_type:'marketing_content',
      page:1,
      paginate:1,
      per_page:10,
      sort_column:'updated_at',
      sort_direction:'desc'
    };

    getForumsList();
  }, []);

  let getForumsList = () => {
    MarketingService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        console.log("res data forums", res);
        setForumList(res.data.digital_contents);
      }
    });
  };


  return (
    <React.Fragment>
      <div className="content top-10">
        <h5 className="float-left font-500">Your ECN Marketing</h5>
        <Link to="/marketing/create" className="bg-highlight round-btn float-right m-r-5">
          <i className="fas fa-plus"></i>
        </Link>

        <div className="page-content row">
          {forumList.map((mark, i) => (
            <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
              <Marketing marketing={mark} toggleModal={onDelete} />
            </div>
          ))}
        </div>
      </div>
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleModal} success={success}/>
    </React.Fragment>
  );
};

export default MarketingList;

/*<Link to={`/track/relationship/${mark.id}/organization`}>Track Relationship</Link>*/
