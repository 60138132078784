import React, {useContext} from "react";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import { Link } from "react-router-dom";
import GenericModal from "../../../Modals/GenericModal";
import useModal from "../../../../hooks/useModal";
import PortalSideMenu from "./PortalSideMenu";

const JourneyInlineMenu = (props) => {
	const {state: { user: currentUser }, dispatch,} = useContext(AuthContext);

	let {journey} = props
  let {data} = journey

  const { isOpen:isNotesOpen, toggleModal:toggleNotesModal } = useModal()
  	
	return(
	  <>	
		  <Link className="round-btn white m-l-15" to={`/health/form/${journey.form_id}/${journey.id}`}>
        <i className="fas fa-edit" />
      </Link>
      <span className="display-inline white m-l-15" onClick={(e)=>toggleNotesModal(e)}>
        <i className="fas fa-comment" />
      </span>
    </>
  )
}

const PortalToggleMenu = (props) => {
	const { isOpen: isSideNavOpen, toggleModal: toggleOpenSideNav } = useModal();

	if(isSideNavOpen){
	  return(
		  <PortalSideMenu openSideNav={isSideNavOpen} setOpenSideNav={toggleOpenSideNav} 
        {...props}
      />
	  )
	}
    
  return (
    <span onClick={(e) => toggleOpenSideNav(e)} className="pathbutton-page zIndex">
      <i className="fa fa-bars p-5" data-tip="Access Advanced Child Nutrition Journey Tools" aria-hidden="true"/>
    </span>
  )
}

export {JourneyInlineMenu, PortalToggleMenu};