import React from "react";
import {Link} from "react-router-dom";
import Rating from "../../../../components/Rating"
import moment from '../../../../../node_modules/moment/min/moment.min.js'
import FormTemplateList from "../../../../constants/FormTemplateList"

const Advisor = (props) => {

  let advisor = props.advisor;
  let data = advisor.data;
  const cardStyle = {
    borderTop: '5px solid '+ (advisor.data_source.status ? advisor.data_source.status.bg_color :'#b5adad')
  };
  
  return (
    <div className="content-boxed shadow-small" style={cardStyle}>
     <div className="content vcard-header">
          <ul className="d-flex">
          <li>
            <img className="rounded-circle bg-highlight" src='' width="50px"/>
          </li>
          <li className="m-l-15" style={{width:'60%'}}>
              <div className="vcard-title">{data.name ? data.name : '-'}</div>
              <span>since {moment(advisor.updated_at).format('MMM DD, YYYY')}</span>
          </li>
          <li style={{right: "5px",position: "absolute"}}>
          <i className="fas fa-expand pull-right expand" onClick={e=>props.routeActivity(advisor)}></i>
          </li>
        </ul>
        <div className="divider bottom-20" style={{ backgroundColor: 'grey'}}/>
        </div>

      <div className={`scroll-auto ${props.cardHeightCss}`}>
      <table className="table card-table">
          <tbody>
              <tr>
                <td>
                  <span className="pull-right"><strong>Industry</strong>: {advisor.data_source.industry.label}</span>
                  <strong>Office</strong>
                  <div>{data.street_1}<br/>{data.street_2}<br/>{data.city}<br/> {data.state}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Rating</strong>
                  <Rating noOfRating={5} sumOfRating={20} />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Link</strong>
                  <a href={data.url}>{data.url}</a>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Mobile</strong>
                  <a href="tel:+{data.telephone}">+{data.telephone}</a>
                </td>
              </tr>
            </tbody>
        </table>
      </div>
      <div className="card-footer">
        <div className="pull-left">
        <props.details ad={advisor} />
        <span className="m-l-15 ">{FormTemplateList.AdvisorRegistration} - {advisor.record_id}</span>
          <span className="m-l-25" onClick={e=>{props.addNotes(e, advisor);}}>
            <i className="far fa-comment font-18 text-muted"></i>
            <span className="badge up bg-lgrey">{advisor.comments_count}</span>
          </span>
          <span className="m-l-25" onClick={e=>{props.addChecklist(e, advisor);}}>
            <i className="far fa-check-circle font-18 text-muted"></i>
            <span className="badge up bg-lgrey">{advisor.checklists_count}</span>
          </span>
          <span className="m-l-25">
            <Link to={`/incoming/emails/advisor/123`}>
              <i className="fas fa-envelope font-18 text-muted"></i>
              <span className="badge up bg-lgrey">0</span>
            </Link>
          </span>
        </div>
        <div className="pull-right">
          <props.menu ad={advisor} toggleModal={props.toggleModal}/>
        </div>
      </div>  
    </div>
  );
};

export default Advisor;
