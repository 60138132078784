import React, { Fragment, useEffect, useRef, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactDOM from 'react-dom';
import env from "../../../../env"
import JourneyProfileService from "../../JourneyProfileService";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import Cs from "../../../../services/CommonService";
import ReactTooltip from "react-tooltip";

let portalPath = null;

const PortalSideMenu = (props) => {
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const sideNavEl = useRef(null)
  const sideNavMenuEl = useRef(null)
  const history = useHistory()

  let {journeyProfileId, child, journeyType, menuDsId, openSideNav, setOpenSideNav} = props
  const permission = currentUser.permission?.permissions?.infant_journey ?? {}

  const [journey, setJourney] = useState()

  useEffect(() => { 
    let req = {
      'id':journeyProfileId, 
      'data_source_params':{
        "data_source_id":[menuDsId]
      }
    }

    JourneyProfileService.get(req).then((res)=>{
      if(res.status == 200){
        res = res.data
        const dsKey = Object.keys(res.data_source_list)
        portalPath = res.data_source_list[dsKey].options
        portalPath.push({
          sid:'portal',
          label:'Journey portal page',
          icon:'fa-cog',
          icon_color: 'rgb(28, 146, 232)'
        })
        setJourney(res.journey_profile)
      }
    })

  }, [])

  useEffect(() => {
    ReactTooltip.rebuild()
    document.addEventListener("keydown", handleEscape);
    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("click", handleClick, true);
    }
  }, []);

  const handleEscape = (e) => {
    if (e.keyCode === 27) setOpenSideNav(false);
  }

  const handleClick = (e) => {
    if (sideNavEl.current && !(sideNavEl.current.contains(e.target) || sideNavMenuEl.current.contains(e.target))) {
      setOpenSideNav(false);
    }
  }

  /*const permission = {
    guides: () => {
      return env.admin_emails.includes(currentUser.email) ? true:false
    }
  }*/

  const Icon = ({icon, icon_color, path}) =>{
    if(permission[path.sid] || true){
      return(
        <Fragment>
          <a onClick={e=>routePath(e, path)}>
            <i className={'fa '+icon} style={{color:icon_color}}/>
            <span data-tip={path.hover_text}>{path.label}</span>
            <i className="fa fa-angle-right" />
          </a>
          <ReactTooltip place="bottom" />
        </Fragment>
      )  
    }else{
      return null
    }
  }

  const Icons = ({icons}) => icons && icons.map((p, i) => (
    <Icon icon={p.icon} icon_color={p.icon_color} path={p}  key={i}/>
  ))

  const routePath = (e, path) => {
    e.stopPropagation();
  }

  return (ReactDOM.createPortal(
    <div id="menu-main" ref={sideNavEl}
      className={`menu pos-fixed right-0 menu-box-path menu-box-detached round-medium ${openSideNav ? "menu-active" : ""} `}
      style={{ marginTop: '40px', width: 260, opacity: 1, display: "block", 'lineHeight': '48px', 'minHeight': '500px' }}>
        
        <div className="menu-icons">
          <p className="under-heading center-text font-800 font-16">
            Journey Menu

            <a href="#" className="close-menu"
              onClick={(e) => {e.preventDefault();setOpenSideNav(false)}}
              style={{'marginBottom':'unset', float: 'right'}}>
                <i className="fa fa-times color-red2-dark" />
            </a>
          </p>
          
          <div className="clear" />
        </div>

        <div className="content top-20 bottom-10">
          <div className="link-list link-list-1" ref={sideNavMenuEl}>
            {journey?<Icons icons={portalPath}/>:<div className="spinner"/>}
          </div>
        </div>
    </div>, document.body)
  )
}

export default PortalSideMenu;