import React, {useState, useEffect, Fragment} from "react";
import {Link} from "react-router-dom";
import ProjectService from "../../../../components/Organizations/Projects/ProjectService";
import Project from "../../../../components/Organizations/Projects/List/Project"
import useModal from '../../../../hooks/useModal';
import ConfirmMenu from '../../../Modals/ConfirmMenu'
import ProjectMenu from '../../../Modals/ProjectMenu'
import useStyle from '../../../../hooks/useStyle';
import JourneyFilesLinksModal from "../../../JourneyFilesLinks/JourneyFilesLinksModal"

let requestParams = {}
let selectedProject = {};

const ProjectList = (props) => {
  useStyle('card');
  useStyle('card_table');
  useStyle('add_role_dialog');

  const params = props.match.params;
  
  const [projects, setProjects] = useState([]);
  const fn = {} //common shareable object for all child element

  React.useEffect(() => {  
    requestParams = {
      'page': 1, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'organization_id':params.organization_id
    }

    getProjectsList();
  }, [params.organization_id, props.searchString]);

  fn.deleteOrg = (project) => {
    ProjectService.delete(project.id).then((res) => {
      if (res.status == 204) {
        setProjects(projects.filter(i => i.id !== project.id))
      }
    });
  }
  
  const {isOpen, toggleModal, success} = useModal(fn.deleteOrg);
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal, success:menuSuccess } = useModal();
  const { isOpen:isFileLinkOpen, toggleModal:toggleFileLinkModal } = useModal();

  const onDelete = (e, project) => {
    console.log('project delete',e,project)
    selectedProject = project;
    toggleModal(e,project);
  }

  const getProjectsList = () =>{
    ProjectService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       res = res.data;
       setProjects(res.projects)
      }
    })
  }

  const openFileLinks = (e) =>{
    e.preventDefault()
    toggleFileLinkModal(e)
  }

  const addMenu = (e, project) => {
    selectedProject = project;
    toggleMenuModal(e);
  }

  const Menu = ({ project, fn }) => (
    <div className="dropup card-menu-right">
            <i onClick={e=>{addMenu(e, project)}} class="fas fa-bars" />  
    </div>
  )


  const menuItems = ({item}) =>{
    console.log('meunitmes project list',item)
    return(
      <Fragment>
        <Link to={`/organization/new/project/${item.organization_id}/${item.id}`}>
          <i className="fa fa-file-contract"></i>
          <span className="font-13">Edit</span>
          <i className="fa fa-angle-right"></i>
        </Link>
        <Link to="/" onClick={(e) => {onDelete(e, item);
            toggleModal(e);
           // console.log('e,org',success);
           // deleteOrganization(org);
          }}>
        <i className="fa fa-trash"></i>
        <span className="font-13">Delete</span>
        <i className="fa fa-angle-right"></i>
            </Link>
        <Link to={`/risk/assessment/${item.id}/project/${item.risk_assessment && item.risk_assessment.id}`}>
          <i className="fa fa-retweet"></i>
          <span className="font-13">Assess Risk Profile</span>
          <i className="fa fa-angle-right"></i>
        </Link>
      </Fragment>
    )
  }

  /*const Menu = ({project, fn}) => (
    <div className="dropup card-menu-right">
      <button className="dropbtn">Menu</button>
      <div className="dropup-content">
        <Link to={`/organization/new/project/${project.organization_id}/${project.id}`}>Edit</Link>
        <Link to="/" onClick={e => {toggleModal(e, project)} }>Delete</Link>
        <Link to={`/risk/assessment/${project.id}/project/${project.risk_assessment && project.risk_assessment.id}`}>Assess Risk Profile</Link>
      </div>
    </div>
  )*/

  const projectsCard = projects.map((project, i) =>
    <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
      <Project project={project} menu={Menu}/> 
      {selectedProject.record_id == project.record_id && 
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedProject} 
        addDelete={onDelete} type="organization" 
        MenuItems={menuItems}/>}
    </div>
  )

  return (<Fragment>
  <div className="content top-10">
    <h5 className="float-left font-500">Project Lists</h5>
    <Link to={`/organization/new/project/${params.organization_id}`} className="bg-highlight round-btn float-right m-r-5">  <i className="fas fa-plus"></i></Link>
    <a href="#" onClick={e=>openFileLinks(e)} data-tip="Journey Files and Links" className="bg-highlight round-btn float-right m-r-5">
              <i className="fas fa-link"/>
            </a>
    <div className="clear"></div>
  </div>
  {projectsCard}
  <ConfirmMenu isOpen={isOpen} toggleModal={toggleModal} success={success}/>
  {isFileLinkOpen && <JourneyFilesLinksModal toggleModal={toggleFileLinkModal} />}
  </Fragment>
  )
};

export default ProjectList;