import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";

const Logo = (props) => {
  const { state: currentUser, dispatch, isAuthenticated: isAuthenticated } = useContext(AuthContext);

  return (
    <Fragment>
      <div className="logo-container">
        <Link to={isAuthenticated?'/home':'/signin'}>
          <img src={"./../../../images/cueTree_logo.png"} alt="logo-img"
			      style={{width:"100px",height:"100px"}}/>
        </Link>
      </div>
    </Fragment>
  )
}

export default Logo;
