import React, {useState} from "react";
import ReactTooltip from 'react-tooltip';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import Milestone from '../../../components/Organizations/ProjectMilestones/Milestone';
import ContractsService from "../../../components/Contracts/ContractsService";
import ProjectMilestoneService from "../../../components/Organizations/ProjectMilestones/ProjectMilestoneService";
import PortalHeader from "../../../components/Organizations/ProjectPortalPage/PortalHeader";
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu";
import {Link} from "react-router-dom";
import useModal from "../../../hooks/useModal";
import FormTemplateId from '../../../constants/FormTemplateList'
import useStyle from '../../../hooks/useStyle';

let requestParams = {};
let project = null;
let selectedContract = {};
let milestones = null;

const MilestoneList = (props) => {
  useStyle('panel');
  useStyle('card');
  useStyle('card_table');

  const { state:currentUser, dispatch } = React.useContext(AuthContext);
  const [contractList, setContractList] = useState([]);
  const [currentpage,setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);

  React.useEffect(() => {
    requestParams = {
      'page': currentpage, 
      'per_page' : 1,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'accelerator_id': currentUser.user.accelerator.id,
      'contract_type':'project_work_order',
      'project_id':props.match.params.project_id,
    }

    getContractList();
  }, [currentUser.user.accelerator, props.searchString,currentpage]);

  let getContractList = () => { 
    ContractsService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       project = res.data.project;
       milestones = res.data.milestones;
       setPagemeta(res.data.meta)
       setContractList(res.data.contracts)
      }
    })
  }

  const Menu = ({contract}) => 
    <div className="dropup card-menu-right" style={{bottom: "0px"}}>
      <button className="dropbtn"><i className="fas fa-bars"/> </button>
      <div className="dropup-content">
        <Link to={`/project/milestone/form/${project.organization_id}/${contract.project_id}/${contract.id}`}>Add Milestones</Link>
        <Link to={`/form/422`}>Final Assessment</Link>
      </div>
    </div>

  if(!project){
    return null;
  } else { 
  return (
    <React.Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-8">
        <div className="font-16 bold-600">
  	    {project.data.name}
   		</div>
    	<div className="font-15">
      Project Milestones
        <span><i className="fa fa-question-circle m-l-5"></i></span>
      </div>        
        </div>
        <div className="col-xs-4"> 
          <Link to={`/organization/project/portal/${project.id}`} className="bg-highlight round-btn float-right m-r-5">
            <i className="fas fa-home"/>
          </Link>
          <ReactTooltip place="left" />
        </div>
      </div>
      <div className="page-content row">
        {contractList.map((c, i) => (
          <div className="col-xs-12" key={i}>
            <Milestone contract={c} menu={Menu} project={project} milestones={milestones}/>
          </div>
        ))}   
      </div>
    </React.Fragment>
  )
  }
};

export default MilestoneList;