import React, {useState, useEffect, Fragment} from "react";
import {Link} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import ServiceRequestService from "../../../../components/Organizations/ProjectServiceRequests/ServiceRequestService";
import ServiceRequest from "../../../../components/Organizations/ProjectServiceRequests/List/ServiceRequest"
import PortalHeader from "../../../../components/Organizations/ProjectPortalPage/PortalHeader";
import useModal from '../../../../hooks/useModal';
import ConfirmMenu from '../../../Modals/ConfirmMenu'
import ProjectMenu from '../../../Modals/ProjectMenu'
import useStyle from '../../../../hooks/useStyle';

let requestParams = {}
let project = {};
let selectedService = {};

export const ServiceRequestList = (props) => {
  useStyle('panel')
  useStyle('card')
  useStyle('card_table');

  
  const params = props.match.params;
  
  const [serviceRequests, setServiceRequests] = useState([]);
  const fn = {} //common shareable object for all child element

  React.useEffect(() => {  
    requestParams = {
      'page': 1, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'project_id':params.project_id
    }

    getServiceRequestList();
  }, [params.project_id, props.searchString]);

  fn.deleteOrg = (org) => {
    //Delete
  }
  
  const {isOpen, toggleModal, success} = useModal(fn.deleteOrg);
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal, success:menuSuccess } = useModal();


  const getServiceRequestList = () =>{
    ServiceRequestService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       res = res.data;
       project = res.project;
       setServiceRequests(res.project_service_requests)
      }
    })
  }

  if(!project.id)return('');

  const Menu = ({ service_request, fn }) => (
    <span className="m-r-15" >
        <i onClick={e=>{addMenu(e, service_request)}} class="fas fa-bars" />  
    </span>
  )

  const addMenu = (e, service_request) => {
    selectedService = service_request;
    toggleMenuModal(e);
  }

  const onDelete = (e, service_request) => {
    console.log('project delete',e,service_request)
    selectedService = service_request;
    toggleModal(e,service_request);
  }

  /*const Menu = ({service_request, fn}) => (
    <div className="dropup card-menu-right">
      <button className="dropbtn">Menu</button>
      <div className="dropup-content">
        <Link to={`/project/search/service_request/${project.organization_id}/${service_request.project_id}/${service_request.id}`}>Edit</Link>
        <Link to="/" onClick={e => {toggleModal(e, service_request)} }>Delete</Link>
        <Link to={`/risk/assessment/${service_request.id}/project_service_request/${service_request.risk_assessment_id}`}>Assess Risk Profile</Link>
      </div>
    </div>
  )*/

  const menuItems = ({item}) =>{
    return(
      <Fragment>
       <Link to={`/project/search/service_request/${item.organization_id}/${item.project_id}/${item.id}`}>
            <i className="fa fa-file-contract"></i>
            <span className="font-13">Edit</span>
            <i className="fa fa-angle-right"></i>
        </Link>
        <Link to="/" onClick={(e) => {toggleModal(e, item);}}>
          <i className="fa fa-trash"></i>
          <span className="font-13">Delete</span>
          <i className="fa fa-angle-right"></i>
        </Link>
        <Link to={`/risk/assessment/${item.id}/project_service_request/${item.risk_assessment_id}`}>
          <i className="fa fa-retweet"></i>
          <span className="font-13"> Assess Risk Profile</span>
         <i className="fa fa-angle-right"></i>
        </Link>
      </Fragment>
    )
  }

  const ServiceRequestView = () => (
    <Fragment>
      {serviceRequests.map((service_request) =>(
        <div className="col-xs-12 col-sm-6 col-md-4" key={service_request.record_id}>
          <ServiceRequest service_request={service_request} menu={Menu} project={project}/> 
          {selectedService.record_id == service_request.record_id && 
            <ProjectMenu isOpen={isMenuOpen}  toggleModal={toggleMenuModal} item={selectedService} 
            addDelete={onDelete} type="assessment" 
            MenuItems={menuItems}/>}
        </div>
      ))}
    </Fragment>
  )

  return (
  <Fragment>
     <div className="content top-10 clearfix">
        <div className="col-xs-8">
        <div className="font-16 bold-600">
  	    {project.data.name}
   		</div>
    	<div className="font-15">
      Service Requests
        <span><i className="fa fa-question-circle m-l-5" ></i></span>
      </div>        
        </div>
        <div className="col-xs-4"> 
        <Link to={`/project/search/service_request/${project.organization_id}/${params.project_id}`} className="bg-highlight round-btn float-right m-r-5">  <i className="fas fa-plus"></i></Link>
        <Link to={`/organization/project/portal/${project.id}`} className="bg-highlight round-btn float-right m-r-5">
            <i className="fas fa-home"/>
          </Link>
          <ReactTooltip place="left" />
        </div>
      </div>

      <div className="page-content row">
            <ServiceRequestView />
       </div>
    <ConfirmMenu isOpen={isOpen} toggleModal={toggleModal} success={success}/>
  </Fragment>
  )
};

export default ServiceRequestList;