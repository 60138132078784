import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import {Link} from "react-router-dom";

const AddDataSource =  memo(({ data, isConnectable }) => {
  return (
    <>
      <div onClick={e=>data.addActivity(data)}>
        <Handle type="target" position={data.targetPosition} style={{ borderRadius: 0 }} />
        <span className="fas fa-plus font-14 add-btn bg-lblack"/>
        <div className="datasource-node">
          {data.label}
        </div>
      </div>

      <Handle type="source"  position={data.sourcePosition} id="a" style={{borderRadius: 0 }}/>
    </>
  )
})

const AddActivity =  memo(({ data, isConnectable }) => {
  return (
    <>
      <div className="wk-center">
        <Handle type="target" position="top" style={{ borderRadius: 0 }} />
        <Link to={`/baseline/${data.journey_profile_id}?id=${data.id}`} >
          <span className="fa-stack fa-2x">
            <i className="fas fa-circle fa-stack-2x" style={{color:data.bg_color}}></i>
            <i className={`fa-stack-1x fa-inverse ${data.icon}`}></i>
          </span>
          <div className="black">{data.label || '-'}</div>
        </Link>
      </div>
      <Handle type="source" position="bottom" id="a" style={{borderRadius: 0 }}/>
    </>
  )
})

const AddStepActivity =  memo(({ data, isConnectable }) => {
  return (
    <>
      <div className="wk-center">
        <Handle type="target" position="top" style={{ borderRadius: 0 }} />
        <Link to={`/baseline/${data.journey_profile_id}?id=${data.activity_id}`} >
          <span className="fa-stack fa-2x">
            <i className="fas fa-circle fa-stack-2x" style={{color:data.bg_color}}></i>
            <i className={`fa-stack-1x fa-inverse ${data.icon}`}></i>
          </span>
          <div className="black">{data.label || '-'}</div>
        </Link>
      </div>
      <Handle type="source" position="bottom" id="a" style={{borderRadius: 0 }}/>
    </>
  )
})

const AddPlanner =  memo(({ data, isConnectable }) => {
  return (
    <>
      <div className="wk-center">
        <Handle type="target" position="top" style={{ borderRadius: 0 }} />
        <Link to={`/goals/${data.journey_profile_id}?id=${data.id}`} >
          <span className="fa-stack fa-2x">
            <i className="fas fa-circle fa-stack-2x" style={{color:data.bg_color}}></i>
            <i className={`fa-stack-1x fa-inverse ${data.icon}`}></i>
          </span>
          <div className="black">{data.label || '-'}</div>
        </Link>
      </div>
      <Handle type="source" position="bottom" id="a" style={{borderRadius: 0 }}/>
    </>
  )
})

export {AddActivity, AddPlanner, AddDataSource, AddStepActivity};