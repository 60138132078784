import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import DynamicForm from '../../../FormBuilder/DynamicForm';
import FormTemplateId from '../../../../constants/FormTemplateList'
import CompanyActivityService from "../../../Company/Activities/CompanyActivityService";

let formMode = null; 
let formId = null; 
let company = {}; 
let data = {}; 
let formFn = {};  

function CompanyActivityForm(props) {
  console.log('props compnay activity',props)
  const { state:authState, dispatch } = React.useContext(AuthContext);

  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;
  formId = params.form_id
  let activity_type = params.activity_type
  let value = params.value
  let currentUser = authState.user;
  console.log('props compnay activity currentUser',currentUser)

  
  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    CompanyActivityService.get(params).then((res) => {
      console.log('company get result',res)
      if(res.status==200){
        company = res.data.company;
        data = company.data || {};
        console.log('get data company',data)
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
      company = null;
    })
  }
  
  useEffect(() => { 
    company = {}; 
    data = {}; 
    formFn = {};
    formMode = 'create-form-submissions';
    if(props.match.path){
      if(props.match.path.includes('details')){
        console.log('details')
        get()
        formMode = 'view-form-submissions';
      }else if(params.id){
        console.log('edit')
        get()
        formMode = 'edit-form-submissions';
      }else if(!isFormRendered){
        setFormRendered(true);
      }
    }
  }, [params.id]);

  const create = (company) => {
    company.accelerator_id = currentUser.accelerator.id;
    company.created_by = currentUser.id;
    company.updated_by = currentUser.id
    company.draft = true
    company.form_id = formId// form_id
    company.activity_type = activity_type// label from data source
    company.activity_id = value// value from data source
    company.organization_id = null
    CompanyActivityService.create(company).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (company) => {
    company.accelerator_id = currentUser.accelerator.id;
    company.id = params.id
    company.created_by = currentUser.id;
    company.updated_by = currentUser.id
    company.accelerator_id = currentUser.accelerator.id// from user
    company.draft = true
    company.form_id = formId// form_id
    company.activity_type = activity_type// label from data source
    company.activity_id = value// value from data source
    company.organization_id = null
           // "record_id": 9         
            
    CompanyActivityService.update(company).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push(`/company/activities/${company.accelerator_id}`)
  }

  if(isFormRendered){
    return (
    <div className="portlet">
      <div className="portlet-heading bg-highlight color-white">
        <h4 className="portlet-title text-uppercase">
          {post.title}
        </h4>
         <div className="clearfix"></div>
      </div>
      <div id="portlet1" className="panel-collapse collapse in">
        <div className="portlet-body p-t-0">
          <div className="form-details" dangerouslySetInnerHTML={{ __html: post.details }}></div>
          <DynamicForm formMode={formMode} formId={formId} form={company} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
        </div>
      </div>
    </div>
    );  
  }else{
    return('')
  }
  
}

export default CompanyActivityForm;