import React, {Fragment, useContext, useState, useEffect} from "react";
import {Link} from "react-router-dom";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import AcceleratorService from "../../components/AcceleratorProfile/AcceleratorService";
import GanttChart from "../../components/Charts/GanttChart";
import CommonService from '../../services/CommonService'
import useStyle from '../../hooks/useStyle';



const AcceleratorDashboard = () => {
  useStyle('dashboard');
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [data, setData] = useState(null);

  const activities = () =>{
    let req = {'accelerator_id': currentUser.accelerator.id}
    AcceleratorService.activities(req).then((res)=>{
      setData(res.data.activities);
    })
  }

  useEffect(() => {
    activities();
  }, [currentUser.accelerator.id])

  const Projects = ({projects}) => projects.map((d, k) =>  {
    return (
      <Fragment key={k}>  
      <div className="d-flex pointer">
        <div>
       
        </div>
        <div className="m-l-10">  
          <h5 className="d-title">{d.data.name} </h5>
          <p className="d-description pull-left">{d.data.start_date}</p>
          <p className="d-description m-l-10 pull-left"><span className="lred">{d.data.end_date}</span></p>
        </div>
        <div class="m-l-20  pull-right">
            <i class="fas fa-chevron-right lgrey"></i>
          </div>
        <div className="ml-auto">
           
        </div>
      </div>
      <div className="divider"/>
    </Fragment>
    )
  })

  const Members = ({members}) => members.map((d, k) =>  {
    return (
      <Fragment key={k}>  
      <div className="d-flex pointer">
        <div>
          <img src="images/avatars/2s.png" className="rounded-circle bg-highlight" width="50"/>
        </div>
        <div className="m-l-10">  
        <h8>{d.email}</h8>
        </div>
        <div className="ml-auto">
           
        </div>
      </div>
      <div className="divider"/>
    </Fragment>
    )
  })

  const Advisors = ({advisors}) => advisors.map((d, k) =>  {
    return (
      <Fragment key={k}>  
      <div className="d-flex pointer">
        <div>
       
        </div>
        <div className="m-l-10">  
        <h4>{d.data.title}</h4>
         
        </div>
        <div className="ml-auto">
           
        </div>
      </div>
      <div className="divider"/>
    </Fragment>
    )
  })

  const ServiceProviders = ({service_providers}) => service_providers.map((d, k) =>  {
    return (
      <Fragment key={k}>  
      <div className="d-flex pointer">
        <div>
       
        </div>
        <div className="m-l-10">  
        <h4>{d.data.name}</h4>
         
        </div>
        <div className="ml-auto">
           
        </div>
      </div>
      <div className="divider"/>
    </Fragment>
    )
  })

  const Organizations = ({organizations}) => organizations.map((d, k) =>  {
    return (
      <Fragment key={k}>  
    <div className="d-flex pointer">
      <div>
     
      </div>
      <div className="m-l-10">  
        <h5 className="d-title">{d.data.name} </h5>
        <p className="d-description "><span className="lred">{CommonService.formatUpdateDate(d.updated_at)}</span></p>
      </div>
      <div className="ml-auto">
         
      </div>
    </div>
    <div className="divider"/>
  </Fragment>
    )
  })

  if(!data)return null;

  return (
    <Fragment>
      <div className="col-xs-12 col-md-6">
        <div className="card card-style">
          <div className="content ht-home">
            <Link to="/organizations" className="head-boder">
              <h3><i className="fas fa-building m-r-5 font-24 color-highlight"/> Organizations</h3>
            </Link>
            <p className="m-b-10">
              Tap to view more.
            </p>
            <Organizations organizations={data.organizations.slice(0,3)}/>
                {data.organizations.length==0 && <h5>No Information</h5>}
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-md-6">
        <div className="card card-style">
          <div className="content ht-home">
            <Link to="/organizations" className="head-boder">
              <h3><i className="fa fa-folder m-r-5 font-24 color-highlight"/> Projects</h3>
            </Link>
            <p className="m-b-10">
              Tap to view more.
            </p>
            <Projects projects={data.projects.slice(0,3)}/>
               {data.projects.length==0 && <h5>No Information</h5>}
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-md-6">
        <div className="card card-style">
          <div className="content ht-home">
            <Link to="" className="head-boder">
              <h3><i className="fas fa-file m-r-5 font-24 color-highlight"/> Documents/Links</h3>
            </Link>
            <p className="m-b-10">
              Tap to view more.
            </p>
           
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-md-6">
        <div className="card card-style">
          <div className="content ht-home">
            <Link to="" className="head-boder">
              <h3><i className="fas fa-user-circle m-r-5 font-24 color-highlight"/> Users</h3>
            </Link>
            <p className="m-b-10">
              Tap to view more.
            </p>
            <Members members={data.members.slice(0,3)}/>
               {data.members.length==0 && <h5>No Information</h5>}
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-md-6">
        <div className="card card-style">
          <div className="content ht-home">
            <Link to="" className="head-boder">
              <h3><i className="fas fa-globe m-r-5 font-24 color-highlight"/> Network</h3>
            </Link>
            <p className="m-b-10">
              Tap to view more.
            </p>
            <Advisors advisors={data.advisors.slice(0,3)}/> 
               <ServiceProviders service_providers={data.service_providers}/>
               {data.service_providers.length==0 && <h5>No Information</h5>}
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-md-6">
        <div className="card card-style">
          <div className="content ht-home">
            <Link to="" className="head-boder">
              <h3><i className="fa fa-calendar-check m-r-5 font-24 color-highlight"/> Milestone</h3>
            </Link>
            <p className="m-b-10">
              Tap to view more.
            </p>
            <GanttChart/>
          </div>
        </div>
      </div>
    
    </Fragment>
  )
};

export default AcceleratorDashboard;