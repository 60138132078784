import React, {useState} from "react";
import {Link} from "react-router-dom";
import PortalHeader from "../../../components/Organizations/ProjectPortalPage/PortalHeader";
import ProjectPath from "../../../components/Organizations/ProjectPortalPage/ProjectPath";
import ProjectService from "../../../components/Organizations/Projects/ProjectService";
import useStyle from '../../../hooks/useStyle';

let projectPath = null;

export const ProjectDetails = (props) => {
  useStyle('panel');
  useStyle('path');

  let params = props.match.params;
  const [statics, setStatics] = useState({});
  const [project, setProject] = useState({});

  React.useEffect(() => {  
    let req = {
      'id':params.project_id,
      'data_source_params':{
        'data_source_id':[3375]
      }
    }

    ProjectService.get(req).then((res)=>{
     if(res.status == 200){
       res = res.data;
       projectPath = res.data_source_list.ecn_path_nodes;
       setStatics(res.statics)
       setProject(res.project)
      }
    })
  }, []);

  const routePath = (e, path) => {
    e.preventDefault();
    if(path.sid == 'planners'){
      props.history.push(`/project/service_requests/${project.id}`);
    }else if(path.sid == 'assessments'){
      props.history.push(`/serviceprovider/assessments/${project.id}`)
    }else if(path.sid == 'contract'){
      props.history.push(`/list/project/contracts/${project.id}`)
    }else if(path.sid == 'activities'){
      props.history.push(`/list/project/milestones/${project.id}`)
    }else if(path.sid == 'metrics'){
      props.history.push(`/organization/milestones/review/${project.id}`)
    }
  }

  if(!project.id)return null;

  return (
    <div className="p-15">
      <div className="portlet">
        <PortalHeader project={project}/>
        <div id="bg-info" className="panel-collapse collapse in">
          <div className="portlet-body">
            <ProjectPath projectPath={projectPath} routePath={routePath} statics={statics}/>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ProjectDetails;