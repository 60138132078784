export default {
  user_setting:`Personalize your user name, profile picture, secret questions, and other settings.
The secret questions are required.`,
  team_activity_role: `Click the + button icon in the top right corner to give permission to others users.`,
  profile_close: `You must fill and submit the Account Profile form before you can use cueTree Education. `,
  new_journey: `Click the + button icon in the top right corner to add new ECN Journey.`,
  new_activity: `Click the + button icon in the top right corner to add new Activity.`,
  new_role: `Click the + button icon in the top right corner to add new Role.`,
  new_user: `Click the + button icon in the top right corner to add new Users by just adding their email IDs (separated by a comma)..`,

};