import env from '../../../../env';
import axios from 'axios';
const url = `${env.ecn_backend_api}step_activity_status`

class StepActivityStatusService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}`, {params:req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(status){
    return new Promise((resolve, reject) => {
      axios.post(`${url}`, status).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(status){
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${status.id}`, status).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(status) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${status.id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new StepActivityStatusService();