import React, {useContext, Fragment} from "react";
import ReactTooltip from "react-tooltip";
import Cs from "../../services/CommonService";
import ShareJourneyService from "../ShareJourney/ShareJourneyService"

let componentObj = null;

const Role = (props) => {

  let role = props.role;
  role.updated_at = Cs.formatUpdateDate(role.updated_at);
  if(!componentObj)componentObj = Cs.listToMap(props.componentList, 'sid')
  const cardStyle = {backgroundColor: "#607D8B"}
  
  const removeUser = (user) =>{
    ShareJourneyService.delete(user).then((res)=>{
      if(res.status == 204){
        let u = role.invited_users.filter(r => r.id !== user.id)
        props.roles[props.index].invited_users = u
        props.setRoles([...props.roles])
      }
    })
  }

  const RoleList = ({list, styleName}) => {
    if(list){
      return(
        list.map((i, k)=>
          <span className={styleName +' m-l-5'} key={i}>{componentObj[i].label}</span>
        )
      )
    }else{
      return null
    }
  }
    
  const UserList = ({list, styleName}) => {
    if(list){
      return(
        list.map((u, k)=>
          <span className={styleName +' m-l-5'} key={k}>{u.email}
            <span className="fas fa-times m-l-5" onClick={e=>removeUser(u)}/>
          </span>
        )
      )
    }else{
      return null
    }
  }

  const Header = () => (
    <div className="white card-header p-5" style={cardStyle}>
      <div className="font-16 ellipsis">
        {role.role}
      </div>
      <div className="font-13">
        {role.updated_at}
      </div>
    </div>
  )

  const Footer = () => (
    <div className="card-footer">
      <div className="pull-left lgrey">
        <span className="m-l-15 font-11">
          <span className="lred">Last by {role.updated_user.name}</span>
          <span className="black-light-1"> {role.updated_at}</span>
        </span>
      </div>
      <div className="pull-right">
        <props.menu role={role} />
      </div>
    </div>
  )

  const Card = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <Header/>
        <div className="scroll-auto ht-150">
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                  <UserList list={role.invited_users} styleName="pill"/>
                </td>
              </tr>
              {role.access_type == 'partial_access' && 
                <tr>
                  <td>        
                    Access List : <br/>
                    <RoleList list={role.access_list} styleName="pill"/>
                  </td>
                </tr>
              }
              <tr>
                <td>
                  {role.description}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Footer />
        <ReactTooltip place="left" />
      </div>
    )
  }

  return <Card/>;
}

export default Role;