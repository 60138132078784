import env from '../../env';
import axios from 'axios';

class ForumService {

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}discussion_requests/${req}`, { params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}discussion_requests`, {params: req}).then((res) => {
        // handle success
        console.log('forums get all',res)
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(profile){
    return new Promise((resolve, reject) => {
      axios.post(env.ecn_backend_api+'discussion_requests',profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  reply(profile) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.ecn_backend_api}discussion_responses`, profile).then((res) => {
        // handle success
        console.log('reply response',res);
        resolve(res);
      }).catch((e) => {
        console.log('reply error',e);
        // handle error
        reject(e);
      })
    })
  }


}

export default new ForumService();