import React, { Fragment, useEffect, useState } from 'react';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../PreLoadSpinner";
import DynamicForm from '../../../FormBuilder/DynamicForm';
import FormContainer from '../../../FormBuilder/FormContainer'
import FormTemplateId from '../../../../constants/FormTemplateList'
import OrganizationService from "../../OrganizationService";
import CommonService from '../../../../services/CommonService';
import env from '../../../../env';

let formMode = null; 
let formId = null; 
let organization = {}; 
let data = {}; 
let formFn = {};  

function OrganizationMemberForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;
  console.log('oarams',params)
  let currentUser = authState.user;

  formFn.country_pagenate = {page:1, per_page:10}
  formFn.country_path = 'name';

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    OrganizationService.getMember(params).then((res) => {
      if(res.status==200){
        organization = res.data.organization_member;
        data = organization.data || {};
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    })
  }
  
  useEffect(() => { 
    organization = {}; 
    data = {}; 
    formFn = {}; 
    formMode = 'create-form-submissions';
    if(props.match.path){
      if(props.match.path.includes('details')){
        get()
        formMode = 'view-form-submissions';
      }else if(params.id){
        get()
        formMode = 'edit-form-submissions';
      }else if(!isFormRendered){
        setFormRendered(true);
      }
    }
   
  }, [params.id]);

  const create = (organization) => {
    organization.accelerator_id = currentUser.accelerator.id;
    organization.organization_id = params.organization_id
    organization.email = organization.data.email
    OrganizationService.createMembers(organization).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    })
  }

  const update = (organization) => {
    organization.accelerator_id = currentUser.accelerator.id;
    organization.organization_id = params.organization_id
    OrganizationService.updateMembers(organization).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push("/contacts")
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.OrganizationMemberRegistration} form={organization} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
      </FormContainer> 
    )  
  }else{
    return null
  }
  
}

export default OrganizationMemberForm;