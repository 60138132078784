import React, {useRef, useEffect, useState} from 'react';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import SignaturePad from '../../../components/FormBuilder/InputComponents/signature_pad';
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel} from '../FieldLabel'

let signaturePad = null;
const InputSignaturePad = ({field, formData, readOnly, errors, formFn, isFormWizard, openFieldLabelModal, currentLocale}) => {
  
  //importScript("/node_modules/signature_pad/dist/signature_pad.min.js");
  let [showError, setShowError] = useState(false);

  const inputEl = useRef(null);

  let inputAttributes = {
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only)
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip,
    'id': field.client_id
  }

  let validation = {required: true}
  
  FormHelper.setDisabled(field, inputAttributes);
  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const save_png = () => {
    let data = signaturePad.toDataURL('image/png');
    formData[field.client_id] = data;
    //setData(data);
    //var file = dataURLtoFile(data, 'signature.png');
    //uploadFileToUrl(file)
  }

  const setCanvas = () => {
    signaturePad = new SignaturePad(inputEl.current, {
      backgroundColor: 'rgb(255, 255, 255)',
      onEnd: save_png
    })
    setError();
  }

  const clear = (e) =>{
    e.stopPropagation();
    if(formData[field.client_id]){
      //removeImage($scope.sign);
      formData[field.client_id] = null;
    }
    signaturePad.clear();
    setError();
  }

  useEffect(() => {
    setCanvas();
  }, [field.client_id]);

  const setError = () => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[name]==null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  return (
  	<div {...parentAttributes}>
  	  <FieldLabel field={field} labelAttributes={labelAttributes} 
        currentLocale={currentLocale}
        openFieldLabelModal={openFieldLabelModal}/>
 	    <div className="wrapper br-black br-grey">
        <canvas ref={inputEl} className="signature-pad" width="400" height="200"></canvas>
        <button type="button" onClick={e=>{clear(e)}} className="btn btn-inverse pull-right">Clear</button>
      </div> 	 
      <span className="fts-13">{field.description}</span>
      <Error/>
 	  </div> 
  )
}

export default InputSignaturePad;