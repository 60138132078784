import React, {Fragment, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import LineChart from "../../Charts/LineChart"
import CommonService from "../../../services/CommonService";
import env from "../../../env";

const LightSailReport = (props) => {

  let [metricData, setMetricData] = useState({})
  let params = props.match.params;

  useEffect(() => {
    getInstanceReport();
  }, []);

  let getInstanceReport = () => { 
    let req = {
      method: "post",
      url: `${env.ecn_backend_api}aws/lightsail/get_instance_metric_data`,
      data: {
        'instance_name': params.instance_name,
        'metric_types': ['CPUUtilization', 'NetworkIn']
      },
    }

    CommonService.useAxios(req).then((res) => {
      setMetricData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }

  let cpuUsage = {
    id:"chart_1",
    series: [{data: metricData.CPUUtilization}],
    type : 'bar',
    title : {text: 'CPU Usage', align: 'left'},
    xaxis : {type: 'datetime'},
    yaxis : {
      title: {
        text: 'Percentage'
      },
      type: 'numeric',
      min:1,
      max:100,
      labels: {
        show: true,
        formatter: function (val, opts) {
          return val.toFixed(0);
        }
      }
    },
    stroke:{}
  }
  
  let networkIn = {
    id:"chart_2",
    series: [{data: metricData.NetworkIn}],
    type : 'bar',
    title : {text: 'Network Usage', align: 'left'},
    xaxis : {type: 'datetime'},
    yaxis : {
      title: {
        text: 'MB'
      },
      type: 'numeric',
      min:1,
      max:35000000,
      labels: {
        show: true,
        formatter: function (val, opts) {
          return (val/1024/1024).toFixed(2) + 'MB';
        }
      }
    },
    stroke:{}
  }

  return (
    <Fragment>
      <div className="content top-10">
        <h5 className="float-left font-500">Aws Insight Report</h5>
        <div className="clear"></div>
      </div>

      <div className="page-content row">
        {metricData.CPUUtilization && <LineChart {...cpuUsage}/>}
      </div>

      <div className="page-content row">
        {metricData.NetworkIn && <LineChart {...networkIn}/>}
      </div>
    </Fragment>
  )

}

export default LightSailReport;