import React, { Fragment, useContext, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Cs from "../../services/CommonService"

const CheckBox = ({list, toggle, exists, labelKey, valueKey}) =>{
  return <CheckBoxObjList list={list} toggle={toggle} exists={exists} labelKey={labelKey} valueKey={valueKey}/>
}

const CheckBoxObjList = ({list, toggle, exists, labelKey, valueKey}) => list.map((o, k) =>
  <span className={`checkbox-container ${o.read_only=='yes'?'input-disabled':''} `} key={k} onClick={e => toggle(o[valueKey], e)}>
    <span>{o[labelKey]}</span>
    <input type="checkbox" checked={exists(o[valueKey])} readOnly/>
    <span className="checkbox-checkmark"></span>
  </span>
)

const Radio = ({list, model, name, handleChange, required}) =>{
  return <RadioObjList list={list} name={name} model={model} handleChange={handleChange} required={required}/>
}

const RadioObjList = ({list, handleChange, model, name, required}) => list.map((o, k) =>
  <label className={`radio-container ${o.read_only=='yes'?'input-disabled':''}` } key={k}>
    <span>{o.label}</span>
    <input type="radio" name={name} required={required} onChange={handleChange}  defaultValue={o.value} checked={model==o.value}/>
    <span className="radio-checkmark"></span>
  </label>
)

const RadioStringList = ({list, handleChange, selected}) => list.map((o,k) =>
  <label className="radio-container pointer" key={k}>
    <span>{o}</span>
    <input type="radio" onChange={handleChange}  defaultValue={o} checked={selected==o}/>
    <span className="radio-checkmark"></span>
  </label>
)

const OptionsObjList = ({list, id_key, label_key}) => list.map((o, k) =>
  <option key={k} value={o[id_key]}>{o[label_key]}</option>
)

const InputTags = ({isObject, tagList, onTagChange, name, onDelete, updateOption, TagCheckBox, required}) =>{
  let [tag, setTag] = useState(tagList || [])

  useEffect(() => {
    if(tagList && tagList.length > 0){
      setTag(tagList)
    }
  }, [tagList])

  const add = (event) => {
    if (event.key === 'Enter') {
      if(event.target.value==null)return;
      tag.push({'label':event.target.value, 'client_id':tag.length+1})
      setTag([...tag])
      event.target.value = null;
      onTagChange(tag)
    }
  }

  const remove = (e, item) => {
    e.preventDefault()
    if(item.id){
      tag = tag.filter(i => i.id !== item.id)
      setTag([...tag])
      onDelete(item)
    }else{
      let idx = tag.findIndex((i) => i.client_id == item.client_id)
      if (idx > -1) {
        tag = (tag.filter(i => i.client_id !== item.client_id))
        setTag([...tag])
      } 
    }
    onTagChange(tag)
  }

  const tagsLabel = tag.map((t, k) =>  
    <span className="tag" key={k}>
      {TagCheckBox && <TagCheckBox option={t}/>}
      <span className="font-16">{t.label}&nbsp;&nbsp;</span>
      <a href="#" title="Removing tag" onClick={e => remove(e, t)}>x</a>
    </span>
  )

  const tags = tag.map((t, k) =>  
    <span className="tag" key={k}>
      {TagCheckBox && <TagCheckBox option={t}/>}
      <span>{t}&nbsp;&nbsp;</span>
      <a href="#" title="Removing tag" onClick={e => remove(e, t)}>x</a>
    </span>
  )

  return(
  	<div id="tags_input" className="tagsinput">
      {isObject?tags:tagsLabel}
      <input name={name} placeholder="add a tag" onKeyDown={add} required={required}/>
      <div className="tags_clear"></div>
    </div>  
  )

}

export {OptionsObjList, InputTags, Radio, CheckBox}