import React from 'react';
import PrivateRoute from "../../routes/PrivateRoute";
import OrganizationsList from '../../components/Organizations/Activities/List/OrganizationList'
import OrganizationProfileForm from '../../components/Organizations/Activities/Form/OrgProfileForm'
import OrganizationMemberForm from '../../components/Organizations/Activities/Form/OrgMemberForm'
import ProjectList from '../../components/Organizations/Projects/List/ProjectList'
import ProjectForm from '../../components/Organizations/Projects/Form/ProjectForm'
import ProjectDetails from '../../components/Organizations/ProjectPortalPage/ProjectDetails'
import ServiceRequestsList from '../../components/Organizations/ProjectServiceRequests/List/ServiceRequestList'
import ServiceRequestForm from '../../components/Organizations/ProjectServiceRequests/Form/ServiceRequestForm'
import ProjectMilestoneForm from '../../components/Organizations/ProjectMilestones/ProjectMilestoneForm'
import MilestoneList from '../../components/Organizations/ProjectMilestones/MilestoneList'
import MilestoneReview from '../../components/Organizations/MilestoneReview/MilestoneReview'
import MilestoneTaskForm from '../../components/Organizations/MilestoneTasks/MilestoneTaskForm'
import MilestoneTasksList from '../../components/Organizations/MilestoneTasks/MilestoneTasksList'
import MilestoneVerificationForm from '../../components/Organizations/MilestoneVerifications/MilestoneVerificationForm'

const OrganizationRouter =  (props) => (
  <React.Fragment>  
    <PrivateRoute path="/contacts" component={OrganizationsList} searchString={props.searchString} exact />
    <PrivateRoute path="/organization/profile/:id?" component={OrganizationProfileForm} exact/>
    <PrivateRoute path="/organization/details/:id?" component={OrganizationProfileForm} exact/>
    <PrivateRoute path="/organization/profile/member/:organization_id?/:id?" component={OrganizationMemberForm} exact/>
    <PrivateRoute path="/organization/projects/:organization_id?" component={ProjectList} exact />
    <PrivateRoute path="/organization/new/project/:organization_id?/:id?" component={ProjectForm} exact/>
    <PrivateRoute path="/organization/project/portal/:project_id?" component={ProjectDetails} exact />
    <PrivateRoute path="/project/service_requests/:project_id?" component={ServiceRequestsList} exact />
    <PrivateRoute path="/project/search/service_request/:organization_id?/:project_id?/:id?" component={ServiceRequestForm} exact/>
    <PrivateRoute path="/list/project/milestones/:project_id" component={MilestoneList} exact/>
    <PrivateRoute path="/project/milestone/form/:organization_id/:project_id/:contract_id/:id?" component={ProjectMilestoneForm} exact/>
    <PrivateRoute path="/milestone/verifications/:organization_id/:project_id/:contract_id/:milestone_id/:id?" component={MilestoneVerificationForm} exact/>
    <PrivateRoute path="/organization/milestones/review/:project_id" component={MilestoneReview} exact />
    <PrivateRoute path="/milestone/task/form/:id?" component={MilestoneTaskForm} exact />
    <PrivateRoute path="/milestone/tasks/:milestone_id" component={MilestoneTasksList} exact />
  </React.Fragment>
)

export default OrganizationRouter;

/*<OrganizationsList searchString={props.searchString}/>*/