import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import DynamicForm from '../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../constants/FormTemplateList'
import ContractsService from "../../components/Contracts/ContractsService";

let formMode = null; 
let contract = {}; 
let data = {}; 
let formFn = {};  

function ContractForm(props) {

  const { state:currentUser, dispatch } = React.useContext(AuthContext);

  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;

  const get = () => {
    ContractsService.get(params).then((res) => {
      if(res.status==200){
        contract = res.data.contract;
        data = contract.data || {};

        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
      contract = null;
    })
  }
  
  useEffect(() => { 
    contract = {}; 
    formFn = {};  
    formMode = 'create-form-submissions';
    data = {'sign_name_1':currentUser.user.accelerator.data.name, 'sign_name_2':params.organization_name}; 
    
    if(params.id && params.id != 'null'){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, [params.id]);

  const create = (contract) => {
    contract.contract_id = params.contract_id;
    contract.contract_type = params.contract_type;
    contract.accelerator_id = currentUser.user.accelerator.id;
    contract.form_id = params.form_id;
    contract.project_id = params.project_id;
    ContractsService.create(contract).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (contract) => {
    ContractsService.update(contract).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    if(params.contract_type=='service_provider'){
      props.history.push("/serviceprovider")
    }else if(params.contract_type=='organization'){
      props.history.push("/organizations")
    }else if(params.contract_type=='advisor'){
      props.history.push("/advisor")
    }else if(params.contract_type=='project_work_order'){
      props.history.push(`/list/project/contracts/${params.project_id}`)
    }
  }

  if(isFormRendered){
    return (
    <div className="portlet">
      <div className="portlet-heading bg-highlight color-white">
        <h4 className="portlet-title text-uppercase">
          {post.title}
        </h4>
         <div className="clearfix"></div>
      </div>
      <div id="portlet1" className="panel-collapse collapse in">
        <div className="portlet-body p-t-0">
          <div className="form-details" dangerouslySetInnerHTML={{ __html: post.details }}></div>
          <DynamicForm formMode={formMode} formId={params.form_id} form={contract} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
        </div>
      </div>
    </div>
    );  
  }else{
    return('')
  }
  
}

export default ContractForm;