import React, {useState,Fragment} from "react";
import ReactTooltip from 'react-tooltip';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import Contract from '../../components/Contracts/Contract';
import ContractsService from "../../components/Contracts/ContractsService";
import PortalHeader from "../../components/Organizations/ProjectPortalPage/PortalHeader";
import Pagination from '../Pagination'
import ConfirmMenu from "../Modals/ConfirmMenu";
import ProjectMenu from '../Modals/ProjectMenu'
import {Link} from "react-router-dom";
import useModal from "../../hooks/useModal";
import FormTemplateId from '../../constants/FormTemplateList'
import SendContractVerificationEmail from '../../components/Contracts/SendContractVerificationEmail'
import useStyle from '../../hooks/useStyle';

let requestParams = {};
let project = null;
let selectedContract = {};
let milestones = null;

export const ContractsList = (props) => {
  useStyle('panel')
  useStyle('card');
  useStyle('card_table');

  
  const { state:currentUser, dispatch } = React.useContext(AuthContext);
  const [contractList, setContractList] = useState([]);
  const [currentpage,setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);

  React.useEffect(() => {
    requestParams = {
      'page': currentpage, 
      'per_page' : 1,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'accelerator_id': currentUser.user.accelerator.id,
      'contract_type':'project_work_order',
      'project_id':props.match.params.project_id,
    }

    getContractList();
  }, [currentUser.user.accelerator, props.searchString,currentpage]);


  let getContractList = () => { 
    ContractsService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       project = res.data.project;
       milestones = res.data.milestones;
       setPagemeta(res.data.meta)
       setContractList(res.data.contracts)
      }
    })
  }

  let deleteContract = (contract) => {
    ContractsService.delete(contract).then((res) => {
      if (res.status == 204) {
        setContractList(contractList.filter(i => i.id !== contract.id))
      }
    })
  }
  
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal, success:menuSuccess } = useModal();
  const { isOpen, toggleModal, success } = useModal(deleteContract);
  const {isOpen:contractPopupOpen, toggleModal:toggleContractPopup} = useModal();

  let sendContractVerification = (e, invite) => {
    e.preventDefault();
    let req = {
      email:invite.email,
      message:invite.message,
      project_id:selectedContract.project_id,
      contract_id:selectedContract.id,
      organization_id:project.organization_id,
      updated_by:currentUser.user.id
    }

    ContractsService.verification(req).then((res) => {
      if (res.status == 204) {
        toggleContractPopup(e);
      }
    })
  }

  const openContractPopup = (e, contract) =>{
    selectedContract = contract;
    toggleContractPopup(e, contract);
  }

  const addMenu = (e, contract) => {
    selectedContract = contract;
    toggleMenuModal(e);
  }

  const Menu = ({ contract }) => (
    <span className="m-r-15" >
        <i onClick={e=>{addMenu(e, contract)}} class="fas fa-bars" />  
    </span>
  )

   /*const Menu = ({contract, project}) => 
    <div className="dropup card-menu-right" style={{bottom: "2px"}}>
      <button className="dropbtn">Menu</button>
      <div className="dropup-content">
        <Link to={`/contract/registration/${contract.contract_type}/${contract.contract_id}/${contract.form_id}/${contract.id}/${contract.project_id}`}>Edit</Link>
        <Link to={`/project/milestone/form/${project.organization_id}/${contract.project_id}/${contract.id}`}>Add Milestones</Link>
        <a href="#" onClick={(e) => {openContractPopup(e, contract);}}>Send for review Signature</a>
        <a href="#" onClick={(e) => {toggleModal(e, contract);}}>Delete</a>
      </div>
    </div>*/

const menuItems = ({item}) =>{
  return(
    <Fragment>
      <Link to={`/contract/registration/${item.contract_type}/${item.contract_id}/${item.form_id}/${item.id}/${item.project_id}`}>
        <i className="fa fa-pen"></i>
        <span className="font-13">Edit</span>
        <i className="fa fa-angle-right"></i>
      </Link>
      <Link onClick={(e) => {openContractPopup(e, item);}}>
        <i className="fa fa-file-contract"></i>
        <span className="font-13">Send for review Signature</span>
        <i className="fa fa-angle-right"></i>
      </Link>
      <Link onClick={(e) => {toggleModal(e, item);}}>
        <i className="fa fa-trash"></i>
        <span className="font-13">Delete</span>
        <i className="fa fa-angle-right"></i>
      </Link>
    </Fragment>
  )
}

    const contractCard = contractList.map((c, i) =>
      <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
        <Contract contract={c}  menu={Menu} project={project} milestones={milestones}/>
        {selectedContract.record_id == c.record_id && 
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedContract} 
          type="contractlist" 
          MenuItems={menuItems}/>}
      </div>
    )

  if(!project){
    return null;
  } else { 
  return (
    <React.Fragment>
    <div className="content top-10 clearfix">
        <div className="col-xs-8">
        <div className="font-16 bold-600">
  	    {project.data.name}
   		</div>
    	<div className="font-15">
      Contract Assessment
        <span><i className="fa fa-question-circle m-l-5" ></i></span>
      </div>        
        </div>
        <div className="col-xs-4"> 
        <Link to={`/organization/project/portal/${project.id}`} className="bg-highlight round-btn float-right m-r-5">
            <i className="fas fa-home"/>
          </Link>
          <ReactTooltip place="left" />
        </div>
      </div>
      <div className="page-content row">

             {contractCard}
              
      </div>
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleModal} success={success}/>
      <SendContractVerificationEmail isOpen={contractPopupOpen} toggleModal={toggleContractPopup} sendContractVerification={sendContractVerification}/>
    </React.Fragment>
  )
  }
};

export default ContractsList;

/*<Pagination pagemeta={pagemeta} currentpage={currentpage} setCurrentpage={setCurrentpage} />*/