import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import ChecklistService from '../../services/ChecklistService';
import useStyle from '../../hooks/useStyle';

let checklist = {};

const ChecklistsModal = ({ item, type, isOpen, toggleModal }) => {
  
  useStyle('add_role_dialog');

  const { state:currentUser, dispatch } = React.useContext(AuthContext);
  const [checkListItem, setCheckListItem] = useState([]);
  const [checklistId, setChecklistId] = useState();
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(true);



  const getCheckList = () => {
    let req = {'item_id':item.id, 'item_type':type, 'user_id':currentUser.user.id}
    ChecklistService.getAll(req).then((res)=>{
      if(res.status == 200){
        setLoading(false)
        setCheckListItem(res.data.checklists)
      }
    })
  }

  useEffect(() => {
    if(isOpen)getCheckList();
  }, [item.id]);

  if(!isOpen)return null;

  const addCheckList = (e) => {
    setLoading(true)

    e.preventDefault();
    checklist.title = text;
    if(!checklist.title)return;
    
    checklist.item_id = item.id;
    checklist.item_type = type;
    checklist.updated_by = currentUser.user.id;
    if(checklist.id){
      ChecklistService.update(checklist).then((res)=>{
        if(res.status == 204){
          setLoading(false)
          setCheckListItem([...checkListItem])
          checklist = {};
          setText('');

        }
      })
    }else{
      ChecklistService.add(checklist).then((res)=>{
        if(res.status == 201){
          setLoading(false)
          checklist.id = res.data.checklist.id;  
          setCheckListItem([...checkListItem, checklist])
          checklist = {};
          setText('');

        }
      })
    }
  }

  const deleteCheckList = (e, checklist) => {
    e.preventDefault();  
    setLoading(true)
    ChecklistService.delete(checklist).then((res)=>{
      setLoading(false)
      setCheckListItem(checkListItem.filter(i => i.id !== checklist.id))
    })  
  }

  const toggleStatus = (e, checklist) =>{
    e.preventDefault();  
    let req = {
      'user_id': currentUser.user.id,
      'checklist_id': checklist.id,
      'status': !checklist.status,
    }
    ChecklistService.status(req).then((res)=>{
      setCheckListItem(checkListItem.map(c => (c.id === checklist.id ? {...c, status:!checklist.status} : c)))
    }) 
  }

  return(ReactDOM.createPortal(
    <React.Fragment>
      <div className="ml-modal">
      <div className="ml-modal-content ml-card-4 ml-animate-zoom">
      
      <div className="ml-center bg-safforn white p-5">
        <h4>Checklist</h4>
        <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal"
        onClick={e=>toggleModal(e)}>×
        </span>
      </div>
    
      <form className="ml-container">
        <div className="ml-section">
          <input onChange={e=>{setText(e.target.value)}} className="ml-input ml-border ml-margin-bottom"
          type="text" placeholder="Add Checklist" name="checklist" required value={text}/>

          <button className="ml-button ml-block bg-safforn white ml-section ml-padding" onClick={e=>{addCheckList(e)}}>
            <b>Submit</b>
          </button>
        </div>
      </form>
      
      <div className="ml-container">
        <ul className="note-ul note-card-4" style={{ marginBottom: '20px'}}>
          { loading ? <div className="spinner"></div> : checkListItem && checkListItem.map((c, key) =>(
            <div className={c.completion_status ? "note-bar bg-ltint" :"note-bar"} key={key}>
            <span className="checkbox-container" onClick={e=>toggleStatus(e, c)}>
              <span>{c.title}</span>
              <input type="checkbox" checked={c.completion_status} readOnly/>
              <span className="checkbox-checkmark"></span>
              <div className="nbt-white font-16 pull-right m-r-5" onClick={e => {checklist = c;setChecklistId(c.id);setText(c.data);}}>
                <i className="far fa-edit" aria-hidden="true"></i>
              </div>
              <div className="nbt-white font-16 pull-right m-r-5" style={{marginRight:'25px'}} onClick={e => {deleteCheckList(e, c)}}>
                <i className="far fa-trash-alt" aria-hidden="true"></i>
              </div>
            </span>
          </div>
          ))}
        </ul>
      </div>

    </div>
  </div>
  </React.Fragment>, document.body))
};

export default ChecklistsModal;