import React, {useState, Fragment, useContext, useEffect} from "react";
import ReactTooltip from "react-tooltip";
import querystringify from "querystringify";
import Pagination from '../Pagination'
import {Link} from "react-router-dom";
import useStyle from '../../hooks/useStyle';
import useModal from "../../hooks/useModal";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import CommonService from "../../services/CommonService";
import JourneyTitle from "../../components/Journey/CollegeJourney/JourneyTitle/JourneyTitle";
import {JourneyHome} from '../Common/MenuItem'
import DataSourceListModal from './DataSourceListModal'
import SideMenuBar from "../Common/SideMenuBar";

let requestParams = {};
let selectedFormField = {};

const DataSourceList = (props) => {
  useStyle('filter_dialog');
  useStyle('add_role_dialog');
  useStyle("card");

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [dataSourceList, setDataSourceList] = useState([]);
  const [formTemplates, setFormTemplates] = useState([]);
  const [formFields, setFormFields] = useState([]);
  const [index,setIndex] = useState()
  const [dataSources, setDataSources] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  
  let params = props.match.params;
  let journey_profile = props.location.state;
  let queryParam = querystringify.parse(props.location.search);

  const {isOpen: isDataSourceModalOpen, toggleModal: toggleDataSourceModal,} = useModal();

  useEffect(() => {
    requestParams = {
      'page': currentpage, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'asc',
      'form_ids':queryParam.form_ids
    }
    /* && queryParam.form_ids.split(',')*/
    getFormTemplates();
  }, [props.searchString, currentpage]);

  let getFormTemplates = () => { 
    CommonService.getFormTemplates(requestParams).then((res)=>{
     if(res.status == 200){
       setFormTemplates(res.data.form_templates)
      }
    })
  }

  let getFormFields = (from) => { 
    let req = {'form_template_id':from.id, 'journey_profile_id':queryParam.journey_profile_id}
    CommonService.getFormFieldsById(req).then((res)=>{
      if(res.status == 200){
        setFormFields(res.data.form_fields)
      }
    })
  }

  const handleClick =(form, i)=> {
    if(index != i){
      getFormFields(form);
      const tabScroll = document.getElementById('tab_'+i);
      window.scrollTo({
        'behavior': 'smooth',
        'left': 0,
        'top': tabScroll.offsetTop - 200
      })
    }  
    setFormFields([])
    setIndex(index == i?null:i)
  }

  let updateStatus = (o, form_template_id, indexList) =>{
    if(o.required)return;
    let req = {
      filterable_type: 'form_field',
      created_by: currentUser.id,
      filterable_id: o.index,  
      filterable_ids: indexList,  
      form_template_id: form_template_id,
      journey_profile_id: queryParam.journey_profile_id
    }

    CommonService.updateDataSourceListStatus(req).then((res)=>{
      if(res.status == 200){
        if(o.inactive_status){
          o.inactive_status=null;
        }else{
          o.inactive_status={};
        }
        setFormFields([...formFields])
      }
    }) 
  }

  const setFormField = (e, field) =>{
    e.stopPropagation()
    selectedFormField = field
    toggleDataSourceModal()
  }

  const checkAllFields = (form_template_id) => {
    let req = {
      filterable_type: 'form_field',
      form_template_id: form_template_id,
      journey_profile_id: queryParam.journey_profile_id
    }
    CommonService.deleteDataSourceListStatus(req).then((res)=>{

    })
  }

  const uncheckAllFields = (form_template_id) => {
    let indexList = formFields.map(d => d.inactive_status==null && d.index)
    if(indexList.length > 0){
      updateStatus({}, form_template_id, indexList);
    }
  }
  
  const FormFieldsView = ({form}) => formFields && formFields.map((c, i) => {
    return(
      <Fragment key={i}>
        <div className="col-xs-12 carrier m-t-10" style={{borderLeft: "3px solid #27a1fb"}}>
          <span className={'checkbox-container ' + (c.required && 'req-field') } key={i} onClick={e => updateStatus(c, form.id)}>
            <span>
              {c.label} 
              {c.data_source_id && <span className="badge bg-lred m-l-5" onClick={e=>setFormField(e, c)}>{c.data_source_name}</span>}
            </span>
            <input type="checkbox" checked={c.inactive_status==null} readOnly/>
            <span className="checkbox-checkmark"></span>
          </span>
        </div>
        <ReactTooltip place="right" />
      </Fragment>
    )
  })

  const FormTemplateView = formTemplates.map((o, k) => {
    const boxShadow =  index == k ? {border: "1px solid lightgrey", boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"} : {}
    return(
      <Fragment key={k}>
        <div className="col-xs-12 font-14 m-b-15" key={k} style={boxShadow} id={'tab_'+ k}>
          <div className="black bg-white p-5 d-flex" onClick={e=>handleClick(o, k)}>
            <div className="font-14 w-100p">
              {o.post.title}
            </div>
            <i className = {'m-r-10 p-5 font-24 ' + (index == k ? "fa fa-caret-up" : "fa fa-caret-down")} ></i>
          </div>
          {index == k && <div key={k} className="ht-250 bg-white p-5 scroll-auto">
            <p className="m-b-10" dangerouslySetInnerHTML={{ __html: o.post.details }}/>
            <div className="pull-left">
              <span className="badge bg-lred m-l-5" onClick={e=>checkAllFields(o.id)}>Check All</span>
              <span className="badge bg-lred m-l-5" onClick={e=>uncheckAllFields(o.id)}>Uncheck All</span>
            </div>
            <div className="pull-right">
              <Link to={`/data_source_list/suggestion/${o.id}`} className="pull-right">
                <h5>Suggest updates to the forms</h5>
              </Link>
            </div>
            <FormFieldsView form={o}/>
           </div>
          }
        </div>
      </Fragment>
    )
  })

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-8">
          {journey_profile && <JourneyTitle title={"Data Choices"} project={journey_profile}/>}
        </div>
        
        <div className="col-xs-4 float-right">
          <JourneyHome id="home_icon" journey_profile={journey_profile}
            link={`/journey/portal/${queryParam.journey_profile_id}`}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home" dataTip="Back to Journey Home" />
        </div>
      </div>

      <div className="content row">
        {FormTemplateView}
        <Pagination pagemeta={pagemeta} currentpage={currentpage} setCurrentpage={setCurrentpage}/>
      </div>
      {isDataSourceModalOpen && <DataSourceListModal form_template_id={selectedFormField.id} 
      data_source_id={selectedFormField.data_source_id} journey_profile_id={queryParam.journey_profile_id}
      toggleModal={toggleDataSourceModal}/>}
      <SideMenuBar helpId="2"/>
    </Fragment>
  )

}

export default DataSourceList;