import React from "react";
import {Link} from "react-router-dom";
import CommonService from '../../../../services/CommonService';
import FormTemplateList from "../../../../constants/FormTemplateList"

export const ServiceRequest = (props) => {
  let {service_request, service_request:{data}} = props;

  const routePath = path => {
    if(path.sid == 'assessments'){
      props.history.push(`/project/service_requests/${props.project.id}`);
    }
  }
  
  return (
    <div className="card-container bg-white shadow-small" style={{paddingTop:'0px'}}>
       <div class="white card-header p-5" style={{backgroundColor:'#607D8B'}}>
        <div className="font-16">
         {data.name}
          <a className="pull-right white link" href={`/serviceprovider/skills/search/${service_request.id}`}>
            <i className="fas fa-share"></i>
          </a>
        </div>
        <div>
          {CommonService.formatUpdateDate(service_request.updated_at)}
        </div> 
      </div>
      
      <div className="content scroll-auto ht-227">

      <table className="table card-table">
          <tbody>
              <tr>
                <td>
                  <span className="pull-left"><strong>Name</strong>: {data.name}</span>
                </td>
              </tr>
              <tr>
                <td>
                <strong>Date</strong>
          <span > {data.start_date}<br/>{data.end_date}</span>
             </td>
              </tr>
              <tr>
                <td>
                  <strong>Notes</strong>
          <p>{data.notes}</p>              
    

            </td>
              </tr>
              
            </tbody>
        </table>
      </div>
      <div className="card-footer">
        <div className="pull-left">
        <span className="m-l-15 ">{FormTemplateList.ProjectServiceRequest} - {service_request.record_id}</span>
          <span className="m-l-25">
            <i className="far fa-comment font-18 text-muted"></i>
            <span className="badge up bg-lgrey">0</span>
          </span>
          <span className="m-l-25" >
            <i className="far fa-check-circle font-18 text-muted"></i>
            <span className="badge up bg-lgrey">0</span>
          </span>
        </div>
        <div className="pull-right">
        <props.menu project={props.project} service_request={service_request} toggleModal={props.toggleModal}/>
   
        </div>
      </div>   
       </div>
  )

};

export default ServiceRequest;
