import React ,{useState, Fragment} from "react";
import { useHistory } from 'react-router-dom'

export const Skills = (props) => {
   let history = useHistory()  	
  let skills = props.skills;
  console.log('skills new props',skills)

  const openSkill = (skill) => {
  	history.push(`/serviceprovider/skill/${skill.service_provider_id}/${skill.id}`)
  }

  return (
  	<Fragment>
  		<div className="skill-container">
        {skills.map((s, i) => (
            <div className="skill" key={i} onClick={e=>{openSkill(s)}}>
              {s.data.title}
            </div>
        ))}
        </div>
  	</Fragment>
  );

};

export default Skills;