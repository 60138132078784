import env from '../../../env';
import axios from 'axios';
let url = `${env.ecn_backend_api}project/service_requests`

class ServiceRequestService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`, { params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(service_request){
    return new Promise((resolve, reject) => {
      axios.post(url, service_request).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(service_request) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${service_request.id}`, service_request).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

}

export default new ServiceRequestService();