import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';
import OrganizationService from '../Organizations/OrganizationService'

const AddMember = ({ member, toggleModal, addNewMember, rolesList, currentUser}) => {

  member.role_ids = member.role_ids || [];
  const [data, setData] = useState(member);
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);

  /* 
   * To check an item exist in checkbox options.
   */
  const setRole = (item) => {
    let list = data.role_ids;
    let idx = list.indexOf(item);
    if(idx > -1) {
      list = list.filter(i => i !== item)
    }else {
      list.push(item)
    }
    data.role_ids = list
    setData({...data,})
  }

  const exists = (item) => {
    let list = data.role_ids;
    return list.indexOf(item) > -1
  }

  const getContacts = (e) =>{
    setContacts([])
    member.email = e.target.value
    OrganizationService.getMembers({'search':e.target.value, 'accelerator_id':currentUser.accelerator.id}).then(res=>{
      if(res.status == 200){
        setContacts(res.data.organization_members)
      }
    })
  }

  const selectContact = (c) =>{
    member.email = c.data.email
    setContacts([])
  }

  const selectableContacts = contacts.map((c, k) =>  
    <div key={k}>
      <span className="fstResultItem" onClick={e => selectContact(c)}>
        {c.data.email}
      </span>
    </div>
  )

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
      <div className="ml-modal-content ml-card-4 ml-animate-zoom">
      
      <div className="ml-center bg-black white p-5">
        <h4>{member.id?'Update':'Add'} Member</h4>
        <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal"
        onClick={e=>toggleModal(e)}>×
        </span>
      </div>
      
      {loading ? <div className="spinner"></div> : <form className="ml-container">
        <div className="ml-section">
          <label><b>Member</b></label>
          <input onChange={e=>getContacts(e)} 
            className="ml-input ml-border ml-margin-bottom"
            type="email" placeholder="User Email..." name="email"  
            value={member.email} required/>
          
          {contacts.length > 0 &&
            <div className="fstResults" id="result_set">
              {selectableContacts}
            </div>
          }
            
          <label><b>Message</b></label>
          <textarea onChange={e=>{member.message = e.target.value}} maxLength="150"
            className="ml-input ml-border ml-margin-bottom" placeholder="Message" 
            defaultValue={member.message} required>
          </textarea>

          <label><b>Roles</b></label>
          {rolesList.map((p, k) => <Fragment>
              <span className="checkbox-container" key={k} onClick={e => setRole(p.record_id)}>
                <span>{p.role}</span>
                <input type="checkbox" checked={exists(p.record_id)} readOnly/>
                <span className="checkbox-checkmark"></span>
                <p className="light-text font-14">{p.description}</p>
              </span>
            </Fragment>
          )}

          <button className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=>{addNewMember(e, data);setLoading(true)}}>
            <b>{member.id?'Update':'Add'}</b>
          </button>
          
        </div>
      </form>}
    
    </div>
  </div>
</Fragment>, document.body))
};

export default AddMember;