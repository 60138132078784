import React, { useState, useEffect, Fragment} from 'react';
import JourneyComments from "./JourneyComments"
import JourneyFileUploads from "./JourneyFileUploads"
import JourneyLinks from "./JourneyLinks"
import useStyle from '../../hooks/useStyle';
import querystringify from "querystringify";
import JourneyTitle from "../Journey/CollegeJourney/JourneyTitle/JourneyTitle";
import {JourneyHome} from '../Common/MenuItem'
import ReactTooltip from "react-tooltip";
import SideMenuBar from "../Common/SideMenuBar";

const WAIT_INTERVAL = 1000;
let timer = null;
let searchKeyword = null;

const JourneyFilesLinks = (props) => {
  useStyle("card");
  useStyle('add_role_dialog');
  
  let params = props.match.params;
  let journey_profile = props.location.state;
  let queryParam = querystringify.parse(props.location.search);

  const [activeTab, setActiveTab] = useState('file_uploads');
  const [search, setSearch] = useState('');

  useEffect(() => {
    timer = null;
    searchKeyword = null;
  }, []);

  const handleChange=(event)=>{
    clearTimeout(timer);
    searchKeyword = event.target.value;
    timer = setTimeout(triggerChange, WAIT_INTERVAL);
  }

  const triggerChange = () => {
    setSearch(searchKeyword);
  }

  return(
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-8">
          {journey_profile && <JourneyTitle title={"Baseline"} project={journey_profile}/>}
        </div>
        
        <div className="col-xs-4 float-right">

        <JourneyHome id="home_icon" journey_profile={journey_profile}
          link={`/journey/portal/${params.journey_profile_id}`}
          className="bg-highlight round-btn float-right m-r-5 m-t-4" 
          icon="fas fa-home" dataTip="Back to Journey Home" />

          <ReactTooltip place="left" />
        </div>
      </div>

        <div className="ml-container bg-white">
          <div className="ml-section">
            <div className="clearfix">
             <div onClick={e=>setActiveTab('file_uploads')} className={`col-xs-4 pill-xs text-center font-14 ${(activeTab == 'file_uploads') ? 'bg-green' :'bg-lgreen'}`}>
              Uploads {activeTab == 'file_uploads' && <div className="ml-tab-active"/>}
             </div>
             <div onClick={e=>setActiveTab('links')} className={`col-xs-4 pill-xs text-center font-14 ${(activeTab == 'links') ? 'bg-green' :'bg-lgreen'}`}>
              Links {activeTab == 'links' && <div className="ml-tab-active"/>}
             </div>
             <div onClick={e=>setActiveTab('comments')} className={`col-xs-4 pill-xs text-center font-14 ${(activeTab == 'comments') ? 'bg-green' :'bg-lgreen'}`}>
              Comments {activeTab == 'comments' && <div className="ml-tab-active"/>}
             </div>
            </div>
            <div className="search-box search-color bg-white shadow-tiny rounded-xl top-5 bottom-10" >
              <i className="fa fa-search"></i>
              <input type="text" placeholder="Search here.." autoFocus onChange={(e)=>handleChange(e)} />
            </div>
          </div>
          <div className="ml-section">
            {activeTab == 'file_uploads' && <div className="col-xs-12"><JourneyFileUploads search={searchKeyword} journeyProfile={journey_profile}/></div>}
            {activeTab == 'comments' && <div className="col-xs-12"><JourneyComments search={searchKeyword} journeyProfile={journey_profile}/></div>}
            {activeTab == "links" && <div className="col-xs-12"><JourneyLinks search={searchKeyword} journeyProfile={journey_profile}/></div>}
          </div>
        </div>
        <SideMenuBar helpId="2"/>
    </Fragment>
  )
}

export default JourneyFilesLinks;