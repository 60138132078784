import env from '../../env';
import axios from 'axios';

class DigitalContentService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}digital_contents`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}digital_contents/${req}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(env.ecn_backend_api+'digital_contents', req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }


  update(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.ecn_backend_api}digital_contents/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.ecn_backend_api}digital_contents/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new DigitalContentService();