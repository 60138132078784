import React from "react";
import { Link } from "react-router-dom";
import FormTemplateList from "../../constants/FormTemplateList"
import CommonService from '../../services/CommonService';
import MilestoneGrid from "../../components/Organizations/ProjectMilestones/MilestoneGrid";

export const Contract = (props) => {
  let contract = props.contract;
  let data = contract.data;
  let data_source = contract.data_source;

  return (
    <div className="card-container bg-white shadow-small" style={{paddingTop:'0px'}}>
     <div class="white card-header  p-5" style={{backgroundColor:'#607D8B'}}>
        <div className="font-16">
        Work Contract       
        </div>
        <div>
          {CommonService.formatUpdateDate(contract.updated_at)}
        </div> 
      </div>
      <div className="scroll-auto ht-227">
      <table className="table card-table">
          <tbody>
              <tr>
                <td>
                <strong>Party 1</strong>
          <div className="font-15">{data_source.party_1.label}</div>
          <div className="font-15">{data.sign_name_1}</div>
                </td>
              </tr>
              <tr>
                <td>
                <strong>Party 2</strong>
          <div className="font-15">{data_source.party_2.label}</div>
          <div className="font-15">{data.sign_name_2}</div>
             </td>
              </tr> 
              <tr>
                <td>
                <strong>Date</strong>
          <div className="font-15">{data.start_date}</div>
          <div className="font-15">{data.end_date}</div>
             </td>
              </tr>
        
              <tr>
                <td>
                <strong>Purpose</strong>
          <div className="font-15">{data.purpose}</div>      
    

            </td>
              </tr>
              <tr>
                <td>
                <strong>Fees</strong>
          <div className="font-15">{data.fees}</div>

            </td>
              </tr>
              <tr>
                <td>
                       
                <strong>Link</strong>
          <a href={data.url}>{data.url}</a>
            </td>
              </tr>
              <tr>
                <td>
                       
                <strong>Link</strong>
          <a href={data.url}>{data.url}</a>
            </td>
              </tr>
              <tr>
                <td>
                       
            {  data.signatures && data.signatures.map((s, key) =>(
        <div className="vcard-field no-border clearfix col-xs-12" key={key}>
          <strong>Signature</strong>
           <div className="font-15">{s.signor_name}</div>
           <div className="font-15">{s.title}</div>
          <div className="font-15">{s.name}</div>
          <div><img src={s.signature} className="img-responsive"/></div>
          <i className="fas fa-marker color-green1-dark" />
        </div>
        ))}
            </td>
              </tr>
            </tbody>
        </table>

        <MilestoneGrid contract={contract} project={props.project} milestones={props.milestones} page="contract"/>

      </div>
      <br/>
      <div className="card-footer">
        <div className="pull-left">
        <span className="m-l-15 "> {FormTemplateList.AdvisorContract}-{contract.record_id} </span>
          <span className="m-l-25" >
            <i className="far fa-comment font-18 text-muted"></i>
            <span className="badge up bg-lgrey">0</span>
          </span>
          <span className="m-l-25" >
            <i className="far fa-check-circle font-18 text-muted"></i>
            <span className="badge up bg-lgrey">0</span>
          </span>
          <span className="m-l-25">
            <Link to={`/incoming/emails/projectContract/213`}>
              <i className="fas fa-envelope font-18 text-muted"></i>
              <span className="badge up bg-lgrey">0</span>
            </Link>
          </span>
        </div>
        <div className="pull-right">
        <props.menu contract={contract} project={props.project}/>

        </div>
      </div> 
    </div>
  );
};

export default Contract;
