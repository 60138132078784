import React ,{useState, Fragment} from "react";
import moment from 'moment'
import { useHistory, Link } from 'react-router-dom'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import StepActivitiesService from "./StepActivitiesService";
import ConfirmMenu from "../../Modals/ConfirmMenu";
import useModal from "../../../hooks/useModal";
import CommonService from '../../../services/CommonService';
import CheckAccess from '../../Roles/CheckAccess';

let selectedStepActivity = null;
export const CompanyStepActivity = (props) => {
  console.log('props step act',props)
  let history = useHistory()    
  //let stepActivities = props.stepActivities;
  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext);
  const [activeIndex, setActiveIndex] = useState();
  const [visible, setVisible] = useState(false);
  const [stepActivities, setStepActivities] = useState(props.stepActivities);
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal();

  const toggleMenu =(i) =>{
    setActiveIndex(i==activeIndex?null:i)
  }

  const deleteStepActivity = (e) => {
    e.preventDefault();
    StepActivitiesService.delete(selectedStepActivity).then((res)=>{
      if(res.status==204){
        setStepActivities(stepActivities.filter(i => i.id !== selectedStepActivity.id))
      }
    })
    toggleDeleteModal(e);
  }

  const confirmDelete = (e, step_activity) =>{
    e.preventDefault();
    selectedStepActivity = step_activity;
    toggleDeleteModal(e);
  }

  const updateStatus = (e, step_activity) =>{
    e.preventDefault();
    step_activity.data.completion_status = step_activity.data.completion_status=='1'?'0':'1';
    StepActivitiesService.update(step_activity).then((res)=>{
      if(res.status==204){
        setStepActivities([...stepActivities]);
      }
    })
  }

  const Menu = ({step_activity}) => {
    let status_form_link = `/form/step_activity_status/${step_activity.journey_profile_id}/${step_activity.activity_id}/${step_activity.id}`
    if(step_activity.status) status_form_link += `/${step_activity.status.id}`;
    return( 
      <span className="dropup pull-right">
        <Fragment>
          <a><i className='fas fa-bars'></i></a>
          <div className="dropup-content w-100p btm-unset r-0">
            {currentUser.id == step_activity.created_by &&
              <Link to={`/step_activity/form/${step_activity.journey_profile_id}/${step_activity.activity_id}/${step_activity.id}?form_id=${step_activity.form_id}`}>
                <i className="far fa-edit"/> Edit
              </Link>
            }
            {currentUser.id == step_activity.created_by &&
              <a href="#" onClick={e => {confirmDelete(e, step_activity)} }>
                <i className="far fa-trash-alt"/> Delete
              </a>
            }
            <Link to={status_form_link}>
              <i className={step_activity.status?'far fa-check-circle':'far fa-circle'}/>  Track Status
            </Link>
          </div>
        </Fragment>
      </span>
    )
  }
  
  const FileList = ({files}) => {
    return(<Fragment>
      {files && files.map((file, k) =>  (
        <a key={k} className="m-t-5 inline-files-list" target="_blank" href={CommonService.getFileUrl(file)}>
          <img src={CommonService.getIconByType(file)} width="35" height="35"/>
          <span className="m-l-5">{CommonService.getFileName(file)}</span>
        </a>
      ))}
      </Fragment>)
  }

  const TeamCard = ({step_activity, data}) => {
    console.log('comapny card step',step_activity,data,stepActivities)
    return(<div className="p-5 font-14">
          <p className="pull-right font-10" style={{    border: "1px solid",
    padding: "2px"}}>{step_activity.author_name}-{CommonService.formatUpdateDate(step_activity.created_at)}</p>
      <a href={data.reference_url} target="_blank">{data.reference_url}</a>
      <FileList files={data.upload_files}/>
      <a className="m-t-5" href="#">{data.reminder}</a>
      <div className="m-t-5" dangerouslySetInnerHTML={{ __html: data.notes }}  />
    </div>)
  }

  const RenderCard = ({step_activity, data}) =>{
    console.log('comapny card RenderCard',step_activity,data,stepActivities)
    if (typeof data.upload_files === 'string') {
      data.upload_files = [data.upload_files]
    }
    if (typeof data.file_upload === 'string') {
      data.file_upload = [data.file_upload]
    }
    console.log('comapny card step1',step_activity,data,stepActivities)

      return <TeamCard step_activity={step_activity} data={data} />
    
  }

  const FileLink = ({step_activity, data}) =>{
      return (<Fragment> 
        {data.upload_files && data.upload_files.length>0 && <span className="pointer pull-right m-r-15">
          <i className="fas fa-paperclip color-highlight"></i>
        </span>}
       </Fragment>  
      )
  }
  console.log('comapny card step2',stepActivities)

  return (
    <Fragment>
      <div className="p-5">
        <strong>Items</strong>
        {stepActivities && stepActivities.map((s, i) =>
          {
            let step_ribbon_color
         
         if(s.updated_at){
           let duration
           var a = moment(s.updated_at);
           var b = moment();

           duration = b.diff(a, 'days')+1;

           if(duration < 7){
             step_ribbon_color= 'green'
           }else if(duration > 7  && duration < 14){
             step_ribbon_color	= 'orange'
           }else {
             step_ribbon_color	= 'red'
           }

         }
         else{
           let duration
           var a = moment(s.created_at);
           var b = moment();
           duration = b.diff(a, 'days')+1;

           if(duration < 7){
             step_ribbon_color= 'green'
           }else if(duration > 7  && duration < 14){
             step_ribbon_color	= 'orange'
           }else {
             step_ribbon_color	= 'red'
           }
         }
         console.log('comapny card step',s,stepActivities,activeIndex,i)

            return(
          <Fragment key={i}>
            <div className="step-activity" style={{borderLeft: '5px solid blue'}} onClick={e=>toggleMenu(i)}>
             <div className="pull-left">{s.data.title}</div>
              <Menu step_activity={s}/>
              <FileLink step_activity={s} data={s.data} /> 
              <span className="pull-right m-r-15 font-12">{s.data.reminder}</span>
            </div>
            {activeIndex == i && <RenderCard step_activity={s} data={s.data} /> }
          </Fragment>
        )})}
        </div>
        <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteStepActivity}/>
    </Fragment>
  )

}

export default CompanyStepActivity;