import React, {useContext, Fragment} from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import Cs from "../../../../../services/CommonService";
import FormTemplateId from "../../../../../constants/FormTemplateList";

const Goals = (props) => {
  const { state: authState, dispatch } = useContext(AuthContext);

  let planner = props.planner;
  let queryParam = props.queryParam;
  planner.updated_at = Cs.formatUpdateDate(planner.updated_at);
  planner.created_at = Cs.formatUpdateDate(planner.created_at);

  let data = planner.data;
  let ds = planner.data_source;

  const SpanField = ({name, label, styleName}) =>(
    <span className={styleName}>{ds[name] && ds[name][label]}</span>
  )

  const SpanList = ({name, label, styleName}) => {
    if(ds[name]){
      return(
        <Fragment>
          {ds[name].map((d, i) => (
            <span className={styleName +' m-l-5'} key={i}>{d.data[label]}</span>
          ))}
          <span className="fas fa-star m-l-5 color-yellow1-dark" onClick={e=>props.openReviewModal(planner)}></span>
        </Fragment>
      )
    }else{
      return null
    }
  }

  const cardStyle = {backgroundColor: "#607D8B"}

  if (!data) return null;

  let incomingMailLink = {
    pathname: `/journey/incoming/emails/planner/${planner.id}`,
    state: { journey: props.journeyCategory, planner: planner },
  }

  const MailTag = () => (
    <a href={`mailto: ${planner.id}-planner@reply.cuetree.com?subject=Planner-${data.name}-${planner.record_id}`}
      target="_blank">
      <i className="far fa-envelope font-18 text-muted" aria-hidden="true"></i>
    </a>
  )

  const Header = () => (
    <div className="white card-header p-5" style={cardStyle}>
      <div className="font-16">
        {data.title}
         <i className="fas fa-expand pull-right white expand p-3" onClick={(e) => props.routeActivity(planner)}></i>     
      </div>
      <div className="font-16">
        <span className="font-14">{planner.updated_at} by {planner.updated_user.name} </span>
      </div>
    </div>
  )

  const Footer = () => (
    <div className="card-footer">
      <Fragment>
        <div className="pull-left lgrey">
          <span id={`comment_${props.index}`} className="m-l-25"
            onClick={(e) => props.addComment(e, planner)}>
            <i className="far fa-comment font-18 text-muted" data-tip="Chat" ></i>
            <span className="badge up bg-lgrey">{planner.comments_count || 0}</span>
          </span>
          <span id={`check_list_${props.index}`} className="m-l-25"
            onClick={(e) => props.addChecklist(e, planner)}>
            <i className="far fa-check-circle font-18 text-muted" data-tip="Checklist"></i>
            <span className="badge up bg-lgrey">{planner.check_list_count || 0}</span>
          </span>
          <span id={`mail_${props.index}`} className="m-l-25">
            <Link onClick={(e) => e.stopPropagation()} to={incomingMailLink}>
              <i className="far fa-envelope font-18 text-muted" data-tip="Email"></i>
              <span className="badge up bg-lgrey">
                {planner.mail_count || 0}
              </span>
            </Link>
          </span>
        </div>
        <div className="pull-right">
          <props.menu planner={planner} />
        </div>
      </Fragment>
    </div>
  )

  const Plan = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <Header/>
        <div className={`scroll-auto ${props.cardHeightCss}`}>
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                  Risk: <SpanList name="risk" label="label" styleName="pill text-left"/>
                </td>
              </tr>
              <tr>
                <td>
                  Completion Data: <span className="pill text-left">{data.completion_date && data.completion_date}</span>
                </td>
              </tr>
              <tr>
                <td>
                  Financial: <SpanList name="financial" label="label" styleName="pill text-left"/>
                </td>
              </tr>
              <tr>
                <td>        
                  Technical : <SpanList name="technical" label="label" styleName="pill text-left"/>
                </td>
               </tr>
               <tr>
                <td>
                  Operational: <SpanList name="operational" label="label" styleName="pill text-left"/>
                </td>
              </tr>
               <tr>
                <td>
                  <div>Description</div>
                  {data.description}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Footer />
        <ReactTooltip place="left" />
      </div>
    )
  }

  return <Plan/>;
}

export default Goals;