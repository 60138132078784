import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from "../contexts/Authentication/AuthStateProvider";

const PrivateRoute = ({component: Component, ...rest}) => {
	const { state:authState, dispatch } = React.useContext(AuthContext);

    if(!authState.isAuthenticated){
    	return(
    		<Route {...rest} render={props => <Redirect to="/signin" />} />
    	)
    }else{
    	return(
    		<Route {...rest} render={(props) => <Component {...rest} {...props}/>}/>
    	)
    }
};

export default PrivateRoute;