import env from '../env';
import axios from 'axios';
import moment from '../../node_modules/moment/min/moment.min.js'

class CommonService {
  
  static useAxios(req) {
    return new Promise((resolve, reject) => {
      axios(req).then((res) => {
        resolve(res);
      })
    })
  }

  /*static getNotifications(paginate){
    var d = $q.defer(); 
      axios({
            url: env.ecn_backend + 'fj_notifications',
            method: "GET",
            params:  paginate
        }).then(function(res){
            if(res.status==200){
              d.resolve(res.data);
            }
        }).catch(function(res){
          d.reject();
        })
      return d.promise; 
  }*/

  static getDataSources(req){
    return new Promise((resolve, reject) => {
      axios.get(env.ecn_backend_api+'data_sources', {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  static getDataSourceList(req){
    return new Promise((resolve, reject) => {
      axios.get(env.ecn_backend_api+'data_source_lists', {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  static getDataSource(params){
    return new Promise((resolve, reject) => {
      axios.post(env.ecn_backend_api+'data_source_lists', params).then((res) => {
        // handle success
          resolve(res.data.data_source_lists);
      })
    })
  }

  static createDataSource(req){
    return new Promise((rs, rj) => {
      axios.post(env.ecn_backend_api+'data_source_list', req).then((res) => {
        // handle success
          rs(res);
      })
    })
  }

  static searchDataSource(params){
    return new Promise((resolve, reject) => { 
      var u = env.ecn_backend_api+'data_source_lists/search'
      axios.post(u,params).then(function success(res){
        if(res.status == 200){
          resolve( res.data );
        }else{
          reject()
        }
      })
    })
  }

  static updateDataSourceListStatus(params){
    return new Promise((resolve, reject) => { 
      var u = env.ecn_backend_api+'data_source_lists/status'
      axios.post(u, params).then(function success(res){
        if(res.status == 200){
          resolve( res );
        }else{
          reject()
        }
      })
    })
  }

  static deleteDataSourceListStatus(req){
    return new Promise((resolve, reject) => {
      axios.delete(env.ecn_backend_api+'data_source_lists/status', {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })    
  }

  /*static updateNotification(id, req){
    var d = $q.defer(); 
    axios.put(env.ecn_backend + 'fj_notifications/'+id, req).then(function(res) {
    if(res.status == 204){
        return res.data;
    }else{
      return res;
    } 
    })
  }

  static deleteNotification(req){
    var d = $q.defer(); 
    let url = '?delete_all='+req.delete_all
    axios.delete(env.ecn_backend + 'fj_notifications/'+req.id+url).then(function(res) {
    if(res.status == 204){
        d.resolve(res.data);
    }else{
      d.reject();
    } 
    })
    return d.promise; 
  }*/

  static uploadFileToUrl(file, privacy_type, data, upload_type, url) {
    return new Promise((resolve, reject) => {
      var fd = new FormData();
      fd.append("file", file);
      fd.append("privacy_type", privacy_type);
      if (data) {
        for (const property in data) {
          fd.append(`${property}`, data[property]);
        }
      }

      var reader = new FileReader();
      var image = new Image();
      reader.readAsDataURL(file);

      reader.onload = function (_file) {
        image.src = _file.target.result;
        var postImageUploadurl = env.ecn_backend_api + (url || "file_uploads");

        if (this.width > 1000) {
          postImageUploadurl = postImageUploadurl + "?max_width=1000";
        }

        axios.post(postImageUploadurl, fd, {
            headers: { "Content-Type": undefined },
        }).then((res)=> {
          try {
            if (upload_type != "comment")
              res.data.file_upload.url = res.data.file_upload.url.split("?")[0];
              resolve(res);
          } catch (e) {
              resolve(res);
          }
        })
      }
    })
  }

  static deleteFileFromUrl(url){
    return new Promise((resolve, reject) => { 
      var list = url.split("/");
      var id = list[5];
      axios.delete(`${env.ecn_backend_api}file_uploads/${id}`).then(function(res){
        resolve(res)
      })
    })  
  }

  static getIconByType(file, type) {
    if (file) {
      if (file.match(/\.(jpeg|jpg|gif|png|ico|svg|gif|webp|avif|heif|heic)$/) != null) {
        if (file.includes("selftree-public")) {
          return file;
        } else {
          return env.file_url + file;
        }
      } else if (file.match(/\.(pdf|PDF)$/)) {
        return "/images/pdf_icon.png";
      } else if (file.match(/\.(xls|XLXS|XLS|xlsx|ods)$/) != null) {
        return "/images/excel_icon.png";
      } else if (file.match(/\.(doc|docx|odt)$/)) {
        return "/images/doc_icon.jpg";
      } else if (file.match(/\.(ppt|pptx|odp)$/)) {
        return "/images/ppt.jpg";
      } else if (file.lastIndexOf(".csv") > 0) {
        return "/images/csv.jpg";
      } else if (file.lastIndexOf(".key") > 0) {
        return "/images/key.png";
      } else {
        return "/images/attachement-64.png";
      }
    }

    if (type == "profile_image") return "/images/avatars/2s.png";
  }

  static getFileUrl(file) {
    return env.file_url + file;
  }
  
  static getFileName(url){
    return url.substring((url.lastIndexOf('/'))+1);
  }

  static extractHostName(url) {
    if (url && url.length > 0) {
      let u = document.createElement("a");
      u.href = url;
      return u.hostname;
    }
    return "";
  }
  
  static getFormTemplateById(req){
    return new Promise((resolve, reject) => {
      var u = env.ecn_backend_api+'form_templates/'+(req.id)

      axios.get(u, {params:req, cache: true}).then(function(response){
       resolve( response.data.form_template );
      })
    })
  }

  static getFormTemplates(req){
    return new Promise((resolve, reject) => {
      var u = env.ecn_backend_api+'form_templates'

      axios.get(u, {params:req}).then(function(response){
       resolve( response );
      })
    })
  }
  
  static getFormFieldsById(req){
    return new Promise((resolve, reject) => {
      var u = env.ecn_backend_api+'form_template/form_fields'

      axios.get(u, {params:req}).then(function(response){
       resolve( response );
      })
    })
  }

  static removeUnusedCss(){
    document.head.querySelectorAll(`link[css-remove="true"]`).forEach(function(a){
      a.remove()
    })    
    //document.querySelectorAll('[rel="stylesheet"]');
  }

  static loadCss(file_name){
    const link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', `/styles/${file_name}.css`);
    link.setAttribute('css-remove', "true");
    document.head.appendChild(link);
  }

  static formatUpdateDate(date, format){
    let f = format || 'MMM D, YYYY';
    if(date)return moment(date).format(f);
  }

  static compareDate(date1, date2, return_type='days', non_negative=false, add_decimal=false){
    const time1 = date2?moment(date2):moment()
    const time2 = moment(date1)

    return  non_negative ? 
      time1.diff(time2, return_type, add_decimal) : time2.diff(time1, return_type, add_decimal)
  }
  
  /*Safari Fix 
    split the date string*/
  static stringToDate(date){
    let s = date.split(/[^0-9]/);
    //for (i=0;i<a.length;i++) { alert(a[i]); }
    //new Date (s[0],s[1]-1,s[2],s[3],s[4],s[5])
    //endDate is considering the 0:00:00 time will display one day before so add 23 59 59
    return new Date (s[0],s[1]-1,s[2], '23', '59', '59');
  }
  
  static getSchedules(req){
    return {
      url: env.ecn_backend_api+'schedules',
      method: 'GET',
      extraParams: {},
      success: function(res) {
        return res.schedules;
      },
      failure: function() {
        alert('there was an error while fetching events!');
      },
    }
  }

  static getFormWizardSchedules(form_wizard_schedule_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(env.backend + `form_wizard_schedulers/${form_wizard_schedule_id}`)
        .then((res) => {
          resolve(res);
        })
    });
  }

  static getFormWizardFields(req) {
    return new Promise((resolve, reject) => {
      axios
        .post(env.backend + "form_wizard_fields/form_templates", req)
        .then((res) => {
          // handle success
          resolve(res);
        })
    });
  }

  static groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  static findObjById(objects, key, value) {
    let r = null
    for (var k in objects) {
      if(objects[k][key] == value)r=objects[k];
    }  
    return r
  }

  static listToMap(list, key){
    return list.reduce((obj, item) => (obj[item[key]] = item, obj) ,{});
  }

  static updateSetting(setting) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.ecn_backend}settings/${setting.id}`, {'setting':setting})
        .then((res) => {
          resolve(res);
        })
    });
  }

  static getSetting() {
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend}settings`)
        .then((res) => {
          resolve(res);
        })
    });
  }
  
  static b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
      encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode("0x" + p1);
        }
      )
    );
  }

  static b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
      atob(str)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  }  

  static getCardCount(screen){
    if(screen.xs){
      return 1
    }else if(screen.sm){
      return 2
    }else if(screen.md){
      return 3
    }else if(screen.lg){
      return 3
    }
  }

  static routeEmissionsPath(path, child, project, history, parent_activity_id, activity_id){
    if(path.sid == 'assess'){
      return {
        match:{params:{
          journey_profile_id: project.id,
          id: activity_id
        }},
        location:{
          state:{id_mother: project.data.cell_number},
          search: `?activity_form_id=526&label=assess`
        }
      }
      //history.push(`/health/activityForm/${project.id}?activity_form_id=488&label=${path.label}`, project)
    }else if(path.sid == 'acquire'){
      return {
        match:{params:{
          journey_profile_id: project.id,
          id: activity_id
        }},
        location:{
          search: `?activity_form_id=527&label=acquire`
        }
      }
    }else if(path.sid == 'design'){
      return {
        match:{params:{
          journey_profile_id: project.id,
          id: activity_id
        }},
        location:{
          search: `?activity_form_id=528&label=design`
        }
      }
    }else if(path.sid == 'pilot'){
      return {
        match:{params:{
          journey_profile_id: project.id,
          id: activity_id
        }},
        location:{
          search: `?activity_form_id=529&label=pilot`
        }
      }
    }else if(path.sid == 'develop'){
      return {
        match:{params:{
          journey_profile_id: project.id,
          id: activity_id
        }},
        location:{
          search: `?activity_form_id=530&label=develop`
        }
      }
    }else if(path.sid == 'operate'){
      return {
        match:{params:{
          journey_profile_id: project.id,
          id: activity_id
        }},
        location:{
          search: `?activity_form_id=531&label=operate`
        }
      }
    }else if(path.sid == 'transact'){
      return {
        match:{params:{
          journey_profile_id: project.id,
          id: activity_id
        }},
        location:{
          search: `?activity_form_id=532&label=transact`
        }
      }
    }else if(path.sid == 'transition'){
      return {
        match:{params:{
          journey_profile_id: project.id,
          id: activity_id
        }},
        location:{
          search: `?activity_form_id=533&label=transition`
        }
      }
    }
  }

  static displayRandomPhrase(i){
    let r_text = [];
    r_text[0] = "Shape the community of the future"
    r_text[1] = "For companies and communities"
    r_text[2] = "From product development to land development"
    r_text[3] = "Build actionable community knowledge"
    r_text[3] = "Augment trained workforce capacity in the field"
    r_text[3] = "Mentor teams, go beyond monitoring"
    r_text[3] = "Translate insights into measurable outcomes"
    return r_text[i]
  }

}

export default CommonService;


