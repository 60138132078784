import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import DynamicForm from '../../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../../constants/FormTemplateList'
import ServiceProviderService from "../../ServiceProviderService";

let formMode = null; 
let skill = {}; 
let data = {}; 
let formFn = {};  

function ServiceSkillAddForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext);

  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  console.log('props',props,isFormRendered)
  let params = props.match.params;
  let service_provider_id = props.match.params.service_provider_id;
  
  let currentUser = authState.user;

  const getById = () => {
    ServiceProviderService.getSkillbyId(params).then((res) => {
      console.log('sp get skill id result',res)
      if(res.status==200){
        skill = res.data.skill;
        data = skill.data || {};
        console.log('get data sp',data)
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
      skill = null;
    })
  }
  
  useEffect(() => { 
    skill = {}; 
    formFn = {};  
    formMode = 'create-form-submissions';
    data = {}; 
    if(params.id){
      formMode = 'edit-form-submissions';
      getById()

    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, [params.id,params.service_provider_id]);

  const create = (skill) => {
    console.log('create',skill,currentUser.accelerator.id);
    skill.accelerator_id = currentUser.accelerator.id;
    skill.service_provider_id =service_provider_id;
    ServiceProviderService.addSkill(skill).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (skill) => {
    skill.accelerator_id = currentUser.accelerator.id;
    skill.service_provider_id =service_provider_id;

    ServiceProviderService.updateSkill(skill).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push("/serviceprovider")
  }
console.log('formrenderd',isFormRendered,data,skill)
  if(isFormRendered){
    return (
    <div className="portlet">
      <div className="portlet-heading bg-highlight color-white">
        <h4 className="portlet-title text-uppercase">
          {post.title}
        </h4>
         <div className="clearfix"></div>
      </div>
      <div id="portlet1" className="panel-collapse collapse in">
        <div className="portlet-body p-t-0">
          <div className="form-details" dangerouslySetInnerHTML={{ __html: post.details }}></div>
          <DynamicForm formMode={formMode} formId={FormTemplateId.ServiceProviderSkillRegistration} form={skill} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
        </div>
      </div>
    </div>
    );  
  }else{
    return('')
  }
  
}

export default ServiceSkillAddForm;