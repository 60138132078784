import React, { useEffect, useState, useContext } from "react";
import querystringify from "querystringify";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../PreLoadSpinner";
import DynamicForm from "../../../../FormBuilder/DynamicForm";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import ManagementService from "../ManagementService";
import FormContainer from '../../../../../components/FormBuilder/FormContainer'

let formMode = null;
let management = {};
let data = {};
let formFn = {};
let formId = null;

function ManagementForm(props) {
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    ManagementService.get({id:params.id}).then((res) => {
      if (res.status == 200) {
        management = res.data.management;
        formId = management.form_id
        data = management.data || {};
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    })
  }

  useEffect(() => {
    management = {}
    data = {}
    formFn = {
      form_type: "management", 
      journey_profile_id: params.journey_profile_id, 
      editPermission:queryParam.edit
    }
    formMode = "create-form-submissions"
    formId = queryParam.form_id
    data = {}

    if (params.id) {
      get();
      formMode = "edit-form-submissions";
    } else if (!isFormRendered) {
      setFormRendered(true);
    }
  }, [])

  const create = (management) => {
    management.created_by = currentUser.id
    management.journey_profile_id = params.journey_profile_id
    management.management_type_id = queryParam.type_id
    management.management_type = queryParam.type
    management.form_id = queryParam.form_id
    ManagementService.create(management).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect();
      }
    })
  }

  const update = (management) => {
    management.updated_by = currentUser.id
    ManagementService.update(management).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect();
      }
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.goBack()
  }

  if(isFormRendered){
    return (
      <FormContainer post={post} formId={formId} showFormUpdateLink={params.journey_profile_id}>
        <DynamicForm formMode={formMode} formId={formId} form={management} data={data}
              formFn={formFn} onCreate={create} onUpdate={update} post={post}
              onCancel={onSubmitPageRedirect} setPost={setPost}/>
      </FormContainer> 
    )  
  }else{
    return null
  }

}

export default ManagementForm;