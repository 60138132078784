import React, {useState} from 'react';
import CueTooltip from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel} from '../FieldLabel'

const InputColorPicker = ({field, formData, readOnly, errors, formFn, isFormWizard, openFieldLabelModal, currentLocale}) => {
  const [showError, setShowError] = useState(false);
  formData[field.client_id] = formData[field.client_id] || field.default_value;

  let inputAttributes = {
    'type':'color',
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only),
    'required':field.required
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let validation = {required: true}
  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  FormHelper.setDisabled(field, inputAttributes, parentAttributes);

  const handleChange = (event) =>{
    const { target } = event;
    const { name } = target;
    formData[name] = target.value;
  }
  
  const setError = (event) => {
    if(field.required){
      const { target:{name, validity:{valueMissing, typeMismatch}} } = event;
      errors[name].invalid = valueMissing;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  return (
  	<div {...parentAttributes}>
  	  <FieldLabel field={field} labelAttributes={labelAttributes} 
        currentLocale={currentLocale}
        openFieldLabelModal={openFieldLabelModal}/>
 	    <input {...inputAttributes} onChange={e => handleChange(e)} onBlur={e => {setError(e)}}
      defaultValue={formData[field.client_id]} className="form-control"
      data-tip data-for={`tooltip_${field.client_id}`}/> 	 
      <span className="fts-13">{field.description}</span>
      <Error/>
      <CueTooltip id={field.client_id} description={field.tooltip}/>
 	  </div> 
  )
}

export default InputColorPicker;