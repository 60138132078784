import React from "react";
import moment from '../../../../node_modules/moment/min/moment.min.js'
import Rating from "../../../components/Rating"

export const ServiceProvider = (props) => {
  console.log('sperovider',props);
  let skill = props.serviceProviderSkill;
  let serviceProvider = skill.service_provider;
  let data = serviceProvider.data;

  const cardStyle = {
      height: '425px',
      borderTop: '5px solid '+ (serviceProvider.data_source.status && serviceProvider.data_source.status.bg_color)
  };

  return (
    <div className="content-boxed shadow-small box-border-highlight" style={cardStyle}>
       <div className="content vcard-header">
          <ul className="d-flex">
          <li>
            <img className="rounded-circle bg-highlight" src='' width="50px"/>
          </li>
          <li className="m-l-15" style={{width:'60%'}}>
              <div className="vcard-title">{data.name ? data.name : '-'}</div>
              <span>since {moment(serviceProvider.updated_at).format('MMM DD, YYYY')}</span>
          </li>
          <li style={{textAlign:'right'}}>
          
          <Rating noOfRating={5} sumOfRating={20} />
          </li>
        </ul>
        <div className="divider bottom-20" style={{ backgroundColor: 'grey'}}/>
        </div>
      
      <div className="content">
        <div className="vcard-field">
          <strong>Industry</strong>
          <a href="#">{serviceProvider.data_source.industry && serviceProvider.data_source.industry.label}</a>
          <i className="far fa-building color-green1-dark" />
        </div>
        <div className="vcard-field">
          <strong>Office</strong>
          <a href="/">{data.street_1}<br/>{data.street_2}<br/>{data.city}<br/> {data.state}</a>
          <i className="fa fa-map-marker color-blue2-dark" />
        </div>
        <div className="vcard-field no-border">
          <strong>Link</strong>
          <a href={data.url}>{data.url}</a>
          <i className="fas fa-external-link-square-alt color-red2-light" />
        </div>
        <div className="vcard-field">
          <strong>Mobile</strong>
          <a href="tel:+{data.mobile}">+{data.mobile}</a>
          <i className="fa fa-phone color-green1-dark" />
        </div>
      </div>
      <props.menu sp={serviceProvider}/>
    </div>
  );
};

export default ServiceProvider;
