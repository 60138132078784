import React,{useState, useEffect, Fragment} from 'react';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import FormTemplateId from '../../constants/FormTemplateList';
import useStyle from '../../hooks/useStyle'
import useEscape from '../../hooks/useEscape';

const ProjectMenu = ({ isOpen, toggleModal, item,addChecklist,addNotes,addDelete,type, MenuItems, menuHeight, menuCss}) => { 
    useStyle('action_sheet');
  
    if(!isOpen) return null;
    
    const menuStyle = {height: (menuHeight || '330px'), opacity: 1, display: 'block', overflowY: 'scroll'}
    const closeStyle = {position: 'absolute', 'right': '0px', 'top': '1px'}

    return(
      <Fragment> 
        <div className={`menu-inline menu-box-bottom menu-box-detached round-medium menu-active ${menuCss}`} style={menuStyle}>
          <br/>
          <span className="ml-button ml-hover-red color-red2-dark font-20 closeStyle" title="Close Modal" 
           onClick={e=>toggleModal(e)} style={closeStyle}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </span>
          <div className="link-list link-list-1 content bottom-0">
            {MenuItems && <MenuItems item={item}/>}
          </div>
        </div>
      </Fragment>
    );
}

export default ProjectMenu;