import React from 'react';
import PrivateRoute from "../../routes/PrivateRoute";
import DigitalContentList from '../../components/DigitalContent/List/DigitalContentList'
import DigitalContentForm from '../../components/DigitalContent/Form/DigitalContentForm'
import DigitalContentDetails from '../../components/DigitalContent/Details/DigitalContentDetails'


const DigitalContentRouter = (props) => (
  <React.Fragment>	
    <PrivateRoute path="/digital_contents" exact >
      <DigitalContentList searchString={props.searchString}/>
    </PrivateRoute>
    <PrivateRoute path="/digital_contents/edit/:id?" component={DigitalContentForm} exact />
    <PrivateRoute path="/digital_contents/details/:id?" component={DigitalContentForm} exact />
    <PrivateRoute path="/content_details/:id?" component={DigitalContentDetails} exact />
    <PrivateRoute path="/digital_contents/create/:type?/:formId?/:id?" component={DigitalContentForm} exact />
  </React.Fragment>
)

export default DigitalContentRouter;


/*
    <PrivateRoute path="/news/activity/create/:journey_category_id?/:education_journey_profile_id?" component={JourneyActivityDashboard} exact />
    */