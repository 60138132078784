//const position = { x: 0, y: 0 };
const edgeType = 'smoothstep';

let initialElements = [
  
]

const resetElement = () => initialElements = [];

const position = [
  { x: 0, y: 80 },
  { x: 500, y: 80 },
  { x: 900, y: 80 },
]

const gradeElement = (grade_data_sources = [], addActivity) => {
  grade_data_sources.forEach((d, i) => {
    
    let data = { 
      label: d.label,
      value:d.value, 
      sourcePosition: i==0?'right':'left',
      targetPosition: i==0?'left':'right', 
      addActivity 
    }

    initialElements.push({
      id: `ds_${d.pk_id}`,
      type: 'AddDataSource',
      data,
      position:position[i]
    })

    if(i!=0){
      initialElements.push({ 
        id: `edge_${d.pk_id}`, 
        source: `ds_${grade_data_sources[i-1]['pk_id']}`, 
        target: `ds_${d.pk_id}`, 
        type: edgeType, 
        animated: true 
      })  
    }
    
  })

}

const setPlanner = (planners = [], planner_color, icons) => {
  let p = { x: 0, y: 160 }
  let positionList = [p]
  planners.forEach((a, i) => {
    if(i!=0){
      p = Object.assign({}, positionList[i-1])
      p.x += 50
      p.y = ((i+1)%2==0?280:160) 
      positionList.push(p)
    }

    initialElements.push({
      id: `planner_${a.id}`,
      type: 'AddPlanner',
      data: { 
        label: (a.data.record_title || a.data.title), 
        journey_profile_id:a.journey_profile_id,
        id:a.id,
        form_id: a.form_id,
        activity_type: a.activity_type,
        bg_color:'lgreen',
        icon:'fas fa-map-marker-alt'
      },
      position: p,
    })

    initialElements.push({ 
      id: `planner_edge_${a.id}`, 
      source: 'ds_39949', 
      target: `planner_${a.id}`, 
      type: edgeType, 
      animated: true 
    })

  })
}

const setActions = (activities = [], planner_color, icons) => {
  let p = { x: 500, y: 160 }
  let positionList = [p]
  activities.forEach((a, i) => {
    if(i!=0){
      p = Object.assign({}, positionList[i-1])
      p.x += 50
      p.y = ((i+1)%2==0?280:160) 
      positionList.push(p)
    }

    initialElements.push({
      id: `activity_${a.id}`,
      type: 'AddActivity',
      data: { 
        label: (a.data.record_title || a.data.name), 
        journey_profile_id:a.journey_profile_id,
        id:a.id,
        form_id: a.form_id,
        activity_type: a.activity_type,
        bg_color:'lgreen',
        icon:'far fa-clipboard'
      },
      position: p,
    })

    initialElements.push({ 
      id: `activity_edge_${a.id}`, 
      source: 'ds_39950', 
      target: `activity_${a.id}`, 
      type: edgeType, 
      animated: true 
    })

  })
}

const setOutcome = (activities = [], planner_color, icons) => {
  let p = { x: 900, y: 160 }
  let positionList = [p]
  activities.forEach((a, i) => {
    if(i!=0){
      p = Object.assign({}, positionList[i-1])
      p.x += 50
      p.y = ((i+1)%2==0?280:160) 
      positionList.push(p)
    }

    initialElements.push({
      id: `activity_${a.id}`,
      type: 'AddStepActivity',
      data: { 
        label: (a.data.record_title || a.data.name), 
        journey_profile_id:a.journey_profile_id,
        id:a.id,
        activity_id:a.activity_id,
        form_id: a.form_id,
        activity_type: a.activity_type,
        bg_color:'lgreen',
        icon:'far fa-chart-bar'
      },
      position: p,
    })

    initialElements.push({ 
      id: `activity_edge_${a.id}`, 
      source: 'ds_39950', 
      target: `activity_${a.id}`, 
      type: edgeType, 
      animated: true 
    })

  })
}

export {initialElements, gradeElement, setPlanner, resetElement, setActions, setOutcome}