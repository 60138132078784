import React, { Fragment, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";

const Footer = (props) => {
  const {state: { screen }, dispatch,} = useContext(AuthContext)
  
  return (
    <Fragment>
      <div className="col-xs-12 footer text-center">
        <div className="col-xs-12 col-md-6">
          <span className={(screen.width > 460 && 'pull-left')+' font-12 white'}>
          <i className="m-r-5 far fa-copyright" aria-hidden="true"></i>
            {new Date().getFullYear()}  IPIPAL INC. All Rights
            Reserved.
          </span>
        </div>
        <div className="col-xs-12 col-md-6">
          <span className={(screen.width > 460 && 'pull-right')+' font-12 white'}>
            <a id="bot_menu_btn" data-tip="Chat Bot"
              onClick={(e)=>{props.setOpenChatBot(!props.openChatBot);}}
              className="">
              <i className="fa fa-circle" />
            </a>         
            <Link className="font-12 white p-5" target="_blank" to="/about_us/terms_of_service">&nbsp;Terms of Service</Link> 
            <Link className="font-12 white p-5" target="_blank" to="/about_us/privacy_policy">&nbsp;Privacy Policy</Link>
          </span>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
