import React ,{Fragment} from "react";
import moment from '../../../../node_modules/moment/min/moment.min.js'
import CommonService from '../../../services/CommonService';

export const Relationship = (props) => {
  let relationship = props.relationship;
  let data = relationship.data;
  let ds = relationship.data_source;
    const SpanField = ({name, label, styleName}) =>(
      <span className={styleName}>{ds[name] && ds[name][label]}</span>
    )

    const SpanList = ({name, label, styleName}) => ds[name] && ds[name].map((d, i) => (
      <span className={styleName +' m-l-5'} key={i}>{d.data[label]}</span>
    ))

    const FileList = ({files}) => {
      return(
        <Fragment>
          {files && files.map((file, k) =>  (
            <a key={k} className="m-t-5 inline-files-list" target="_blank" href={CommonService.getFileUrl(file)}>
              <img src={CommonService.getIconByType(file)} width="35" height="35"/>
              <span className="m-l-5">{CommonService.getFileName(file)}</span>
            </a>
          ))}
        </Fragment>
      )
    }

  return (
    <div className="content-boxed shadow-small box-border-highlight" style={{height: '400px'}}>
      <div className="content">
        <h1 className="vcard-title color-highlight bold">{data.name}</h1>
        <div className="pull-right m-t-n24">{moment(relationship.updated_at).format('MMM DD, YYYY')}</div>
        <div className="vcard-field">
          <strong>Title</strong>
          <div>{data.title}</div>
          <i className="fas fa-male color-green1-dark" />
        </div>
        <div className="vcard-field">
          <strong>Date</strong>
          <div>{data.date}</div>
          <div>{data.end_date}</div>
          <i className="fas fa-calendar-alt color-green1-dark" />
        </div>
        <table>
          <tbody>
            <tr>
              <td><SpanField name="strength" label="label" styleName="pill bg-highlight pull-left"/></td>
              <td><SpanField name="source" label="label" styleName="pill bg-highlight pull-right"/></td>
            </tr>
            <tr>
              <td><SpanField name="purpose" label="label" styleName="pill bg-highlight pull-left"/></td>
              <td><SpanField name="outcome" label="label" styleName="pill bg-highlight pull-right"/></td>
            </tr>
            <tr>
              <td><SpanField name="helpful" label="label" styleName="pill bg-highlight pull-left"/></td>
              <td><SpanList name="channel" label="label" styleName="pill bg-highlight pull-right"/></td>
            </tr>
            <tr>
              <td>
              <FileList files={data.reference_upload}/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <props.menu relationship={relationship}/>
    </div>
  );
};

export default Relationship;
