import React from "react";
import moment from '../../../../../node_modules/moment/min/moment.min.js'
import Skills from '../../../../components/ServiceProvider/Activities/List/Skills';
import Rating from "../../../../components/Rating"
import {Link} from "react-router-dom";
import FormTemplateList from "../../../../constants/FormTemplateList"

const ServiceProvider = (props) => {
  console.log('sperovider',props.serviceProvider);
  let serviceProvider = props.serviceProvider;
  let data = serviceProvider.data;
  
  const cardStyle = {
      borderTop: '5px solid '+ (serviceProvider.data_source.status ? serviceProvider.data_source.status.bg_color :'#b5adad')
  };

  const SkillMenu = ({sp}) => 
    <div className="button button-m shadow-small bg-red1-light">
        <Link to={`/serviceprovider/skill/${sp.service_provider_id}/${sp.id}`}>Edit</Link>
    </div>

  return (
    <div className="content-boxed shadow-small" style={cardStyle}>

<div className="content vcard-header">
          <ul className="d-flex">
          <li>
            <img className="rounded-circle bg-highlight" src='' width="50px"/>
          </li>
          <li className="m-l-15" style={{width:'60%'}}>
              <div className="vcard-title">{data.name ? data.name : '-'}</div>
              <span>since {moment(serviceProvider.updated_at).format('MMM DD, YYYY')}</span>
          </li>
          <li style={{right: "5px",position: "absolute"}}>
          <i className="fas fa-expand pull-right expand" onClick={e=>props.routeActivity(serviceProvider)}></i>
          </li>
        </ul>
        <div className="divider bottom-20" style={{ backgroundColor: 'grey'}}/>
        </div>

      <div className={`scroll-auto ${props.cardHeightCss}`}>
      <table className="table card-table">
          <tbody>
              <tr>
                <td>
                  <span className="pull-right"><strong>Industry</strong>: {serviceProvider.data_source.industry && serviceProvider.data_source.industry.label}</span>
                  <strong>Office</strong>
                  <div>{data.street_1}<br/>{data.street_2}<br/>{data.city}<br/> {data.state}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Rating</strong>
                  <Rating noOfRating={5} sumOfRating={20} />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Link</strong>
                  <a href={data.url}>{data.url}</a>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Mobile</strong>
                  <a href="tel:+{data.telephone}">+{data.telephone}</a>
                </td>
              </tr>
              <tr>
              {serviceProvider.skills && <td><strong>Products/Services</strong>
          <Skills skills={serviceProvider.skills}/></td>}
              </tr>
            </tbody>
        </table>
      </div>
      <div className="card-footer">
        <div className="pull-left">
        <props.details sp={serviceProvider} />
        <span className="m-l-15 ">{FormTemplateList.ServiceProviderRegistration} - {serviceProvider.record_id}</span>
          <span className="m-l-25" onClick={e=>{props.addNotes(e, serviceProvider)}}>
            <i className="far fa-comment font-18 text-muted"></i>
            <span className="badge up bg-lgrey">{serviceProvider.comments_count}</span>
          </span>
          <span className="m-l-25" onClick={e=>{props.addChecklist(e, serviceProvider);}}>
            <i className="far fa-check-circle font-18 text-muted"></i>
            <span className="badge up bg-lgrey">{serviceProvider.checklists_count}</span>
          </span>
          <span className="m-l-25">
            <Link to={`/incoming/emails/serviceProvider/213`}>
              <i className="fas fa-envelope font-18 text-muted"></i>
              <span className="badge up bg-lgrey">0</span>
            </Link>
          </span>
        </div>
        <div className="pull-right">
          <props.menu sp={serviceProvider} toggleModal={props.toggleModal}/>
        </div>
      </div>  
    </div>
  );
};

export default ServiceProvider;



/*

 { serviceProvider.skills !== null ? (
        <div className="vcard">
        <div className="divider bg-highlight"></div>  
        <h1>Skills</h1>
        { serviceProvider.skills !== null ? (
        serviceProvider.skills.map((sp, i) => (
          <div className="vcard" key={i}>
            <ServiceProviderSkill serviceProvider={sp} skillMenu={SkillMenu}/>
          </div>
        ))) :''}
        </div>) :''} */