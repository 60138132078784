export default {
  backend: 'https://beta.cuetree.com/',
  ecn_backend: 'https://enc-api.beta.cuetree.com/',
  ecn_backend_api: 'https://enc-api.beta.cuetree.com/enc/',
  file_url:'https://enc-api.beta.cuetree.com/enc/files?url=',
  ecn_marketing:'https://ace.api.cuetree.com/',
  terms_backend:"https://beta.cuetree.com/",
  admin_emails: [
    "info@cuetree.com",
    "author@ipipal.com",
    "skasi@ipipal.com",
    "jaibharathi1991@gmail.com",
  ]
};

/*export default {
  backend: 'https://beta.cuetree.com/',
  ecn_backend: 'http://127.0.0.1:9000/',
  ecn_backend_api: 'http://127.0.0.1:9000/enc/',
  file_url:'http://127.0.0.1:9000/enc/files?url=',
  admin_emails: [
    "info@cuetree.com",
    "author@ipipal.com",
    "skasi@ipipal.com",
    "jaibharathi1991@gmail.com",
  ]
}*/