import env from '../../../env';
import axios from 'axios';
const url = `${env.ecn_backend_api}project/milestones`

class ProjectMilestoneService {
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}`, {params:req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(contract){
    return new Promise((resolve, reject) => {
      axios.post(`${url}`, contract).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(contract){
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${contract.id}`, contract).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(contract) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${contract.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  createGanttChartData(milestones){
    let chartData = []
    milestones.forEach((item, index)=>{
      let d = {
        id: item.record_id,
        name: item.data.name,
        start: new Date(item.data.start_date_formatted_date),
        end: new Date(item.data.end_date_formatted_date),
        progress: `${item.milestone_status && item.milestone_status.data.progress}0`,
        dependencies: 'Task 2, Task 3',
        custom_class: 'bar-milestone' // optional
      }
      chartData.push(d);
    });
    return chartData;
  }

}

export default new ProjectMilestoneService();