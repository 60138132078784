import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import DynamicForm from '../../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../../constants/FormTemplateList'
import StepActivityStatusService from "./StepActivityStatusService";
import StepActivitiesService from "../StepActivities/StepActivitiesService";
import FormContainer from '../../../../components/FormBuilder/FormContainer'

let formMode = null; 
let status = {}; 
let data = {};
let formFn = {};

function StepActivityStatusForm(props) {

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;

  formFn.milestone_impacted_pagenate = {page:1, per_page:10}
  formFn.milestone_impacted_path = 'name';

  const get = () => {
    StepActivityStatusService.get(params).then((res) => {
      if(res.status==200){
        status = res.data.step_activity_status;
        data = status.data || {};

        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    })
  }
  
  useEffect(() => { 
    status = {}; 
    formFn = {
      'form_type':'step_activity_status', 
      'journey_profile_id':params.journey_profile_id, 
      editPermission:true
    };
    formMode = 'create-form-submissions';
    data = {}; 
    if(params.id && params.id != 'null'){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, [params.id]);

  const create = (status) => {
    status.form_id = FormTemplateId.ProjectMilestone;
    status.journey_profile_id = params.journey_profile_id;
    status.activity_id = params.activity_id;
    status.step_activity_id = params.step_activity_id;
    
    StepActivityStatusService.create(status).then((res) => {
      if(res.status==201){
        status.id = res.data.step_activity_status.id
        onSubmitPageRedirect(status);
      }
    })
  }

  const update = (status) => {
    StepActivityStatusService.update(status).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect(status);
      }
    })
  }

  const onSubmitPageRedirect = (status) => {
    if(props.isPopupMode){
      props.toggleModal()
      props.onSubmit(status)
    }else{
      props.history.goBack();
    }
  }

  formFn.get_milestone_impacted = function(search, on_success, on_failure){
    let req = {'journey_profile_id':params.journey_profile_id}
    StepActivitiesService.getAll(req).then((res) => {
      if(res.status == 200){
        let list = res.data.step_activities.map((i, k)=>{
          return {'name':i.data.name, 'id':i.id, 'record_id':i.record_id}
        });
      /*  if(res.data.meta.total && formFn.milestone_impacted_pagenate.per_page){
          formFn.milestone_impacted_pagenate.pageSize = Math.ceil(res.data.meta.total/formFn.milestone_impacted_pagenate.per_page);
        }*/
        on_success(list);
      }
    })
  }

  formFn.toggle_milestone_impacted = function(item){
    let list = data.milestone_impacted_array || []
    let idx = list.findIndex((i)=>{
      return i.id == item.id;
    }) 

    if(idx > -1) {
      list = list.filter(i => i.id != item.id)
    }else {
      list.push(item)
    }
    data.milestone_impacted = list.map((item)=> {
      return item.record_id
    })
    data.milestone_impacted = data.milestone_impacted.toString()
    return list
  }

  formFn.exist_milestone_impacted = function(item){
    let idx = data.milestone_impacted_array.findIndex((i)=>{
      return i.id == item.id;
    })
    return idx > -1
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.MilestoneStatus} 
          form={status} data={data} formFn={formFn} onCreate={create} 
          onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
      </FormContainer> 
    )  
  }else{
    return null
  }
  
}

export default StepActivityStatusForm;