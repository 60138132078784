import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import DynamicForm from '../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../.././constants/FormTemplateList'
import StepActivitiesService from "./StepActivitiesService";

let formMode = null; 
let stepActivity = {}; 
let data = {}; 
let formFn = {};  

function StepActivityForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  let params = props.match.params;
  let currentUser = authState.user;
  
  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    StepActivitiesService.get(params).then((res) => {
      if(res.status==200){
        stepActivity = res.data.step_activity;
        data = stepActivity.data || {};
        /*TODO Render Delay Issue*/
        params.form_template_id = stepActivity.activity_form_id;
        setFormRendered(true);
      }
    }).catch(function(res){
       stepActivity = null;
    })
  }  
  
  useEffect(() => { 
    stepActivity = {}; 
    data = {}; 
    formFn = {'form_type':'edu_team_journey.step_activity', 'journey_category_id':params.journey_category_id}; 
    formMode = 'create-form-submissions';
    data = {}; 
  
    if(params.id){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, [params.id]);

  const create = (stepActivity) => {
    stepActivity.accelerator_id = currentUser.accelerator.id;
    stepActivity.created_by = currentUser.id;
    stepActivity.updated_by = currentUser.id;
    stepActivity.accelerator_id = currentUser.accelerator.id
    stepActivity.draft = true
    stepActivity.form_id = null
    stepActivity.step_activity_type = null;
    stepActivity.step_activity_id = null
    stepActivity.activity_id = params.activityId
    stepActivity.organization_id = null    
    console.log('create step activity',stepActivity)
    StepActivitiesService.create(stepActivity).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (stepActivity) => {
    stepActivity.accelerator_id = currentUser.accelerator.id;
    stepActivity.created_by = currentUser.id;
    stepActivity.updated_by = currentUser.id;
    stepActivity.draft = false
    stepActivity.form_id = null
    stepActivity.step_activity_type = null;
    stepActivity.step_activity_id = null
    stepActivity.activity_id = params.activityId
    stepActivity.organization_id = null 
    //stepActivity.id = params.id        
    StepActivitiesService.update(stepActivity).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push(`/company/activities/${currentUser.accelerator.id}`);
  }

  if(isFormRendered){
    return (
      <div className="portlet">
        <div className="portlet-heading bg-highlight color-white ">
          <h4 className="portlet-title text-uppercase">
            {post.title}
          </h4>
           <div className="clearfix"></div>
        </div>
        <div id="portlet1" className="panel-collapse collapse in">
          <div className="portlet-body p-t-0">
            <div className="form-details m-t-10" dangerouslySetInnerHTML={{ __html: post.details }}></div>
            <DynamicForm formMode={formMode} formId={FormTemplateId.CompanyStepActivity} form={stepActivity} data={data} 
            formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
            setPost={setPost}/>
          </div>
        </div>
      </div>
    )  
  }else{
    return null;
  }
  
}

export default StepActivityForm;