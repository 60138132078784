import env from '../../env';
import axios from 'axios';
const url = `${env.ecn_backend_api}contracts`

class ContractsService {
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}`, {params:req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(contract){
    return new Promise((resolve, reject) => {
      axios.post(`${url}`, contract).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(contract){
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${contract.id}`, contract).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(contract) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${contract.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  verification(contract){
    return new Promise((resolve, reject) => {
      axios.post(`${url}/invite`, contract).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

}

export default new ContractsService();