import React, { useState ,Fragment} from "react";
import querystringify from "querystringify";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import Organization from "../../../../components/Organizations/Activities/List/Organization";
import OrganizationService from "../../OrganizationService";
import Pagination from '../../../Pagination'
import ConfirmMenu from "../../../Modals/ConfirmMenu";
import CommentsModal from "../../../Modals/CommentsModal";
import MenuModal from "../../../Modals/Menu";
import ProjectMenu from "../../../Modals/ProjectMenu"
import ChecklistsModal from "../../../Modals/ChecklistsModal"
import { Link } from "react-router-dom";
import useModal from "../../../../hooks/useModal";
import FormTemplateId from '../../../../constants/FormTemplateList';
import useStyle from '../../../../hooks/useStyle';
import FilterModal from "../List/OrganizationsFilterModal";
import OrganizationsTable from "../List/OrganizationsTable"
import {MenuItem,CardMenuInfo,NavIcon,} from '../../../Common/MenuItem'
import SideMenuBar from "../../../Common/SideMenuBar";

let requestParams = {};
let data_source = {};
let selectedOrg = {};
let filter = {};

export const OrganizationList = (props) => {
  useStyle('card');  
  useStyle('card_table');
  useStyle('add_role_dialog');

  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  let cardGridCss = 'col-xs-12 col-sm-6 col-md-4'
  let cardHeightCss = 'ht-227'
  if(queryParam.id){
    cardGridCss = 'col-sm-12 col-sm-6 col-md-8'
    cardHeightCss = 'mih-380'
  }

  const { state: authState, dispatch } = React.useContext(AuthContext);
  const [organizationList, setOrganizationList] = useState([]);
  const [currentpage,setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const [cardView, setCardView] = useState(true);

  const fn = {}; //common shareable object for all child element

  fn.deleteOrg = (org) => {
    //Delete
    deleteOrganization(org)
  };

  const { isOpen: isDeleteModalOpen, toggleModal: toggleDelete,} = useModal();
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal, success:menuSuccess } = useModal();
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal, success:commentSuccess } = useModal();
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal, success:checklistSuccess } = useModal();
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal();
  
  React.useEffect(() => {
    requestParams = {
      page: currentpage,
      per_page: 30,
      search: props.searchString || null,
      sort_column: "updated_at",
      sort_direction: "desc",
      accelerator_id: authState.user.accelerator.id,
      filter:{},
      data_source_params:{
        data_source_id:[3383, 3293]
      }
    }

    getOrganizationList();
  }, [authState.user.accelerator, props.searchString, currentpage]);

  let getOrganizationList = () => {
    OrganizationService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        setPagemeta(res.data.meta);
        setOrganizationList(res.data.organizations);
        data_source = res.data.data_source_list;
      }
    })
  }

  let deleteOrganization = (e) => {
    e.preventDefault();
    OrganizationService.delete(selectedOrg).then((res) => {
      if (res.status == 204) {
        setPagemeta(res.data.meta);
        setOrganizationList(organizationList.filter((i) => i.id !== selectedOrg.id));
      }
    })
    toggleDelete(e);
  }

  const onDelete = (e, org) => {
    selectedOrg = org;
    toggleDelete(e);
  }

  const addMenu = (e, org) => {
    selectedOrg = org;
    toggleMenuModal(e);
  }

  const addNotes = (e, org) => {
    selectedOrg = org;
    toggleCommentModal(e);
  }

  const addChecklist = (e, org) => {
    selectedOrg = org;
    toggleChecklistModal(e);
  }

  const Details = ({ org, fn }) => (
      <span className="m-l-15" onClick={ ()=> props.history.push(`/organization/details/${org.id}`)}  >
        <i className="fas fa-info-circle font-18 text-muted" />
      </span> 
  );

  const Menu = ({ org, fn }) => (
    <span className="m-r-15" >
      <i onClick={e=>{addMenu(e, org)}} className="fas fa-bars font-18 text-muted" />     
    </span>
  )

  const routeActivity = (org) =>{
    props.history.push(`/organizations?id=${org.id}`)
    setOrganizationList(organizationList.filter(i => i.id == org.id))
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <CardMenuInfo formId={item.form_id} recordId={item.record_id} 
          createdAt={item.created_at} userName={item.created_user.name}/>
        <MenuItem id="addmember_icon" 
          link={`/organization/profile/member/${item.id}`}
          icon="fas fa-plus"
          action="Add Member"
        />
        <MenuItem id="edit_icon" 
          link={`/organization/profile/${item.id}`}
          icon="far fa-edit"
          action="Edit"
        />
        <MenuItem id="delete_icon" 
          icon="far fa-trash-alt"
          action="Delete"
          onSelect={(e) => {onDelete(e, item);}}
        />
        <MenuItem id="contract_icon" 
          link={`/contract/registration/organization/${item.id}/${FormTemplateId.OrganizationContract}/${item.contract_id}`}
          icon="fa fa-file-contract"
          action="Standard Contract"
        />
        <MenuItem id="track_icon" 
          link={`/list/relationship/tracking/${item.id}/organization`}
          icon="fa fa-retweet"
          action="Track Relationship"
        />
      </Fragment>
    )
  }

  const organizationsCard = organizationList.map((org, i) =>
    <div className={cardGridCss} key={i}>
      <Organization cardHeightCss={cardHeightCss} routeActivity={routeActivity}  organization={org} addNotes={addNotes} addChecklist={addChecklist} fn={fn} menu={Menu} details={Details} toggleModal={toggleDelete}/>
      {selectedOrg.record_id == org.record_id && 
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedOrg} 
        addChecklist={addChecklist} addNotes={addNotes} onDelete={onDelete} type="organization" 
        MenuItems={menuItems}/>}
    </div>
  )

  return (
    <React.Fragment>
      <div className="content top-10">
        <h5 className="float-left font-500">Contact Lists</h5>

        <NavIcon id="add_goal" dataTip="Create New Contact" 
            link={`/organization/profile`}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-plus"/>
        
        <NavIcon id="filter_items" dataTip="Filter Items" 
          className="bg-highlight round-btn float-right m-r-5 m-t-4" 
          onSelect={e=>toggleFilterModal(e)} icon="fas fa-sliders-h"/>

        <NavIcon id="toggle_view" dataTip="Toggle View" 
          className="bg-highlight round-btn float-right m-r-5 m-t-4" 
          onSelect={e=>setCardView(!cardView)} icon="fas fa-bars"/>

        <div className="clear"></div>
      </div>

      <div className="page-content row">
        {cardView && organizationsCard}
        {queryParam.id && organizationList.length>0 && <div className="col-sm-12 col-sm-6 col-md-4">
        <CommentsModal isOpen="true" toggleModal={toggleCommentModal} item={selectedOrg} type="organization" showInline="true" /> 
        </div>}
        {!cardView && <OrganizationsTable organizationList={organizationList} addMenu={addMenu} requestParams={requestParams} getOrganizationList={getOrganizationList}/>}
        <Pagination pagemeta={pagemeta} currentpage={currentpage} setCurrentpage={setCurrentpage}/>
      </div>
  
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDelete} success={deleteOrganization}/>
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedOrg} type="organization"/>}
      {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} item={selectedOrg} type="organization"/>}
      {isFilterOpen && <FilterModal filterData={requestParams.filter} filterDataSources={data_source} isOpen={isFilterOpen} toggleModal={toggleFilterModal} onSearch={getOrganizationList}/>}
      <SideMenuBar helpId="2"/>
    </React.Fragment>
  );
};

export default OrganizationList;

/*<Link to={`/track/relationship/${org.id}/organization`}>Track Relationship</Link>*/