import React, { useEffect, useState, useContext } from "react";
import querystringify from "querystringify";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import DynamicForm from "../../../../../components/FormBuilder/DynamicForm";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import ActivityService from "../ActivityService";
import FormContainer from '../../../../../components/FormBuilder/FormContainer'

let formMode = null;
let activity = {};
let data = {};
let formFn = {};
let formId = null;

function ActivityForm(props) {
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);
  console.log('props form',props)
  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    ActivityService.get({id:params.id}).then((res) => {
      if (res.status == 200) {
        activity = res.data.activity;
        formId = activity.form_id
        data = activity.data || {};
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    })
  }

  useEffect(() => {
    activity = {}
    data = {}
    formFn = {form_type: "activity", journey_profile_id: params.journey_profile_id, editPermission:queryParam.edit}
    formMode = "create-form-submissions"
    formId = queryParam.activity_form_id
    data = {}

    if (params.id) {
      get();
      formMode = "edit-form-submissions";
    } else if (!isFormRendered) {
      setFormRendered(true);
    }
  }, [])

  const create = (activity) => {
    activity.created_by = currentUser.id
    activity.journey_profile_id = params.journey_profile_id
    activity.activity_type = queryParam.label
    activity.form_id = queryParam.activity_form_id
    ActivityService.create(activity).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect();
      }
    })
  }

  const update = (activity) => {
    activity.updated_by = currentUser.id
    ActivityService.update(activity).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect();
      }
    })
  }

  const onSubmitPageRedirect = () => {
    //props.history.push(`/baseline/${params.journey_profile_id}`)
    props.history.goBack()
  }

  if(isFormRendered){
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={formMode} formId={queryParam.activity_form_id || formId} form={activity} data={data}
          formFn={formFn} onCreate={create} onUpdate={update} post={post}
          onCancel={onSubmitPageRedirect} setPost={setPost}/>
      </FormContainer> 
    )  
  }else{
    return null
  }

}

export default ActivityForm;