import React, { useEffect, useState, useContext} from "react";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import querystringify from "querystringify";
import PreLoadSpinner from "../../../components/PreLoadSpinner";
import DynamicForm from "../../../components/FormBuilder/DynamicForm";
import FormTemplateId from "../../../constants/FormTemplateList";
import JourneyProfileService from "../JourneyProfileService";
import CommonService from "../../../services/CommonService";
import FormContainer from '../../../components/FormBuilder/FormContainer'
import FormWizard from "../../../components/FormBuilder/FormWizard";
import env from "../../../env";

let formMode = null;
let journeyProfile = {};
let data = {};
let formFn = {};

function JourneyProfileForm(props) {
  const { state: authState, dispatch } = useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);
  let currentUser = authState.user;

  /*TODO: Move API to Router to load data before view*/
  const getById = () => {
    let req = {'id':params.id}
    JourneyProfileService.get(req).then((res) => {
      if (res.status == 200) {
        journeyProfile = res.data.journey_profile;
        data = journeyProfile.data || {};
        setFormRendered(true);
      }
    })
  }

  useEffect(() => {
    formMode = "create-form-submissions";
    data = {};
    journeyProfile = {};
    formFn = { form_type: "journey_profile" };
    if (queryParam.view_mode) {
      getById(params.id);
      formMode = "view-form-submissions";
    } else if (params.id) {
      formMode = "edit-form-submissions";
      getById();
    } else {
      setFormRendered(true);
    }
  }, [])

  const create = (journey_profile) => {
    journey_profile.created_by = authState.user.id;
    journey_profile.form_id = queryParam.form_id;
    journey_profile.journey_type = queryParam.journey_type;
    journey_profile.journey_type_id = queryParam.journey_type_id;
    JourneyProfileService.create(journey_profile).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect(true);
      }
    })
  }

  const update = (journey_profile) => {
    JourneyProfileService.update(journey_profile).then((res) => {
      if (res.status == 204) {
        journeyProfile = journey_profile;
        onSubmitPageRedirect();
      }
    })
  }

  const onSubmitPageRedirect = (show_success_message) => {
    props.history.push('/journey')    
  }

  const back = () => {
    props.history.goBack();
  }

  if (queryParam.show_form_wizard) {
    return (
      <FormWizard
        form_wizard_id={queryParam.form_wizard_id}
        form={journeyProfile}
        data={data}
        formFn={formFn}
        onCreate={create}
        onUpdate={update}
        onCancel={onSubmitPageRedirect}
        isInsideChatBot={props.isInsideChatBot}/>
    );
  }
  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={queryParam.form_id} form={journeyProfile} data={data}
         formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={back} setPost={setPost}/>
      </FormContainer> 
    )  
  }else{
    return null
  }


}

export default JourneyProfileForm;
