import React, {useState, Fragment} from "react";
import querystringify from "querystringify";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import ServiceProvider from '../../../../components/ServiceProvider/Activities/List/ServiceProvider';
import ServiceProviderService from "../../ServiceProviderService";
import Pagination from '../../../Pagination'
import ConfirmMenu from "../../../Modals/ConfirmMenu";
import FormTemplateId from '../../../../constants/FormTemplateList';
import CommentsModal from "../../../Modals/CommentsModal";
import CardMenu from "../../../Modals/CardMenu"
import {Link} from "react-router-dom";
import useModal from "../../../../hooks/useModal";
import NotesModal from "../../../Modals/NotesModal";
import MenuModal from "../../../Modals/Menu";
import ChecklistsModal from "../../../Modals/ChecklistsModal"
import useStyle from '../../../../hooks/useStyle';
import FilterModal from "../List/ServiceProviderFilterModal";


let requestParams = {}
let selectedSp = {};
let data_source = {};

export const ServiceProviderList = (props) => {
  useStyle('card');
  useStyle('card_table');
  useStyle('add_role_dialog');

  console.log('props sp',props)
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  let cardGridCss = 'col-xs-12 col-sm-6 col-md-4'
  let cardHeightCss = 'ht-227'
  if(queryParam.id){
    cardGridCss = 'col-sm-12 col-sm-6 col-md-8'
    cardHeightCss = 'mih-380'
  }

  const { state:authState, dispatch } = React.useContext(AuthContext);

  const [serviceProviderList, setServiceProviderList] = useState([]);
  const [currentpage,setCurrentpage] = useState(1);

  const [pagemeta, setPagemeta] = useState([]);
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal();


  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal, success:commentSuccess } = useModal();


  const deleteServiceprovider = () => {
   ServiceProviderService.delete(selectedSp).then((res) => {
      if (res.status == 204) {
        setServiceProviderList(serviceProviderList.filter(i => i.id !== selectedSp.id))
      }
    })
  };

  const { isOpen, toggleModal:toggleDeleteModal, success } = useModal(deleteServiceprovider);
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal, success:menuSuccess } = useModal();
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal, success:checklistSuccess } = useModal();

  React.useEffect(() => {
    requestParams = {
      'page': currentpage, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'accelerator_id': authState.user.accelerator.id,
    }

    getServiceproviderList();
  }, [authState.user.accelerator,props.searchString,currentpage]);

  let getServiceproviderList = () => { 
    ServiceProviderService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       console.log('res data companies',res)
       setPagemeta(res.data.meta)
       setServiceProviderList(res.data.service_providers)
       data_source = res.data.data_source_list;
      }
    })
  }

  const onDelete = (e, sp) => {
    selectedSp = sp;
    toggleDeleteModal(e);
  }

  const addMenu = (e, sp) => {
    selectedSp = sp;
    toggleMenuModal(e);
  }

  const addChecklist = (e, sp) => {
    selectedSp = sp;
    toggleChecklistModal(e);
  }
  const addNotes = (e, sp) => {
    selectedSp = sp;
    toggleCommentModal(e);
  }

  const routeActivity = (sp) =>{
    props.history.push(`/serviceprovider?id=${sp.id}`)
    setServiceProviderList(serviceProviderList.filter(i => i.id == sp.id))
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <Link to={`/serviceprovider/skill/${item.id}/`} >
        <i className="fa fa-shield-alt"></i>
        <span className="font-13">Add Skills</span>
        <i className="fa fa-angle-right"></i>
        </Link>
        <Link to={`/contract/registration/service_provider/${item.id}/${FormTemplateId.OrganizationContract}/${item.contract_id}`} >
        <i className="fa fa-file-contract"></i>
        <span className="font-13">Standard Contract</span>
        <i className="fa fa-angle-right"></i>
            </Link>
        <Link to={`/list/relationship/tracking/${item.id}/service_provider`} >
         <i className="fa fa-retweet"></i>
         <span className="font-13">Track Relationship</span>
         <i className="fa fa-angle-right"></i>
             </Link>
      </Fragment>
    )
  }

  const Menu = ({ sp, fn }) => (
    <span className="m-r-15" >
        <i onClick={e=>{addMenu(e, sp)}} class="fas fa-bars" />       
    </span>
  );

  const Details = ({ sp, fn }) => (
    <span className="m-l-15" onClick={ ()=> props.history.push(`/serviceprovider/details/${sp.id}`)}  >
    <i className="fa fa-info-circle" />
    </span> 
  );

  const serviceProviderCard = serviceProviderList.map((sp, i) => (
    <div className={cardGridCss} key={i}>
      <ServiceProvider serviceProvider={sp} cardHeightCss={cardHeightCss} routeActivity={routeActivity}  menu={Menu} addNotes={addNotes} addChecklist={addChecklist}
        details={Details}
        toggleModal={toggleDeleteModal} />
         {selectedSp.record_id == sp.record_id && 
          <CardMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedSp} addChecklist={addChecklist} addNotes={addNotes} onDelete={onDelete} type="serviceprovider" MenuItems={menuItems}/>
          }
    </div>
  ))
  

  return (
    <React.Fragment>
     <div className="content top-10">
        <h5 className="float-left font-500">Vendor List</h5>
        <Link to="/serviceprovider/profile" className="bg-highlight round-btn float-right m-r-5">  <i className="fas fa-plus"></i></Link>
        
        <Link to="/serviceprovider/profile" className="bg-highlight round-btn float-right m-r-5" onClick={e=>toggleFilterModal(e)}>
          <i className="fas fa-sliders-h"></i>
        </Link>
        <div className="clear"></div>
    </div>

    <div className="page-content row">
        {serviceProviderCard}
        {queryParam.id && serviceProviderList.length>0 && <div className="col-sm-12 col-sm-6 col-md-4">
        <CommentsModal isOpen="true" toggleModal={toggleCommentModal} item={selectedSp} type="serviceprovider" showInline="true" /> 
        </div>}
        <Pagination pagemeta={pagemeta} currentpage={currentpage} setCurrentpage={setCurrentpage} />
    </div>
    <ConfirmMenu
        isOpen={isOpen}
        toggleModal={toggleDeleteModal}
        success={success}
      />
    {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedSp} type="serviceprovider"/>}
    {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} item={selectedSp} type="serviceprovider"/>}
    {isFilterOpen && <FilterModal filterData={requestParams.filter} filterDataSources={data_source} isOpen={isFilterOpen} toggleModal={toggleFilterModal} onSearch={getServiceproviderList}/>}

    </React.Fragment>
  )

};

export default ServiceProviderList;
