import React, { Fragment, useState, useEffect, useRef, useContext} from "react";
import ReactDOM from "react-dom";
import { useHistory, useLocation } from "react-router-dom";
import useStyle from "../../hooks/useStyle";
import HelpSideNav from "../../components/Help/HelpSideNav";
import NotesModal from "../../components/Modals/NotesModal";
import useModal from "../../hooks/useModal";
import usePageTour from "../../components/Common/usePageTour";
//import InviteModal from "../../components/Modals/InviteModal";
import env from "../../env";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";

const SideMenuBar = (props) => {
  const history = useHistory();
  const location = useLocation();

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  let { isOpen: isPageNoteOpen, toggleModal: togglePageNoteModal } = useModal();
  let { isOpen: isInviteUserPopupOpen, toggleModal: toggleInviteUserPopup,} = useModal();

  const [isSideMenuOpen, setSideMenuOpen] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  let tour = usePageTour(true);

  useEffect(() => {
    if (isSideMenuOpen) {
      setIsHelpOpen(false);
    }
  }, [isSideMenuOpen]);

  const openFeedback = (e) => {
    history.push(`/feedback?url=${location.pathname}`);
  }

  const openTour = (e) => {
    setTimeout(function () {
      tour && tour.start();
    }, 1000);
    setSideMenuOpen(!isSideMenuOpen);
  };

  if (props.app) {
    props.app.openHelp = (e) => {
      e.preventDefault();
      setIsHelpOpen(!isHelpOpen);
    }
  }

  if (isSideMenuOpen) {
    let style = { padding: "12px", borderRadius: "10px" };
    return ReactDOM.createPortal(
      <Fragment>
        <div className="side-menu-bar" style={style}>
          <div onClick={(e) => setSideMenuOpen(!isSideMenuOpen)}>
            <i className="fas fa-arrow-right"></i>
          </div>
          <div className="element" onClick={(e) => {setIsHelpOpen(!isHelpOpen);setSideMenuOpen(!isSideMenuOpen);}}>
            Help
          </div>
          <div className="element" onClick={(e) => {openFeedback(e);setSideMenuOpen(!isSideMenuOpen);}}>
            Feedback
          </div>
          <div className="element" onClick={(e) => {togglePageNoteModal(e);setSideMenuOpen(!isSideMenuOpen);}}>
            My Notes
          </div>
          <div className="element-dotted" onClick={(e) => {openTour(e);setSideMenuOpen(!isSideMenuOpen);}}>
            Screen Guide
          </div>
          {env.admin_emails.includes(currentUser.email) && (
            <Fragment>
              <div className="element" onClick={(e) => {toggleInviteUserPopup(e);setSideMenuOpen(!isSideMenuOpen);}}>
                Invite Others
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>, document.body);
  } else {
    let style = { padding: "5px" };
    return ReactDOM.createPortal(
      <Fragment>
        <div className="side-menu-bar" style={style}
          onClick={(e) => setSideMenuOpen(!isSideMenuOpen)}>
          <i className="fas fa-arrow-left"></i>
        </div>
        {isHelpOpen && (
          <HelpSideNav helpId={props.helpId} setIsHelpOpen={setIsHelpOpen} />
        )}
        {isPageNoteOpen && <NotesModal toggleModal={togglePageNoteModal} />}
      </Fragment>, document.body
    )
  }
}

export default SideMenuBar;