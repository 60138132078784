import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../components/PreLoadSpinner";
import DynamicForm from '../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../constants/FormTemplateList'
import ForumService from "../../../components/Forums/ForumService";

let formMode = null; 
let formId = null; 
let forum = {}; 
let data = {}; 
let formFn = {};  

function ForumForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext);
  console.log('props forum',props)
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;
  
  let currentUser = authState.user;

  /*TODO: Move API to Router to load data before view*/
 
  
  useEffect(() => { 
    forum = {}; 
    data = {}; 
    formFn = {}; 
    formMode = 'create-form-submissions';
    if(props.match.path){
      if(props.match.path.includes('details')){
        console.log('details')
       // get()
        formMode = 'view-form-submissions';
      }else if(!isFormRendered){
        setFormRendered(true);
      }
    }
   
  }, [params.id]);

  const create = (forum) => {
    forum.accelerator_id = currentUser.accelerator.id;
    ForumService.create(forum).then((res) => {
      console.log('forums form ',res)
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }


  const onSubmitPageRedirect = () => {
    props.history.push("/forums")
  }

  if(isFormRendered){
    return (
    <div className="portlet">
      <div className="portlet-heading bg-highlight color-white">
        <h4 className="portlet-title text-uppercase">
          {post.title}
        </h4>
         <div className="clearfix"></div>
      </div>
      <div id="portlet1" className="panel-collapse collapse in">
        <div className="portlet-body p-t-0">
          <div className="form-details" dangerouslySetInnerHTML={{ __html: post.details }}></div>
          <DynamicForm formMode={formMode} formId={FormTemplateId.ForumNewDiscussion} form={forum} data={data} 
          formFn={formFn} onCreate={create} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
        </div>
      </div>
    </div>
    );  
  }else{
    return('')
  }
  
}

export default ForumForm;