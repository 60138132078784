import React, {Fragment, useState, useEffect} from "react";
import ReactTooltip from "react-tooltip";
import AudioRecorder from 'audio-recorder-polyfill'
import Cs from '../../services/CommonService'
window.MediaRecorder = AudioRecorder

let recordedChunks = [];

const RecordAudio = (props) => {
  const [showPlayer, setShowPlayer] = useState(false); 
  const [showStopBtn, setShowStopBtn] = useState(false); 
  const [permissionDenied, setPermissionDenied] = useState(false); 

  useEffect(() => {
    ReactTooltip.rebuild()
    recordedChunks = []
  }, []);

  function supportsRecording(mimeType)
  {
      if (!window.MediaRecorder)
          return false;
      if (!MediaRecorder.isTypeSupported)
          return mimeType.startsWith("audio/mp4") || mimeType.startsWith("video/mp4");
      return MediaRecorder.isTypeSupported(mimeType);
  }
  console.log('supprt',supportsRecording('audio/mp4'))
  
  const record = () =>{
    setPermissionDenied(false)
    recordedChunks = []
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    .then(handleSuccess)
    .catch(handleFailure);
    setShowStopBtn(true)
  }

  const handleFailure = function(e) {
    console.log(e)
    setPermissionDenied(true)
  }

  const handleSuccess = function(stream) {
    const stopButton = document.getElementById('stop');

      const options = {mimeType: 'audio/mp4'};
      const mediaRecorder = new MediaRecorder(stream, options);

      mediaRecorder.addEventListener('dataavailable', function(e) {
        if (e.data.size > 0) recordedChunks.push(e.data);
      });

      stopButton.addEventListener('click', function() {
        mediaRecorder.stop();
        setShowPlayer(true)
        setShowStopBtn(false)
        play()
        /*downloadLink.href = URL.createObjectURL(new Blob(recordedChunks));
        downloadLink.download = 'acetest.wav';*/
      });

      mediaRecorder.start();
  }

  const play = () =>{
    setTimeout(() => {
      const player = document.getElementById('player');
      const url = URL.createObjectURL(new Blob(recordedChunks));
      // Do something with the audio file.
      player.src = url;
    }, 0);
  }

  if (MediaRecorder.notSupported) {
    return(
      <label className="lred">Audio Recorder Not Supported</label>
    )
  }else if(permissionDenied){
    return(
      <label className="lred">Audio Permission Denied</label>
    )
  }

  return(
    <Fragment>
      <audio id="player" className={showPlayer?'':'hidden'} controls></audio>
      <br/>
      <span id="start" data-tip="Start Recording" className="bg-highlight round-btn m-r-5 m-t-4" onClick={e=>record()}>
        <i className="fas fa-microphone-alt"/>
      </span>
      {showStopBtn && <label className="green"> Started Recording</label>
      }
      {showStopBtn && 
        <span id="stop" data-tip="Stop Recording" className="bg-highlight round-btn m-r-5 m-t-4">
          <i className="fas fa-stop-circle"/>
        </span>
      }
      <ReactTooltip place="bottom" />
    </Fragment>
  )
}

const RecordAudioSafari = (props) =>{
  let EVENTS = ['start', 'stop']
  let TYPES = ['audio/webm', 'audio/ogg', 'audio/wav', 'audio/acc']

  let recorder, list, recordFull, stop, loading

  const KB = 1 << 10
  const MB = 1 << 20

  function bytes (value) {
    let mag = Math.abs(value)

    let unit
    if (mag >= MB) {
      unit = 'MB'
      value = value / MB
    } else if (mag >= KB) {
      unit = 'KB'
      value = value / KB
    } else {
      unit = 'B'
    }

    return value.toFixed(0).replace(/(?:\.0*|(\.[^0]+)0+)$/, '$1') + ' ' + unit
  }

  function startRecording (type) {
    list.innerHTML = ''
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      recorder = new MediaRecorder(stream)

      /*EVENTS.forEach(name => {
        recorder.addEventListener(name, changeState.bind(null, name))
      })*/
      recorder.addEventListener('dataavailable', saveRecord)

      if (type === 'full') {
        recorder.start()
      } 
    })
    //recordParts.blur()
    recordFull.style.display = 'none'
    loading.style.display = 'block'
    stop.style.display = 'block'
  }

  function blobToFile(theBlob, fileName){       
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
  }
  
  function saveRecord (e) {
    let file = blobToFile(e.data, 'audio.wav')

    let li = document.createElement('div')

    let strong = document.createElement('strong')
    strong.innerText = 'data available: '
    li.appendChild(strong)

    let s = document.createElement('span')
    s.innerText = e.data.type + ', ' + bytes(e.data.size)
    li.appendChild(s)

    let audio = document.createElement('audio')
    audio.controls = true
    audio.src = URL.createObjectURL(e.data)
    li.appendChild(audio)

    list.appendChild(li)

    Cs.uploadFileToUrl(file, 'private', {
      'journey_profile_id': props.journeyProfileId,
      'fileable_type': 'journey_profile_audio',
      'fileable_id': props.journeyProfileId,
    }).then((res)=>{
      if(res.status == 201)props.onUploadSuccess(res.data);
    })
  }

  function stopRecording () {
    recorder.stop()
    recorder.stream.getTracks()[0].stop()
    stop.style.display ='none'
    recordFull.style.display = 'block'
    loading.style.display = 'none'
  }

  useEffect(() => {
    list = document.getElementById('list')

    //recordParts = document.getElementById('sec')
    recordFull = document.getElementById('record')
    /*request = document.getElementById('request')
    resume = document.getElementById('resume')
    pause = document.getElementById('pause')*/
    stop = document.getElementById('stop')
    loading = document.getElementById('loading')
    stop.style.display = 'none'
    loading.style.display = 'none'
    
    if (MediaRecorder.notSupported) {
      list.style.display = 'none'
      document.getElementById('controls').style.display = 'none'
      document.getElementById('formats').style.display = 'none'
      document.getElementById('support').style.display = 'block'
    } else {
      document.getElementById('formats').innerText =
        'Format: ' +
        TYPES.filter(i => {
          console.log(i)
          console.log(MediaRecorder.isTypeSupported(i))
          return MediaRecorder.isTypeSupported(i)
        }).join(', ')

      //recordParts.addEventListener('click', startRecording.bind(null, 'parts'))
      recordFull.addEventListener('click', startRecording.bind(null, 'full'))
      /*request.addEventListener('click', requestData)
      resume.addEventListener('click', resumeRecording)
      pause.addEventListener('click', pauseRecording)*/
      stop.addEventListener('click', stopRecording)

      //recordParts.disabled = false
      recordFull.disabled = false
    }
  }, [])
    
  return(
    <div className="row p-5">
      <div className="col-xs-12 p-5">
        <div id="controls" className="d-flex">
          <span className="loading" id="loading"/>
          <span id="record" data-tip="Start Recording" className="bg-highlight round-btn m-r-5 m-t-4">
            <i className="fas fa-microphone-alt"/>
          </span>

          <span id="stop" data-tip="Stop Recording" className="bg-highlight round-btn m-r-5 m-t-4">
            <i className="fas fa-stop-circle"/>
          </span>
        </div>
        <div id="formats">Format: audio/webm</div>
        <div id="list" />
      </div>
    </div>
  )

}

export {RecordAudio, RecordAudioSafari};