import React, { useEffect, useState } from 'react';
import querystringify from "querystringify";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../components/PreLoadSpinner";
import DynamicForm from '../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../constants/FormTemplateList'
import DigitalContentService from '../../DigitalContent/DigitalContentService';
import FormContainer from '../../../components/FormBuilder/FormContainer'

let formMode = null; 
let formId = null; 
let help = {}; 
let data = {}; 
let formFn = {};  

function HelpForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  let params = props.match.params;
  let currentUser = authState.user;
  let queryParam = querystringify.parse(props.location.search);

  /*TODO: Move API to Router to load data before view*/
  const getById = (id) => {
    DigitalContentService.get(id).then((res) => {
      if(res.status==200){
        help = res.data.digital_content;
        data = help.data || {};
        setFormRendered(true);
      }
    })
  }
  
  useEffect(() => { 
    formMode = 'create-form-submissions';
    data = {}; 
    help = {}; 
    formFn = {'form_type':'help'};
   
    if(params.id){
      getById(params.id)
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, []);


  const create = (help) => {
    help.created_by = authState.user.id;
    help.is_user_suggested = true;
    help.page_url = queryParam.page;
    help.content_type = 'help';
    help.content_form_id = FormTemplateId.HelpRegistration;
    DigitalContentService.create(help).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    })
  }

  const update = (help) => {
    DigitalContentService.update(help).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect(help.journey_category_id);
      }
    })
  }

  const onSubmitPageRedirect = (id) => {
    props.history.push("/help")
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.HelpRegistration} form={help} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost} />
      </FormContainer> 
    )  
  }else{
    return null
  }
  
}

export default HelpForm;