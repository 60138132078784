import React, {useState} from "react";
import { Link } from "react-router-dom";
import ProjectMilestoneService from "../../../components/Organizations/ProjectMilestones/ProjectMilestoneService";
import CommonService from '../../../services/CommonService';
import MilestoneGrid from "../../../components/Organizations/ProjectMilestones/MilestoneGrid";

export const Milestone = (props) => {
  let contract = props.contract;
  let data = contract.data;
  let data_source = contract.data_source;
  let milestoneList = props.project.milestones || [];

  const [isToggle, setIsToggle] = useState(false);
  const [milestones, setMilestones] = useState(milestoneList);

  const deleteMilestone = (e, milestone) => {
    ProjectMilestoneService.delete(milestone).then((res)=>{
      if(res.status==204){
        setMilestones(milestones.filter(i => i.id !== milestone.id))
      }
    })
  }

  return (
    <div className="card-container bg-white shadow-small" style={{paddingTop:'0px'}}>
     <div className="white card-header p-5" style={{backgroundColor:'#607D8B'}}>
        <div className="font-16">
        Contract Details      
        </div>
        <div>
          {CommonService.formatUpdateDate(contract.updated_at)}
        </div> 
      </div>
      <div className="row content">
        <div className="accordion m-t-5">
        <div className="accordion-title">
            <div className="font-16 pull-left m-r-5 m-l-5">Milestones Info</div> 
            <i className="fa fa-chevron-circle-down m-t-5 cursor" onClick={()=>setIsToggle(isToggle => !isToggle)}></i>
        </div>
        <div className="accordion-content row" style={isToggle ? { display: 'block' }:{ display: 'none' }}>
        <div className="col-xs-12 col-sm-6">
          <div className="vcard-field">
            <strong>Party 1</strong>
            <div className="font-15">{data_source.party_1.label}</div>
            <div className="font-15">{data.sign_name_1}</div>
            <i className="fas fa-male color-green1-dark" />
          </div>
          <div className="vcard-field">
            <strong>Party 2</strong>
            <div className="font-15">{data_source.party_2.label}</div>
            <div className="font-15">{data.sign_name_2}</div>
            <i className="fas fa-male color-green1-dark" />
          </div>
          <div className="vcard-field">
            <strong>Date</strong>
            <div className="font-15">{data.start_date}</div>
            <div className="font-15">{data.end_date}</div>
            <i className="fas fa-calendar-alt color-green1-dark" />
          </div>
        </div>
        <div className="col-xs-12 col-sm-6">
          <div className="vcard-field">
          <strong>Purpose</strong>
            <div className="font-15">{data.purpose}</div>
            <i className="fas fa-receipt color-green1-dark" />
          </div>
          <div className="vcard-field no-border">
            <strong>Fees</strong>
            <div className="font-15">{data.fees}</div>
            <i className="fas fa-dollar-sign color-red2-light" />
          </div>
          <div className="vcard-field no-border">
            <strong>Link</strong>
            <a href={data.url}>{data.url}</a>
            <i className="fas fa-paper-plane color-red2-light" />
          </div>
        </div>
        
        </div>

        </div>
        <div className="bg-lgrey" style={{height:'1px'}}></div>
        <MilestoneGrid contract={contract} project={props.project} milestones={props.milestones} page="milestone"/>

      </div>
      <div className="card-footer m-t-5">
      <div className="pull-right">
      <props.menu contract={contract}/>
        </div>
      </div>
    </div>
  );
};

export default Milestone;
