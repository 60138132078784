import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import DynamicForm from '../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../constants/FormTemplateList'
import MilestoneTaskService from "../../../components/Organizations/MilestoneTasks/MilestoneTaskService";

let formMode = null; 
let milestone_task = {}; 
let data = {};
let formFn = {};

function MilestoneTaskForm(props) {

  const { state:currentUser, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;
  let queryparams = queryString.parse(props.location.search)

  const get = () => {
    MilestoneTaskService.get(params).then((res) => {
      if(res.status==200){
        milestone_task = res.data.milestone_task;
        data = milestone_task.data || {};

        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
      milestone_task = null;
    })
  }
  
  useEffect(() => { 
    milestone_task = {}; 
    data = {};
    formFn = {};
    formMode = 'create-form-submissions';
    data = {}; 
    if(params.id && params.id != 'null'){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, [params.id]);

  const create = (milestone_task) => {
    milestone_task.accelerator_id = currentUser.user.accelerator.id;
    milestone_task.project_id = queryparams.project_id;
    milestone_task.organization_id = queryparams.organization_id;
    milestone_task.form_id = FormTemplateId.MilestoneTask;
    milestone_task.service_provider_id = queryparams.service_provider_id=='null'?null:queryparams.service_provider_id;
    milestone_task.contract_id = queryparams.contract_id;
    milestone_task.milestone_id = queryparams.milestone_id;
    MilestoneTaskService.create(milestone_task).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (milestone_task) => {
    MilestoneTaskService.update(milestone_task).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.goBack();
  }

  if(isFormRendered){
    return (
    <div className="portlet">
      <div className="portlet-heading bg-highlight color-white">
        <h4 className="portlet-title text-uppercase">
          {post.title}
        </h4>
         <div className="clearfix"></div>
      </div>
      <div id="portlet1" className="panel-collapse collapse in">
        <div className="portlet-body p-t-0">
          <div className="form-details" dangerouslySetInnerHTML={{ __html: post.details }}></div>
          <DynamicForm formMode={formMode} formId={FormTemplateId.MilestoneTask} form={milestone_task} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
        </div>
      </div>
    </div>
    );  
  }else{
    return null
  }
  
}

export default MilestoneTaskForm;