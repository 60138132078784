import React, {Fragment} from "react";
import PrivateRoute from "../../../routes/PrivateRoute";
import PortalDetails from "../../Journey/CollegeJourney/PortalPage/PortalDetails";
import ActivityList from "../../Journey/CollegeJourney/Activities/List/ActivityList"
import ActivityForm from "../../Journey/CollegeJourney/Activities/Form/ActivityForm"
import StepActivityForm from "./StepActivities/StepActivityForm"
import StepActivityStatusForm from "./StepActivityStatus/StepActivityStatusForm"
import ReviewForm from "../../Journey/CollegeJourney/Review/ReviewForm"
import Analyze from "./Analyze/Analyze"
import ManagementList from "../../Journey/CollegeJourney/Management/List/ManagementList"
import ManagementForm from "../../Journey/CollegeJourney/Management/Form/Form"
import PortalTabs from "./PortalPage/PortalTabs"

const CollegeRouter = (props) => (
  <Fragment>
    <PrivateRoute path="/journey/portal/:journey_profile_id" app={props.app} component={PortalDetails} exact />
  	<PrivateRoute path="/baseline/:journey_profile_id" component={ActivityList} searchString={props.searchString} exact />
    <PrivateRoute path="/governance/:journey_profile_id" component={ManagementList} searchString={props.searchString} exact />
    <PrivateRoute path="/form/baseline/:journey_profile_id/:id?" searchString={props.searchString} component={ActivityForm} exact />
  	<PrivateRoute path="/track/:journey_profile_id" component={ActivityList} searchString={props.searchString} exact />
  	<PrivateRoute path="/step_activity/form/:journey_profile_id/:activity_id/:id?" component={StepActivityForm} exact/>
  	<PrivateRoute path="/analyze/:journey_profile_id" component={Analyze} searchString={props.searchString} exact />
  	<PrivateRoute path="/form/step_activity_status/:journey_profile_id/:activity_id/:step_activity_id/:id?" component={StepActivityStatusForm} exact/>
  	<PrivateRoute path="/form/review/:assessment_id/:assessment_type/:id?" component={ReviewForm} exact />
    <PrivateRoute path="/form/management/:journey_profile_id/:id?" component={ManagementForm} exact />
    <PrivateRoute path="/journey/tabs/:journey_profile_id" component={PortalTabs} searchString={props.searchString} exact />
  </Fragment>
)

export default CollegeRouter;
