import React, { Fragment, useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import useStyle from '../../../hooks/useStyle';
import useEscape from '../../../hooks/useEscape';
import CommonService from "../../../services/CommonService";

const ListMenuModal = ({ title, params, isOpen, toggleModal, dataSourceId, onSelect}) => {
  useStyle('add_role_dialog');
  useStyle('filter_dialog');
  useEscape(toggleModal);

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [plannerCategory, setPlannerCategory] = useState({options:[]});
  
  let requestParams = {data_source_id: [dataSourceId]};

  useEffect(() => {
    getPlannerCategory();
  }, [])

  let getPlannerCategory = () => {
    CommonService.getDataSource(requestParams).then((res) => {
      let d = CommonService.findObjById(res, 'id', dataSourceId)
      setPlannerCategory(d);
    })
  }

  const Filters = plannerCategory && plannerCategory.options.map(k => 
    <div className="ver-icons" onClick={(e) => onSelect(e, k)} key={k.pk_id}>
      <a href="/">
        <i className={`${k.icon || "fas fa-plus"}`} style={{ color: k.icon_color }}/>
      </a>
      <div className="popup-menu-title">{k.label}</div>
      <i className="fas fa-chevron-right lgrey pull-right" />
    </div>
  )

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">
          
          <div className="ml-center bg-black white p-5">
            <h4>{title}</h4>
            <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal" onClick={e=>toggleModal(e)}>×
            </span>
          </div>
          
          <div className="popup-menu-grid">
            {Filters}
            <small className="font-9 lgrey lh-16 p-5">{dataSourceId}</small>  
          </div>
            
        </div>
      </div>
    </Fragment>, document.body)
  )

}

export default ListMenuModal;