import React, { useState, useEffect } from 'react';
import CueTooltip from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel, OptionLabel} from '../FieldLabel'

const InputMultiSelect = ({field, formData, readOnly, errors, forceUpdate, isFormWizard, openDataSourceModal, openFieldLabelModal, currentLocale}) => {
  
  let listKey = field.client_id+'_array';
  formData[listKey] = formData[listKey] || [];

  let [modelarray, setModelarray] = useState(formData[listKey]);

  let objList = {};

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only)
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip
  }
  
  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);

  let validation = {
    'required': field.required
  }
      
  let op_kv = field.options_key_value;
  if(op_kv && op_kv.options){
    objList = op_kv.options.reduce((obj, item) => (obj[item.value] = item.label, obj) ,{});
  }
  
  useEffect(() =>{
    formData[field.client_id] = modelarray.toString();
    setError();
    FormHelper.setFormTitle(field, formData, modelarray, op_kv)
  }, [modelarray])
  
  /* 
   * To check an item exist in checkbox options.
   */
  const toggle = (item) => {
    if(field.max && field.max == modelarray.length){
      return
    }

    let idx = modelarray.indexOf(item);
    if(idx > -1) {
      setModelarray(modelarray.filter(i => i !== item))
      formData[listKey].splice(idx, 1);
    }else {
      setModelarray([...modelarray, item])
      formData[listKey].push(item);
    }
  }

  const exists = (item) => {
    return modelarray.indexOf(item) > -1
  }

  const bindWindowClickEvent = () =>{
    let el = document.getElementById(field.client_id+'_result_set');
    el.classList.remove("hidden");
    //$scope.onOpen({req:$scope.model});
    window.addEventListener('click',eventFunction)
  }

  const eventFunction = (event) => {
    try{
      if(document.getElementById(field.client_id+'_container').contains(event.target)){
        console.log(field)
      }else{
        let el = document.getElementById(field.client_id+'_result_set');
        el.classList.add("hidden");
        window.removeEventListener("click",eventFunction);
        //$scope.onClose({req:$scope.model});
        forceUpdate();
      }
    }catch(e){
    
    }
  }

  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[listKey].length==0?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  return (
    <div {...parentAttributes} data-tip data-for={`tooltip_${field.client_id}`}>
      <FieldLabel field={field} labelAttributes={labelAttributes} currentLocale={currentLocale}
        openDataSourceModal={openDataSourceModal}
        openFieldLabelModal={openFieldLabelModal}/>
      <div className={`fstElement fstMultipleMode ${readOnly && 'input-readonly'}`} id={field.client_id+ '_container'}>
      {(op_kv && op_kv.options) 
        ? <OptionsObjList list={op_kv.options} toggle={toggle} currentLocale={currentLocale}
        exists={exists} object={objList} modelarray={modelarray} field={field}
        bindWindowClickEvent={bindWindowClickEvent} inputAttributes={inputAttributes}/> 
        : <OptionsStringList list={field.options} toggle={toggle} 
        exists={exists} object={objList} modelarray={modelarray} field={field}
        bindWindowClickEvent={bindWindowClickEvent} inputAttributes={inputAttributes}/>
      }   
      </div>  
      <span className="fts-13">{field.description}</span>
      <Error/>
      <CueTooltip id={field.client_id} description={field.tooltip}/>
    </div> 
  )
}

const OptionsObjList = ({list, toggle, exists, object, modelarray, field, bindWindowClickEvent, inputAttributes, currentLocale}) => {

  let [optionList, setOptionList] = useState(list);

  const search = (term) =>{
    let result = list.filter(item => item.label.toLowerCase().indexOf(term) > -1);
    setOptionList(result)
  }

  const selectableItems = optionList.map((o, k) =>  
    <span key={k} className={`fstResultItem ${exists(o.value)?'fstSelected':''} `}
     onClick={e => toggle(o.value)} title={o.description}>
      {(o.locale && o.locale[currentLocale]) || o.label}
      { o.url && 
      <a href={o.url} target="_blank" onClick={e => e.stopPropagation()}>
        <i className="material-icons fts-14"> open_in_new </i>
      </a> }
    </span>
  )

  const selectedItems = modelarray.map((i, k) =>  
    <div key={k} className="fstChoiceItem">
      {object[i]}
      <span className="fstChoiceRemove" type="button" onClick={e => toggle(i, e)}>×</span>
    </div>
  )

  return(<React.Fragment>
    <div className="fstControls" onClick={e => bindWindowClickEvent(e)}>
      {selectedItems}
      <input {...inputAttributes} onChange={e=>search(e.target.value)} className="fstQueryInput" placeholder="Search here.." />
    </div>
    <div className="fstResults hidden" id={field.client_id+ "_result_set"}>
      {selectableItems}
    </div>
  </React.Fragment>)
}

const OptionsStringList = ({list, toggle, exists, object, modelarray, field, bindWindowClickEvent, inputAttributes}) => {
  
  let [optionList, setOptionList] = useState(list);

  const search = (term) =>{
    let result = list.filter(item => item.toLowerCase().indexOf(term) > -1);
    setOptionList([...result])
  }

  const selectableItems = optionList && optionList.map((o, k) =>  
    <span key={k} className={`fstResultItem ${exists(o)?'fstSelected':''} `}
     onClick={e => toggle(o)} title={o}>
      {o}
    </span>
  )

  const selectedItems = modelarray.map((i, k) =>  
    <div key={k} className="fstChoiceItem">
      {i}
      <span className="fstChoiceRemove" type="button" onClick={e => toggle(i, e)}>×</span>
    </div>
  )

  return(<React.Fragment>
    <div className="fstControls" onClick={e => bindWindowClickEvent(e)}>
      {selectedItems}
      <input {...inputAttributes} onChange={e=>search(e.target.value)} className="fstQueryInput" placeholder="Search here.." />
    </div>
    <div className="fstResults hidden" id={field.client_id+ "_result_set"}>
      {selectableItems}
    </div>
  </React.Fragment>)
}

export default InputMultiSelect;