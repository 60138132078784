import React, { Fragment, useState, useEffect, useContext} from 'react';
import ReactDOM from 'react-dom';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import CommentsService from '../../services/CommentsService';
import Cs from '../../services/CommonService';
import useStyle from '../../hooks/useStyle';
import useEscape from '../../hooks/useEscape';

let comment = {};
const CommentsModal = ({ item, type, isOpen, toggleModal,showInline }) => {
  useStyle('chat');
  useStyle('add_role_dialog');
  useStyle('panel');
  useEscape(toggleModal);
  
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  if(item)item.comments = item.comments || [];
  const [commentsList, setCommentsList] = useState([]);
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(true);
  const [parentComment, setParentComment] = useState({});

  let requestParams = {
    sort_column: "updated_at",
    sort_direction: "asc",
    commentable_id: item.id,
    commentable_type: type
  }

  let getNotesList = () => {
    CommentsService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        setLoading(false)
        setCommentsList(res.data.comments);
        scrollTo(res.data.comments);
      }
    })
  }

  useEffect(() => {
    if(isOpen){
      resetComment()
      getNotesList();
    }
  }, [item.id]);

  const resetComment = ()=>{
    comment = {};
    comment.commentable_id = item.id 
    comment.commentable_type = type
    comment.journey_profile_id = item.journey_profile_id;
  }

  const addComment = (e, text) => {
    setLoading(true)
    e.preventDefault()
    if(text=='')return
    if(comment.id){
      comment.comment = text
      CommentsService.update(comment).then((res)=>{
        if(res.status == 204){
          setLoading(false)
          setText('')
          setCommentsList((commentsList.filter(i => i.id !== comment.id)).concat([comment]))
          resetComment();
          scrollTo(commentsList)
        }
      })
    }else{
      comment.comment = text
      if(parentComment.id)comment.parent_comment_id = parentComment.id;
      CommentsService.add(comment).then((res)=>{
        if(res.status == 201){
          setLoading(false)
          comment = res.data.comment
          comment.author_name = currentUser.name
          //comment.record_id = res.data.comment.record_id;
          comment.parent_comment = parentComment;
          setCommentsList([...commentsList, comment])
          /*setText('');*/
          resetComment()
          setParentComment({})
          scrollTo(commentsList)
        }
      })  
    }
  }

  const uploadFileToUrl = (event) => {
    try {
      let files = event.target.files
      let ele =  event.target
      let FileSize = files[0].size / 1024 / 1024; // in MB
      if (FileSize > 2) {
        ele.value = null
        //setFileSizeError(true)
        return
      }else{
        //setFileSizeError(false)
      }

     if(files.length>0){
        setLoading(true)
        Cs.uploadFileToUrl(files[0], null, comment, 'comment', 'comments/file_upload').then((res)=> {
          ele.value = null
          comment = res.data.comment;  
          //comment.record_id = res.data.comment.record_id;
          setLoading(false)
          setCommentsList([...commentsList, comment])
          /*setText('');*/
          resetComment()
          scrollTo(commentsList);
        }, (err)=>{
          setLoading(false)
        })
      }
    } catch (e) {
      setLoading(false)
    }
  }

  const setEdit =(c)=>{
    comment = c;
    setText(c.comment);
  }

  const setReply = (comment) =>{
    setParentComment(comment)
    scrollTo(commentsList)
  }
  
  const deleteComment = (comment) => {
    setLoading(true)
    CommentsService.delete(comment).then((res)=>{
      setLoading(false)
      setCommentsList(commentsList.filter(i => i.id !== comment.id))
      scrollTo(commentsList);
    })  
  }

  function scrollTo(comments){
    if(comments.length>0){
      try{
        //let scroll_id = comments[comments.length - 1].id;
        let ele = document.getElementById('last_comment');
        document.getElementById('chat-main').scrollTop = ele.offsetTop;
      }catch(e){
        console.log(e)
      }
    }
  }

  const CommentType = ({comment}) =>{
    if(comment.file_upload_id){
      return(
        <a target="_blank" href={Cs.getFileUrl(comment.comment)}>
          <img src={Cs.getIconByType(comment.comment)} width="75px"/>
          <span>{Cs.getFileName(comment.comment)}</span>
        </a>
      )
    }else if(JSON.stringify(comment.parent_comment) != "{}"){
      return(<Fragment>
        {comment.parent_comment && <div className="reply-comment" dangerouslySetInnerHTML={{ __html: comment.parent_comment.comment}}></div>}
        <span dangerouslySetInnerHTML={{ __html: comment.comment}}></span>
      </Fragment>)
    }else{
      return(<Fragment>
        <span dangerouslySetInnerHTML={{ __html: comment.comment}}></span>
      </Fragment>)
    }
  }

  const commentsItem = commentsList.map((c, k) =>
    <Fragment key={k}>
      <div id={`comment_${c.id}`} className={`speech-bubble ${c.created_by == currentUser.id?'speech-left':'speech-right'}`}>
      <span className="portlet-dropdown">
        <a data-tip="Journey Menu" className="p-dropbtn"><i className='fas fa-caret-down'></i></a>
        <div className="p-dropdown-comment">
          { c.created_by == currentUser.id &&
            <Fragment>
              {!c.file_upload_id &&
                <a onClick={e=>setEdit(c)} >
                  <i className="far fa-edit"/> Edit
                </a>
              }
              <a onClick={e=>deleteComment(c)}>
                <i className="far fa-trash-alt"/> Delete
              </a>
            </Fragment>
          }
          <a onClick={e=>setReply(c)}>
            <i className="fas fa-reply" aria-hidden="true"></i> Reply
          </a>
        </div>
      </span>
      {c.created_by == currentUser.id &&
        <div className="pull-right">
          <div className="font-16 pull-right m-r-5">
            <i className={`far ${c.read_status?'fa-eye':'fa-eye-slash'} text-muted`} aria-hidden="true"></i>
          </div>
        </div>
      }
    <CommentType comment={c}/>
    <span className="speech-read mb-3">{c.author_name} - {Cs.formatUpdateDate(c.updated_at)}</span>
    </div>
    <div className="clear"/>
    </Fragment>
  )

  const CommentForm = (props) =>{
    const [text, setText] = useState(props.text || '');
    
    return(
      <div className="container">
        <form className="clearfix speech-footer" onSubmit={e=>props.addComment(e, text)}>
        {parentComment.id && <div className="speech-reply">{parentComment.comment}
          <span onClick={e=>setReply({})} className="pull-right">
            <i className="fas fa-times"></i>
          </span>
        </div>}
        <div className="col-xs-1 col-md-1 p-t-3 center-text">
          <label className="round-btn brd-17 bg-lgreen" htmlFor="file_upload">
            <i className="fas fa-paperclip"></i>
          </label>
          <input type="file" id="file_upload" className="hidden" onChange={e => props.uploadFileToUrl(e)} />
        </div>
        <div className="form-group col-md-10 col-xs-10 speach-input center-text">
          <input className="m-l-5" type="text" value={text} placeholder="Enter your message here" onChange={e => setText(e.target.value)}/>
        </div>
        <div className="col-xs-1 col-md-1 p-t-3 center-text">
          <div onClick={e=>addComment(e,text)} className="round-btn brd-17 bg-lgreen"><i className="fas fa-arrow-up"></i></div>
        </div>
        </form>
      </div>
    )
  }

  const CommentBox = () =>(
    <Fragment>
      <div className="ml-center bg-lgreen white p-5">
        <div className="font-16 bold-600">Comments</div>
        {!showInline &&
        <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal"
          onClick={e=>toggleModal(e)}>×
        </span>}
      </div>

      <div className="bottom-0 chat-main" id="chat-main" style={{height:screen.height-300}}>
        {commentsItem.length==0 && 
          <div className="no-chat font-15">This Activity Tile has no comments yet.You can add one now.</div>
        }
        {loading ? <div className="spinner"></div> : commentsItem}
        <div id="last_comment" className="m-t-5"></div>
      </div>
      <CommentForm text={text} addComment={addComment} uploadFileToUrl={uploadFileToUrl}/>
    </Fragment>    
  )  

  if(showInline){
    return(
      <div className="m-t-15">
        <div className="ml-card-4 bg-white">
          <CommentBox/>
        </div>
      </div>
    )
  }

  return(ReactDOM.createPortal(
    <React.Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom bg-white">
        <CommentBox/> 
        </div>
      </div>
    </React.Fragment>, document.body)
  )
};

export default CommentsModal;
