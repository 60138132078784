import React, {Fragment} from "react";
import PrivateRoute from "../../../../routes/PrivateRoute";
import GoalsForm from "../Goals/Form/Form"
import GoalsList from "../Goals/List/GoalsList"

const GoalsRouter = (props) => (
  <Fragment>
  	<PrivateRoute path="/goals/:journey_profile_id" component={GoalsList} exact />
    <PrivateRoute path="/form/goals/:journey_profile_id/:id?" component={GoalsForm} exact />
  </Fragment>
)

export default GoalsRouter;
