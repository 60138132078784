import React, {Fragment} from "react"
import {JourneyBlock} from "../../List/Journey"
import EmissionsActivityTable from "../Activities/List/EmissionsActivityTable"
import Roles from "../../../Roles/RoleList"
import MemberList from "../../../ShareJourney/MemberList"

const Components = (props) => {
  let components = {
    journey_profile: JourneyBlock,
    assess:EmissionsActivityTable,
    acquire:EmissionsActivityTable,
    design:EmissionsActivityTable,
    pilot:EmissionsActivityTable,
    develop:EmissionsActivityTable,
    operate:EmissionsActivityTable,
    roles:Roles,
    users:MemberList,
  }

  const TagName = components[props.componentName];  

  if(TagName){
  	return <TagName {...props}/>
  }else{
  	return <div>None</div>
  }
  
}

export default Components