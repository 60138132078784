import React, { useEffect, useState, useContext} from 'react';
import querystringify from "querystringify"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import DynamicForm from '../../../components/FormBuilder/DynamicForm';
import FormWizard from '../../../components/FormBuilder/FormWizard';
import CalendarService from "../CalendarService"
import FormContainer from '../../../components/FormBuilder/FormContainer'
import FormTemplateId from '../../../constants/FormTemplateList'

let formMode = null; 
let schedule = {}; 
let data = {}; 
let formFn = {};  
let form_template_id = null;

function ScheduleForm(props) {
  
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  
  let params = props.match.params;
  let journey = props.location.state;
  let queryParam = querystringify.parse(props.location.search);
  
  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    CalendarService.getSchedulesById(params.id).then((res) => {
      if(res.status==200){
        schedule = res.data.schedule;
        data = schedule.data || {};
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    })
  }
  
  useEffect(() => { 
    schedule = {}; 
    data = {}; 
    formFn = {
      'form_type':'schedule.module', 
      'journey_profile_id':params.journey_profile_id,
      'editPermission':queryParam.edit
    }; 
    formMode = 'create-form-submissions';
    data = {}; 
    if(params.id){
      get(params)
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, []);

  const create = (req) => {
    req.created_by = currentUser.id;
    req.journey_profile_id = params.journey_profile_id;
    req.schedule_item_type = queryParam.item_type;
    req.schedule_item_id = queryParam.item_id; 
    req.start_date = req.start_date_string = data.start_formatted_date
    req.end_date = req.end_date_string = data.end_formatted_date
    CalendarService.create(req).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    })
  }

  const update = (req) => {
    req.start_date = req.start_date_string = data.start_formatted_date
    req.end_date = req.end_date_string = data.end_formatted_date
    CalendarService.updateSchedule(req).then((res) => {
      if(res.status == 204){
        onSubmitPageRedirect(req);
      }
    })
  }

  const onSubmitPageRedirect = (req) => {
    props.history.push(`/calendar/${params.journey_profile_id}?item_id=${params.journey_profile_id}&item_type=${params.journey_type}`, journey)
  }

  if(queryParam.form_wizard_id || queryParam.form_wizard_schedule_id){
    return(<FormWizard form_wizard_id={queryParam.form_wizard_id} form={schedule} data={data} 
      formFn={formFn} onCreate={create} onUpdate={update} onCancel={onSubmitPageRedirect}
      form_wizard_schedule_id={queryParam.form_wizard_schedule_id}/>)
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.Schedule} form={schedule} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
      </FormContainer> 
    )  
  }else{
    return null
  }
  
}

export default ScheduleForm;