import React, { Fragment } from "react";
import {Link} from "react-router-dom";
import Cs from "../../services/CommonService";

const SharedItem = (props) => {
  let {sharedItem, dataSources:{permissions, notificationsTypes, roleType}} = props;
  sharedItem.updated_at = Cs.formatUpdateDate(sharedItem.updated_at);

  const cardStyle = {backgroundColor: "#607D8B"}

  /*const RoleList = ({list, styleName, dsName}) => list && list.map((i, k)=>
    <span className={styleName +' m-l-5'} key={i}>{componentObj[i].label}</span>
  )*/

  const Header = () => (
    <div className="white card-header p-5" style={cardStyle}>
      <div className="font-16 ellipsis">
        {sharedItem.email}
      </div>
      <div className="font-13">
        {sharedItem.updated_at}
      </div>
    </div>
  )

  const Footer = () => (
    <div className="card-footer">
      <div className="pull-left lgrey">
        <span className="m-l-15">
          {sharedItem.record_id}
        </span>
        <span className="m-l-15 font-11">
          <span className="lred">Last by</span>
          <span className="black-light-1"> {sharedItem.updated_at}</span>
        </span>
      </div>
      <div className="pull-right">
         <props.menu item={sharedItem} />
      </div>
    </div>
  )

  const Card = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <Header/>
        <div className="scroll-auto ht-150">
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                  Permission: <br/>
                  {sharedItem.permissions && Object.keys(sharedItem.permissions).map((p)=>(
                    <span className="text-cap round-tab bg-blue-white" key={p}>{permissions[p]}&emsp;</span>
                  ))}
                </td>
              </tr>
                <tr>
                  <td>        
                    Notification for Step Activity : <br/>
                    {sharedItem.notification_step_activity && sharedItem.notification_step_activity.map((n)=>(
                      <span className="text-cap round-tab bg-red-white" key={n}>{notificationsTypes[n]}&emsp;</span>
                    ))}
                  </td>
                </tr>
              <tr>
                <td>
                  Notification for Comment Posting: <br/>
                  {sharedItem.notification_comment && sharedItem.notification_comment.map((n)=>(
                    <span className="text-cap round-tab bg-yellow-white" key={n}>{notificationsTypes[n]}&emsp;</span>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Footer />
      </div>
    )
  }

  return <Card/>;

}

export default SharedItem;