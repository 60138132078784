import React from "react";
import {Link} from "react-router-dom";
import useModal from '../../../hooks/useModal';
import ConfirmMenu from '../../Modals/ConfirmMenu'

const PortalHeader = (props) => {
  const {project} = props;
  
  const deleteProject = () => {
    //Delete
  }
  
  const {isOpen, toggleModal, success} = useModal(deleteProject);

  const Menu = () => (
    <span className="portlet-dropdown">
      <a className="p-dropbtn"><i className='fas'>&#xf00a;</i></a>
      <div className="p-dropdown-content">
        <Link to={`/organization/new/project/${project.organization_id}/${project.id}`}>
          <i className="far fa-edit"/>Edit
        </Link>
        <a href="#" onClick={e => {toggleModal(e, project)} }>
          <i className="far fa-trash-alt"/>Delete
        </a>
      </div>
    </span>
  )

  return (
    <div className="portlet-heading clearfix">
      <div className="col-xs-12 col-sm-10">
        <h3 className="portlet-title ng-binding">
          <span>{project.data.name}</span>
        </h3>
      </div>

      <div className="col-xs-12 col-sm-2">
        <div className="portlet-widgets">
          <Link to={`/organization/project/portal/${project.id}`}>
            <i className="fas fa-home"/>
          </Link>
          <span className="divider" />
          <span className="portlet-dropdown">
      <a className="p-dropbtn"><i className='fas'>&#xf00a;</i></a>
      <div className="p-dropdown-content">
        <Link to={`/organization/new/project/${project.organization_id}/${project.id}`}>
          <i className="far fa-edit"/>Edit
        </Link>
        <a href="#" onClick={e => {toggleModal(e, project)} }>
          <i className="far fa-trash-alt"/>Delete
        </a>
      </div>
    </span>
        </div>
      </div>
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleModal} success={success}/>
    </div>
  )
}

export default PortalHeader;