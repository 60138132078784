import React, {useState,Fragment} from "react";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import PortalHeader from "../../../components/Organizations/ProjectPortalPage/PortalHeader";
import ServiceProviderSkill from '../../../components/ServiceProvider/SkillsSearch/ServiceProviderSkill';
import ServiceProviderService from "../../../components/ServiceProvider/ServiceProviderService";
import FavouritesService from "../../../services/FavouritesService"
import Pagination from '../../Pagination'
import ProjectMenu from '../../Modals/ProjectMenu'
import {Link} from "react-router-dom";
import useModal from "../../../hooks/useModal";
import useStyle from '../../../hooks/useStyle';

let requestParams = {}
let serviceRequest = {}
let selectedSkill = {};

export const ServiceProviderSkillsList = (props) => {
  useStyle('card')
  const params = props.match.params;
  const { state:currentUser, dispatch } = React.useContext(AuthContext);
  const [serviceProviderSkills, setServiceProviderSkills] = useState([]);
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal, success:menuSuccess } = useModal();
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);

  React.useEffect(() => {
    requestParams = {
      'page': currentpage, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'asc',
      'project_service_request_id': params.project_service_request_id
    }

    getServiceProviderSkills();
  }, [params.service_request_id, props.searchString,currentpage]);

  let getServiceProviderSkills = () => { 
    ServiceProviderService.searchServiceProviderBySkill(requestParams).then((res)=>{
     if(res.status == 200){
       //setPagemeta(res.data.meta)
       serviceRequest = res.data.service_request;
       setServiceProviderSkills(res.data.service_provider_skills)
      }
    })
  }

  let addToFavourites = (e, item) =>{
    e.preventDefault();
    let req = {
      'favourable_type': 'service_providers',
      'favourable_id': item.id,
      'created_by': currentUser.user.id,
      'project_id': serviceRequest.project_id
    }
    FavouritesService.add(req).then((res)=>{
      props.history.push(`/serviceprovider/assessments/${serviceRequest.project_id}`)
    }, (res)=>{
      props.history.push(`/serviceprovider/assessments/${serviceRequest.project_id}`)
    })
  }

  const addMenu = (e, project) => {
    selectedSkill = project;
    toggleMenuModal(e);
  }

  const Menu = ({ project, fn }) => (
    <div className="dropup card-menu-right">
            <i onClick={e=>{addMenu(e, project)}} class="fas fa-bars" />       
    </div>
  )

  const menuItems = ({item}) =>{
    console.log('meunitmes sp',item)
    return(
      <Fragment>
        <Link onClick={e => {addToFavourites(e, item)}}>
          <i className="fa fa-file-contract"></i>
          <span className="font-13">Save to Assess</span>
          <i className="fa fa-angle-right"></i>
        </Link>
      </Fragment>
    )
  }

  /*const Menu = ({sp}) => 
    <div className="dropup card-menu-right">
      <button className="dropbtn">Menu</button>
      <div className="dropup-content" style={{"minWidth":"200px"}}>
        <a href="#" onClick={e => {addToFavourites(e, sp)}}>Save to Assess</a>
      </div>
    </div>*/

const spSkillCard = serviceProviderSkills.map((sp, i) =>
<div className="col-xs-12 col-sm-6 col-md-4" key={i}>
<ServiceProviderSkill serviceProviderSkill={sp} menu={Menu}/>
  {selectedSkill.id == sp.id && 
    <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedSkill} 
    type="serviceProvider" 
    MenuItems={menuItems}/>}
</div>
)

  return (
    <React.Fragment>
    <div className="content top-10">
      <h5 className="float-left font-500">Service Provider Skills</h5>
      <div className="clear"></div>
    </div>

    <div className="page-content row">
      {spSkillCard}
      <Pagination pagemeta={pagemeta} currentpage={currentpage} setCurrentpage={setCurrentpage} />
    </div>
    </React.Fragment>
  )

};

export default ServiceProviderSkillsList;