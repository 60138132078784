import React, { useState, Fragment } from 'react'
import CueTooltip from '../../../components/Common/CueTooltip'
import FormHelper from '../../../components/FormBuilder/FormHelpers'
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel} from '../FieldLabel'

const SEARCH_WAIT_INTERVAL = 700;
let searchKeyword = null;
let timer = null;

const InputMultiSelectSearch = ({field, formData, readOnly, errors, forceUpdate, formFn, isFormWizard, openFieldLabelModal, currentLocale}) => {
  let pagenate = formFn[field.client_id+'_pagenate'] || {};
  let resultPath = formFn[field.client_id+'_path'] || 'label'
  let listKey = field.client_id+'_array'
  formData[listKey] = formData[listKey] || [];

  let [modelarray, setModelarray] = useState(formData[listKey]);
  let [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  let objList = {};

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only)
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip
  }
  
  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);

  let validation = {
    'required': field.required
  }
      
  const handleChange=(event)=>{
    clearTimeout(timer);
    searchKeyword = event.target.value;
    pagenate.page = 1; 
    timer = setTimeout(onSearch, SEARCH_WAIT_INTERVAL);
  }

  const onSearch = (focus) => {
    if(searchKeyword && searchKeyword != '' || focus){
      try{
        setLoading(true)
        formFn['get_'+field.client_id](searchKeyword, onSuccessCallback, onFailureCallback);
        //showResultSet = true; 
        //onChange(search);
      }catch(e){
        setLoading(false)
        console.log(e)
      }
    }else{
      setList([]);  
      formData[field.client_id] = null;
      setError(true);
    }
  }

  const onSuccessCallback = (data) =>{
    setLoading(false)
    setList(data);
  }

  const onFailureCallback = (error) =>{
    setLoading(false)
    setList(error);
  }

  /* 
   * To check an item exist in checkbox options.
   */
  const toggle = (item, is_selected) => {
    if(!is_selected && field.max && field.max == modelarray.length){
      return
    }

    let selectedItems = formFn['toggle_'+field.client_id](item);
    setModelarray([...selectedItems]);
    setError();
  }

  const exists = (item) => {
    return formFn['exist_'+field.client_id](item);
  }

  const bindWindowClickEvent = () =>{
    let el = document.getElementById(field.client_id+'_result_set');
    el.classList.remove("hidden");
    window.addEventListener('click',eventFunction)
  }

  const eventFunction = (event) => {
    try{
      if(document.getElementById(field.client_id+'_container').contains(event.target)){
        console.log(field)
      }else{
        let el = document.getElementById(field.client_id+'_result_set');
        el.classList.add("hidden");
        window.removeEventListener("click",eventFunction);
        forceUpdate();
      }
    }catch(e){
    
    }
  }

  const close = () =>{
    let el = document.getElementById(field.client_id+'_result_set');
    el.classList.add("hidden");
  }

  FormHelper.setDisabled(field, inputAttributes);

  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[listKey].length==0?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  return (
    <div {...parentAttributes}>
      <FieldLabel field={field} labelAttributes={labelAttributes} 
        currentLocale={currentLocale}
        openFieldLabelModal={openFieldLabelModal}/>
      {loading && <div className="spinner"></div>}
      <div className={`fstElement fstMultipleMode ${readOnly && 'input-readonly'}`} id={field.client_id+ '_container'}>
      {list && <OptionsObjList list={list} toggle={toggle} handleChange={handleChange}
        exists={exists} modelarray={modelarray} field={field} resultPath={resultPath}
        bindWindowClickEvent={bindWindowClickEvent} inputAttributes={inputAttributes}
        close={close} onSearch={onSearch}/> 
      }   
      </div>  
      <span className="fts-13">{field.description}</span>
      <Error/>
      <CueTooltip id={field.client_id} description={field.tooltip}/>
    </div> 
  )
}

const OptionsObjList = ({ list, toggle, exists, object, modelarray, field, bindWindowClickEvent, inputAttributes, handleChange, resultPath, close, onSearch}) => {

  const selectableItems = list.map((o, k) => {
    let isSelected = exists(o)
    return( 
      <span key={k} className={`fstResultItem ${isSelected?'fstSelected':''} `}
       onClick={e => toggle(o, isSelected)} title={o.description}>
        {o[resultPath]}
      </span>
    )
  })
    
  const selectedItems = modelarray.map((i, k) => 
    <div key={k} className="fstChoiceItem">
      {i[resultPath]}
      <span className="fstChoiceRemove" type="button" onClick={e => toggle(i, true)}>×</span>
    </div>
  )

  return(
    <Fragment>
      <div className="fstControls" onClick={e => bindWindowClickEvent(e)}>
        {selectedItems}
        <input {...inputAttributes} onFocus={e=>onSearch(true)} onChange={e=>handleChange(e)} className="fstQueryInput" placeholder="Search here.." 
        data-tip data-for={`tooltip_${field.client_id}`}/>
      </div>
      <div className="fstResults hidden" id={field.client_id+ "_result_set"}>
        <span className="btn-close pull-right" onClick={e=>close()}>Close</span>
        {selectableItems}
      </div>
    </Fragment>
  )
}

export default InputMultiSelectSearch;
