import React, { useState, Fragment, useEffect, useContext} from "react";
import ReactTooltip from "react-tooltip";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import {Journey} from "../../../components/Journey/List/Journey";
import JourneyProfileService from "../JourneyProfileService";
import Pagination from "../../Pagination";
import ConfirmMenu from "../../Modals/ConfirmMenu";
import FormTemplateId from "../../../constants/FormTemplateList";
import CommentsModal from "../../Modals/CommentsModal";
import ProjectMenu from "../../Modals/ProjectMenu";
import { Link } from "react-router-dom";
import useModal from "../../../hooks/useModal";
import NotesModal from "../../Modals/NotesModal";
import MenuModal from "../../Modals/Menu";
import ChecklistsModal from "../../Modals/ChecklistsModal";
import useStyle from "../../../hooks/useStyle";
import CheckAccess from "../../../components/Roles/CheckAccess";
import SideMenuBar from "../../../components/Common/SideMenuBar";
import JourneyTypeMenu from "./JourneyTypeMenu";
import NotificationPopup from '../../Common/NotificationPopup'
import HelpMessageList from '../../../constants/HelpMessageList'
//import FilterModal from "../../../components/Organizations/List/OrganizationsFilterModal";
import {MenuItem, CardMenuInfo ,NavIcon} from '../../Common/MenuItem'

let requestParams = {};
let selectedJourney = {};
let data_source = {};
let users = {};

const JourneyList = (props) => {
  useStyle("card");
  useStyle("card_table");
  const { state: authState, dispatch } = useContext(AuthContext);
  const {isOpen: isJourneyMenuOpen, toggleModal: toggleJourneyMenu,} = useModal();

  const [journeyList, setJourneyList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  
  const { isOpen, toggleModal: toggleDeleteModal } = useModal();
  const { isOpen: isMenuOpen, toggleModal: toggleMenuModal } = useModal();
  const { isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage } = useModal();

  const deleteJourney = (e) => {
    e.preventDefault();
    JourneyProfileService.delete(selectedJourney).then((res) => {
      if (res.status == 204) {
        setJourneyList(journeyList.filter((i) => i.id !== selectedJourney.id));
      }
    })
    toggleDeleteModal(e);
  }

  useEffect(() => {
    requestParams = {
      data_source_params: {
        response_type: "object",
        data_source_id: [2214, 3170, 3171, 2860, 3231, 3293],
        group_by: "value",
      },
      page: currentpage,
      paginate: 1,
      per_page: 15,
      show_journey_templates: false,
      sort_column: "updated_at",
      sort_direction: "desc",
      search: props.searchString || null,
    }

    getJourneyList();
  }, [props.searchString, authState.user, currentpage]);

  let getJourneyList = () => {
    JourneyProfileService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        //users = res.data.users;
        data_source = res.data.data_source_list;
        //requestParams.totalItems = res.data.meta.total;
        setJourneyList(res.data.journey_profiles);
        res.data.journey_profiles.length == 0 && toggleHelpMessage()
      }
    })
  }

  const addDelete = (e, ja) => {
    selectedJourney = ja;
    toggleDeleteModal(e);
  }

  const addMenu = (e, ja) => {
    selectedJourney = ja;
    toggleMenuModal(e);
  }

  const addNewJourney = (e) => {
    e.preventDefault();
    let profile = authState.user.domain_info;
    /*if(profile.data.type_of_use == 1){
      //Personal
    }else if(profile.data.type_of_use == 2){
      //Organization Use
    }*/
    toggleJourneyMenu(e);
  }

  const menuItems = ({ item }) => {
    return (
      <Fragment>
        <CardMenuInfo formId={item.form_id} recordId={item.record_id} 
          createdAt={item.created_at} userName={item.created_user.name}/>

        {CheckAccess.isJourneyAuthor(authState.user, item) && (
          <Fragment>
            <MenuItem id="delete_icon" 
              icon="far fa-trash-alt"
              action="Delete"
              onSelect={(e) => {addDelete(e, item);toggleMenuModal(e);}}
            />
            <MenuItem id="edit_icon" 
              link={`/journey_profile/form/${item.id}?form_id=${item.form_id}`}
              icon="far fa-edit"
              action="Edit"
            />
            <MenuItem id="invite_icon" 
              link={{
                pathname: `/invite/users/${item.id}`,
                state: {'journeyProfile':item, 'share':null},
              }}
              icon="far fa-edit"
              action="Invite User"
            />
          </Fragment>
        )}
      </Fragment>
    )
  }

  const Menu = ({ ja, fn }) => (
    <i data-tip="Open Tile Menu" id="journey_menu" onClick={(e) => addMenu(e, ja)} className="fas fa-bars font-18"/>
  )

  const journeyListCard = journeyList.map((ja, i) => (
    <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
      <Journey journey={ja} menu={Menu} currentUser={authState.user}
        users={users} toggleModal={toggleDeleteModal}
        dataSources={data_source}/>
      {selectedJourney.record_id == ja.record_id && (
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal}
          item={selectedJourney} addDelete={addDelete}
          type="journey" MenuItems={menuItems}
          menuHeight="275px"/>
      )}
    </div>
  ))
  
  return (
    <Fragment>
      <div className="content top-10">
        <h4 className="float-left font-500 m-t-5">Journey List</h4>
        <NavIcon id="add_new_journey" dataTip="Launch New Journey" 
        className="bg-highlight round-btn float-right m-r-5 m-t-4" 
        onSelect={addNewJourney} icon="fas fa-plus"/>
        <div className="clear"></div>
      </div>

      <div className="page-content row">
        {journeyListCard}
        <Pagination totalItems={requestParams.totalItems}
          currentpage={currentpage} setCurrentpage={setCurrentpage}
          itemsPerPage={requestParams.per_page}/>
      </div>

      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteJourney}/>

      {isJourneyMenuOpen && (
        <JourneyTypeMenu isOpen={isJourneyMenuOpen} toggleModal={toggleJourneyMenu}/>
      )}

      {isHelpMessageOpen && (
        <NotificationPopup header="Note" message={HelpMessageList.new_journey} 
          onSuccess={toggleHelpMessage} isOpen={isHelpMessageOpen} 
          toggleModal={toggleHelpMessage}/>
      )} 

      <ReactTooltip place="left" />
      <SideMenuBar helpId="2" />
    </Fragment>
  )
}

export default JourneyList;