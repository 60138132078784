import React ,{useState, Fragment, useContext} from "react"
import moment from 'moment'
import { useHistory, Link } from 'react-router-dom'
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import StepActivitiesService from "./StepActivitiesService"
import ConfirmMenu from "../../../Modals/ConfirmMenu"
import useModal from "../../../../hooks/useModal"
import Cs from '../../../../services/CommonService'
import CheckAccess from '../../../Roles/CheckAccess'
import GenericModal from "../../../Modals/GenericModal"

let selectedStepActivity = null;
const StepActivity = (props) => {
  let history = useHistory()    
  //let stepActivities = props.stepActivities;
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [activeIndex, setActiveIndex] = useState()
  const [visible, setVisible] = useState(false)
  const [stepActivities, setStepActivities] = useState(props.stepActivities)

  const {isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal} = useModal()
  const {isOpen:isStepActivityOpen, toggleModal:toggleStepActivityModal} = useModal()

  const toggleMenu =(i, s) =>{
    //setActiveIndex(i==activeIndex?null:i)
    selectedStepActivity = s
    toggleStepActivityModal()
  }

  const deleteStepActivity = (e) => {
    e.preventDefault()
    StepActivitiesService.delete(selectedStepActivity).then((res)=>{
      if(res.status==204){
        setStepActivities(stepActivities.filter(i => i.id !== selectedStepActivity.id))
      }
    })
    toggleDeleteModal(e)
  }

  const confirmDelete = (e, step_activity) =>{
    e.preventDefault()
    selectedStepActivity = step_activity
    toggleDeleteModal(e)
  }

  const updateStatus = (e, step_activity) =>{
    e.preventDefault()
    step_activity.data.status = step_activity.data.status=='1'?'0':'1';
    StepActivitiesService.update(step_activity).then((res)=>{
      if(res.status==204){
        setStepActivities([...stepActivities])
      }
    })
  }

  const Menu = ({step_activity}) => {
    let status_form_link = `/form/step_activity_status/${step_activity.journey_profile_id}/${step_activity.activity_id}/${step_activity.id}`
    if(step_activity.status) status_form_link += `/${step_activity.status.id}`

    return( 
      <span className="dropup pull-right">
        <Fragment>
          <a><i className='fas fa-bars'></i></a>
          <div className="dropup-content w-100p btm-unset">
            {CheckAccess.hasDeleteAccess(currentUser, props.journeyProfile, step_activity) &&
              <Link to={`/step_activity/form/${step_activity.journey_profile_id}/${step_activity.activity_id}/${step_activity.id}?form_id=${step_activity.form_id}&title={props.activity.data.name}`}>
                <i className="far fa-edit"/> 
                Edit
              </Link>
            }
            {CheckAccess.hasDeleteAccess(currentUser, props.journeyProfile, step_activity) &&
              <a href="#" onClick={e => {confirmDelete(e, step_activity)} }>
                <i className="far fa-trash-alt"/> 
                Delete
              </a>
            }
            <Link to={status_form_link}>
              <i className={step_activity.status?'far fa-check-circle':'far fa-circle'}/>  
              Track Status
            </Link>
          </div>
        </Fragment>
      </span>
    )
  }

  const FileList = ({files}) => {
    return(
      <Fragment>
        {files && files.map((file, k) =>  (
          <a key={k} className="m-t-5 inline-files-list" target="_blank" href={Cs.getFileUrl(file)}>
            <img src={Cs.getIconByType(file)} width="35" height="35"/>
            <span className="m-l-5">{Cs.getFileName(file)}</span>
          </a>
        ))}
      </Fragment>
    )
  }

  const Card = ({step_activity, data}) => {
    let milestone_impacted = null
    try{
      milestone_impacted = step_activity.status.data.milestone_impacted_array
    }catch(e){}

    return(
      <div className="p-10 font-14">
        <p className="pull-right font-10" style={{border:"1px solid", padding:"2px"}}>{step_activity.author_name}-{Cs.formatUpdateDate(step_activity.created_at)}</p>
        <a href={data.reference_url} target="_blank">{data.reference_url}</a>
        <FileList files={data.upload}/>
        <div className="m-t-5" href="#">{data.start_date} to {data.end_date}</div>
        <div className="m-t-5" dangerouslySetInnerHTML={{ __html: data.notes }}/>
        {milestone_impacted && <Fragment>
            <u>Milestone Impacted</u> 
            <MilestoneImpacted list={milestone_impacted}/>
          </Fragment>  
        }
      </div>
    )
  }

  const RenderCard = ({step_activity, data}) =>{
    if (typeof data.upload === 'string') {
      data.upload = [data.upload]
    }
    return <Card step_activity={step_activity} data={data} />
  }

  const FileLink = ({step_activity, data}) =>{
    return (
      <Fragment> 
        {data.upload && data.upload.length>0 && <span className="pointer pull-right m-r-15">
          <i className="fas fa-paperclip color-highlight"></i>
        </span>}
      </Fragment>  
    )
  }

  const MilestoneImpacted = ({list}) => list.map((m, i) => (
    <div className="lred">{m.name}</div>
  ))

  const StepActivityCard = () => stepActivities && stepActivities.map((s, i) => {
    let cardStyle = {borderLeft:'5px solid rgb(163, 163, 255)'}
    try{cardStyle.borderLeft = '5px solid '+s.status.data_source.status.bg_color}catch(e){} 
    const rDays = s.status ? null : Cs.compareDate(s.data.start_date)

    return(
      <Fragment key={i}>
        <div className="step-activity" style={cardStyle} onClick={()=>toggleMenu(i, s)}>
          <div>
            {s.data.name} {rDays?`- (${rDays}D)`:null}
            <Menu step_activity={s}/>
            <FileLink step_activity={s} data={s.data} /> 
          </div>
        </div>
        {activeIndex == i && <RenderCard step_activity={s} data={s.data} /> }
      </Fragment>
    )
  })

  return (
    <Fragment>
      <div className="p-5">
        <strong>{stepActivities && 'Tasks'}</strong>
        <StepActivityCard/>
      </div>
      {isStepActivityOpen && (
        <GenericModal component={RenderCard} 
          title={selectedStepActivity.data.name} 
          step_activity={selectedStepActivity}
          data={selectedStepActivity.data}
          isOpen={isStepActivityOpen} 
          toggleModal={toggleStepActivityModal} />
      )}
      <ConfirmMenu isOpen={isDeleteModalOpen} 
        toggleModal={toggleDeleteModal} 
        success={deleteStepActivity}/>
    </Fragment>
  )

}

export default StepActivity;