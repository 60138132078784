import React, {useState, useEffect, Fragment, useContext} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import Role from '../../components/Roles/Role'
import RoleService from "../../components/Roles/RoleService";
import ConfirmMenu from '../Modals/ConfirmMenu'
import AddRole from '../../components/Roles/AddRole'
import {Link} from "react-router-dom";
import useModal from '../../hooks/useModal';
import useStyle from '../../hooks/useStyle';
import JourneyTitle from "../../components/Journey/CollegeJourney/JourneyTitle/JourneyTitle";
import ReactTooltip from "react-tooltip";
import querystringify from "querystringify";
import Cs from "../../services/CommonService";
import ProjectMenu from "../Modals/ProjectMenu";
import CheckAccess from "./CheckAccess"
import NotificationPopup from '../Common/NotificationPopup'
import HelpMessageList from '../../constants/HelpMessageList'
import Pagination from "../Pagination";
import SideMenuBar from "../Common/SideMenuBar";
import {MenuItem,CardMenuInfo ,NavIcon,JourneyHome} from '../Common/MenuItem'
import useSort from '../../hooks/useSorting'
import SortDirection from '../../components/Common/SortDirection'

let requestParams = {}
let selectedRole = {};
let dataSources = {};
let components = {};

const RoleList = (props) => {
  
  useStyle('card');  
  useStyle('card_table');
  useStyle('add_role_dialog');

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [roles, setRoles] = useState([]);

  const {isOpen:isDeletePopupOpen, toggleModal:toggleDeletePopup} = useModal();
  const {isOpen:isNewRolePopupOpen, toggleModal:toggleNewRolePopup} = useModal();
  const {isOpen: isMenuOpen, toggleModal: toggleMenu} = useModal();
  const {isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage} = useModal();
  const [currentpage, setCurrentpage] = useState(1);
  const [showTableView, setShowTableView] = useState(false);

  let params = props.match.params;
  let journey_profile = props.location?.state;
  let queryParam = querystringify.parse(props.location?.search);

  let notificationsTypes = {
      'hourly':'Hourly Report',
      'daily_digest':'Daily Digest',
      'immediate':'Immediate',
  }

  useEffect(() => {  
    requestParams = {
      'page': currentpage, 
      'per_page' : 10,
      'totalItems': 0,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'accelerator_id':currentUser.accelerator.id,
      'journey_profile_id':params.journey_profile_id,
      'data_source_params':{
        'data_source_id':[3290, 3410, 3411, 3412, 3475, queryParam.component_id]
      }
    }

    getRoleList();
  }, [props.searchString]);

  let getRoleList = () => { 
    RoleService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        dataSources = res.data.data_source_list;
        components = Cs.findObjById(dataSources, 'id', queryParam.component_id)
        requestParams.totalItems = res.data.meta.total;
        requestParams.page = res.data.meta.current_page;
        setRoles(res.data.roles)
        res.data.roles.length == 0 && toggleHelpMessage()
      }
    })
  }

  const {sort} = useSort(requestParams, getRoleList)

  const deleteRole = (e) => {
    RoleService.delete(selectedRole.id).then((res) => {
      if(res.status==204){
        setRoles(roles.filter(r => r.record_id !== selectedRole.record_id));
      }
    })  
    toggleDeletePopup(e);  
  }

  const setEdit = (e, role) => {
    selectedRole = role;
    toggleNewRolePopup(e);
  }
  
  const setDelete = (e, role) => {
    selectedRole = role;
    toggleDeletePopup(e);
  }

  const addNewRole = (role, e) => {
    if(role.id){
      RoleService.update(role).then((res) => {
        if(res.status==204){
          restModal(e);
          setRoles([...roles])
        }
      })  
    }else{
      role.created_by = currentUser.id;
      role.accelerator_id = currentUser.accelerator.id;
      role.journey_profile_id = params.journey_profile_id;
      RoleService.create(role).then((res) => {
        if(res.status==201){
          role.id = res.data.role.id;
          role.updated_user = {name:currentUser.name}
          restModal(e);
          setRoles([role, ...roles])
        }
      })  
    }
  }

  const restModal = (e) =>{
    selectedRole = {};
    toggleNewRolePopup(e);
  }

  const showMenu = (e, a) => {
    selectedRole = a;
    toggleMenu(e);
  }

  const switchTableView = (e) =>{
    setShowTableView(!showTableView)
  }

  const menuItems = ({ item }) => {
    return (
      <Fragment>
        <CardMenuInfo formId={item.form_id} recordId={item.record_id} 
          createdAt={item.updated_at} userName={item.updated_user.name}/>

        {CheckAccess.hasEditAccess(currentUser, journey_profile, item) &&
          <MenuItem id="edit_icon" 
            icon="far fa-edit"
            action="Edit"
            onSelect={(e) => {setEdit(e, item);}}
          />
        }

        {CheckAccess.hasDeleteAccess(currentUser, journey_profile, item) &&
          <MenuItem id="delete_icon" 
            icon="far fa-trash-alt"
            action="Delete"
            onSelect={(e) => {setDelete(e, item)}}
          />
          
        }
      </Fragment>
    )
  }

  const Menu = ({ role }) => (
    <i data-tip="Menu" onClick={(e) => showMenu(e, role)} className="fas fa-bars m-r-15"/>
  )

  const tableData = roles.map((o, k) => {
    return(
      <tr key={k}>
        <td className="text-center">
          {o.record_id}
        </td>
        <td className="ellipsis-lg text-center">
          {o.role}
        </td>
        <td className="text-center">
          {Cs.formatUpdateDate(o.created_at)}
        </td>
        <td className="">
          <span className="fas fa-edit m-l-15" onClick={e => {setEdit(e, o);} }/>
          <span className="fas fa-trash m-l-15" onClick={e => {setDelete(e, o);} }/>
        </td>
      </tr>
    )
  })

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-8">
          {journey_profile && <JourneyTitle title={"Roles"} project={journey_profile}/>}
        </div>
        
        <div className="col-xs-4 float-right">

          <NavIcon id="add_role" dataTip="Create New Role" 
            onSelect={restModal}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-plus"/>

          {!props.isPopupMode &&
            <JourneyHome id="home_icon" journey_profile={journey_profile}
              link={`/journey/portal/${params.journey_profile_id}`}
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fas fa-home" dataTip="Back to Journey Home" />
          }

          <span data-tip="Toggle Table View" className="bg-highlight round-btn float-right m-r-5 m-t-4" onClick={e=>switchTableView(e)}>
            <i className="fas fa-table"></i>
          </span>
              
          <ReactTooltip place="left" />
        </div>
      </div>

      <div className="page-content row">
        {!showTableView && roles && roles.map((role, i) => (
          <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
            <Role role={role} menu={Menu} componentList={components.options}
              setRoles={setRoles} roles={roles} index={i}/>

            {selectedRole.id == role.id && 
              <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenu}
                item={selectedRole} type="role"
                MenuItems={menuItems} />
            }
          </div>
        ))}
        {!showTableView && <Pagination totalItems={requestParams.totalItems}
          currentpage={currentpage} setCurrentpage={setCurrentpage}
          itemsPerPage={requestParams.per_page}/>}

        { showTableView &&
          <div className="table-responsive">
            <table className="shadow-small">
              <thead>
                <tr className="white bg-black">
                  <th onClick={e=>sort('record_id')} className="p-2 bold-400 text-center miw-95">
                    ID 
                    <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/>
                  </th>
                  <th onClick={e=>sort('role')} className="p-2 bold-400 text-center miw-95">
                    Role 
                    <SortDirection sort_column={requestParams.sort_column} column="role" reverse={requestParams.reverse}/>
                  </th>
                  <th onClick={e=>sort('updated_at')} className="p-2 bold-400 text-center miw-95">
                    Date
                    <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
                  </th>
                  <th>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData}
              </tbody>
            </table>
          </div>
        }
      </div>

      <ConfirmMenu isOpen={isDeletePopupOpen} toggleModal={toggleDeletePopup} success={deleteRole}/>
      {isNewRolePopupOpen && <AddRole role={selectedRole} isOpen={isNewRolePopupOpen} toggleModal={toggleNewRolePopup} 
      addNewRole={addNewRole} componentList={components.options} notificationsTypes={notificationsTypes} />}
      {isHelpMessageOpen && (
        <NotificationPopup header="Note" message={HelpMessageList.new_role} 
          onSuccess={toggleHelpMessage} isOpen={isHelpMessageOpen} 
          toggleModal={toggleHelpMessage}/>
      )} 
      <SideMenuBar helpId="2"/>
    </Fragment>
  )

};

export default RoleList;