import React, {useState, useEffect, Fragment, useRef, useContext} from 'react';
import querystringify from "querystringify"
import ReactTooltip from 'react-tooltip';
import {Calendar, Views, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {Link} from "react-router-dom";
import CalendarService from "./CalendarService"
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import DragObject from "./DragObject"
import AddEvent from "./AddEvent"
import useModal from '../../hooks/useModal'
import useStyle from '../../hooks/useStyle'
import SideMenuBar from "../../components/Common/SideMenuBar";
import CheckAccess from '../Roles/CheckAccess';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import AddScheduleType from "./AddScheduleType";
import JourneyProfileService from "../Journey/JourneyProfileService"
import Cs from "../../services/CommonService"
import GenericModal from "../Modals/GenericModal"
import StepActivity from '../Journey/CollegeJourney/StepActivities/StepActivityModal'
// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer
const DragAndDropCalendar = withDragAndDrop(Calendar)
let dataSources = {}
let selectedEvent = null
let newEvent = null
let dragabbleEvents = null
let newScheduleType = null

let CalendarView = (props) => {
  useStyle('add_role_dialog')
  useStyle('card')
  
  const {state: { user: currentUser }, dispatch,} = useContext(AuthContext)
  
  let events = []
  let params = props.match.params
  let journey = props.location.state || {}
  let queryParam = querystringify.parse(props.location.search)

  const [data, setData] = useState({events: [], displayDragItemInCell: true,})
  const [isNewEventPopupOpen, setNewEventPopupOpen] = useState(false)

  const {isOpen:isStepActivityOpen, toggleModal:toggleStepActivityModal } = useModal()

  const addDataSource = (e, type) =>{
    if(type.id){
      JourneyProfileService.updateDataSource(type).then((res)=>{
        dragabbleEvents[type.id] = type.data
        props.toggleScheduleType(e)
        setData({...data})
      })
    }else{
      type.item_id = params.journey_profile_id
      type.item_type = queryParam.item_type
      JourneyProfileService.createDataSource(type).then((res)=>{
        dragabbleEvents = dragabbleEvents || {}
        dragabbleEvents[res.data.data_source_list.id] = res.data.data_source_list.data
        props.toggleScheduleType(e)
        setData({...data})
      })
    }
    newScheduleType = null
  }

  const editEventType = (e, type) =>{
    newScheduleType = {id:type.pk_id, data:type}
    props.toggleScheduleType(e)
  }

  const deleteDataSource = (e, type) =>{
    e.stopPropagation()
    JourneyProfileService.deleteDataSource(type.pk_id).then((res)=>{
      delete dragabbleEvents[type.pk_id]
      setData({...data})
    })
  }

  useEffect(() => {
    let req = {
      'page':1,
      'per_page':100,
      'journey_type':queryParam.item_type,
      'journey_profile_id':params.journey_profile_id,
      'schedule_item_type':queryParam.item_type,
      'schedule_item_id':queryParam.item_id,
      'add_schedule_type':true,
      'data_source_params': {
        'response_type':'object', 
        'data_source_id': 3393
      }
    }

    CalendarService.getSchedules(req).then((res)=>{
      if(res.status==200){
        dataSources = res.data.data_source_list
        dragabbleEvents = res.data.schedule_types
        res.data.schedules.forEach((e, i)=>{
          e.start = Cs.stringToDate(e.start_date)
          e.end = Cs.stringToDate(e.end_date || e.start_date)
          let d = setEventInfo(e)
          d.category = e.data.category
          d.color = e.status?.data_source?.status?.bg_color || e.data.color
          events.push(d)
        })

        data.events = events
      }
      setData({...data})
    })
    
    setAgendaLabel()
  }, [])

  const setAgendaLabel = () =>{
    setTimeout(function() {
      let calendarEl = document.querySelector("div.rbc-toolbar > span:nth-child(3) > button:nth-child(4)");
      if(calendarEl){
        calendarEl.innerText = 'Upcoming'
      }else{
        setAgendaLabel()
      }
    }, 1000)
  }

  const handleDragStart = (e, drag_item) => {
    if(journey.is_admin_user){
      let item = {
        'id':drag_item.pk_id,
        'data_type':queryParam.item_type,
        'title':drag_item.label,
        'activity_type':drag_item.label,
        'activity_form_id':443,
        'category':drag_item.pk_id,
        'color':drag_item.bg_color
      }
      data.draggedEvent = item;
      setData({...data})
    }
  }

  const dragFromOutsideItem = () => {
    return data.draggedEvent
  }

  const onDropFromOutside = ({ start, end, allDay }) => {
    if(journey.is_admin_user){
      const { draggedEvent } = data;
      //let event = {id: draggedEvent.id, title: draggedEvent.title, start, end, allDay: allDay,}
      let startDate = moment(start).format('YYYY-MM-DD')
      let req = {
        start_date: startDate,
        start_date_string: startDate,
        data: {
          'title':draggedEvent.title,
          'start':startDate,
          'start_formatted_date':startDate,
          'color':draggedEvent.color,
          /*'end':event.end,
          'end_formatted_date':event.end,*/
          'category':draggedEvent.id
        },
        journey_profile_id: params.journey_profile_id,
        journey_category_type: params.journey_type,
        privacy_type: 'shared'
      }
      
      req.schedule_item_id = params.journey_profile_id
      req.schedule_item_type = draggedEvent.data_type
      req.journey_profile_id = params.journey_profile_id
      req.data.form_id = draggedEvent.activity_form_id
      req.data.type = draggedEvent.activity_type

      CalendarService.createSchedule(req).then((res)=>{
        if(res.status==201){
          let d = res.data.schedule;
          d.start = Cs.stringToDate(d.start_date);
          d.end = d.end_date?Cs.stringToDate(d.end_date):d.start;
          let event = setEventInfo(d)
          event.category = d.data.category
          event.is_new = true;
          moveEvent({ event, start, end })
        }
      })

      data.draggedEvent = null;
    }    
  }

  const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    if(journey.is_admin_user){
      const { events } = data;
      let nextEvents = []
      let allDay = event.allDay

      if (!event.allDay && droppedOnAllDaySlot) {
        allDay = true
      } else if (event.allDay && !droppedOnAllDaySlot) {
        allDay = false
      }

      if(event.is_new){
        events.push({ ...event, start, end })
        data.events = events;
      }else{
        nextEvents = events.map(existingEvent => {
          return existingEvent.id == event.id
            ? { ...existingEvent, start, end }
            : existingEvent
        })
        data.events = nextEvents;
        updatedEvent(event, start, end);
      }
      setData({...data})
    }
  }

  const resizeEvent = ({ event, start, end }) => {
    if(journey.is_admin_user){
      const { events } = data

      const nextEvents = events.map(existingEvent => {
        return existingEvent.id == event.id
          ? { ...existingEvent, start, end }
          : existingEvent
      })

      setData({
        events: nextEvents,
      })

      updatedEvent(event, start, moment(end).subtract(1, 'days'))
    }
  }

  const updatedEvent = (event, start, end) =>{
    let startDate = moment(start).format('YYYY-MM-DD')
    let endDate = moment(end).format('YYYY-MM-DD')
    event.start_date = event.start_date_string = startDate
    event.data.start = event.data.start_formatted_date = startDate
    if(event.end){
      event.end_date = event.end_date_string = endDate
      event.data.end = event.data.end_formatted_date = endDate
    }

    CalendarService.updateSchedule(event).then((res)=>{
      if(res.status==204){}
    })
  }

  const onSelectEvent = (event) =>{
    //if(event.created_by === currentUser.id && event.data && event.data.form_id){
    if(event.data && event.data.form_id){
      selectedEvent = event
      event.item_type == 'step_activity' && toggleStepActivityModal()  
      //props.history.push(`/calendar/schedule/${params.journey_profile_id}/${event.item_type}/${event.id}`, journey)
    }else if(!event.created_by){
      //open popup
      newEvent = event
      setNewEventPopupOpen(true)
    }
  }

  const onSelectSlot = (slotInfo)=>{  
    newEvent = {
      'schedule_item_id': params.journey_profile_id, 
      'schedule_item_type': queryParam.item_type,
      'journey_profile_id': params.journey_profile_id,
      'journey_category_type' : params.journey_type,
      'start_date': moment(slotInfo.start).format("YYYY-MM-DD"),
      'privacy_type':'private',
      'data':{}
    }
    setNewEventPopupOpen(true)
  }

  const addNewEvent = (e, event)=>{
    if(event.id){
      CalendarService.updateSchedule(event).then((res)=>{
        if(res.status==204){
          let e = setEventInfo(event)
          data.events = data.events.map(existingEvent => {
            return existingEvent.id == event.id? e: existingEvent
          })
          setData({...data})
        }
      })
    }else{
      CalendarService.createSchedule(event).then((res)=>{
        if(res.status==201){
          let d = res.data.schedule;
          if(d.start_date){
            d.start = Cs.stringToDate(d.start_date);
            d.end = d.end_date?Cs.stringToDate(d.end_date):d.start;
            let e = setEventInfo(d)
            data.events = [e].concat(data.events)
            setData({...data})
          }     
        }
      })
    }
    newEvent = null
    setNewEventPopupOpen(false)
  }
  
  const setEventInfo = (d) =>{
    return {
      'id':d.id,
      'title':d.data.title,
      'start': d.start,
      'end': d.end,
      'allDay':true,
      'item_id':d.schedule_item_id,
      'item_type':d.schedule_item_type,
      'data':d.data,
      'created_by':d.created_by
    }  
  }

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = event.color || (event.category && dragabbleEvents[event.category]?.bg_color);
    
    return {
      style: {backgroundColor: backgroundColor || 'rgb(102, 102, 102)',}
    }
  }

  return(
    <Fragment>
      <div className="rbc-container p-5 d-flex scroll-x m-t-5">
        {dragabbleEvents && 
          Object.values(dragabbleEvents).map((o, k) =>
            <DragObject item={o} handleDragStart={handleDragStart} key={k} bgColor="bg_color"
            onEdit={editEventType} onDelete={deleteDataSource}/>
          )
        }
      </div>

      <div className="rbc-container p-5 bg-white m-t-5">
        <DragAndDropCalendar
          defaultView='day'
          localizer={localizer}
          events={data.events}
          startAccessor="start"
          endAccessor="end" 
          onEventDrop={moveEvent}
          resizable
          selectable={true}
          onEventResize={resizeEvent}
          onDragStart={console.log('ondragstart')}
          dragFromOutsideItem={data.displayDragItemInCell ? dragFromOutsideItem : null}
          onSelectEvent={(event)=>onSelectEvent(event)}
          onDropFromOutside={onDropFromOutside}
          handleDragStart={handleDragStart}
          onSelectSlot={(slotInfo)=>onSelectSlot(slotInfo)}
          eventPropGetter={eventStyleGetter}/>
      </div>

      {isNewEventPopupOpen &&
        <AddEvent newEvent={newEvent} isOpen={isNewEventPopupOpen} 
        toggleModal={setNewEventPopupOpen} addNewEvent={addNewEvent}/>
      }

      {props.isScheduleTypeOpen && 
        <AddScheduleType newScheduleType={newScheduleType} 
        toggleModal={props.toggleScheduleType} addNewScheduleType={addDataSource}/>
      }
      
      {isStepActivityOpen && (
        <GenericModal component={StepActivity} 
          title={selectedEvent.data.title} 
          stepActivityId={selectedEvent.item_id}
          isOpen={isStepActivityOpen} 
          toggleModal={toggleStepActivityModal} />
      )}
    </Fragment>
  )
}

export default CalendarView;