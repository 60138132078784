import React, { Fragment, useState } from 'react';
import useStyle from '../../hooks/useStyle'

let addToScreenPrompt = null;

window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        addToScreenPrompt = e;
})

const AddToHomeScreen = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  useStyle('snackbar');
  
  if (!addToScreenPrompt) {
    return null
  }

  if(!isOpen) return null;

/*if (nativeApp && doesVersionSendPushMessages(nativeApp.version)) {
  // There’s an installed native app that handles sending push messages.
  // No need to do anything.
  return;
}*/

  const addToHome = () =>{
    // Show the prompt
    if(addToScreenPrompt){
      addToScreenPrompt.prompt();
      // Wait for the user to respond to the prompt
      addToScreenPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
          setIsOpen(false);
        }
        addToScreenPrompt = null;
      })
    }
  }

  return (<div id="snackbar" className="show">
    <span onClick={e=>setIsOpen(false)} className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal" 
      style={{position: 'absolute',top: '3px'}}><i className="fa fa-times" aria-hidden="true"></i>
    </span>
    <div className="col-xs-3">
      <img src="images/logo/apple-touch-icon-57x57.png" alt="logo"/>
    </div>
    <div className="col-xs-9">
      <h4 className="green">cueTree ECN</h4>
      <div className="font-15">Guided Acceleration</div>
    </div>
    <a><u>www.beta.ecn.cuetree.com</u></a>
    <div className="add-to-home" onClick={e=>addToHome(e)}>ADD TO HOME SCREEN</div>
  </div>)

}

export default AddToHomeScreen;