import React, {Fragment,useState} from "react";
import {Link} from "react-router-dom";
import moment from '../../../../../node_modules/moment/min/moment.min.js'
import Rating from "../../../../components/Rating"
import FormTemplateList from "../../../../constants/FormTemplateList"
import env from "../../../../env";
import CommonService from '../../../../services/CommonService'
import TrackRelationship from '../../../Journey/CollegeJourney/StepActivities/TrackRelationship'
import ContactMembers from '../../../Journey/CollegeJourney/StepActivities/ContactMembers'

let profileImage = "images/avatar-2.jpg";

const Organization = (props) => {
  let org = props.organization;
  let data = org.data;

  const [index,setIndex] = useState()

  if (data.logo) {
    profileImage = env.file_url + data.logo;
  }

  const cardStyle = {
      borderTop: '5px solid '+ ( org.data_source.status ? org.data_source.status.bg_color :'#b5adad'  )
  };

  const handleClick =(form, i)=> {
    if(index != i){
      const tabScroll = document.getElementById('tab_'+i);
      window.scrollTo({
        'behavior': 'smooth',
        'left': 0,
        'top': tabScroll.offsetTop - 200
      })
    }  
    setIndex(index == i?null:i)
  }

  //const routeEventDetails = () => props.history.push(`/organization/details/${org.id}`)

  return (
    <div className="card-container bg-white shadow-small" style={cardStyle} >
          <div className="white card-header p-5">
            <ul className="d-flex">
            <li>
              <img className="rounded-circle bg-highlight" src={profileImage} width="50px"/>
            </li>
            <li className="m-l-15" style={{width:'60%'}}>
              <Link  to={`/organization/projects/${org.id}`}>
              <div className="vcard-title black">{data.name ? data.name : '-'}</div>
                <span className="black">since {moment(org.updated_at).format('MMM DD, YYYY')}</span>
              </Link>
            </li>
            <li style={{right: "5px",position: "absolute",    top: "20px"}}>
            <Link className="black-light-1" to={`/organization/projects/${org.id}`}>
              <i className='fas fa-share'></i>   
             </Link>
            </li>
            <li style={{right: "5px",position: "absolute"}}>
            <i className="fas fa-expand pull-right expand black" onClick={e=>props.routeActivity(org)}></i>
            </li>
          </ul>
          <div className="divider bottom-20" style={{ backgroundColor: 'grey'}}/>
        </div>

      <div className={`scroll-auto ${props.cardHeightCss}`}>
        <span className="pull-right font-11 lgrey m-l-15 p-7">
          Last by {org.updated_user.name} {CommonService.formatUpdateDate(org.updated_at)}
        </span>
        <TrackRelationship  stepActivities={org.relationship_trackings} activity={org} />
        <ContactMembers stepActivities={org.organization_members} activity={org} />
        <table className="table card-table">
          <tbody>
              <tr>
                <td>
                  <span className="pull-right"><strong>Industry</strong>: {org.data_source.industry && org.data_source.industry.label}</span>
                  <strong>Office</strong>
                  <div>{data.street_1}<br/>{data.street_2}<br/>{data.city}<br/> {data.state}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Rating</strong>
                  <Rating noOfRating={5} sumOfRating={20} />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Personal</strong>
                  <a href="tel:+{data.mobile}">{data.principal_contact}</a>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Mobile</strong>
                  <a href="tel:+{data.telephone}">+{data.telephone}</a>
                </td>
              </tr>
            </tbody>
        </table>       
      </div>
     
      <div className="card-footer">
        <div className="pull-left">
          <props.details org={org} />
          <span className="m-l-25" onClick={e=>{props.addNotes(e, org);}}>
            <i className="far fa-comment font-18 text-muted"></i>
            <span className="badge up bg-lgrey">{org.comments_count || 0}</span>
          </span>
          <span className="m-l-25" onClick={e=>{props.addChecklist(e, org);}}>
            <i className="far fa-check-circle font-18 text-muted"></i>
            <span className="badge up bg-lgrey">{org.checklists_count || 0}</span>
          </span>
          <span className="m-l-25">
            <Link to={`/incoming/emails/organization/123`}>
              <i className="fas fa-envelope font-18 text-muted"></i>
              <span className="badge up bg-lgrey">0</span>
            </Link>
          </span>
        </div>
        <div className="pull-right">
          <props.menu org={org} toggleModal={props.toggleModal}/>
        </div>
      </div>      
    </div>
  )

};

export default Organization;