import React, {useState} from "react";
import ReactTooltip from 'react-tooltip';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import ProjectMilestoneService from "../../../components/Organizations/ProjectMilestones/ProjectMilestoneService";
import PortalHeader from "../../../components/Organizations/ProjectPortalPage/PortalHeader";
import MilestoneTable from "../../../components/Organizations/MilestoneReview/MilestoneTable";
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu";
import {Link} from "react-router-dom";
import useStyle from '../../../hooks/useStyle';

let requestParams = {};
let project = null;
let selectedContract = {};
let milestones = null;

export const MilestoneReview = (props) => {
  useStyle('panel');
  useStyle('card');
  
  const { state:currentUser, dispatch } = React.useContext(AuthContext);
  const [milestoneList, setMilestoneList] = useState([]);
  const [currentpage,setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);

  React.useEffect(() => {
    requestParams = {
      'page': currentpage, 
      'per_page' : 15,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'accelerator_id': currentUser.user.accelerator.id,
      'project_id':props.match.params.project_id,
    }

    getProjectMilestones();
  }, [currentUser.user.accelerator, props.searchString,currentpage]);

  let getProjectMilestones = () => { 
    ProjectMilestoneService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       project = res.data.project;
       milestones = res.data.milestones;
       setPagemeta(res.data.meta)
       setMilestoneList(res.data.project_milestones)
      }
    })
  }

  if(!project){
    return null;
  } else { 
    return (
      <React.Fragment>
       <div className="content top-10 clearfix">
        <div className="col-xs-8">
        <div className="font-16 bold-600">
  	    {project.data.name}
   		</div>
    	<div className="font-15">
      Project Milestones
        <span><i className="fa fa-question-circle m-l-5" ></i></span>
      </div>        
        </div>
        <div className="col-xs-4"> 
        <Link to={`/organization/project/portal/${project.id}`} className="bg-highlight round-btn float-right m-r-5">
            <i className="fas fa-home"/>
          </Link>
          <ReactTooltip place="left" />
        </div>
      </div>
      <div className="page-content row">
          <MilestoneTable milestoneList={milestoneList} getProjectMilestones={getProjectMilestones} requestParams={requestParams}/> 
        </div>
      </React.Fragment>
    )
  }
};

export default MilestoneReview;