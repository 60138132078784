import React ,{useState, Fragment, useContext} from "react";
import moment from 'moment'
import { useHistory, Link } from 'react-router-dom'
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import StepActivitiesService from "./StepActivitiesService";
import ConfirmMenu from "../../../Modals/ConfirmMenu";
import useModal from "../../../../hooks/useModal";
import CommonService from '../../../../services/CommonService';
import CheckAccess from '../../../Roles/CheckAccess';

let selectedStepActivity = null;
const TrackRelationship = (props) => {
  let history = useHistory()    
  //let stepActivities = props.stepActivities;
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [activeIndex, setActiveIndex] = useState();
  const [visible, setVisible] = useState(false);
  const [stepActivities, setStepActivities] = useState(props.stepActivities);
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal();

  const toggleMenu =(i) =>{
    setActiveIndex(i==activeIndex?null:i)
  }

  const deleteStepActivity = (e) => {
    e.preventDefault();
    StepActivitiesService.delete(selectedStepActivity).then((res)=>{
      if(res.status==204){
        setStepActivities(stepActivities.filter(i => i.id !== selectedStepActivity.id))
      }
    })
    toggleDeleteModal(e);
  }

  const confirmDelete = (e, step_activity) =>{
    e.preventDefault();
    selectedStepActivity = step_activity;
    toggleDeleteModal(e);
  }

  const Menu = ({step_activity}) => {
    
    return( 
      <span className="dropup pull-right">
        <Fragment>
          <a><i className='fas fa-bars'></i></a>
          <div className="dropup-content w-100p btm-unset">
              <Link to={`/track/relationship/${step_activity.tracking_id}/${step_activity.tracking_type}/${step_activity.id}`}>
                <i className="far fa-edit"/> Edit
              </Link>
              <a href="#" onClick={e => {confirmDelete(e, step_activity)} }>
                <i className="far fa-trash-alt"/> Delete
              </a>
          </div>
        </Fragment>
      </span>
    )
  }

  const FileList = ({files}) => {
    return(
      <Fragment>
        {files && files.map((file, k) =>  (
          <a key={k} className="m-t-5 inline-files-list" target="_blank" href={CommonService.getFileUrl(file)}>
            <img src={CommonService.getIconByType(file)} width="35" height="35"/>
            <span className="m-l-5">{CommonService.getFileName(file)}</span>
          </a>
        ))}
      </Fragment>
    )
  }

  const Card = ({step_activity, data}) => {
    let ds = step_activity.data_source;
    const SpanField = ({name, label, styleName}) =>(
      <span className={styleName}>{ds[name] && ds[name][label]}</span>
    )

    const SpanList = ({name, label, styleName}) => ds[name] && ds[name].map((d, i) => (
      <span className={styleName +' m-l-5'} key={i}>{d.data[label]}</span>
    ))
    return(
      <div className="p-5 font-14">
        <p className="pull-right font-10" style={{border:"1px solid", padding:"2px"}}>{step_activity.author_name}-{CommonService.formatUpdateDate(step_activity.created_at)}</p>
        <a href={data.reference_url} target="_blank">{data.reference_url}</a>
        <div className="m-t-5" href="#">{data.title}</div>
        <table>
          <tbody>
            <tr>
              <td><SpanField name="strength" label="label" styleName="pill bg-highlight pull-left"/></td>
              <td><SpanField name="source" label="label" styleName="pill bg-highlight pull-right"/></td>
            </tr>
            <tr>
              <td><SpanField name="purpose" label="label" styleName="pill bg-highlight pull-left"/></td>
              <td><SpanField name="outcome" label="label" styleName="pill bg-highlight pull-right"/></td>
            </tr>
            <tr>
              <td><SpanField name="helpful" label="label" styleName="pill bg-highlight pull-left"/></td>
              <td><SpanList name="channel" label="label" styleName="pill bg-highlight pull-right"/></td>
            </tr>
            <tr>
              <td>
              <FileList files={data.reference_upload}/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  const RenderCard = ({step_activity, data}) =>{
    if (typeof data.reference_upload === 'string') {
      data.reference_upload = [data.reference_upload]
    }
    return <Card step_activity={step_activity} data={data} />
  }

  const FileLink = ({step_activity, data}) =>{
    return (
      <Fragment> 
        {data.reference_upload && data.reference_upload.length>0 && <span className="pointer pull-right m-r-15">
          <i className="fas fa-paperclip color-highlight"></i>
        </span>}
      </Fragment>  
    )
  }

  const StepActivityCard = () => stepActivities && stepActivities.map((s, i) => {
    let cardStyle = {borderLeft:'5px solid rgb(163, 163, 255)'}
    try{cardStyle.borderLeft = '5px solid '+s.status.data_source.status.bg_color}catch(e){} 
    return(
      <Fragment key={i}>
        <div className="step-activity" style={cardStyle} onClick={e=>toggleMenu(i)}>
          <div className="pull-left">{s.data.title} - {s.data.name}</div>
          <Menu step_activity={s}/>
          <FileLink step_activity={s} data={s.data} /> 
        </div>
        {activeIndex == i && <RenderCard step_activity={s} data={s.data} /> }
      </Fragment>
    )
  })

  return (
    <Fragment>
      <div className="p-5">
        <strong>{ stepActivities && "Tracks"}</strong>
        <StepActivityCard/>
      </div>
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteStepActivity}/>
    </Fragment>
  )

}

export default TrackRelationship;