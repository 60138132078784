import React, {useState} from 'react';
import CueTooltip from '../../../components/Common/CueTooltip'
import FormHelper from '../../../components/FormBuilder/FormHelpers';
/*import useCustomForm from '../../../hooks/useCustomForm';*/
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel, Description} from '../FieldLabel'
import NumberFormat from 'react-number-format';


const InputTextField = ({field, formData, formFn, readOnly, errors, isFormWizard, position, openFieldLabelModal, currentLocale}) => {
  
  /*let validator = {
    set: function(obj, prop, value) {
      if (field === 'age') {
        if (!Number.isInteger(value)) {
            throw new TypeError('The age is not an integer');
        }
        if (value > 200) {
            throw new RangeError('The age seems invalid');
        }
      }

      // The default behavior to store the value
      obj[prop] = value;

      // Indicate success
      return true;
    }
  }

  let modal = new Proxy(formData, validator);*/

  const [showError, setShowError] = useState(false);

  let inputAttributes = {
  	'id': field.client_id,
  	'name': field.client_id,
  	'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only),
    'type':'text',
    'required':field.required
  }

  let parentAttributes = {
  	'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);

  let validation = {
    required: 'This is required'
  }

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    formData[name] = target.value;
    FormHelper.setFormTitle(field, formData, target.value)
    //setValue("lastName", "Hopper")
  }

  const setFormatNumber = (values) =>{
    const { formattedValue, value } = values;
    formData[field.client_id] = value;
    formData[field.client_id+'_formatted'] = formattedValue;
    FormHelper.setFormTitle(field, formData, formattedValue)
  }

  const setError = (event) => {
    if(field.required){
      const { target:{name, validity:{valid, valueMissing, typeMismatch, patternMismatch, tooShort, tooLong, rangeOverflow, rangeUnderflow}} } = event;
      errors[name].invalid = !valid;
      /*errors[name].typeMismatch = typeMismatch;
      errors[name].patternMismatch = patternMismatch;
      errors[name].tooShort = tooShort;
      errors[name].tooLong = tooLong;
      errors[name].rangeOverflow = rangeOverflow;
      errors[name].rangeUnderflow = rangeUnderflow;*/
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
        if(valueMissing){
          errors[name].errormsg =  field.label+' is required.'
        }else if(typeMismatch){
          errors[name].errormsg = 'Invalid Input'
        }else if(patternMismatch){
          errors[name].errormsg = 'Invalid Input'
        }else if(tooShort){
          errors[name].errormsg = 'Input too short'
        }else if(tooLong){
          errors[name].errormsg = 'Input too long'
        }else if(rangeOverflow){
          errors[name].errormsg = 'Range too short'
        }else if(rangeUnderflow){
          errors[name].errormsg = 'Range too long'
        }
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }

  FormHelper.setInputTypeValidation(field, inputAttributes, validation, position);
  FormHelper.setMinMaxValidation(field, inputAttributes, validation);
  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          {errors[field.client_id].errormsg && <div>
              {errors[field.client_id].errormsg}
            </div>
          }
        </div>
      )
    }else{
      return null
    }
  }

  if(field.component_type == "phone_no"){
    return(
      <div {...parentAttributes}>
        <FieldLabel field={field} labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal}/>
        <NumberFormat mask="_" 
        defaultValue={formData[field.client_id]}
        onValueChange={(values) => setFormatNumber(values)}
        {...inputAttributes} className="form-control"
        onBlur={e => {setError(e)}}
        data-tip data-for={`tooltip_${field.client_id}`}/>
        <Description description={field.description}/>
        <Error/>
        <CueTooltip id={field.client_id} description={field.tooltip}/>
      </div> 
    )
  }

  return (
  	<div {...parentAttributes}>
  	  <FieldLabel field={field} labelAttributes={labelAttributes} 
        currentLocale={currentLocale}
        openFieldLabelModal={openFieldLabelModal}/>
      <input {...inputAttributes} data-tip data-for={`tooltip_${field.client_id}`} onChange={e => handleChange(e)} defaultValue={formData[field.client_id]} 
      onBlur={e => {setError(e)}} className="form-control"/> 	 
 	    <Description description={field.description}/>
      {inputAttributes.maxLength && <span className="pull-right p-7">{inputAttributes.maxLength} Characters Max</span>}
      <Error/>
      <CueTooltip id={field.client_id} description={field.tooltip}/>
    </div> 
  )
}

export default InputTextField;