import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import useModal from "../../../hooks/useModal";
import ProjectMenu from "../../Modals/ProjectMenu"
import ConfirmMenu from "../../Modals/ConfirmMenu";
import CommentsModal from "../../Modals/CommentsModal";
import moment from '../../../../node_modules/moment/min/moment.min.js'
import MilestoneTaskService from "../../../components/Organizations/MilestoneTasks/MilestoneTaskService";

const MilestoneTask = ({task, cardHeightCss}) => {
  const { isOpen, toggleModal:toggleDeleteModal } = useModal();
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal();

  let deleteMilestoneTask = (e) => {
    e.preventDefault();
    MilestoneTaskService.delete(task).then((res) => {
      if (res.status == 204) {
        toggleDeleteModal(e)
      }
    })
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <Link to={`/milestone/task/form/${item.id}`}>
          <i className="far fa-edit font-15" aria-hidden="true"></i>
          <span className="font-13">Edit</span>
          <i className="fa fa-angle-right"></i>
        </Link>

        <a href="#" onClick={e=>toggleDeleteModal(e)}>
          <i className="far fa-trash-alt font-15" aria-hidden="true"></i>
          <span className="font-13">Delete</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  return (
    <Fragment>
    <div className="card-container bg-white shadow-small">
      <div className="white card-header p-5 bg-lgrey">
        <div className="font-16">
          Milestone {task.data.name}
        </div>
        <div>
          {task.updated_at}
        </div> 
      </div>
      <div className={`scroll-auto ${cardHeightCss}`}>
        <table className="table card-table">
          <tbody>
              <tr>
                <td>
                  <strong>Date</strong>
                  <div>{task.data.end_date}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Notes</strong>
                  <div>{task.data.notes}</div>
                </td>
              </tr>
            </tbody>
        </table>
      </div>
      <div className="card-footer">
        <div className="pull-left">
          <span className="m-l-15">{task.record_id}</span>
        </div>
        <div className="pull-right">
          <span className="m-r-15" >
            <i onClick={e=>toggleMenuModal(e)} className="fas fa-bars" />     
          </span>
        </div>
      </div>
    </div>
    <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} MenuItems={menuItems} item={task}/>
    <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteMilestoneTask}/>
    {isCommentOpen &&
      <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={task} type="milestone_task"/>
    }
    </Fragment>
  )

};

export default MilestoneTask;