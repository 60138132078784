import React, { Fragment, useState, useEffect, useContext} from "react";
import querystringify from "querystringify"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import useStyle from "../../hooks/useStyle";
import env from "../../env";
import useModal from "../../hooks/useModal";
import AuthenticationService from "../../services/AuthService";
import DigitalContentService from "../DigitalContent/DigitalContentService";
import CommonService from "../../services/CommonService";
import ImageEditor from "../ImageEditor/ImageEditor";
import GenericModal from "../Modals/GenericModal";
import Tabs, { TabPane } from 'rc-tabs';
import SecretQuestions from './SecretQuestions';
import NotificationPopup from '../Common/NotificationPopup'
import HelpMessageList from '../../constants/HelpMessageList'
//import UserSubscription from '../Subscriptions/UserSubscription'

let profileImage = 'images/avatar-2.jpg' 
let error = {};
let digiRequestParams = {}
let msgRequestParams = {}

const DemoUserProfile = (props) => {
  useStyle("user_profile");
  let queryParam = querystringify.parse(props.location.search);
  
  const {state: { screen, user: currentUser }, dispatch, } = useContext(AuthContext);
  const [user, setUser] = useState({});
  const [msgs, setMsgs] = useState();
  const [content, setContent] = useState();
  const [settings, setSettings] = useState({});
  const { isOpen:isImageEditorOpen, toggleModal:toggleImageEditorModal } = useModal();
  const { isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage } = useModal();

  if (currentUser.profile_image) {
    profileImage = CommonService.getIconByType(currentUser.profile_image, 'profile_image');
  }

  useEffect(() => {
    digiRequestParams = {
      content_type: "what_new",
      page: 1,
      paginate: 1,
      per_page: 10,
      sort_column: "updated_at",
      sort_direction: "asc",
      totalItems: 0,
      search: null,
    };

    msgRequestParams = {
      content_type: "in_app_notif",
      page: 1,
      paginate: 1,
      per_page: 10,
      sort_column: "updated_at",
      sort_direction: "asc",
      totalItems: 0,
      search: null,
    };

    getSettings()
    getWhatsNew();
    getMessages();
  }, []);

  let getWhatsNew = () => {
    DigitalContentService.getAll(digiRequestParams).then((res) => {
      if (res.status == 200) {
        digiRequestParams.totalItems = res.data.meta.total;
        setContent(res.data.digital_contents)
      }
    })
  }

  let getMessages = () => {
    DigitalContentService.getAll(msgRequestParams).then((res) => {
      if (res.status == 200) {
        digiRequestParams.totalItems = res.data.meta.total;
        setMsgs(res.data.digital_contents)
      }
    })
  }

  const handleChange = (e) => {
    user[e.target.name] = e.target.value;
    setUser({ ...user });
  };

  const handlePasswordChange = (e) => {
    user[e.target.name] = e.target.value;
    setUser({ ...user });
    checkPasswordValid();
  };

  const updatePassword = () => {
    let data = {
      user: user,
    };
    AuthenticationService.updatePassword(data).then((res) => {
      if (res.status == 200) {
        error.pwsuccess = res.data.message;
      } else if (res.status == 400) {
        error.pwerror = res.data.message;
      } else {
        error.pwerror = "Password Reset Failed";
      }
      setUser({ ...user });
    });
  };

  const checkPasswordValid = () => {
    error.is_password_valid = false;
    if (user.password && user.new_password && user.password_confirmation) {
      if (user.new_password === user.password_confirmation) {
        error.is_password_valid = true;
      }
    }
  };

  const updateUserName = (name) => {
    let req = {name:name}
     AuthenticationService.updateUserName(req).then((res) => {
      if (res.status == 204) {
        currentUser.name = name;
        error.username = 'UserName Updated Successfully'
        dispatch({
          type: "UPDATE",
          payload: {'user': currentUser},
        })
      }else{
        error.username = 'UserName Update Failed... Try after sometime...'
        setUser({ ...user });
      }
    })
  };

  const profileImageUpload = (event, ele, blob) => {
    try {
      let file = null; 
      if(event){
        let files = event.target.files;
        let FileSize = files[0].size / 1024 / 1024; // in MB
        if (FileSize > 5) {
          event.target.value = null;
          return;
        } else {
          file = files[0]
        }
      }else{
        file = blob
      }

      if (file) {
        AuthenticationService.updateProfileImage(file).then((res) =>{
          if (res.status == 422) {
          
          } else if (res.status == 201) {
            toggleImageEditorModal()
            currentUser.profile_image = res.data.file_upload.url;
            ele.value = null;
            dispatch({
              type: "UPDATE",
              payload: {'user': currentUser},
            })
          }
        })
      }
    } catch (e) {
      console.log(e);
    }
  }

  const callback = (e) => {
    console.log(e);
  }

  const updateNotificationSetting = (field) => {
    settings[field] = !settings[field];
    updateSetting(settings);
  };

  const updateMobile = (e) => {
    settings[e.target.name] = e.target.value;
    if (
      settings.country_code &&
      settings.mobile_no &&
      settings.mobile_no.length == 10
    ) {
      updateSetting(settings);
    }
  };

  const updateSetting = (settings) => {
    CommonService.updateSetting(settings).then((res) => {
      if (res.status == 204) {
        setSettings({...settings})
      }
    });
  };

  const getSettings = () => {
    CommonService.getSetting().then((res) => {
      if (res.status == 200) {
        setSettings(res.data.setting)
      }
    });
  };

  const infoCard = content && content.map((c, i) => (
    <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
      <div className="profile-card profile-card-style ht-250">
        <div className="content">
          <div className="d-flex font-16 bg-black white p-5" style={{alignItems: "center",justifyContent: "left"}}>
            <div>
              {c.data.title || c.data.heading}
            </div>
          </div>
          <div className="d-flex">
            <div className="p-12 w-50p">
              <p dangerouslySetInnerHTML={{ __html: c.data.details }}></p>
            </div>
            <div className="p-12 w-50p">
            <p className="pull-right font-10" style={{ border: "1px solid", padding: "2px" }}>
              {CommonService.formatUpdateDate(c.created_at)}
            </p>
          </div>
          </div>
        </div>
      </div>
    </div>
  ));

  const msgCard = msgs && msgs.map((m, i) => (
    <div className="col-xs-12 col-sm-6 col-md-4">
      <div className="profile-card profile-card-style ht-250">
        <div className="content">
          <div className="d-flex font-16 white p-5" style={{alignItems: "center",justifyContent: "left",backgroundColor:m.data.color}}>
            <div>
              {m.data.title || m.data.heading}
            </div>
          </div>
          <div className="d-flex">
            <div className="p-12 w-50p">
            <p dangerouslySetInnerHTML={{ __html: m.data.details }}></p>
          </div>
          <div className="p-12 w-50p">
          <p className="pull-right font-10" style={{ border: "1px solid", padding: "2px" }}>
          {CommonService.formatUpdateDate(m.created_at)}
        </p>
          </div>
          </div>
        </div>
      </div>
    </div>
  ));

  /* let ds = currentUser.accelerator.data_source;
  const SpanField = ({name, label, styleName}) =>(
    <span className={styleName}>{ds[name] ? ds[name][label] : '-'}</span>
  )*/
  
  return (
    <Fragment>
      <Tabs defaultActiveKey={queryParam.tab || '1'} onChange={callback}>
        <TabPane tab="About Me" key="1">
          <div className="col-xs-12 col-md-6">
            <div className="profile-card profile-card-style ht-150">
              <div className="content">
                <div className="d-flex">
                  <div>
                    <img id="profile_img" src={profileImage} width={50}
                      className="mr-3 bg-highlight rounded-xl"/>
                  </div>
                  <div>
                    <h1 className="mb-0 pt-1">{currentUser.accelerator.data.name}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="profile-card profile-card-style ht-150">
              <div className="content mb-0">
                <h3 className="font-600">Profile Image</h3>

                <div className="form-group">
                  <label>
                    Add/Edit Profile Image
                    <small>(size should be 100 x 100)</small>
                  </label>
                  <img onClick={e=>toggleImageEditorModal(e)}
                    title="Add/Edit Profile Image"
                    style={{ width: "64px" }}
                    src={profileImage}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="profile-card profile-card-style ht-280">
              <div className="content mb-0">
                <h3 className="font-600">Basic Info</h3>

                <div className="form-group">
                  <label>Email</label>
                  <input
                    placeholder="Email"
                    defaultValue={currentUser.email}
                    className="form-control"
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label>Username</label>
                  <input
                    placeholder="User Name"
                    name="name"
                    onChange={(e) => handleChange(e)}
                    defaultValue={currentUser.name}
                    className="form-control"
                  />
                </div>

                {error.username && (
                  <div className="form-group error">
                    <label>{error.username}</label>
                  </div>
                )}

                {user.name && (
                  <button type="button" onClick={(e) => updateUserName(user.name)}
                    className="btn btn-inverse">
                    Update
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="profile-card profile-card-style ht-280">
              <div className="content">
              <div className="d-flex">                  
                <h4>Profile Name:</h4>
                <span className="m-l-5">{currentUser.name}</span>
              </div>
              <div className="d-flex">                  
                <h4>Organization Name:</h4>
                <span className="m-l-5">{currentUser.accelerator.data.name}</span>
              </div>
              <div className="d-flex">                  
                <h4>State:</h4>
                <span className="m-l-5">{currentUser.accelerator.data.state}</span>
              </div>
              <div className="d-flex">                  
                <h4>Telephone:</h4>
                <span className="m-l-5">{currentUser.accelerator.data.tele_contact}</span>
              </div>
              <div className="d-flex">                  
                <h4>Zip:</h4>
                <span className="m-l-5">{currentUser.accelerator.data.code}</span>
              </div>
              <div className="d-flex">                  
                <h4>URl:</h4>
                <a className="m-l-5" href="{currentUser.accelerator.data.url}">{currentUser.accelerator.data.url}</a>
              </div>
            </div>
          </div>
          </div>
        </TabPane>
        
        <TabPane tab="Password Change" key="4">
          <div className="col-xs-12 col-md-12">
            <div className="profile-card profile-card-style">
              <div className="content mb-0">
                <h3 className="font-600">Password Change</h3>

                <div className="form-group">
                  <label>Password</label>
                  <input
                    placeholder="Old Password"
                    name="password"
                    onChange={(e) => handlePasswordChange(e)}
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label>New Password</label>
                  <input
                    placeholder="New Password"
                    name="new_password"
                    onChange={(e) => handlePasswordChange(e)}
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label>Re-New Password</label>
                  <input
                    placeholder="Retype New Password"
                    name="password_confirmation"
                    onChange={(e) => handlePasswordChange(e)}
                    className="form-control"
                  />
                </div>

                {(error.pwsuccess || error.pwerror) && (
                  <div className="form-group error">
                    <label>{error.pwsuccess}</label>
                    <label>{error.pwerror}</label>
                  </div>
                )}

                {error.is_password_valid && (
                  <button type="button" onClick={(e) => updatePassword()}
                    className="btn btn-inverse">
                    Update
                  </button>
                )}

                {error.is_password_valid == false && (
                  <label className="error">Password Mismatch</label>
                )}
              </div>
            </div>
          </div>
        </TabPane>

        <TabPane tab="Secret Questions" key="5">
          <SecretQuestions history={props.history}/>
        </TabPane>
        
        { settings && <TabPane tab="Notifications" key="6">
          <div className="col-xs-12 col-md-12">
            <div className="profile-card profile-card-style">
              <div className="content mb-0">
                <h3 className="font-600">
                  Set your preferences to receive cueTree notifications
                </h3>
                
                <div className="form-group m-t-15">
                  
                  <span className="checkbox-container"
                     onClick={(e) =>updateNotificationSetting("notify_journey_interactions")}>
                    <span data-toggle="tooltip"
                      title="Notification sent soon after specified interactions (e.g., a comment, an invitation).">
                      Journey Interactions
                    </span>
                    <input type="checkbox" checked={settings.notify_journey_interactions}/>
                    <span className="checkbox-checkmark"></span>
                    <span className="help-block">
                      Notification set up for journeys that you are participating in.
                    </span>
                  </span>

                  <span className="checkbox-container"
                    onClick={(e) =>updateNotificationSetting("notify_daily_interactions")}>
                    <span data-toggle="tooltip"
                      title="Notification sent once a day, summarizing action items applicable to a user.">
                      Daily Interactions
                    </span>
                    <input type="checkbox" checked={settings.notify_daily_interactions}/>
                    <span className="checkbox-checkmark"></span>
                    <span className="help-block">
                      A daily compilation of journey notifications and general news items from cueTree.
                    </span>
                  </span>

                  <span
                    className="checkbox-container event-none"
                    onClick={(e) =>
                      updateNotificationSetting("notify_weekly_report")
                    }>
                    <span
                      data-toggle="tooltip"
                      title="Notification sent once a week, summarizing action items applicable to a user.">
                      Weekly Report
                    </span>
                    <input type="checkbox" checked={settings.notify_weekly_report}/>
                    <span className="checkbox-checkmark"></span>
                    <span className="help-block">
                      A weekly compilation of journey notifications and general news items from cueTree.
                    </span>
                  </span>

                  <span className="checkbox-container event-none"
                     onClick={(e) =>updateNotificationSetting("notify_marketing")}>
                    <span
                      data-toggle="tooltip"
                      title="Notification about the cueTree system, sent for administrative purposes.">
                      Administrative
                    </span>
                    <input type="checkbox" checked={settings.notify_marketing}/>
                    <span className="checkbox-checkmark"></span>
                    <span className="help-block">
                      Notifications from cueTree about system status and rules.
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </TabPane> }

        <TabPane tab="Plans" key="7">
          <div className="col-xs-12 col-md-12">
            <div className="profile-card profile-card-style">
            </div>
          </div>
        </TabPane>
        
        <TabPane tab="Deactivate" key="8">
          <div className="col-xs-12 col-md-12">
            <div className="profile-card profile-card-style">
              <div className="content">
                <div className="d-flex" style={{alignItems: "center",justifyContent: "center"}}>
                  <div>
                    <button type="button" className="btn btn-danger bg-lred white">Click to Deactivate Account</button>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="p-12">
                  <p>Note:Once Deactivated, you cannot access this account.You will need to register again to use cueTree Journey Services</p>
                </div>
                </div>
              </div>
            </div>
          </div>
        </TabPane>

        <TabPane tab="Whats New" key="9">
          {infoCard}
        </TabPane>

        <TabPane tab="Messages" key="10">
          {msgCard}
        </TabPane>
      </Tabs>

      {isImageEditorOpen && (
        <GenericModal
          component={ImageEditor}
          image={profileImage}
          saveImage={profileImageUpload}
          title="Image Editor"
          isOpen={isImageEditorOpen}
          toggleModal={toggleImageEditorModal}
          minWidth = "200"
          minHeight = "200"
          maxWidth = "200"
          maxHeight = "200"/>
      )}

      {isHelpMessageOpen && (
        <GenericModal
          component={NotificationPopup}
          header="Note"
          message={HelpMessageList.user_setting}
          onSuccess={toggleHelpMessage}
          isOpen={isHelpMessageOpen}
          toggleModal={toggleHelpMessage}/>
      )}
    </Fragment>
  );
};

export default DemoUserProfile;