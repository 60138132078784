import React, {useContext, Fragment} from 'react';
import EditDataSourceIcon from './EditDataSourceIcon'

const FieldLabel = ({field, labelAttributes, openFieldLabelModal, openDataSourceModal, currentLocale}) => {
   field.locale = field.locale || {}
   let label = field.locale[currentLocale] || field.label
   let icon = field.icon
   let iconColor = field.icon_color
   
   return(
   	<label  {...labelAttributes}>
      {icon && <i className={icon + " font-20 m-r-10"} style={{color:iconColor}}/>}
      <span className={field.rating_type == 'general_rating' ? "font-14":""} dangerouslySetInnerHTML={{ __html: label}}/>
      {openDataSourceModal && <EditDataSourceIcon field={field} open={openDataSourceModal}/>}
      <EditDataSourceIcon field={field} open={openFieldLabelModal} iconClass="fas fa-edit"/>
    </label>	
   )  
}

const OptionLabel = ({option, currentLocale}) => {
   option.locale = option.locale || {}
   let label = option.locale[currentLocale] || option.label
   return label
}

const Description = ({description}) => (
   <span className="rich-text-img r-img-250 fts-13 pull-left p-7" dangerouslySetInnerHTML={{ __html: description }}></span>   
)
export {FieldLabel, OptionLabel, Description};
