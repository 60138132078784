import env from "../../env";
import axios from "axios";

class CalendarService {  
  getSchedules(req) {
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}schedules`, { params: req })
        .then((res) => {
          // handle success
          resolve(res);
        })
    })
  }

  getSchedulesById(id){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}schedules/${id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  createSchedule(req) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.ecn_backend_api}schedules`, req).then((res) => {
          // handle success
          resolve(res);
        })
    })
  }

  updateSchedule(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.ecn_backend_api}schedules/${req.id}`, req).then((res) => {
          // handle success
          resolve(res);
        })
    })
  }

  deleteSchedule(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.ecn_backend_api}schedules/${req.id}`).then((res) => {
          // handle success
          resolve(res);
        })
    })
  }

}

export default new CalendarService();