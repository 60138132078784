import React ,{Fragment,useEffect,useState,useContext} from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import moment from 'moment';
import useModal from "../../hooks/useModal";
import ProjectMenu from "../Modals/ProjectMenu";
import ChecklistsModal from "../Modals/ChecklistsModal";
import CalendarService from "./CalendarService";
import CommentsModal from "../Modals/CommentsModal";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
//import JourneyCategoryService from "../../JourneyCategoryService"
import ConfirmMenu from "../Modals/ConfirmMenu";
import querystringify from "querystringify"
import CheckAccess from "../Roles/CheckAccess";
import CommonService from "../../services/CommonService"
import {MenuItem,CardMenuInfo} from '../Common/MenuItem'
import Pagination from "../Pagination";

let selectedActivity ={}
let data_sources = {}
let requestParams = {}
const CalendarCardView = (props) => {
  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)
  let journey = props.location.state || {}

  const {state: { user: currentUser }, dispatch,} = useContext(AuthContext)

  const [schedules, setSchedulesList] = useState([])
  const [scheduleType, setScheduleTypeList] = useState([])
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  
  const {isOpen: isMenuOpen, toggleModal: toggleMenuModal } = useModal()
  const {isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal,} = useModal()
  const {isOpen: isChecklistOpen, toggleModal:toggleChecklistModal } = useModal()
  const {isOpen: isCommentOpen, toggleModal:toggleCommentModal } = useModal()

  useEffect(() => {
    requestParams = {
      'page':currentpage,
      'per_page':15,
      'journey_type':queryParam.item_type,
      'journey_profile_id':params.journey_profile_id,
      'schedule_item_type':queryParam.item_type,
      'schedule_item_id':queryParam.item_id,
      'add_schedule_type':true,
      'data_source_params': {
        'response_type':'object', 
        'data_source_id': 3393
      }
    }

    CalendarService.getSchedules(requestParams).then((res)=>{
      if(res.status === 200){
        data_sources = res.data.data_source_list
        requestParams.totalItems = res.data.meta.total;
        setScheduleTypeList(res.data.schedule_types)
        setSchedulesList(res.data.schedules)
      }
    })
  }, [currentpage])

  const addChecklist = (e, a) => {
    selectedActivity = a
    selectedActivity.journey_profile_id = params.journey_profile_id
    toggleChecklistModal(e)
  }

  const addComment = (e, a) => {
    selectedActivity = a
    selectedActivity.journey_profile_id = params.journey_profile_id
    toggleCommentModal(e)
  }

  const updateStatus = (e, event) =>{
    e.preventDefault()
    event.data.status = event.data.status == 1 ? 2 : 1
    CalendarService.updateSchedule(event).then((res)=>{
      if(res.status==204){
        setSchedulesList([...schedules])
      }
    })
  }

  const onDelete = (e, a) => {
    selectedActivity = a
    selectedActivity.journey_profile_id = params.journey_profile_id
    toggleDeleteModal(e)
  }

  const deleteActivity = (e) => {
    e.preventDefault();
    let req = {id:selectedActivity.id, journey_category_id:params.journey_category_id}
    CalendarService.deleteSchedule(req).then((res)=>{
      if(res.status === 204){
        setSchedulesList(schedules.filter(i => i.id !== selectedActivity.id))
      }
    })
    toggleDeleteModal(e);
  }

  const Footer = (props) => {
    const {activity} = props
    let incomingMailLink = {
      pathname: `/journey/incoming/emails/schedule/${activity.id}`,
      state: { journey: journey, activity: activity },
    }
    return(
      <div className="card-footer">
        <div className="pull-left lgrey">
          <span className="m-l-25" id={`comment_${props.index}`}
            onClick={(e) => addComment(e, activity)}>
            <i className="far fa-comment font-18 text-muted" data-tip="Chat"></i>
            <span className="badge up bg-lgrey">0</span>
          </span>
          <span className="m-l-25" id={`check_list_${props.index}`}
            onClick={(e) => addChecklist(e, activity)}>
            <i className="far fa-check-circle font-18 text-muted" data-tip="Checklist"></i>
            <span className="badge up bg-lgrey">0</span>
          </span>
          <span className="m-l-25" id={`mail_${props.index}`}>
            <Link to={incomingMailLink}>
              <i className="far fa-envelope font-18 text-muted" data-tip="Mail"></i>
              <span className="badge up bg-lgrey">
                {activity.mail_count || 0}
              </span>
            </Link>
          </span>
        </div>
        <div className="pull-right">
          <Menu activity={activity} />
        </div>
      </div>
    )
  }

  const ActivityCard = (props) => {
    const {activity, ds} = props
    let data = activity.data

    let backgroundColor = data.color
    if(backgroundColor)backgroundColor = data.category && scheduleType[data.category] && scheduleType[data.category].bg_color;
    let cardStyle = {backgroundColor: backgroundColor || 'rgb(102, 102, 102)',}
    
    return (
       <div className="card-container bg-white shadow-small">
         <div className="white card-header p-5" style={cardStyle}>
           <div className="font-16">
             <p className="white ellipsis">{data.title}</p>
           </div>
           <div> {CommonService.formatUpdateDate(activity.created_at)} {activity.created_user.name} </div>
         </div>
 
         <div className="scroll-auto p-5 ht-125">
          <span className="pull-right font-11 lgrey m-l-15 p-7">
            Last by {activity.updated_user.name} {CommonService.formatUpdateDate(activity.updated_at)}
          </span>
           <table className="table card-table">
             <tbody>
              <tr>
                { data.notes ? <td>
                  {data.notes || data.description}
                </td> : <Link className="font-16 lred" to={{pathname:`/calendar/schedule/${params.journey_profile_id}/${queryParam.item_type}/${activity.id}?edit=true`, state:journey}}>Click here to Add Details</Link>}
              </tr>
             </tbody>
           </table>
         </div>
         {activity.data && <Footer activity={activity} />}
       </div>
     )
  }

  const menuItems = ({ item }) => {
    const title = item.data && item.data.title.replace(/\s/g, "_");
    return (
      <Fragment>
        <CardMenuInfo formId={item.form_id} recordId={item.record_id} 
          createdAt={item.created_at} userName={item.created_user.name}/>

        {CheckAccess.hasEditAccess(currentUser, journey, item) &&
          <MenuItem id="edit_icon" 
            link={{pathname:`/calendar/schedule/${params.journey_profile_id}/${queryParam.item_type}/${item.id}?edit=true`, state:journey}}
            icon="far fa-edit"
            action="Edit"
          />
        }
        
        {CheckAccess.hasDeleteAccess(currentUser, journey, item) &&
          <Fragment>
            <MenuItem id="delete_icon" 
              icon="far fa-trash-alt"
              action="Delete"
              onSelect={(e) => {onDelete(e, item);}}
            />
            <MenuItem id="edit_icon" 
              link={{pathname:`/calendar/schedule/${params.journey_profile_id}/${queryParam.item_type}/${item.id}?edit=true`, state:journey}}
              icon="far fa-edit"
              action="Edit"
            />
            <MenuItem id="invite_icon" 
              link={{pathname:`/share/items/${params.journey_profile_id}/schedule/${item.id}`, state:{journeyProfile:journey, activity:item}}}
              icon="fa fa-users"
              action="Invite Users"
            />
            <a href="/" onClick={e=>updateStatus(e, item)}>
              <i className={item.data.status == 1 ? "far fa-check-circle": "far fa-circle"}></i>
              <span className="font-13">Mark status for entry</span>
              <i className="fa fa-angle-right"></i>
            </a>
          </Fragment>
        }

        <a href={`mailto:${title}_schedule_${item.record_id}@cuetree.com`} target="_blank">
          <i className="far fa-envelope font-15" aria-hidden="true"></i>
          <span className="font-13">{`${title}_schedule_${item.record_id}@cuetree.com`}</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const showMenu = (e, a) => {
    selectedActivity = a;
    toggleMenuModal(e);
  }

  const Menu = ({ activity }) => (
    <i data-tip="Menu" onClick={(e) => showMenu(e, activity)} className="fas fa-bars m-r-15"/>
  )

  const journeyActivityCard = schedules && schedules.map((a, i) => {
    return(
      <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
        <ActivityCard activity ={a} ds={data_sources} menu={Menu}/>
        {selectedActivity.id == a.id && (
          <ProjectMenu
            isOpen={isMenuOpen}
            toggleModal={toggleMenuModal}
            item={selectedActivity}
            type="training"
            MenuItems={menuItems}
            menuHeight="200px"/>
        )}
        <ReactTooltip place="left" />
      </div>
    )
  })

  return (
    <Fragment>
        <div>
          {journeyActivityCard}
          <Pagination totalItems={requestParams.totalItems}
            currentpage={currentpage} setCurrentpage={setCurrentpage}
            itemsPerPage={requestParams.per_page}/>
        </div>

        {isChecklistOpen && 
          <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} 
          item={selectedActivity} type="schedule"/>
        }
        {isCommentOpen && 
          <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} 
          item={selectedActivity} type="schedule"/>
        }
        {isDeleteModalOpen && 
          <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} 
          success={deleteActivity}/>
        }
    </Fragment>
  )
  
}
export default CalendarCardView;