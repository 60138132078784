import React, { useState, Fragment, useEffect } from 'react'

let requestParams = null;

const AddUser = ({ userObj, isOpen, toggleModal, addUser, permissions }) => {
  userObj.role_manager_permissions = userObj.role_manager_permissions || {}
  
  const [user, setUser] = useState(userObj);

  const setAccess = (access_type) => {
    let obj = user.role_manager_permissions
    obj[access_type] = !obj[access_type]
    setUser({...user})
  }

  const PermissionList = () => permissions.map((g, k) =>(
    <Fragment key={k}>
      <span className="checkbox-container" onClick={e => setAccess(g.label)}>
        <span>{g.label}</span>
        <input type="checkbox" checked={user.role_manager_permissions[g.label]} readOnly/>
        <span className="checkbox-checkmark"></span>
      </span>
    </Fragment>
  ))

  return(
    <Fragment>
      <form className="ml-container">
        <div className="ml-section">
              
          <label><b>Email</b></label>
          <input onChange={e=>{user.email = e.target.value}} 
            className="ml-input ml-border ml-margin-bottom"
            type="text" placeholder="Email..." name="email"  
            defaultValue={user.email} required/>
          
          <label><b>Permissions</b></label>
          <PermissionList/>

          <button className="ml-button ml-block ml-green ml-section ml-padding" 
            onClick={e=>addUser(e, user)}>
            <b>{user.id?'Update':'Add'}</b>
          </button>

        </div>
      </form>
    </Fragment>
  )

}

export default AddUser;