import React, { Fragment, useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import CommonService from '../../services/CommonService';
import JourneyProfileService from '../Journey/JourneyProfileService'
import useStyle from '../../hooks/useStyle';
import Pagination from '../Pagination'

let requestParams = {}
const JourneyLinks = ({journeyProfile, search}) => {
  useStyle('chat');
  
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [loadingStatus, setLoadingStatus] = useState('Loading...');
  const [links, setLinks] = useState([]);

  useEffect(() => {
    requestParams = {
      'sort_column': 'updated_at',
      'sort_direction': 'desc',
      'user_id':currentUser.id,
      'page':1,
      'per_page':15,
      'journey_profile_id':journeyProfile.id,
      'search': search
    }
    getJourneyHyperLinks();
  }, [search]);

  let getJourneyHyperLinks = (page_no) => {
    requestParams.page = page_no || 1;
    JourneyProfileService.getJourneyHyperLinks(requestParams).then((res) => {
      if (res.status == 200) {
        requestParams.totalItems = res.data.meta.total;
        setLinks(res.data.journey_links);
        if(links.length == 0)setLoadingStatus('No Record Found')
      }
    })
  }

  const linkItem = (list) => list.map((c, k) =>
    <div className="col-xs-12 p-5" key={k}>
      <a target="_blank" href={c.value} className="font-15">
        {CommonService.extractHostName(c.value)}
      </a>
    </div>
  )

  const HyperLinks = () =>(
    <Fragment>
      <div className="chat-main" style={{height:screen.height-300}}>
        {links.length==0 && <div className="no-chat font-15">{loadingStatus} </div>}
        {linkItem(links)}
      </div>
    </Fragment>    
  ) 

  return(
    <div className="m-t-15">
      <HyperLinks/>
    </div>
  )
  
}

export default JourneyLinks;

/*
      <Pagination totalItems={requestParams.totalItems} currentpage={requestParams.page} setCurrentpage={0} itemsPerPage={requestParams.per_page}/>
*/