import React, {Fragment, useState, useRef, useEffect} from 'react';
import CueTooltip from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel, OptionLabel} from '../FieldLabel'
import useModal from "../../../hooks/useModal";
import GenericModal from "../../Modals/GenericModal";
import Cs from '../../../services/CommonService';

const InputSelect = ({formId, screen, field, formData, readOnly, errors, forceUpdate, formFn, isFormWizard, openDataSourceModal, openFieldLabelModal, currentLocale}) => {
  const [showError, setShowError] = useState(false);
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();
  let objList = {};

  const fieldEleRef = useRef();
  const fstListRef = useRef();

  useEffect(() => {
    return () => {
      /*if(formFn['is_'+field.client_id+'_open']){
        bindWindowClickEvent()
      } */     
    }
  }, [])

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only),
    'required':field.required
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }
  
  let validation = {
    required: field.required
  }
  
  let op_kv = field.options_key_value;
  if(op_kv && op_kv.options){
    objList = op_kv.options.reduce((obj, item) => (obj[item.value] = item.label, obj) ,{});
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);

  const handleChange = (value) =>{
  	/*const { target } = event;*/
    const name = field.client_id;
    if(value=='null'){
      formData[name]=null;
    }else{
      formData[name] = FormHelper.isNumeric(value);
    }
    setError(false)
    FormHelper.setFormTitle(field, formData, value, op_kv)
    forceUpdate();
  }

  const setError = (reset_state) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[name]==null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      if(reset_state){
        setShowError(!showError);
        formFn.refreshFormSubmit();  
      }
    }
  }

  const exists = (item) => {
    return formData[field.client_id] == item
  }

  const bindWindowClickEvent = () =>{
    if(screen.xs == true){
      toggleMenuModal()
    }else{
      fstListRef.current.classList.remove("hidden");
      formFn['is_'+field.client_id+'_open'] = true
      //$scope.onOpen({req:$scope.model});
      window.addEventListener('click',eventFunction)  
    }
  }

  const eventFunction = (event) => {
    try{
      if(fieldEleRef.current.contains(event.target)){
        //console.log(field)
      }else{
        fstListRef.current.classList.add("hidden");
        formFn['is_'+field.client_id+'_open'] = false
        window.removeEventListener("click",eventFunction);
        //$scope.onClose({req:$scope.model});
        forceUpdate();
      }
    }catch(e){
    
    }
  }

  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  return (
  	<Fragment>

      <div {...parentAttributes}>
    	  <FieldLabel field={field} labelAttributes={labelAttributes} currentLocale={currentLocale}
          openDataSourceModal={openDataSourceModal}
          openFieldLabelModal={openFieldLabelModal}/>
   	    <div className={`fstElement fstMultipleMode ${readOnly && 'input-readonly'}`} ref={fieldEleRef} id={field.client_id+ '_container'}>
          {(op_kv && op_kv.options) 
            ? <OptionsObjList objList={objList} list={op_kv.options} exists={exists} handleChange={handleChange} currentLocale={currentLocale}
            model={formData[field.client_id]} field={field} bindWindowClickEvent={bindWindowClickEvent} inputAttributes={inputAttributes} 
            fstListRef={fstListRef} formId={formId}/> 
            : <OptionsStringList list={field.options} exists={exists} handleChange={handleChange} model={formData[field.client_id]} field={field}
            bindWindowClickEvent={bindWindowClickEvent} inputAttributes={inputAttributes} 
            fstListRef={fstListRef}/>
          }   
        </div>
    	  <span className="fts-13">{field.description}</span>
        <Error/>
        <CueTooltip id={field.client_id} description={field.tooltip}/>
   	  </div> 

      {isMenuOpen && (
        <GenericModal
            component={SingleSelectPopup}
            handleChange={handleChange} 
            exists={exists}
            optionObjList={op_kv?op_kv.options:null}
            optionStringList={field.options}
            title="Select an Item"
            isOpen={isMenuOpen}
            toggleModal={toggleMenuModal}
            currentLocale={currentLocale}/>
      )}

    </Fragment>
  )
}

const OptionsStringList = ({list, model, exists, field, handleChange, bindWindowClickEvent, inputAttributes, fstListRef}) => {
  
  let [optionList, setOptionList] = useState(list);

  const search = (term) =>{
    let result = list.filter(item => item.toLowerCase().indexOf(term) > -1);
    setOptionList([...result])
  }

  const selectableItems = optionList && optionList.map((o, k) =>{
    return(  
      <span key={k} className={`fstResultItem ${exists(o)?'fstSelected':''} `}
       onClick={e => handleChange(o)} title={o}>
        {o}
      </span>
    )
  })
    
  return(
    <Fragment>
      <div className="fstControls" onClick={e=>bindWindowClickEvent(e)}>
        <input {...inputAttributes} defaultValue={model} value={model||''} onChange={e=>search(e.target.value)} className="fstQueryInput" placeholder="Search here.." />
      </div>
      <div className="fstResults hidden" ref={fstListRef} id={field.client_id+ "_result_set"}>
        {selectableItems}
      </div>
    </Fragment>
  )
}

const OptionsObjList = ({formId, list, objList, model, exists, field, handleChange, bindWindowClickEvent, inputAttributes, currentLocale, fstListRef}) => {

  let [optionList, setOptionList] = useState(list);

  let itemHeight = optionList && optionList[0].image_url?{height: '100px'}:{};

  const search = (term) =>{
    let result = list.filter(item => item.label.toLowerCase().indexOf(term) > -1);
    setOptionList(result)
  }

  const selectableItems = optionList.map((o, k) => { 
    return(
      <Fragment key={k}>  
        <span style={itemHeight} className={`fstResultItem ${(k%2 == 0) ? 'bg-lime':''} ${exists(o.value)?'fstSelected':''} `}
         onClick={e => handleChange(o.value)} title={o.description}>
          <div>
            {(o.locale && o.locale[currentLocale]) || o.label}
            {o.image_url && <img src={Cs.getIconByType(o.image_url)} alt="img" className="w-100" style={{height:"60px"}} alt="img"/>}
          </div>
        </span>
      </Fragment>
    )
  })

  return(
    <Fragment>
      <div className="fstControls" onClick={e=>bindWindowClickEvent(e)}>
        <input {...inputAttributes} defaultValue={model} value={objList[model] || ''} onChange={e=>search(e.target.value)} className="fstQueryInput" placeholder="Search here.." />
      </div>
      <div className="fstResults hidden"  ref={fstListRef} id={field.client_id+ "_result_set"}>
        {selectableItems}
      </div>
    </Fragment>
  )
}

const SingleSelectPopup = ({handleChange, exists, optionObjList, optionStringList, currentLocale}) =>{

  let itemHeight = optionObjList && optionObjList[0].image_url?{height: '100px'}:{};

  const optionStringItems = optionStringList ? optionStringList.map((o, k) =>  
    <span key={k} className={`fstResultItem ${exists(o)?'fstSelected':''} `}
     onClick={e => handleChange(o)} title={o}>
      {o}
    </span>
  ):null;

  const optionObjectItems = optionObjList.map((o, k) =>  
    <span key={k} style={itemHeight} className={`fstResultItem ${(k%2 == 0) ? 'bg-lime':''} ${exists(o.value)?'fstSelected':''} `}
      onClick={e => handleChange(o.value)} title={o.description}>
        <div className="font-16">
          {(o.locale && o.locale[currentLocale]) || o.label}
          {o.image_url && <img src={Cs.getIconByType(o.image_url)} alt="img" className="w-100 pull-right" style={{height:"60px"}} alt="img"/>}
        </div>
    </span>
  )

  if(optionObjList){
    return(
      <Fragment>
        {optionObjectItems}
      </Fragment>
    )
  }
  
  return(
    <Fragment>
      {optionStringItems}
    </Fragment>
  )

}

/*const OptionsObjList = ({list, currentLocale}) => list.map((o, k) =>
  <option key={k} value={o.value}>
    {OptionLabel({option:o, currentLocale:currentLocale})}  
  </option>
);

const OptionsStringList = ({list}) => list.map((o,k) =>
  <option key={k} value={o}>{o}</option>
)*/

export default InputSelect;