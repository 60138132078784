import React, { Fragment, useRef, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import { Link } from "react-router-dom";
import AcceleratorRole from "../../components/Roles/AcceleratorRoles"
import AuthenticationService from "../../services/AuthService";
import env from "../../env"

const SideMenu = (props) => {
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
   //AcceleratorRole.initRole(currentUser);

  let role = {}
  role.is_master = env.admin_emails.includes(currentUser.email)
  
  const sideNavEl = useRef(null);
  const sideNavMenuEl = useRef(null);

  const signout = (e)=>{
    e.preventDefault();
    AuthenticationService.logout().then(()=>{
      dispatch({type: "LOGOUT"});
    })
  }

  useEffect(() => {
    document.addEventListener("keydown", handleEscape)
    document.addEventListener("click", handleClick, true)
    document.addEventListener("touchend", handleClick, true)

    return () => {
      document.removeEventListener("keydown", handleEscape)
      document.removeEventListener("click", handleClick, true)
      document.removeEventListener("touchend", handleClick, true)
    }
  }, [])

  const handleEscape = (e) => {
    if (e.keyCode === 27) props.setOpenSideNav(false)
  }

  const handleClick = (e) => {
    if (sideNavEl.current && (!sideNavEl.current.contains(e.target) || sideNavMenuEl.current.contains(e.target))){
      props.setOpenSideNav(false)
    }
  }

  return (
    <div id="menu-main" ref={sideNavEl}
      className={`menu menu-box-left menu-box-detached round-medium ${
        props.openSideNav ? "menu-active" : ""
      } `}
      style={{ width: 260, opacity: 1, display: "block" }}>
      <div className="menu-icons">
        <a href="#" data-toggle-theme>
          <i className="fa fa-lightbulb color-yellow1-dark" />
        </a>
        <a href="#" data-menu="menu-highlights">
          <i className="fa fa-brush color-green1-dark" />
        </a>
        <a href="#" data-menu="menu-share">
          <i className="fa fa-share-alt color-orange-dark" />
        </a>
        <a href="settings.html">
          <i className="fa fa-cog color-blue2-dark" />
        </a>
        <a href="#" className="close-menu"
          onClick={(e) => {
            e.preventDefault();
            props.setOpenSideNav(!props.openSideNav);
          }}>
          <i className="fa fa-times color-red2-dark" />
        </a>
        <div className="clear" />
      </div>
       
      <div className="menu-items">
      <div className="center-text">
        <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-facebook">
          <i className="fab fa-facebook-f" />
        </a>
        <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-twitter">
          <i className="fab fa-twitter" />
        </a>
        <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-instagram">
          <i className="fab fa-instagram" />
        </a>
        <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-linkedin">
          <i className="fab fa-linkedin-in" />
        </a>
        <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-whatsapp">
          <i className="fab fa-whatsapp" />
        </a>
      </div>
      <div className="menu-header">
        <p className="under-heading center-text font-11 p-7 lh-16">
          Signed in as <span className="highlight">{currentUser.name}</span>
        </p>
        <a href="#" className="shadow-small" />
        <h1 className="center-text font-800 font-28 line-height-medium uppercase">
          CueTree ECN
        </h1>
        <p className="under-heading center-text font-11">Guided Transformation</p>
      </div>
      <div className="sm-content top-20 bottom-10">
        <div className="link-list link-list-1" ref={sideNavMenuEl}>
          <Link id="nav-home" to="/home">
            <i className="fa fa-home color-blue2-dark" />
            <span>Home</span>
            <i className="fa fa-angle-right" />
          </Link>

          <Link id="nav-welcome" to="/journey">
            <i className="fa fa-desktop color-blue2-dark" />
            <span>Journeys</span>
            <i className="fa fa-angle-right" />
          </Link>

          <Link id="nav-contact" to="/contacts">
            <i className="far fa-building color-blue2-dark" />
            <span>Contacts</span>
            <i className="fa fa-angle-right" />
          </Link>

          {role.is_master && 
            <Link id="nav-roles" to="/digital_contents">
              <i className="fas fa-film color-blue2-dark" />
              <span>Digital Content</span>
              <i className="fa fa-angle-right" />
            </Link>
          }

          { role.is_master &&
            <Link id="nav-roles" to="/list/feedback">
              <i className="fas fa-comments color-blue2-dark" />
              <span>Feedback</span>
              <i className="fa fa-angle-right" />
            </Link>
          }
          
          <a href="/" onClick={(e) => signout(e)}>
            <i className="fa fa-times color-red2-dark" />
            <span>Sign Out</span>
          </a>
        </div>
        <div className="center-text bottom-75 m-t-10">
        <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-facebook">
          <i className="fab fa-facebook-f" />
        </a>
        <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-twitter">
          <i className="fab fa-twitter" />
        </a>
        <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-instagram">
          <i className="fab fa-instagram" />
        </a>
        <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-linkedin">
          <i className="fab fa-linkedin-in" />
        </a>
        <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-whatsapp">
          <i className="fab fa-whatsapp" />
        </a>
        <p className="top-10 font-10 opacity-50">
          <i className="fa fa-copyright" aria-hidden="true"></i>
          <span className="copyright-year">{new Date().getFullYear()}</span> IPIPAL Inc. All rights
          reserved
        </p>
      </div>
      </div>
      </div>
    </div>
  )
}

export default SideMenu;