import env from '../../env';
import axios from 'axios';

class AcceleratorService {
  
  create(profile){
    return new Promise((resolve, reject) => {
      axios.post(env.ecn_backend_api+'accelerators',profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(profile) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.ecn_backend_api}accelerators/${profile.id}`, profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  activities(accelerator){
    return new Promise((resolve, reject) => {
      axios.post(env.ecn_backend_api+'accelerators/activities', accelerator).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

}

export default new AcceleratorService();