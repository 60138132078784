import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import moment from '../../../../node_modules/moment/min/moment.min.js'
import Rating from "../../../components/Rating"
import FormTemplateList from "../../../constants/FormTemplateList"

const Company = (props) => {
  console.log('props company',props)
  let org = props.advisor;
  let data = org.data;
  const cardStyle = {
      borderTop: '5px solid #b5adad'
  };
  //const routeEventDetails = () => props.history.push(`/organization/details/${org.id}`)

  const RelationshipTracking = () => {
    if(!org.relationship_trackings){
      return null;
    }else{
      return (
        <Fragment>
          {org.relationship_trackings.map((r, k) => (
            <div className="step-panel" key={k}>{r.data.name}</div>
          ))}
        </Fragment>
      )  
    }
  }

  return (
    <div className="content-boxed shadow-small" style={cardStyle} >
          <div className="content vcard-header">
          <ul className="d-flex">
          <li>
            <img className="rounded-circle bg-highlight" src='' width="50px"/>
          </li>
          <li className="m-l-15" style={{width:'60%'}}>
            <Link to={`/company/activities/${org.profile_id}`}>
            <div className="vcard-title black">{data.name ? data.name : '-'}</div>
              <span className="black">since {moment(org.updated_at).format('MMM DD, YYYY')}</span>
            </Link>
          </li>
          <li style={{right: "5px",position: "absolute",    top: "20px"}}>
          <Link className="black-light-1" to={`/company/activities/${org.profile_id}`}>
<i className='fas fa-share'></i>            </Link>
          </li>
          <li style={{right: "5px",position: "absolute"}}>
          <i className="fas fa-expand pull-right expand" onClick={e=>props.routeActivity(org)}></i>
          </li>
        </ul>
        <div className="divider bottom-20" style={{ backgroundColor: 'grey'}}/>
        </div>

      <div className={`scroll-auto ${props.cardHeightCss}`}>
      <table className="table card-table">
          <tbody>
          <tr>
  
              </tr>
              <tr>
                <td>
                  <span className="pull-right"><strong>Industry</strong>: </span>
                  <strong>Office</strong>
                  <div>{data.street_1}<br/>{data.street_2}<br/>{data.city}<br/> {data.state}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Rating</strong>
                  <Rating noOfRating={5} sumOfRating={20} />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Personal</strong>
                  <a href="tel:+{data.mobile}">{data.principal_contact}</a>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Mobile</strong>
                  <a href="tel:+{data.telephone}">+{data.telephone}</a>
                </td>
              </tr>
            </tbody>
        </table>
       
      </div>
     
          <div className="card-footer">
        <div className="pull-left">
        <props.details com={org} />
        <span className="m-l-15 ">{FormTemplateList.OrganizationRegistration} - {org.record_id}</span>
          <span className="m-l-25" onClick={e=>{props.addNotes(e, org);}}>
            <i className="far fa-comment font-18 text-muted"></i>
            <span className="badge up bg-lgrey">{org.comments_count}</span>
          </span>
          <span className="m-l-25" onClick={e=>{props.addChecklist(e, org);}}>
            <i className="far fa-check-circle font-18 text-muted"></i>
            <span className="badge up bg-lgrey">{org.checklists_count}</span>
          </span>
          <span className="m-l-25">
            <Link to={`/incoming/emails/organization/123`}>
              <i className="fas fa-envelope font-18 text-muted"></i>
              <span className="badge up bg-lgrey">0</span>
            </Link>
          </span>
        </div>
        <div className="pull-right">
          <props.menu com={org} toggleModal={props.toggleModal}/>
        </div>
      </div>      
    </div>
  )

};

export default Company;