import React, { Fragment} from 'react';
import {Link} from "react-router-dom";

const FormContainer = (props) => {
  let {post, formId, showFormUpdateLink} = props	

  return (
    <div className="page-content row">
      <div className={`portlet ${props.isPopupMode && 'p-0 m-t-0'}`} >
        <div className="portlet-heading bg-highlight color-white">
          <h4 className="portlet-title text-uppercase">
            {post.title}
          </h4>
          <div className="clearfix"></div>
        </div>
        <div id="portlet1" className="panel-collapse collapse in">
          <div className="portlet-body p-t-0">
            <div className="row p-7">
              {showFormUpdateLink && 
                <Link className="font-15 pull-right" to={`/data_source_list/suggestion/${formId}`}>
                  <u>Suggest updates to the forms</u>
                </Link>
              }
              <div className="form-details" dangerouslySetInnerHTML={{ __html: post.details }}></div>
              <label className="pull-right clear"><span className="lred font-16">* </span>Required field</label>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormContainer;