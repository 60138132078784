import React from 'react';
import PrivateRoute from "../../routes/PrivateRoute";
import ServiceProviderList from '../../components/ServiceProvider/Activities/List/ServiceProviderList'
import ServiceProviderProfileForm from '../../components/ServiceProvider/Activities/Form/ServiceProviderProfileForm'
import ServiceSkillAddForm from '../../components/ServiceProvider/Activities/Form/ServiceSkillAddForm'
import ServiceProviderSkillsList from '../../components/ServiceProvider/SkillsSearch/ServiceProviderSkillsList'
import AssessmentsList from '../../components/ServiceProvider/Assessments/AssessmentsList'
import AssessmentForm from '../../components/ServiceProvider/Assessments/AssessmentForm'

const ServiceProviderRouter = (props) => (
  <React.Fragment>	
    <PrivateRoute path="/serviceprovider" component={ServiceProviderList} searchString={props.searchString} exact />
	  <PrivateRoute path="/serviceprovider/profile/:id?" component={ServiceProviderProfileForm} exact />
    <PrivateRoute path="/serviceprovider/details/:id?" component={ServiceProviderProfileForm} exact />
    <PrivateRoute path="/serviceprovider/skill/:service_provider_id?/:id?" component={ServiceSkillAddForm} exact />
  	<PrivateRoute path="/serviceprovider/skills/search/:project_service_request_id" component={ServiceProviderSkillsList} style="organization" exact />
  	<PrivateRoute path="/serviceprovider/assessments/:project_id" component={AssessmentsList} style="organization" exact />
  	<PrivateRoute path="/serviceprovider/assessments/form/:service_provider_id/:id?" component={AssessmentForm} exact />
  </React.Fragment>
)

export default ServiceProviderRouter;
