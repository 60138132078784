import React, {useContext, useState} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import AuthenticationService from "../../services/AuthService";
import useStyle from '../../hooks/useStyle';

export const ResetPassword = (props) => {
  useStyle('signup');

  const { state: authState, dispatch } = useContext(AuthContext);
  
  const initialState = {
    new_password: "",
    password_confirmation:"",
    isSubmitting: false,
    errorMessage: null,
    errorSign: false
  };

  const [data, setData] = useState(initialState);

  const handleInputChange = (event) => {
    setData({...data,[event.target.name]: event.target.value,});
    //checkRegex(event.target.value)
  };

  const checkRegex = (val) =>{
    var pattern = new RegExp(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,19}$/);
    const newData = data
    if(pattern.test(val) === true ){
      setData({
        ...newData,
        errorMessage: null,
        errorSign: true
      });
    }else{
      setData({
        ...newData,
        errorMessage: 'password should have one numeric, one special character and should be greater than 6 chars',
        errorSign:false
      });
    }
  }

  const  checkCpassword = () =>{
    if(data.new_password === data.password_confirmation){
        return true
    }else{
        return false
    }
  }

  const handleForgetSubmit = (event) => {
    event.preventDefault();
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });
   
    let req = { user: { new_password: data.new_password,password_confirmation:data.password_confirmation } };
    const userId = props.match.params.userId
    const token = props.match.params.token
    AuthenticationService.ResetPassword(req,userId,token).then((res) => {
      if (res.status == 200) {
        props.history.push('/signin')
      } else {
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: res.message || res.statusText,
        })
      }
    }).catch((error) => {
      setData({ ...data, errorMessage: error.response.data.error });
    })
  }

  return (
    <React.Fragment>
      <div className="header header-fixed header-logo-app header-auto-show">
        <a href="index.html" className="header-subtitle">
          Back to Pages
        </a>
        <a href="#" data-back-button className="header-icon header-icon-1">
          <i className="fas fa-arrow-left" />
        </a>
        <a href="#" data-toggle-theme className="header-icon header-icon-2">
          <i className="fas fa-lightbulb" />
        </a>
        <a href="settings.html" className="header-icon header-icon-3">
          <i className="fas fa-cog" />
        </a>
      </div>
      <div className="page-content">
        <div className="page-title-bg bg-20" style={{ height: "150px" }}>
          {/* image */}
        </div>
        <div
          className="page-title-bg dark-mode-tint"
          style={{ height: "150px" }}
        >
          {/* contrast for dark mode */}
        </div>
        <div
          className="page-title-bg opacity-90 bg-highlight"
          style={{ height: "150px" }}
        >
          {/* background color */}
        </div>
        <div className="page-title-small color-white bottom-30">
          <h1>
            Reset{" "}
          </h1>
        </div>
        <form className="signform">
        <div className="content-boxed shadow-small">
          <div className="content">
            <h4>Reset your Password</h4>
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-lock font-11" />
              <span>Password</span>
              <em>(required)</em>
              <input type="password" placeholder="Choose a Password"
                name="new_password" onChange={handleInputChange} required/>
            </div>
            {data.errorMessage && (
              <span className="form-error">
                {data.errorMessage}
              </span>
            )}
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-lock font-11" />
              <span>Password</span>
              <em>(required)</em>
              <input type="password" placeholder="Confirm your Password"
                name="password_confirmation" onChange={handleInputChange}
                required/>
            </div>
           
            <div className="clear" />
            <button  disabled={checkCpassword() ? false : true}
              onClick={handleForgetSubmit}
              className="button button-full button-m shadow-large button-round-small bg-highlight top-30 bottom-0">
              Reset Password
            </button>
          </div>
        </div>
        <a href="signin"
          className="button button-m button-full round-small button-margins bg-green1-dark bottom-30 shadow-samll">
          Want to Sign In? Click Here
        </a>
        </form>
        
        {/* Footer */}
        <div className="signinbg">
        <div className="signincon">
        <img src={'./../../../images/cueTree_logo.png'} style={{display: 'block',marginLeft: 'auto',marginRight: 'auto',width: '50%'}} />
        </div>
        <div className="signcopy hidden-xs">
        <i className="far fa-copyright"></i> {new Date().getFullYear()} IPIPAL INC. All Rights Reserved.</div>
        </div>
        <div className="footer" data-footer-load="menu-footer.html" />
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;