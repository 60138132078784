import { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import importScript from "../../hooks/importScript";
import useStyle from "../../hooks/useStyle";
//import Shepherd from 'Shepherd';

let tour = {};
let page_tours = {};

let buttons = [
  {
    action() {
      return this.back();
    },
    text: "Back",
  },
  {
    action() {
      window.Shepherd.activeTour.currentStep.destroy();
      return this.next();
    },
    text: "Next",
  },
  {
    action() {
      window.Shepherd.activeTour.complete();
    },
    text: "Don't Show",
  }
];

page_tours["header"] = [
  {
    title: " Edit/Update your account settings",
    text: `Click this icon to update your user name, password,
      profile photograph, and other cueTree settings.`,
    attachTo: {
      element: "#edit_user_profile",
      on: "bottom",
    },
    buttons: buttons,
    id: "edit_user_profile",
    showOn: function () {
      return document.querySelector(`#edit_user_profile`) ? true : false;
    },
  },
  {
    title: "Access the cueTree Menu",
    text: `Click this icon to access the main menu for your account`,
    attachTo: {
      element: "#side_menu_btn",
      on: "bottom",
    },
    buttons: buttons,
    id: "side_menu_btn",
    showOn: function () {
      return document.querySelector(`#side_menu_btn`) ? true : false;
    },
  },
  {
    title: "View account notifications",
    text: `Click this icon to view cueTree notifications for your account `,
    attachTo: {
      element: "#notification_btn",
      on: "bottom",
    },
    buttons: buttons,
    id: "notification_btn",
    showOn: function () {
      return document.querySelector(`#notification_btn`) ? true : false;
    },
  },
  {
    title: "Search this page",
    text: `Click this icon to search for content on this page`,
    attachTo: {
      element: "#search_btn",
      on: "bottom",
    },
    buttons: buttons,
    id: "search_btn",
    showOn: function () {
      return document.querySelector(`#search_btn`) ? true : false;
    },
  },
];
page_tours["/journey"] = [
  {
    title: "Add New Journey",
    text: `Click the + button \ to launch and customize a new \`Journey\` .`,
    attachTo: {
      element: "#add_new_journey",
      on: "bottom",
    },
    buttons: buttons,
    id: "add_new_journey",
    showOn: function () {
      return document.querySelector(`#add_new_journey`) ? true : false;
    },
  }
];

page_tours["/baseline"] = page_tours["/track"] = page_tours["/governance"] = [
  {
    title: "Go to Journey Dashboard Page",
    text: `Click this icon to go to the Journey Dashboard page and access other Journey features.`,
    attachTo: {
      element: "#home_icon",
      on: "bottom",
    },
    buttons: buttons,
    id: "home_icon",
    showOn: function () {
      return document.querySelector(`#home_icon`) ? true : false;
    }
  },
  {
    title: "Add New Baseline",
    text: `Click this icon to add a new baseline.`,
    attachTo: {
      element: "#add_baseline",
      on: "bottom",
    },
    buttons: buttons,
    id: "add_baseline",
    showOn: function () {
      return document.querySelector(`#add_baseline`) ? true : false;
    },
  },  
  {
    title: "View emails sent directly to this Tile",
    text: `Click this icon to view emails relating to the topic of this Tile, sent by collaborators.`,
    attachTo: {
      element: "#mail_0",
      on: "bottom",
    },
    buttons: buttons,
    id: "email",
    showOn: function () {
      return document.querySelector(`#mail_0`) ? true : false;
    },
  },
  {
    title: "Create/Edit/Read Checklist Items for this Tile",
    text: ` Click this icon to collaborate with others on this Tile.
          Create, edit and read checklist items relating to this Tile.`,
    attachTo: {
      element: "#check_list_0",
      on: "bottom",
    },
    buttons: buttons,
    id: "check_list",
    showOn: function () {
      return document.querySelector(`#check_list_0`) ? true : false;
    },
  },
  {
    title: "Send/Read Messages on this Tile",
    text: ` Click this icon to read and write messages to other users who are collaborating with you on this Tile.`,
    attachTo: {
      element: "#comment_0",
      on: "bottom",
    },
    buttons: buttons,
    id: "chat",
    showOn: function () {
      return document.querySelector(`#comment_0`) ? true : false;
    },
  }
]

page_tours["/governance"][1] = {
    title: "Add New Governance",
    text: `Click this icon to add a new governance.`,
    attachTo: {
      element: "#add_new_management",
      on: "bottom",
    },
    buttons: buttons,
    id: "add_new_management",
    showOn: function () {
      return document.querySelector(`#add_new_management`) ? true : false;
    },
}

page_tours["/goals"] = [
  {
    title: "Go to Journey Dashboard Page",
    text: `Click this icon to go to the Journey Dashboard page and access other Journey features.`,
    attachTo: {
      element: "#home_icon",
      on: "bottom",
    },
    buttons: buttons,
    id: "home_icon",
    showOn: function () {
      return document.querySelector(`#home_icon`) ? true : false;
    }
  },
  {
    title: "Add New Goal",
    text: `Click this icon to add a new goal.`,
    attachTo: {
      element: "#add_goal",
      on: "bottom",
    },
    buttons: buttons,
    id: "add_goal",
    showOn: function () {
      return document.querySelector(`#add_goal`) ? true : false;
    },
  },
  {
    title: "View emails sent directly to this Tile",
    text: `Click this icon to view emails relating to the topic of this Tile, sent by collaborators.`,
    attachTo: {
      element: "#mail_0",
      on: "bottom",
    },
    buttons: buttons,
    id: "email",
    showOn: function () {
      return document.querySelector(`#mail_0`) ? true : false;
    },
  },
  {
    title: "Create/Edit/Read Checklist Items for this Tile",
    text: ` Click this icon to collaborate with others on this Tile.
          Create, edit and read checklist items relating to this Tile.`,
    attachTo: {
      element: "#check_list_0",
      on: "bottom",
    },
    buttons: buttons,
    id: "check_list",
    showOn: function () {
      return document.querySelector(`#check_list_0`) ? true : false;
    },
  },
  {
    title: "Send/Read Messages on this Tile",
    text: ` Click this icon to read and write messages to other users who are collaborating with you on this Tile.`,
    attachTo: {
      element: "#comment_0",
      on: "bottom",
    },
    buttons: buttons,
    id: "chat",
    showOn: function () {
      return document.querySelector(`#comment_0`) ? true : false;
    },
  }
]

page_tours["/calendar"] = [
  {
    title: "Add New Schedule",
    text: `Click this icon to add a new schedule.`,
    attachTo: {
      element: "#add_schedule_type",
      on: "bottom",
    },
    buttons: buttons,
    id: "add_schedule_type",
    showOn: function () {
      return document.querySelector(`#add_schedule_type`) ? true : false;
    },
  },
  {
    title: "Toggle view",
    text: `Click this icon to view schedules in card view.`,
    attachTo: {
      element: "#toggle_icon",
      on: "bottom",
    },
    buttons: buttons,
    id: "toggle_icon",
    showOn: function () {
      return document.querySelector(`#toggle_icon`) ? true : false;
    },
  },
  {
    title: "Go to Journey Dashboard Page",
    text: `Click this icon to go to the Journey Dashboard page and access other Journey features.`,
    attachTo: {
      element: "#home_icon",
      on: "bottom",
    },
    buttons: buttons,
    id: "home_icon",
    showOn: function () {
      return document.querySelector(`#home_icon`) ? true : false;
    },
  },
];

page_tours["/invite/users"] = [
  {
    title: "Invite New User",
    text: `Click this icon to add new user and assign role.`,
    attachTo: {
      element: "#add_user",
      on: "bottom",
    },
    buttons: buttons,
    id: "add_user",
    showOn: function () {
      return document.querySelector(`#add_user`) ? true : false;
    },
  },
  {
    title: "Toggle view",
    text: `Click this icon to view in table view.`,
    attachTo: {
      element: "#toggle_view",
      on: "bottom",
    },
    buttons: buttons,
    id: "toggle_role",
    showOn: function () {
      return document.querySelector(`#toggle_role`) ? true : false;
    },
  },
  {
    title: "Go to Journey Dashboard Page",
    text: `Click this icon to go to the Journey Dashboard page and access other Journey features.`,
    attachTo: {
      element: "#back_home",
      on: "bottom",
    },
    buttons: buttons,
    id: "back_home",
    showOn: function () {
      return document.querySelector(`#back_home`) ? true : false;
    },
  },
];

page_tours["/roles"] = [
  {
    title: "Add New Role",
    text: `Click this icon to add new role and assign permissions.`,
    attachTo: {
      element: "#add_role",
      on: "bottom",
    },
    buttons: buttons,
    id: "add_role",
    showOn: function () {
      return document.querySelector(`#add_role`) ? true : false;
    },
  },
  {
    title: "Toggle view",
    text: `Click this icon to view in table view.`,
    attachTo: {
      element: "#toggle_role",
      on: "bottom",
    },
    buttons: buttons,
    id: "toggle_role",
    showOn: function () {
      return document.querySelector(`#toggle_role`) ? true : false;
    },
  },
  {
    title: "Go to Journey Dashboard Page",
    text: `Click this icon to go to the Journey Dashboard page and access other Journey features.`,
    attachTo: {
      element: "#back_home",
      on: "bottom",
    },
    buttons: buttons,
    id: "back_home",
    showOn: function () {
      return document.querySelector(`#back_home`) ? true : false;
    },
  },
];

const usePageTour = (restart_tour) => {
  useStyle("shepherd");

  const match = useRouteMatch();
  const pageId = match.path.split("/:")[0];
  
  let isTourComplete = localStorage.getItem(`ecn_${pageId}_page_tour`);

  if(isTourComplete || !restart_tour)return;

  importScript("/scripts/shepherd.js", () => {
    if (window.Shepherd) {
      Object.assign(
        tour,
        new window.Shepherd.Tour({
          defaultStepOptions: {
            cancelIcon: {
              enabled: true,
            },
            useModalOverlay: true,
            allowSkipSteps: true,
            showCancelLink: true
          },
          useModalOverlay: true,
          tourName: pageId,
        })
      )

      window.Shepherd.on("complete", () => {
        localStorage.setItem(`${pageId}_page_tour`, true);
      })

      tour.addSteps(page_tours[pageId]);
    
    }
  })

  return tour;
};

export default usePageTour;

/*scrollTo: { behavior: "smooth", block: "center" },*/
