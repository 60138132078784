import React, { Fragment, useState, useEffect, useContext} from "react";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import env from "../../env";
import { Link } from "react-router-dom";
//import Socket from "../../components/Common/Socket";
//import usePageTour from "../../components/Common/usePageTour";
import NotificationList from "../Notifications/NotificationList"

const WAIT_INTERVAL = 10;
let timer = null;
let searchKeyword = null;
let profileImage = "images/avatar-2.jpg";

const Header = (props) => {
  
  const { state: { screen, user: currentUser }, dispatch, } = useContext(AuthContext);
  const history = useHistory();
  const [searchButton, setSearchButton] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [instantNotification, setInstantNotification] = useState(null);

  //let tour = usePageTour();

  /*const [scroll, setScroll] = useState(0)      
  const [isTop, setIsTop] = useState(false) */

  const setNotification = (data) =>{
    setInstantNotification(data);
    setShowNotification(true);
  }

  //Socket(currentUser, setNotification);

  if (currentUser.profile_image) {
    profileImage = env.file_url + currentUser.profile_image;
  }

  useEffect(() => {
    timer = null;
    searchKeyword = null;

    let unlisten = history.listen((location, action) => {
      props.setSearchString(null);
      setSearchButton(false);
    })
    
    /*setTimeout(function () {
      tour && tour.start();
    }, 1000)*/
    
    return () => {
      unlisten();
    }
  }, [])

  const searchClick = () => {
    if (searchButton === true) {
      setSearchButton(false);
    } else {
      setSearchButton(true);
    }
  }

  const handleChange = (event) => {
    clearTimeout(timer);
    searchKeyword = event.target.value;
    timer = setTimeout(triggerChange, WAIT_INTERVAL);
  }

  const clearChange = (event) => {
    clearTimeout(timer);
    searchKeyword = null;
    //props.setSearchString(searchKeyword);
    timer = setTimeout(triggerChange, WAIT_INTERVAL);
  }

  const triggerChange = () => {
    props.setSearchString(searchKeyword);
  }

  const openFeedback = (e) => {
    props.history.push(`/feedback?url=${props.location.pathname}`);
  }

  const toggleNotification = (e) =>{
    e.preventDefault()
    setInstantNotification(null)
    setShowNotification(!showNotification)
  }

  /*useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY < 100
      if (scrollCheck !== scroll) {
        setIsTop(scrollCheck)
        setScroll(scrollCheck)
      }
    })
  },[])*/

  return (
    <Fragment>
      <div style={{ marginBottom: "62px" }}>
        <div className="header header-fixed header-logo-app bg-highlight demo-shadow ">
          <a href="/home" className="header-title color-white">
            Home
          </a>
          <a href="#" onClick={(e) => {e.preventDefault();history.goBack();}}
            className="header-icon header-icon-1 color-white">
            <i id="back-btn" className="fa fa-arrow-left" />
          </a>
          <div id="edit_user_profile" className="header-icon header-icon-2">
            <Link data-tip="Settings" to="/user/profile">
              <img className="user-logo" src={profileImage} />
            </Link>
          </div>
          <a id="side_menu_btn" data-tip="Menu"
            onClick={(e) => {props.setOpenSideNav(!props.openSideNav);}}
            className="header-icon header-icon-3 color-white">
            <i className="fa fa-bars" />
          </a>
          <a id="notification_btn" onClick={e=>toggleNotification(e)} 
            data-tip="Notifications" className="header-icon header-icon-4 color-white">
            <i className="fas far fa-bell" />
            <span className="badge up bg-lred">
              {currentUser.notification_count > 99 ? 
                "99 +" : (currentUser.notification_count)
              }
            </span>
          </a>
          <a id="search_btn" data-tip="Search" onClick={(e) => searchClick()} className="header-icon header-icon-5 color-white">
            <i className="fas far fa-search" />
          </a>
        </div>
        <ReactTooltip place="left" />
      </div>

      {searchButton && (
        <div className="search-box search-color bg-white shadow-tiny top-5 bottom-10">
          <i className="fa fa-search"></i>
          <input type="text" placeholder="Search here.." value={searchKeyword == null ? "": searchKeyword} autoFocus onChange={(e) => handleChange(e)}/>
          <i className="fa fa-times-circle" onClick={(e) => clearChange(e)}></i>
        </div>
      )}

      {showNotification && <NotificationList showAsPopup="true" toggleNotification={toggleNotification} 
      instantNotification={instantNotification} history={props.history}/>}
    </Fragment>
  )
};

export default Header;