import React, { Fragment, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import useStyle from '../../../../hooks/useStyle';

const FilterModal = ({ filterData, filterDataSources, isOpen, toggleModal, onSearch }) => {
  useStyle('add_role_dialog');
  useStyle('filter_dialog');

  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext);
  const [filter, setFilter] = useState(filterData);

  /* To check an item exist in checkbox options. */
  const toggle = (item, filter_key) => {
    let list = filter[filter_key] = filter[filter_key] || [];
     let idx = list.indexOf(item);
     if (idx > -1) {
      filter[filter_key].splice(idx, 1);
     }else {
      filter[filter_key].push(item);
     }
     setFilter({...filter});
  }

  const exists = (filter_key, item) => {
    return filter[filter_key] && filter[filter_key].indexOf(item) > -1
  }

  const OptionsObjList = (list, option_key) => list.map((o, k) =>
    <span className="checkbox-container" key={k} onClick={e => toggle(o.value, option_key)}>
      <span>{o.label}</span>
      <input type="checkbox" checked={exists(option_key, o.value)} readOnly/>
      <span className="checkbox-checkmark"></span>
    </span>
  )

  const Filters = Object.keys(filterDataSources).map(k => 
    <Fragment key={k}>
      <div className="filter-label">{filterDataSources[k].label}</div>
      {OptionsObjList(filterDataSources[k].options, k)}
    </Fragment>
  )

  return(ReactDOM.createPortal(
    <React.Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">
          
          <div className="ml-center bg-black white p-5">
            <h4>Filters</h4>
            <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal"
            onClick={e=>toggleModal(e)}>×
            </span>
          </div>
          
          <div className="p-5 scroll-x" style={{height:screen.height-300}}>
            {Filters}
          </div>
            
          <div onClick={e=>onSearch()} className="btn filter-btn bg-highlight">DONE</div>

        </div>
      </div>
    </React.Fragment>, document.body)
  )

}

export default FilterModal;