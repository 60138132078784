import React from "react";
import Rating from "../../../components/Rating"
import CommonService from '../../../services/CommonService';
import FormTemplateList from "../../../constants/FormTemplateList"

export const Assessment = (props) => {
  console.log('assessment props',props)
  let favourite = props.serviceProvider;
  let serviceProvider = favourite.service_provider;
  if(serviceProvider==null)return null;
  let data = serviceProvider && serviceProvider.data;
  let assessment = favourite.risk_assessment || {};

  const cardStyle = {
      borderTop: '5px solid '+ (serviceProvider.data_source.status && serviceProvider.data_source.status.bg_color)
  };

  return (
    <div className="card-container bg-white shadow-small" style={cardStyle}>
       <div className="content vcard-header">
          <ul className="d-flex">
          <li>
            <img className="rounded-circle bg-highlight" src='' width="50px"/>
          </li>
          <li className="m-l-15" style={{width:'50%'}}>
              <div className="vcard-title">{data.name ? data.name : '-'}</div>
              <span>since {CommonService.formatUpdateDate(serviceProvider.updated_at)}</span>
          </li>
          <li style={{textAlign:'right'}}>
         
          </li>
        </ul>
        <div className="divider bottom-20" style={{ backgroundColor: 'grey'}}/>
        </div>

      <div className="content scroll-auto ht-227">

      <table className="table card-table">
          <tbody>
              <tr>
                <td>
                  <span className="pull-left"><strong>Name</strong>: {serviceProvider.data_source.industry && serviceProvider.data_source.industry.label}</span>
                </td>
              </tr>
              <tr>
                <td>
                <strong>Office</strong>
                <span> {data.street_1}<br/>{data.street_2}<br/>{data.city}<br/> {data.state}</span>
             </td>
              </tr> 
              <tr>
                <td>
                <strong>Rating</strong>
                <Rating noOfRating={5} sumOfRating={20} />
             </td>
              </tr>
        
              <tr>
                <td>
                <strong>Link</strong>
          <a href={data.url}>{data.url}</a>            
    

            </td>
              </tr>
              <tr>
                <td>
                <strong>Mobile</strong>
          <a href="tel:+{data.mobile}">+{data.mobile}</a>          
         

            </td>
              </tr>
              <tr>
                <td>
                       
          <strong>Project Risk Score</strong>
          <a>{assessment.data && assessment.data.total_assessment_score}</a>

            </td>
              </tr>
            </tbody>
        </table>

      </div>
      <div className="card-footer">
        <div className="pull-left">
        <span className="m-l-15 ">{FormTemplateList.ServiceProviderAssessment} - {serviceProvider.record_id} </span>
          <span className="m-l-25" >
            <i className="far fa-comment font-18 text-muted"></i>
            <span className="badge up bg-lgrey">0</span>
          </span>
          <span className="m-l-25" >
            <i className="far fa-check-circle font-18 text-muted"></i>
            <span className="badge up bg-lgrey">0</span>
          </span>
        </div>
        <div className="pull-right">
        <props.menu sp={serviceProvider} favourite={favourite} toggleModal={props.toggleModal}/>

        </div>
      </div> 
    </div>
  );
};

export default Assessment;
