import React, {useState, Fragment} from "react";
import {Link} from "react-router-dom";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import Feedback from '../../../components/Feedback/List/Feedback';
import FeedbackService from "../../../components/Feedback/FeedbackService";
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu";
import ProjectMenu from "../../Modals/ProjectMenu"
import useModal from "../../../hooks/useModal";
import MenuModal from "../../Modals/Menu";
import useStyle from '../../../hooks/useStyle';
import SideMenuBar from "../../Common/SideMenuBar";

let requestParams = {}
let selectedFeedback = {};
let data_source = {};

const FeedbackList = (props) => {
  useStyle('card');
  useStyle('card_table');

  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext);
  const [feedbackList, setFeedbackList] = useState([]);
  const [currentpage,setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const { isOpen, toggleModal:toggleDeleteModal } = useModal();
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();

  React.useEffect(() => {
    requestParams = {
      'is_active': true,
      'page': currentpage,
      'paginate': 1,
      'per_page': 9,
      'sort_column': 'updated_at',
      'sort_direction': 'desc',
      'search': null,
    }

    getFeedbackList();
  }, [currentUser.id, currentpage]);

  let getFeedbackList = () => { 
    FeedbackService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       requestParams.totalItems = res.data.meta.total;
       setFeedbackList(res.data.feedbacks)
       data_source = res.data.data_source_list;
      }
    })
  }

  const deleteFeedback = (e) => {
    e.preventDefault();
    FeedbackService.delete(selectedFeedback).then((res) => {
      if (res.status == 200) {
        setFeedbackList(feedbackList.filter(i => i.id !== selectedFeedback.id))
      }
    })
    toggleDeleteModal(e);
  }

  const updateStatus = (feeback, callback) => {
    feeback.data.status = feeback.data.status==1?0:1;
    FeedbackService.update(feeback).then((res) => {
      if (res.status == 200) {
        callback(feeback.data);
      }
    })
  }

  const addDelete = (e, f) => {
    selectedFeedback = f;
    toggleDeleteModal(e);
  }

  const addMenu = (e, f) => {
    selectedFeedback = f;
    toggleMenuModal(e);
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <Link to="/journey" onClick={(e) => {addDelete(e, item)}}>
          <i className="far fa-trash-alt"></i>
          <span className="font-13">Delete</span>
          <i className="fa fa-angle-right"></i>
        </Link>
      </Fragment>
    )
  }

  const Menu = ({ f }) => (
    <i onClick={e=>{addMenu(e, f)}} className="fas fa-bars m-r-15" />     
  )
     
  const feedbackListCard =  feedbackList && feedbackList.map((f, i) => (
    <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
      <Feedback feedback={f} menu={Menu} toggleModal={toggleDeleteModal} updateStatus={updateStatus}/>
      {selectedFeedback.id == f.id && 
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedFeedback} addDelete={addDelete} MenuItems={menuItems} menuHeight="150px"/>
      }
    </div>
  ))
  
  return (
    <Fragment>
      <div className="content top-10">
        <h5 className="float-left font-500">Feedbacks</h5>
        <div className="clear"></div>
      </div>
      <div className="page-content row">
        { feedbackListCard}
        <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
      </div>
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteFeedback}/>
      <SideMenuBar helpId="2"/>
    </Fragment>
  )

}

export default FeedbackList;