import React, { Fragment, useEffect, useState } from "react";
import useStyle from "../../../hooks/useStyle";
import { Link } from 'react-router-dom';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import DigitalContentService from "../../../components/DigitalContent/DigitalContentService";
import HamBar from "./../../../components/Authentication/HamBar";
import env from "../../../env";
import LottieLight from "../../Common/LottieLight"


let requestParams = {};

const CheckoutMarketing = () => {
  useStyle("spmarketing");
  const { state: currentUser, dispatch,isAuthenticated: isAuthenticated } = React.useContext(AuthContext);
  const [digitalContentList, setDigitalContentList] = useState([]);

  window.addEventListener("scroll", () => {
    console.log(document.documentElement.scrollTop);
  });

  useEffect(() => {
    requestParams = {
      content_type: "marketing",
      page: 1,
      paginate: 1,
      per_page: 10,
      sort_column: "updated_at",
      sort_direction: "asc",
      totalItems: 0,
      search: null,
    };

    getMarketingContent();
  }, [currentUser.id]);

  let getMarketingContent = () => {
    DigitalContentService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        requestParams.totalItems = res.data.meta.total;
        setDigitalContentList(res.data.digital_contents);
      }
    });
  };

  let filteredYourJourney = [];

	for (var i = 0; i < digitalContentList.length; i++) {
		if(digitalContentList[i].data.web_page == "Check out your journey"){
			filteredYourJourney.push(digitalContentList[i]);
		}
	}

  const ImageSvgRender = ({ ma }) => {
    if(ma.data.lottie){
      return(
        <LottieLight id={ma.id} {...ma.data.lottie}/>
      )
    }else{
      return(
      <img src={env.file_url+ma.data.illustration} />)
    }
  }

  const SocialShare =({ma}) =>{
    return(
      <div className="center-text">
          <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-facebook">
          <i className="fa fa-share-alt" />
        </a>
        <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-facebook">
          <i className="fab fa-facebook-f" />
        </a>
        <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-twitter">
          <i className="fab fa-twitter" />
        </a>
        <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-instagram">
          <i className="fab fa-instagram" />
        </a>
        <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-linkedin">
          <i className="fab fa-linkedin-in" />
        </a>
        <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-whatsapp">
          <i className="fab fa-whatsapp" />
        </a>
      </div>
    )
  }

  const SectionWithButton = ({ ma }) => {
    let color = {color:ma.data.text_color}
    return(
    <div className="row m-b-30 p-50 section" style={{backgroundColor:ma.data.color}}>
      <div className="intro-img col-xs-12 col-sm-6">
       <ImageSvgRender ma={ma} />
      </div>
      <div className="intro-content col-xs-12 col-sm-6 m-t-10 text-left ">
        <h1 style={color}> {ma.data.heading}</h1>

        <p className="lg-p mt-2" dangerouslySetInnerHTML={{ __html: ma.data.notes }}  style={color}>

        </p>
		  <button className="btn mt-2" style={{color:ma.data.button_text_color,backgroundColor:ma.data.button_color}}> {ma.data.button_text}</button>
      </div>
      <div className="social ht-40">
        <SocialShare ma={ma} />
      </div>
    </div>
  )};

  const SectionOnly = ({ ma }) => {
    let color = {color:ma.data.text_color}
    return(
    <div className="row m-b-30 p-50 section" style={{backgroundColor:ma.data.color}}>
      <div className="col-xs-12 col-sm-6 ">
      <ImageSvgRender ma={ma} />
      </div>
      <div className="col-xs-12 col-sm-6 m-t-10 text-left">
        <h2 style={color}>{ma.data.heading}</h2>
        <p className="lg-p mt-2" dangerouslySetInnerHTML={{ __html: ma.data.notes }}  style={color}>

        </p>
      </div>
      <div className="social ht-40">
        <SocialShare ma={ma} />
      </div>
    </div>
  )};

  const ThreeSection = ({ ma }) => {
    let color = {color:ma.data.text_color}

    return(
    <div className="m-b-30 p-50 section" style={{backgroundColor:ma.data.color}}>
      <div className="row">
        <div className="col-xs-12 col-sm-6 intro-img">
        <ImageSvgRender ma={ma} />
        </div>
        <div className="col-xs-12 col-sm-6 m-t-10 text-left intro-content">
          <h1 style={color}> {ma.data.heading}</h1>
          <p className="lg-p mt-2" dangerouslySetInnerHTML={{ __html: ma.data.notes }}  style={color}>
          </p>
        </div>
      </div>
      <div className="row m-b-30">
        <div className="col-xs-12 col-sm-4">
          <div className="testimonial-item-text">
		  <img className="img-responsive m-t-15 ht-200"
		src={env.file_url+ma.data.image_box_1}
		/>
		  <h3 style={color}> {ma.data.heading_1}</h3>
            <p style={color}>
              {ma.data.box_1_note}
            </p>
          </div>
		  <div className="testimonial-item-reviewer"></div>
        </div>

        <div className="col-xs-12 col-sm-4">
          <div className="testimonial-item-text">
		  <img className="img-responsive m-t-15 ht-200"
		src={env.file_url+ma.data.image_box_2}
		/>
		  <h3 style={color}> {ma.data.heading_2}</h3>
            <p style={color}>
			{ma.data.box_2_note}

            </p>
          </div>
		  <div className="testimonial-item-reviewer"></div>
        </div>

        <div className="col-xs-12 col-sm-4">
          <div className="testimonial-item-text">
		  <img className="img-responsive m-t-15 ht-200"
		src={env.file_url+ma.data.image_box_3}
		/>
		  <h3 style={color}> {ma.data.heading_3}</h3>
            <p style={color}>
			{ma.data.box_3_note}
            </p>
          </div>
		  <div className="testimonial-item-reviewer"></div>
        </div>
      </div>
      <div className="social ht-40">
        <SocialShare ma={ma} />
      </div>
    </div>
  )};

  const filteredYourJourneyCard = filteredYourJourney.map((ma, i) => {
    if (ma.data.template == 1) {
      return <SectionWithButton ma={ma} />;
    } else if (ma.data.template == 2) {
      return <SectionOnly ma={ma} />;
    } else {
      return <ThreeSection ma={ma} />;
    }
  });


  return (
    <Fragment>
	<div className="bg"></div>
      <header>
        
		<HamBar />
      </header>

      <main>
        <section className="intro">
		  <div className="w-100p">
          <h1> Check out your journey</h1>
		  	{filteredYourJourneyCard}
		  </div>
		</section>
        <section className="cta">
          <div className="cta-item">
            <div className="cta-text">
              <h2>Get early access today </h2>
              <p>
                It only takes a minute to sign up and our free starter tier is
                extremely generous. If you have any questions, our support team
                would be happy to help you.
              </p>
            </div>
            <form className="cta-form mt-2" action="">
              <input
                className="input-block"
                type="text"
                placeholder="example@email.com"
              />
              <button className="btn-block">Get Started For Free</button>
            </form>
          </div>
        </section>
      </main>
      <footer>
        <section className="contact-info">
          <div className="contact-info-logo">
            
            <img
              className="logo"
              src={"./../../../images/cueTree_logo.png"}
              alt=""
            />
          </div>

          <div className="contact-info-social-links">
            <a className="icon-link" href="#">
              <div className="contact-info-social-link-item flex-center">
                <i className="fab fa-facebook-f"></i>
              </div>
            </a>
            <a className="icon-link" href="#">
              <div className="contact-info-social-link-item flex-center">
                <i className="fab fa-twitter"></i>
              </div>
            </a>
            <a className="icon-link" href="#">
              <div className="contact-info-social-link-item flex-center">
                <i className="fab fa-instagram"></i>
              </div>
            </a>
          </div>
        </section>
      </footer>
    </Fragment>
  );
};

export default CheckoutMarketing;
