import React, { useContext, useState, Fragment} from "react";
import ReactTooltip from "react-tooltip";
import { Link, useHistory } from "react-router-dom";
import useModal from "../../../../hooks/useModal";
import ConfirmMenu from "../../../Modals/ConfirmMenu";
import JourneyProfileService from "../../JourneyProfileService";
//import ActivityService from "../Activities/CollegeActivityService";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import useNotification from "../../../../hooks/useNotification";
import MultiSelectModal from "../../../Modals/MultiSelectModal";

const PortalHeader = (props) => {
  const { journeyProfile, share } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const { showNotification } = useNotification();

  const deleteProject = (e) => {
    e.preventDefault();
    setLoading(true);
    JourneyProfileService.delete(journeyProfile).then((res) => {
      if (res.status == 204) {
        setLoading(false);
        history.push("/journey");
      }
    })
    toggleDeleteModal(e);
  }

  const toggleChatlistStatus = (e) => {
    e.preventDefault();
    setLoading(true);
    JourneyProfileService.update(journeyProfile).then((res) => {
      if (res.status == 204) {
        setLoading(false);
      }
    })
  }

  /*const getActiveUserReport = (e, journey_profile_id) => {
    e.preventDefault();
    setLoading(true);
    let req = {
      journey_profile_id: journey_profile_id,
      type: "active_users",
    };
    ActivityService.reports(req).then((res) => {
      if (res.status == 200) {
        setLoading(false);
        let activeUsers = res.data.report;
      }
    });
  };*/

  const cloneJourney = (e, journey_profile_id) => {
    e.preventDefault();
    setLoading(true);
    JourneyProfileService.cloneJourney(journey_profile_id).then((res) => {
      if (res.status == 200) {
        showNotification("Success", "Journey Cloned Successfully");
        setLoading(false);
        history.push("/journey");
      }
    })
  }

  const updateStatus = (e, journey_profile, field) => {
    e.preventDefault();
    setLoading(true);
    let req = {
      id: journey_profile.id,
    }
    req[field] = !journey_profile[field];
    JourneyProfileService.update(req).then((res) => {
      if (res.status == 204) {
        journey_profile[field] = !journey_profile[field];
        setLoading(false);
      }
    })
  }

  const updateDemoJourneyUserType = (list) => {
    let req = {
      id: journeyProfile.id,
      demo_journey_user_type: list,
    }
    JourneyProfileService.update(req).then((res) => {
      if (res.status == 204) {
        setLoading(false);
      }
    })
  }

  const Menu = () => (
    <span id="portal_menu" className="portlet-dropdown">
      <a data-tip="Journey Menu" className="p-dropbtn">
        <i className="fas fa-bars m-r-15"></i>
      </a>
      <div className="p-dropdown-content">
        {journeyProfile.is_admin_user && <Fragment>
            <Link to={`/journey_profile/form/${journeyProfile.id}?form_id=${journeyProfile.form_id}`}>
              <i className="far fa-edit"/> Edit
            </Link>
            <a href="#" onClick={(e) => toggleDeleteModal(e, journeyProfile)}>
              <i className="far fa-trash-alt"/> Delete
            </a>
            <Link to={{
                pathname: `/invite/users/${journeyProfile.id}`,
                state: {'journeyProfile':journeyProfile, 'share':share},
                search:"?permission_list_id=3475"
              }}>
              <i className="fa fa-user"></i> Invite User
            </Link>
            <Link to={{
                pathname: `/path/${journeyProfile.id}`,
                state: journeyProfile,
                search:"?menu=3475"
              }}>
              <i className="fa fa-path"/> Path
            </Link>
          </Fragment>
        }  
        {journeyProfile.is_admin_user && (
          <a href="#" onClick={(e) =>updateStatus(e, journeyProfile, "is_journey_completed")}>
            <i className={`far ${journeyProfile.is_journey_completed? "fa-check-circle": "fa-circle"}`}></i> 
            Completed
          </a>
        )}
        <a href="#" onClick={(e) => props.app.openHelp(e)}>
          <i className="fas fa-question-circle"></i> Help
        </a>
      </div>
    </span>
  )

  return (
    <div className="portlet-heading clearfix">
      {loading && <div className="spinner" />}
      <div className="col-xs-12 col-sm-10">
        <h3 className="portlet-title">
          <span>
            {journeyProfile.data.org_name || journeyProfile.data.name_org}
            <i className="m-l-5 fas fa-angle-right" />
          </span>
          <span>
            {journeyProfile.data.name}
          </span>
        </h3>
      </div>

      <div className="col-xs-12 col-sm-2">
        <div className="portlet-widgets">
          <span className="divider" />
          <Menu />
          <Link data-tip="Back to Journey List" to={{pathname: '/journey'}}>
            <i className="fas fa-home"></i>
          </Link>
        </div>
      </div>
      
      <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteProject}/>
    </div>
  )
}

export default PortalHeader;