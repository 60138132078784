import React, { useState ,useRef, Fragment} from 'react';
import ReactDOM from 'react-dom';

const AddRole = ({ role, isOpen, toggleModal, addNewRole, componentList, notificationsTypes }) => {

  if(role){
    role.permissions = role.permissions || {}
    role.access_list = role.access_list || []
    role.notification_comment = role.notification_comment || []
    role.notification_step_activity = role.notification_step_activity || []
  }

  const [newRole, setNewRole] = useState(role);
  const [loading, setLoading] = useState(false);
  const userRoleEle = useRef(null);

  /* 
   * To check an item exist in checkbox options.
   */
  const setRole = (item, key) => {
    let list = newRole[key];
    let idx = list.indexOf(item);
    if(idx > -1) {
      list = list.filter(i => i !== item)
      newRole[key] = list;
    }else {
      list.push(item)
    }
    setNewRole({...newRole})
  }

  const exists = (item, key) => {
    let list = newRole[key];
    return list.indexOf(item) > -1
  }

  const toggle = (access_type) => {
    setNewRole({...newRole, 'access_type': access_type});
  }

  /* 
   * To check an item exist in checkbox options.
   */
  const setAccess = (access_type) => {
    let obj = newRole.permissions;
    if (obj[access_type]) {
      delete obj[access_type]
    }else {
      obj[access_type] = true; 
    }
    setNewRole({...newRole})
  }

  /*primary_admin: user who creates journey and invite users */

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">
      
        <div className="ml-center bg-black white p-5">
          <h4>{role.id?'Update':'Add'} Role</h4>
          <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal"
          onClick={e=>toggleModal(e)}>×
          </span>
        </div>
      
        {loading ? <div className="spinner"></div> : <form className="ml-container">
          <div className="ml-section">
            <label><b>Role</b></label>
            <input onChange={e=>{newRole.role = e.target.value}} className="ml-input ml-border ml-margin-bottom"
              type="text" placeholder="Role name" name="role_name"
              required defaultValue={newRole.role}/>

            <label className="req-field"><b>Permissions</b></label>
            <span className="checkbox-container" onClick={e => toggle('full_access')}>
              <span>Full Access (Admin level permission)</span>
              <input type="checkbox" checked={newRole.access_type == 'full_access'} readOnly/>
              <span className="checkbox-checkmark"></span>
            </span>

            {newRole.access_type == 'full_access' && <Fragment>
              <span className="checkbox-container m-l-15" onClick={e => setAccess('is_primary_admin_removeable')}>
                <span>Invited Admins can remove primary Admin</span>
                <input type="checkbox" checked={newRole.permissions.is_primary_admin_removeable} readOnly/>
                <span className="checkbox-checkmark"></span>
              </span>

              <span className="checkbox-container m-l-15" onClick={e => setAccess('is_primary_admin_removeable')}>
                <span>Invited Admins cannot remove primary Admin </span>
                <input type="checkbox" checked={!newRole.permissions.is_primary_admin_removeable} readOnly/>
                <span className="checkbox-checkmark"></span>
              </span>
              </Fragment>
            }
              
            <span className="checkbox-container" onClick={e => toggle('partial_access')}>
              <span>Specific Journey Sections (select from list)</span>
              <input type="checkbox" checked={newRole.access_type == 'partial_access'} readOnly/>
              <span className="checkbox-checkmark"></span>
            </span>

            <span className="checkbox-container" onClick={e => toggle(null)}>
              <span>Only when invited to specific journey tiles</span>
              <input type="checkbox" checked={!newRole.access_type} readOnly/>
              <span className="checkbox-checkmark"></span>
            </span>

            {newRole.access_type == 'partial_access' &&
              <Fragment>
                <label><b>Select component to access</b></label>
                {componentList.map((r, k) => (
                  <span key={k} className="checkbox-container" onClick={e => setRole(r.sid, 'access_list')}>
                    <span>{r.label}</span>
                    <input type="checkbox" checked={exists(r.sid, 'access_list')} readOnly/>
                    <span className="checkbox-checkmark"></span>
                  </span>
                ))}
              </Fragment>
            }

            <label><b>Notification for Step Activity</b></label>
            {Object.keys(notificationsTypes).map((p)=>
              <Fragment key={p}>
                <span className="checkbox-container" onClick={e => setRole(p, 'notification_step_activity')}>
                  <span>{notificationsTypes[p]}</span>
                  <input type="checkbox" checked={exists(p, 'notification_step_activity')} readOnly/>
                  <span className="checkbox-checkmark"></span>
                </span>
              </Fragment>
            )}

            <label><b>Notification for Comments</b></label>
            {Object.keys(notificationsTypes).map((p)=>
              <Fragment key={p}>
                <span className="checkbox-container" onClick={e => setRole(p, 'notification_comment')}>
                  <span>{notificationsTypes[p]}</span>
                  <input type="checkbox" checked={exists(p, 'notification_comment')} readOnly/>
                  <span className="checkbox-checkmark"></span>
                </span>
              </Fragment>
            )}

            <label><b>Description</b></label>
            <textarea onChange={e=>{newRole.description = e.target.value}} maxLength="150"
              className="ml-input ml-border ml-margin-bottom" placeholder="Description" 
              defaultValue={newRole.description} required>
            </textarea>

            <div className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=>{addNewRole(newRole);setLoading(true)}}>
              <b>{role.id?'Update':'Create'}</b>
            </div>
            
          </div>
        </form>}
      
      </div>
    </div>
  </Fragment>, document.body))
}

export default AddRole;