import env from '../../env';
import axios from 'axios';

class AdvisorService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}advisors`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}advisors/${req.id}`, {params: req}).then((res) => {
        console.log('getid ad',res)
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(profile){
    return new Promise((resolve, reject) => {
      axios.post(env.ecn_backend_api+'advisors',profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(profile) {
    return new Promise((resolve, reject) => {
      console.log('adviosr update profile',profile)
      axios.put(`${env.ecn_backend_api}advisors/${profile.id}`, profile).then((res) => {
        // handle success
        console.log('adviosr update',res)
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.ecn_backend_api}advisors/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

}

export default new AdvisorService();