import React, {useRef, useEffect, useState} from 'react'
import SignaturePad from '../Common/SignaturePad'
import ScribblePadService from '../../services/ScribblePadService'
import SortDirection from '../Common/SortDirection'
import useSort from '../../hooks/useSorting';
import Cs from '../../services/CommonService'

let requestParams = null

const ScribblePadList = ({journeyProfileId}) => {
  //importScript("/node_modules/signature_pad/dist/signature_pad.min.js");
  const inputEl = useRef(null);

  const [list, setList] = useState([])
  const [selectedItem, setSelectedItem] = useState()

  useEffect(() => {
    requestParams = {
      page:1,
      per_page:100,
      journey_type:'infant_journey',
      journey_profile_id:journeyProfileId,
    }

    getAll()
  }, []);

  const getAll = () =>{
    ScribblePadService.getAll(requestParams).then((res)=>{
      setList(res.data.scribble_pads)
    })
  }

  const {sort} = useSort(requestParams, getAll)

  const save = (data) => {
    let req = {
      id:selectedItem?.id,
      journey_type:'infant_journey',
      journey_profile_id:journeyProfileId,
      data: data
    }
    if(selectedItem?.id){
      ScribblePadService.update(req).then((res)=>{

      }) 
    }else{
      ScribblePadService.create(req).then((res)=>{
        setList([res.data.scribble_pad, ...list])
      })  
    }
  }

  /*const setCanvas = () => {
    signaturePad = new SignaturePad(inputEl.current, {
      backgroundColor: 'rgb(255, 255, 255)',
    })
  }*/

  /*const clear = (e) =>{
    e.stopPropagation();
    ScribblePadService.delete(selectedItem.id)
    signaturePad.clear();
  }*/

  /*const dataURLtoFile = (dataurl, filename) => {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
  }*/

  const onDelete = () => {

  }

  const setEdit = (item) =>{
    setSelectedItem(item)
  }

  const ScribblePadTable = () =>(
    <div className="table-responsive">
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th onClick={e=>sort('record_id')} className='p-2 bold-400 text-center miw-95'>
              Id 
            </th>
            <th onClick={e=>sort('updated_at')} className='p-2 bold-400 text-center miw-95'>
              Date
            </th>
            <th onClick={e=>sort('created_by')} className='p-2 bold-400 text-center miw-95'>
              User
            </th>
            <th onClick={e=>sort('record_id')} className='p-2 bold-400 text-center miw-95'>
              Options
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </table>
    </div>
  )

  const tableData = list.map((s, k) => {
    return(
      <tr key={k}>
        <td className="text-center" data-title="Id">{s.record_id}</td>

        <td className="text-center">
          {Cs.formatUpdateDate(s.updated_at, 'MMMM DD YYYY')}
        </td>
       
        <td className="text-center">
          {s.author.name}
        </td>

        <td className="text-center">
          <span className="fas fa-edit m-r-15 font-18 text-muted" onClick={e=>setEdit(s)}/> 
        </td>
      </tr>
    )
  })

  return (
    <>
      <SignaturePad canvasData={selectedItem?.data} onSave={save}/>
      <ScribblePadTable/>
    </>
  )
}

export default ScribblePadList;