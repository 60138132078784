import React, { Fragment, useState, useEffect, useContext} from "react";
import ReactDOM from "react-dom";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import useStyle from "../../../hooks/useStyle";
import CommonService from "../../../services/CommonService";
import useEscape from "../../../hooks/useEscape";
import FormTemplateId from "../../../constants/FormTemplateList";
import env from "../../../env"

let requestParams = {};

const JourneyTypeMenu = ({ params, isOpen, toggleModal }) => {
  useStyle("add_role_dialog");
  useStyle("filter_dialog");
  useEscape(toggleModal);
  
  const history = useHistory();
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [journeyCategory, setJourneyCategory] = useState();
  
  let profile = currentUser.accelerator;
  let data_source = profile.data_source || {};
  let is_admin = env.admin_emails.includes(currentUser.email)

  useEffect(() => {
    requestParams = {data_source_id: [3418]}
    getJourneyCategory();
  }, [])

  let getJourneyCategory = () => {
    CommonService.getDataSource(requestParams).then((res) => {
      setJourneyCategory(res.journey_phases.options);
    })
  }

  const routeJourney = (e, journey_type) => {
    e.preventDefault();
    if(journey_type.wizard_id){
      history.push(
        `/journey_profile/form?show_form_wizard=1&form_id=${journey_type.parent_form}&form_wizard_id=${journey_type.wizard_id}&journey_type=${journey_type.label}&journey_type_id=${journey_type.value}`
      )
    }else{
      history.push(
        `/journey_profile/form?form_id=${journey_type.parent_form}&journey_type=${journey_type.label}&journey_type_id=${journey_type.value}`
      )
    }
  }

  const MenuItem = ({ k }) => (
    <div className="ver-icons" onClick={(e) => routeJourney(e, k)}>
      <a href="/">
        <i className={`${k.icon || "fas fa-plus"}`} style={{ color: k.icon_color }}/>
      </a>
      <div className="popup-menu-title">{k.label}</div>
      <i className="fas fa-chevron-right lgrey pull-right" />
    </div>
  )

  const checkAdmin = (k) => {
    if(k.read_only != "yes"){
      return true
    }else if(is_admin && k.master_user == 'yes'){
      return true
    }
  }
    
  const MenuList = () => journeyCategory.map((k) => {
    return(
      <Fragment key={k.value}>
        {checkAdmin(k) && <MenuItem k={k} />}
      </Fragment>
    )
  })

  return ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">
          <div className="ml-center bg-black white p-5">
            <h5>TYPE OF JOURNEY</h5>
            <span className="ml-button ml-xlarge ml-hover-red ml-display-topright"
              title="Close Modal" onClick={(e) => toggleModal(e)}>
              ×
            </span>
          </div>

          {journeyCategory && (
            <div className="popup-menu-grid">
              {MenuList()}
              <small className="font-9 lgrey lh-16 p-5">3418</small>  
            </div>
          )}
        </div>
      </div>
    </Fragment>, document.body
  )
}

export default JourneyTypeMenu;