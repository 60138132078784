import React, { Fragment, useEffect, useState } from 'react';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import DynamicForm from '../../../../components/FormBuilder/DynamicForm';
import FormContainer from '../../../../components/FormBuilder/FormContainer'
import FormTemplateId from '../../../../constants/FormTemplateList'
import OrganizationService from "../../OrganizationService";
import CommonService from '../../../../services/CommonService';
import env from '../../../../env';

let formMode = null; 
let formId = null; 
let organization = {}; 
let data = {}; 
let formFn = {};  

function OrganizationProfileForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;
  let currentUser = authState.user;

  formFn.country_pagenate = {page:1, per_page:10}
  formFn.country_path = 'name';

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    OrganizationService.get(params).then((res) => {
      if(res.status==200){
        organization = res.data.organization;
        data = organization.data || {};
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    })
  }
  
  useEffect(() => { 
    organization = {}; 
    data = {}; 
    formFn = {}; 
    formMode = 'create-form-submissions';
    if(props.match.path){
      if(props.match.path.includes('details')){
        get()
        formMode = 'view-form-submissions';
      }else if(params.id){
        get()
        formMode = 'edit-form-submissions';
      }else if(!isFormRendered){
        setFormRendered(true);
      }
    }
   
  }, [params.id]);

  const create = (organization) => {
    organization.accelerator_id = currentUser.accelerator.id;
    OrganizationService.create(organization).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    })
  }

  const update = (organization) => {
    organization.accelerator_id = currentUser.accelerator.id;
    OrganizationService.update(organization).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    })
  }

  formFn.get_country = (search, on_success, on_failure) => {
    formFn.country_pagenate.name= search
    let req = {
      method: "get",
      url: `${env.ecn_backend_api}countries`,
      params:formFn.country_pagenate
    }
    CommonService.useAxios(req).then((res) => {
      if(res.status == 200){
        if(res.data.meta.total && formFn.country_pagenate.per_page){
          formFn.country_pagenate.pageSize = Math.ceil(res.data.meta.total/formFn.country_pagenate.per_page);
        }
        on_success(res.data.countries);
      }
    })
  }

  formFn.set_country = (item) =>{
    data.country = item.name
    data.country_fk_id = item.id
  }

  const onSubmitPageRedirect = () => {
    props.history.push("/contacts")
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.OrganizationRegistration} form={organization} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
      </FormContainer> 
    )  
  }else{
    return null
  }
  
}

export default OrganizationProfileForm;