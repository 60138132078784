import React, {useState, Fragment, useContext, useEffect} from "react";
import querystringify from "querystringify";
import {Link} from "react-router-dom";
import ReactDOM from 'react-dom';
import Pagination from '../Pagination'
import useStyle from '../../hooks/useStyle';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import Cs from "../../services/CommonService";
import ReactTooltip from "react-tooltip";

let requestParams = {};
const DataSourceListModal = (props) => {

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [dataSourceList, setDataSourceList] = useState([])
  const [dataSource, setDataSource] = useState({})
  const [showDataSourceForm, setShowDataSourceForm] = useState(false)
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])

  useEffect(() => {
    requestParams = {
      'page': currentpage, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'asc',
    }

    getDataSourceList();
  }, [currentpage]);

  let getDataSourceList = () => { 
    let req = {
      'form_template_id':props.form_template_id,
      'data_source_id':props.data_source_id, 
      'journey_profile_id':props.journey_profile_id,
      'per_page' : 30
    }
    Cs.getDataSourceList(req).then((res)=>{
     if(res.status == 200){
       setDataSourceList(res.data.data_source_lists)
       setDataSource(res.data.data_source)
      }
    })
  }

  let updateStatus = (o) =>{
    let req = {
      filterable_type: 'data_source_list',
      created_by: currentUser.id,
      journey_profile_id: props.journey_profile_id,
      filterable_id: o.id,
      data_source_id: o.data_source_id,
      form_template_id: props.form_template_id 
    }

    Cs.updateDataSourceListStatus(req).then((res)=>{
      if(res.status == 200){
        if(o.inactive_status){
          o.inactive_status=null;
        }else{
          o.inactive_status={};
        }
        setDataSourceList([...dataSourceList])
      }
    }) 
  }

  const addDataSource = (data) =>{
    let req = {
      'form_template_id':props.form_template_id,
      'data_source_id': dataSource.id, 
      'item_id': props.journey_profile_id, 
      'item_type': 'journey_profile',
      'privacy_type':'private',
      'data':data
    }
    Cs.createDataSource(req).then((res)=>{
      if(res.status == 201){
        setDataSourceList([res.data.data_source_list , ...dataSourceList])
        setShowDataSourceForm(false)
      }
    })
  }

  const DataSourceForm = () => {
    const [newDataSource, setNewDataSource] = useState({})

    const handleInput = (e) =>{
      setNewDataSource({...newDataSource, [e.target.name]:e.target.value})
    }

    return(
      <form>
        <div className="ml-section">
          <label><b>Role</b></label>
          <input onChange={e=>handleInput(e)} 
            className="ml-input ml-border ml-margin-bottom"
            type="text" placeholder="Label" name="label"
            required defaultValue={newDataSource.label}/>

          <div className="ml-button ml-block ml-section ml-padding bg-lgrey white font-16" onClick={e=>addDataSource(newDataSource)}>
            <b>{newDataSource.id?'Update':'Create'}</b>
          </div>  
        </div>
      </form>  
    )
  }

  const DataSourceList = dataSourceList.map((o, k) => 
    <Fragment key={k}>
      <span className="checkbox-container" key={k} onClick={e => updateStatus(o)}>
        <span data-tip={o.data.hover_text}>{o.data.label}</span>
        <input type="checkbox" checked={o.inactive_status==null} readOnly/>
        <span className="checkbox-checkmark"></span>
      </span>
      <ReactTooltip place="right" />
    </Fragment>
  )

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom bg-white">
          <div className="bg-lgreen white p-5">
            <div className="m-l-5 font-16 bold-600">
              {dataSource.title}
              <br/>
              <small>Data Choices</small>
            </div>
            <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal"
              onClick={e=>props.toggleModal(e)}>×
            </span>
          </div>

          <div className="ml-container m-t-10">
            {!showDataSourceForm && <Fragment>
              <span className="text-right font-15 cursor" onClick={e=>setShowDataSourceForm(true)}>
                <u>Add custom values</u>
              </span>
              {DataSourceList}
              <Pagination pagemeta={pagemeta} currentpage={currentpage} 
                setCurrentpage={setCurrentpage}/>
              </Fragment>
            }
            {showDataSourceForm && <DataSourceForm/>}
          </div>
        </div>
      </div>
    </Fragment>, document.body)
  )
};

export default DataSourceListModal;