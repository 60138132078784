import React, { Fragment, useState, useEffect, useContext} from 'react';
import ReactDOM from 'react-dom';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import CommonService from '../../services/CommonService';
import JourneyProfileService from '../Journey/JourneyProfileService'
import useStyle from '../../hooks/useStyle';
import Pagination from '../Pagination'

let requestParams = {};
const JourneyFileUploads = ({journeyProfile, search}) => {
  useStyle('chat');
  
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [filesList, setFilesList] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState('Loading...');

  useEffect(() => {
    requestParams = {
      'sort_column': 'updated_at',
      'sort_direction': 'desc',
      'user_id':currentUser.id,
      'page':1,
      'per_page':15,
      'journey_profile_id':journeyProfile.id,
      'search':search
    }
    getFileUploads();
  }, [search]);
    
  //currentpage
  let getFileUploads = (page_no) => {
    requestParams.page = page_no || 1;
    JourneyProfileService.getFileUploads(requestParams).then((res) => {
      if (res.status == 200) {
        requestParams.totalItems = res.data.meta.total;
        setFilesList(res.data.file_uploads);
        if(filesList.length == 0)setLoadingStatus('No Record Found')
      }
    })
  }

  const fileItem = filesList.map((c, k) =>
    <div className="col-xs-12 col-sm-4 p-5 wk-center" key={k}>
      <a target="_blank" href={CommonService.getFileUrl(c.file)}>
        <img src={CommonService.getIconByType(c.file)} width="75px" height="75px"/>
      </a>
      {c.filename}
    </div>
  )

  const FileUplaodBox = () =>(
    <Fragment>
      <div className="chat-main" id="chat-main" style={{height:screen.height-300}}>
        {filesList.length==0 && <div className="no-chat font-15">{loadingStatus} </div>}
        {fileItem}
      </div>
      </Fragment>    
  ) 

  return(
    <div className="m-t-15">
      <FileUplaodBox/>
    </div>
  )

}

export default JourneyFileUploads;

/*
  <Pagination totalItems={requestParams.totalItems} currentpage={requestParams.page} setCurrentpage={getFileUploads} itemsPerPage={requestParams.per_page}/>
  */