import env from '../../env';
import axios from 'axios';
let url = env.ecn_backend_api+'planners';

class PlannerService {

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`, {params:req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params:req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(profile){
    return new Promise((resolve, reject) => {
      axios.post(url, profile).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(profile){
    return new Promise((resolve, reject) => {
      axios.put(url+'/'+profile.id, profile).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(id){
    return new Promise((resolve, reject) => {
      axios.delete(url+'/'+id).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new PlannerService();