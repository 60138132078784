import React, {useState, useEffect, useReducer} from "react";
import { Route, Switch, withRouter } from 'react-router-dom';
import Home from "./components/Home";
import Login from "./components/Authentication/Login";
import Signup from "./components/Authentication/Signup"
import ForgetPassword from "./components/Authentication/ForgetPassword"
import ResetPassword from './components/Authentication/ResetPassword'
import Privacy from "./components/Terms/Privacy";
import AboutUs from "./components/Marketing/List/AboutUs";
import CheckoutMarketing from "./components/Marketing/List/CheckoutMarketing";
import PackyourMarketing from "./components/Marketing/List/PackyourMarketing";
import TeamMarketing from "./components/Marketing/List/TeamMarketing";
import ContactUs from "./components/Marketing/List/ContactUs";
//import AuthProvider from "./contexts/Authentication/AuthStateProvider";
import PublicRoute from './routes/PublicRoute';
import AxiosConfig from './AxiosConfig';
import ContractVerificationForm from './components/Contracts/ContractVerificationForm'
import MarketingList from "./components/Marketing/List/MarketingList";
import MarketingForm from "./components/Marketing/Form/MarketingForm"
import { AuthContext, initialState } from "./contexts/Authentication/AuthStateProvider";
import Reducer from './contexts/Authentication/AuthReducer';
import AuthenticationService from "./services/AuthService";
import PreLoadSpinner from "./components/PreLoadSpinner";
import RegistrationConfirmation from "./components/Authentication/RegistrationConfirmation"

function App() {

  const [state, dispatch] = useReducer(Reducer, initialState);
  const [getCurrentUser, setGetCurrentUser] = useState(false);   

  const getCurrentUserSession = () =>{
    state.getUserSession = true;
    AuthenticationService.get().then((res)=>{
      if(res.status==200){
        state.user = res.data.user;
        state.isAuthenticated = true;
        setGetCurrentUser(true);
      }else{
        setGetCurrentUser(true);
      }
    })
  }

  if(!state.getUserSession)getCurrentUserSession(); 
  
  if(!getCurrentUser){
    return(
      <PreLoadSpinner/>
    )
  }

  return (
    <AuthContext.Provider value={{state, dispatch}}>
      <Switch>
        <PublicRoute path="/" component={Login} exact />
        <PublicRoute path="/signin" component={Login} exact/>
        <PublicRoute path="/signup/:invitation_id?" component={Signup} exact/>
        <PublicRoute path="/registration/confirmation/:verification_id" component={RegistrationConfirmation} exact/>
        <PublicRoute path={"/about_us/:type"} component={Privacy} exact />
        <PublicRoute path="/forget_password" component={ForgetPassword} exact/>
        <PublicRoute path="/password_reset/:userId/:token"  component={ResetPassword} exact/>
        <PublicRoute path="/public/contract/verification/:project_id/:id/:email?" component={ContractVerificationForm} exact />
        <PublicRoute path="/aboutUs" component={AboutUs} exact />
		    <PublicRoute path="/features" component={CheckoutMarketing} exact />
		    <PublicRoute path="/essentials" component={PackyourMarketing} exact />
		    <PublicRoute path="/team" component={TeamMarketing} exact />
		    <PublicRoute path="/contactUs" component={ContactUs} exact />
        <Route path="/marketing" component={MarketingList} exact />
        <Route path="/marketing/create/:id?" component={MarketingForm} exact/>
        <Route path = "/" component={Home}/>
        <Route component={Page404} />
      </Switch>
    </AuthContext.Provider>
  )
}

const Page404 = ({ location }) => (
  <div>
    <h2>No match found for <code>{location.pathname}</code></h2>
  </div>
)

export default withRouter(App);