import React from 'react';
import PrivateRoute from "../../routes/PrivateRoute";
import ForumList from '../../components/Forums/List/ForumList'
import ForumDetails from '../../components/Forums/List/ForumDetails'
import ForumNewForm from '../../components/Forums/Form/ForumNewForm'
import ForumReplyForm from '../../components/Forums/Form/ForumReplyForm'

const ForumRouter =  (props) => (
  <React.Fragment>  
    <PrivateRoute path="/forums" exact >
      <ForumList />
    </PrivateRoute>
    <PrivateRoute path="/forums/create/:id?" component={ForumNewForm} exact/>
    <PrivateRoute path="/forums/reply/:id?" component={ForumReplyForm} exact/>
    <PrivateRoute path="/forums/details/:id?" component={ForumDetails} exact/>
  </React.Fragment>
)

export default ForumRouter;