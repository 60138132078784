import React from "react";
import moment from '../../../../node_modules/moment/min/moment.min.js'
import {Link} from "react-router-dom";
import CommonService from '../../../services/CommonService';

const Help = (props) => {
  
  let helpList = props.helpList;
  let data = helpList.data;
  
  const cardStyle = {
    borderTop: '3px solid #00a2ff',
    height: '60px',
    backgroundColor:'#c0bfc5'
  }

  if(data !== null){
    return (
      <div className="card-container shadow-small" >
        <div className="card-header p-5 bg-white" style={cardStyle}>
          <div className="font-16 black-light-1">
            {data.title}
          </div>
          <div className="lred">
            {CommonService.formatUpdateDate(helpList.created_at)}
          </div> 
        </div>
        <div className="br-grey"></div>
        <div className="ht-200 scroll-auto p-5 bg-white">         
          <h5>Guide Information</h5>
          <p dangerouslySetInnerHTML={{ __html: data.help_detail}}></p>
        </div>

        <div className="card-footer ht-60 p-5">
          <div className="pull-right">
            <div className="p-b-4">
              <props.menu help={helpList} />
            </div>
          </div>
        </div>
      </div>
    )
  }else{
   return null;
  }
  
};

export default Help;
