import React, {Fragment,useEffect,useState} from "react";
import {Link} from "react-router-dom";
import moment from '../../../../node_modules/moment/min/moment.min.js'
import Rating from "../../../components/Rating"

export const Forum = (props) => {
  console.log('forum props',props);
  let forums = props.forum;
  let data = forums.data;
  const [profiles, setSkillProfile] = useState(false);


  useEffect(() => {

    let skillProfile = [];
  
    
    if (props.forum && props.forum.data_source){
      console.log('props forums',props.forum,props.forum.data_source);
      for (const key in props.forum.data_source) {
        console.log('has keys datasource',key,props.forum.data_source.hasOwnProperty(key))
        if (props.forum.data_source.hasOwnProperty(key)) {
          console.log('keys2',key)
          const element = props.forum.data_source[key];          
          if (parseInt(data[key])){    
            console.log('data key',data[key])                              
          console.log('element',(element));
          skillProfile.push(element.label);
                 
        }
        }

      }
    }
    console.log('forummapping',skillProfile,props.forum.discussion_responses,)
    setSkillProfile(skillProfile);


},[])

  return (
    <div>
      <div className="clearfix">
      <div className="content-boxed shadow-small" >
      <div className="content">
        <h1 className="vcard-title color-highlight bold">
        <i className="fa fa-comments" aria-hidden="true"></i>
        </h1>

        <div className="vcard">
        <strong>{data.subject}</strong>
        </div>
        
        <div className="vcard-field">
       <p>{data.description}</p>
        <div className="vcard">
        { profiles && profiles.map((title, value) => (
                    <a key={value} ><span>{title}</span></a>
                    
                ))}
                <props.details forum={forums} />
        </div>
        </div>
      
        <div className="vcard">
        <props.reply forum={forums} toggleModal={props.toggleModal}/>


        </div>
      </div>
 
    </div>
      </div>

      </div>
  )

};

export default Forum;
