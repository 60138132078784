import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import DynamicForm from '../../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../../constants/FormTemplateList'
import ReviewService from "./ReviewService";
import FormContainer from '../../../../components/FormBuilder/FormContainer'

let formMode = null; 
let assessment = {}; 
let data = {}; 
let formFn = {};  

function ReviewForm(props) {

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  let params = props;

  const get = () => {
    ReviewService.get(params.journey_profile_id).then((res) => {
      if(res.status==200){
        assessment = res.data.assessment;
        data = assessment.data || {};

        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    })
  }
  
  useEffect(() => { 
    assessment = {}; 
    data = {}; 
    formFn = {form_type: "review", journey_profile_id: props.journey_profile_id, editPermission:true};

    formMode = 'create-form-submissions';
    if(props.id && props.id != 'null'){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, [props.id]);

  const create = (assessment) => {
    assessment.assessment_id = props.assessment_id;
    assessment.assessment_type = props.assessment_type;
    assessment.journey_profile_id = props.journey_profile_id;
    assessment.form_id = FormTemplateId.AssessRisk;
    ReviewService.create(assessment).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    })
  }

  const update = (assessment) => {
    ReviewService.update(assessment).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    })
  }

  const onSubmitPageRedirect = () => {
    props.toggleModal();
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.AssessRisk} form={assessment} data={data} 
            formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
            setPost={setPost}/>
      </FormContainer> 
    )  
  }else{
    return null
  }
  
}

export default ReviewForm;