import React, { Fragment, useState } from "react";
import ZoomMeetingService from "./ZoomMeetingService";

const ZoomCredentialModal =({credential, toggleModal, onUpdate}) =>{
  const [loading, setLoading] = useState(false)
  const [apiInfo, setApiInfo] = useState(credential || {})

  const update = () =>{
    if(apiInfo && apiInfo.id){
      ZoomMeetingService.update_credential(apiInfo).then((res)=>{
        onUpdate(res.data.zoom)
      })    
    }else{
      ZoomMeetingService.create_credential(apiInfo).then((res)=>{
        onUpdate(apiInfo)
      })  
    }
  }

  const handleInputChange = (event) => {
    setApiInfo({
      ...apiInfo,
      [event.target.name]: event.target.value,
    })
  }

  return(
    <Fragment>
      {loading ? <div className="spinner"></div> :
        <form className="ml-container">          
          <div className="ml-section">
            <a className="m-t-5" href="https://marketplace.zoom.us/develop/create" target="_blank">
              Click this link to configure Zoom JWT API 
            </a>

            <label><b>API KEY</b></label>
            <input onChange={e=>handleInputChange(e)} 
              className="ml-input ml-border ml-margin-bottom"
              type="text" placeholder="API Key..." name="api_key"  
              defaultValue={apiInfo.api_key} required/>

            <label><b>API SECRET</b></label>
            <input onChange={e=>handleInputChange(e)} 
              className="ml-input ml-border ml-margin-bottom"
              type="text" placeholder="API Secret..." name="api_secret"  
              defaultValue={apiInfo.api_secret} required/>

            {apiInfo.api_key && apiInfo.api_secret &&
              <div className="ml-button ml-block ml-section ml-padding bg-highlight white"
                onClick={e=>update()}>
                <b>{apiInfo.id?'Update':'Create'}</b>
              </div>
            }

          </div>
        </form>
      }
    </Fragment>  
  )
}


export default ZoomCredentialModal;