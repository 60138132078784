import React, { Fragment, useContext } from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import { Link } from "react-router-dom";

const MenuItem = ({id, link, className, icon, action,onSelect}) => {
  let linkAttribute = {'id':id,'to':link, 'className':className}

  return (
    <Link {...linkAttribute} onClick={e=>onSelect && onSelect(e)}>
      <i className={icon}></i>
      <span className="font-13">{action}</span>
      <i className="fa fa-angle-right"></i>
    </Link>  
  )
}

const CardMenuInfo = ({formId, recordId, createdAt, userName}) => {
  const {state: { screen }, dispatch,} = useContext(AuthContext)

  return (
    <a href="">
      <i className="fas fa-info-circle"></i>
      <span className={(screen.width < 460 && 'lh-20')+' font-15'}>
        <small className="lred">since {createdAt}</small>
        <small className="m-l-10"> by {userName}</small>
        <small className="m-l-10">({formId}-{recordId})</small>
      </span>
      <i className="fa fa-angle-right"></i>
    </a>  
  )
}

const NavIcon = ({id, dataTip, link, className, icon, onSelect}) => {
  let linkAttribute = {'id':id, 'data-tip':dataTip, 'to':link, 'className':className}

  if(link){
    return (
      <Link {...linkAttribute}>
        <i className={icon}></i>
      </Link>  
    )
  }else{
    return (
     <span {...linkAttribute} onClick={e=>onSelect && onSelect(e)}>
        <i className={icon}></i>
      </span>
    )   
  }
  
}

const JourneyHome = ({id,journey_profile, link, className, icon, dataTip}) => {

  if(journey_profile){
    if(journey_profile.journey_type_id == "7"){
      link =link+"?menu=3522"
    }else if(journey_profile.journey_type_id == "6"){
      link = link+"?menu=3475"     
    }else{
      link = link
    }
  }
 
  let linkAttribute = {'id':id,'to':link, 'className':className,'data-tip':dataTip}

  return (
    <Link {...linkAttribute}>
      <i className={icon}></i>
    </Link>  
  )
}

export {MenuItem, CardMenuInfo , NavIcon ,JourneyHome}
