import React, {useState,Fragment} from "react";
import {Link} from "react-router-dom";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import Relationship from '../../../components/TrackRelationship/List/Relationship';
import TrackRelationshipService from "../../../components/TrackRelationship/TrackRelationshipService";
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu";
import ProjectMenu from '../../Modals/ProjectMenu'
import useModal from "../../../hooks/useModal";
import useStyle from '../../../hooks/useStyle';

let requestParams = {};
let project = null;
let selectedRelation = {};

export const TrackRelationshipList = (props) => {
  useStyle('card');
  useStyle('panel');

  const { state:currentUser, dispatch } = React.useContext(AuthContext);
  const [trackingList, setTrackingList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  let params = props.match.params;

  React.useEffect(() => {
    requestParams = {
      'page': currentpage, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'asc',
      'tracking_type':params.tracking_type,
      'tracking_id':params.tracking_id,
    }

    getTrackRelationshipList();
  }, [currentUser.user.accelerator, props.searchString, currentpage]);

  let getTrackRelationshipList = () => { 
    TrackRelationshipService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       setPagemeta(res.data.meta)
       setTrackingList(res.data.relationship_trackings)
      }
    })
  }

  let deleteTrackRelationship = (relationship) => {
    TrackRelationshipService.delete(relationship).then((res) => {
      if (res.status == 204) {
        setTrackingList(trackingList.filter(i => i.id !== relationship.id))
      }
    });
  }
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal, success:menuSuccess } = useModal();
  const { isOpen, toggleModal:toggleDeleteModal, success } = useModal(deleteTrackRelationship);


const addMenu = (e, relationship) => {
  selectedRelation = relationship;
  toggleMenuModal(e);
}

const Menu = ({ relationship }) => (
  <div className="dropup card-menu-right">
    <i onClick={e=>{addMenu(e, relationship)}} class="fas fa-bars" />       
  </div>
)

const menuItems = ({item}) =>{
  return(
    <Fragment>
      <Link to={`/track/relationship/${params.tracking_id}/${params.tracking_type}/${item.id}`}>
        <i className="far fa-edit"></i>
        <span className="font-13">Edit</span>
        <i className="fa fa-angle-right"></i>
      </Link>
      <Link onClick={e => {toggleDeleteModal(e, item)}}>
        <i className="fa fa-file-contract"></i>
        <span className="font-13">Delete</span>
        <i className="fa fa-angle-right"></i>
      </Link>
    </Fragment>
  )
}

const trackRelationShipCard = trackingList.map((tracking, i) =>
<div className="col-xs-12 col-sm-6 col-md-4" key={i}>
<Relationship relationship={tracking}  menu={Menu}/>
  {selectedRelation.record_id == tracking.record_id && 
    <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedRelation} 
    type="trackingRelation" 
    MenuItems={menuItems}/>}
</div>
)
  return (
    <React.Fragment>
      <div className="content top-10">
        <h5 className="float-left font-500">Relationship Tracking</h5>
        <Link to={`/track/relationship/${params.tracking_id}/${params.tracking_type}`} className="bg-highlight round-btn float-right m-r-5">
        <i className="fas fa-plus"></i>
        </Link>
        <div className="clear"></div>
      </div>

      <div className="page-content row">
        {trackRelationShipCard}
        <Pagination pagemeta={pagemeta} currentpage={currentpage} setCurrentpage={setCurrentpage}/>
      </div>
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={success}/>
    </React.Fragment>
  )
};

export default TrackRelationshipList;