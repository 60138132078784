import React, { useState } from 'react';
import CueTooltip from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel, OptionLabel} from '../FieldLabel'

const InputCheckbox = ({field, formData, readOnly, errors, forceUpdate, isFormWizard, openDataSourceModal, openFieldLabelModal, currentLocale}) => {
  let op_kv = field.options_key_value;
  let listKey = field.client_id+'_array';
  formData[listKey] = formData[listKey] || [];

  /*For State Management*/
  const [modelarray, setModelarray] = useState(formData[listKey]);

  let inputAttributes = {
    'type':'text',
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only)
  }

  let parentAttributes = {
    'tooltip': field.tooltip,
    'id': field.client_id
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  let validation = {'required': field.required}
  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);
  
  /* 
   * To check an item exist in checkbox options.
   */
  const toggle = (item) => {
     let idx = modelarray.indexOf(item);
     if (idx > -1) {
       setModelarray(modelarray.filter(i => i !== item))
       formData[listKey].splice(idx, 1);
     }else {
      setModelarray([...modelarray, item])
      item = FormHelper.isNumeric(item)
      formData[listKey].push(item);
     }
     
     formData[field.client_id] =  formData[listKey].toString();
     setError();
     forceUpdate();
     FormHelper.setFormTitle(field, formData, modelarray, op_kv)
     //$scope.setValidity();
  }

  const exists = (item) => {
    return modelarray.indexOf(item) > -1
  }

  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[listKey].length==0?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  return (
    <div {...parentAttributes} data-tip data-for={`tooltip_${field.client_id}`}>
      <FieldLabel field={field} labelAttributes={labelAttributes} currentLocale={currentLocale}
        openDataSourceModal={openDataSourceModal}
        openFieldLabelModal={openFieldLabelModal}/>
      {(op_kv && op_kv.options) 
        ? <OptionsObjList list={op_kv.options} inputAttributes={inputAttributes} toggle={toggle} exists={exists} currentLocale={currentLocale}/> 
        : <OptionsStringList list={field.options} inputAttributes={inputAttributes} toggle={toggle} exists={exists} />
      }     
      <span className="fts-13">{field.description}</span>
      <Error/>
      <CueTooltip id={field.client_id} description={field.tooltip}/>
    </div> 
  )
}

const OptionsObjList = ({list, inputAttributes, toggle, exists, currentLocale}) => list.map((o, k) =>
  <span className={`checkbox-container ${o.read_only=='yes'?'input-disabled':''} `} key={k} onClick={e => toggle(o.value, e)}>
    <span>{OptionLabel({option:o, currentLocale:currentLocale})}  </span>
    <input type="checkbox" checked={exists(o.value)} readOnly/>
    <span className="checkbox-checkmark"></span>
  </span>
);

const OptionsStringList = ({list, inputAttributes, toggle, exists}) => list.map((o, k) =>
  <span className="checkbox-container" key={k} onClick={e => toggle(o, e)}>
    <span>{o}</span>
    <input type="checkbox" checked={exists(o)} readOnly/>
    <span className="checkbox-checkmark"></span>
  </span>
)

export default InputCheckbox;