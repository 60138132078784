import React from "react";
import {Link} from "react-router-dom";
import moment from '../../../../../node_modules/moment/min/moment.min.js'

export const Project = (props) => {
  console.log('project props',props)
  let {project, project:{data}, project:{risk_assessment}} = props;

  return (
    <div className="content-boxed shadow-small" style={{height: '420px',paddingTop:'0px'}}>
     <div class="white card-header  p-5" style={{backgroundColor:'#607D8B'}}>
       <div className="pull-left" style={{width:"80%"}}>
       <div className="font-16">
         {data.name}
        </div>
        <div>
        {moment(project.updated_at).format('MMM DD, YYYY')}
        </div>
       </div>
        <div>
        <a className="pull-right white link" href={`/organization/project/portal/${project.id}`}>
            <i className="fas fa-share"></i>
          </a>
        </div> 
      </div>
      <div className="content">
        <div className="vcard-field">
          <strong>Name</strong>
          <a href="#">{data.name}</a>
        </div>
        <div className="vcard-field">
          <strong>Date</strong>
          <a href="/">{data.start_date}<br/>{data.end_date}</a>
          <i className="fas fa-calendar-alt color-blue2-dark" />
        </div>
        <div className="vcard-field no-border">
          <strong>Notes</strong>
          <a>{data.notes}</a>
          <i className="fas fas fa-book-reader color-red2-light" />
        </div>
        <div className="vcard-field no-border">
          <strong>Project Risk Score</strong>
          <a>{risk_assessment && risk_assessment.data.total_risk_score}</a>
          <i className="fas fa-star color-red2-light" />
        </div>
      </div>
      <props.menu project={project} toggleModal={props.toggleModal}/>
    </div>
  )

};

export default Project;
