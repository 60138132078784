import React, {Fragment, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import useStyle from '../../../hooks/useStyle';
import CommonService from "../../../services/CommonService";
import env from "../../../env";

const LightSailInstanceList = (props) => {
  useStyle('card');  
  useStyle('card_table');

  let [instances, setInstances] = useState([])

  useEffect(() => {
    getInstanceList();
  }, []);

  let getInstanceList = () => { 
    let req = {
      method: "get",
      url: `${env.ecn_backend_api}aws/lightsail/get_instances`,
      params: {},
    }

    CommonService.useAxios(req).then((res) => {
      setInstances(res.data.instances)
    }).catch((err) => {
      console.log(err)
    })
  }

  const instanceListCard = instances.map((instance, i) =>
    <div className="col-xs-12 col-sm-4" key={i}>
      <div className="content-boxed shadow-small">
        
        <div className="content vcard-header">
          <ul className="d-flex">
            <li>
              <img className="rounded-circle" src='images/server-48.png' width="50px"/>
            </li>
            <li className="m-l-15">
              <Link to={`/aws/lightsail/report/${instance.name}`}>
                <div className="vcard-title black">{instance.name}</div>
                <span className="black">since {CommonService.formatUpdateDate(instance.createdAt)}</span>
              </Link>
            </li>
          </ul>
        </div>  
      
        <div className="scroll-auto">
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                  <span>Machine Type - </span>
                  <span className="m-l-5">{instance.blueprintName}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Status - </span>
                  <span className="m-l-5">{instance.state.name}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  )

  return (
    <Fragment>
      <div className="content top-10">
        <h4 className="float-left font-500">Aws LightSail Instances</h4>
        <div className="clear"></div>
      </div>

      <div className="page-content content">
        {instanceListCard}
      </div>
    </Fragment>
  )

}

export default LightSailInstanceList;