import env from '../../env';
import axios from 'axios';
let url = env.ecn_backend_api+'notifications';

class NotificationService {

  getNotifications(req) {
    return new Promise((resolve, reject) => {
      axios.get(url, { params: req }).then((res) => {
          // handle success
        resolve(res);
      })
    })
  }

  updateNotification(id, req) {
    return new Promise((resolve, reject) => {
      axios.put(url +'/'+ id, req).then((res) => {
          // handle success
        resolve(res);
      })
    })
  }

  deleteNotification(req) {
    let url = "?delete_all=" + req.delete_all;
    return new Promise((resolve, reject) => {
      axios.delete(url+'/' + req.id + url).then((res) => {
          // handle success
        resolve(res);
      })
    })
  }

}

export default new NotificationService();