import React ,{Fragment, useState, useEffect} from "react"
import {Link, useHistory} from "react-router-dom"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import Cs from "../../../../../services/CommonService"
import {PillDsField, PillDsList} from "../../../UserRoles/NotificationPill"
import ReactTooltip from "react-tooltip"
import SortDirection from '../../../../Common/SortDirection'
import useSort from '../../../../../hooks/useSorting'
import ActivityService from "../ActivityService"
import PreLoadSpinner from "../../../../PreLoadSpinner"
import GenericModal from "../../../../Modals/GenericModal"
import ActivityForm from '../Form/ActivityForm'
import useModal from "../../../../../hooks/useModal"
import ActivityMenuModal from "./ActivityMenuModal"
import ActivityCard from './ActivityCard'

let requestParams = null
let activityForm = {
  prenatal_care: 507,
  instant_visit: 503,
  feeding_assessment: 502,
  nutrition_intake: 504,
}
let actFormProps = null
let selectedItem = null

const EmissionsActivityTable = (props) => {

  let history = useHistory()

  const {toggleMenuModal, journeyProfile, searchString, componentName, dataSource} = props
  const {child_form:formId} = dataSource

  const [child, setChild] = useState()
  const [loading, setLoading] = useState(true)
  const [activities, setActivities] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  
  const { isOpen:isEmissionsActivityFormOpen, toggleModal:toggleEmissionsActivityModal } = useModal()
  const { isOpen:isNutritionCareOpen, toggleModal:toggleNutritionCareModal } = useModal()
  const { isOpen:isAssessMenuOpen, toggleModal: toggleAssessMenuModal } = useModal()
  const { isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage } = useModal();

  useEffect(() => {
    requestParams = {
      sort_column: 'updated_at',
      sort_direction: 'desc',
      journey_profile_id: journeyProfile.id,
      search: searchString,
      page: currentpage,
      per_page: 20,
      form_id: formId,
    }

    getActivityList()

    if(componentName == 'complementary_feeding')toggleAssessMenuModal() 
  }, [searchString, currentpage])

  let getActivityList = () => {
    setLoading(true)
    ActivityService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        requestParams.totalItems = res.data.meta.total;
        //journey_category = res.data.meta.journey_category;
        //CheckAccess.userAccess(authState.user, journey_category, res.data.meta.invited_journey_member)

        setActivities(res.data.activities)
        setChild(res.data.child)
        setLoading(false)
      }
    })
  }

  const {sort} = useSort(requestParams, getActivityList)

  const routeActivityForm = (path, id, schedule_id) =>{
    actFormProps = Cs.routeEmissionsPath(path, {}, journeyProfile, history, null, id)
   /* if(child){
      let c = child
      c.data.baby_age = Cs.getAge(c.data.dob_baby_formatted_date)
      actFormProps.location.state = {
        child_fk_id: c.record_id, 
        dob_baby_formatted_date: c.data.dob_baby_formatted_date, 
        id_mother: journeyProfile.data.cell_number, 
        baby_age: c.data.baby_age
      }
    }
    actFormProps.match.params.schedule_id = schedule_id*/
    actFormProps.component = ActivityForm
    actFormProps.history=history
    toggleEmissionsActivityModal()
  }

  const onClickCard =(data)=>{
    selectedItem = data
    toggleHelpMessage()
  }

  const onFormSubmit = () =>{
    getActivityList()
    toggleEmissionsActivityModal()
  }

  /*const tabChange = function(key) {
    if(key=='nutrition_intake_bf'){
      toggleAssessMenuModal()
      setActivityType(key)
    }else{
      setActivityType(key)  
    }
  }*/

  const openNotification = (activity) =>{
    selectedItem = activity
    toggleNutritionCareModal()
  }

  const routeAssess = (path) =>{
    Cs.routeEmissionsPath(path, child, journeyProfile, history)  
  }

  const AssessTable = () =>(
    <div className="table-responsive">
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right">
        <i className="fas fa-download" />
      </span>
      <span className="bg-highlight round-btn highlight font-20 m-b-5 pull-right m-r-15" onClick={e=>routeActivityForm({sid: 'assess'})} >
        <i className="fas fa-plus" />
      </span>
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th onClick={e=>sort('record_id')} className='p-2 bold-400 text-center miw-95'>Id 
              <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('updated_at')} className='p-2 bold-400 text-center miw-95'>Date 
              <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.risks_array')} className='p-2 bold-400 text-center miw-95'>Risk
              <SortDirection sort_column={requestParams.sort_column} column="data.risks_array" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.soil_array')} className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="data.soil_array" reverse={requestParams.reverse}/>
                Soil
            </th>
            <th onClick={e=>sort('data.water_array')}className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="data.water_array" reverse={requestParams.reverse}/>
                Water
            </th>
            <th onClick={e=>sort('updated_by')} className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="updated_by" reverse={requestParams.reverse}/>
                By
            </th>
            <th onClick={e=>sort('created_at')} className='p-2 bold-400 text-center miw-95'>Option
              <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
            </th>
          </tr>
        </thead>
        <tbody>
          {assessTableData}
        </tbody>
      </table>
    </div>  
  )
  
  const assessTableData = activities.map((a, k) => {
    let ds = a.data_source || {}
    let data = a.data || {}

    return(
      <tr key={k}>
        <td className="text-center" data-title="Id">{a.record_id}</td>

        <td className="text-center">
          {Cs.formatUpdateDate(a.updated_at, 'MMMM DD YYYY')}
        </td>
       
        <td className="text-center">
          {ds.risks?.label}
        </td>

        <td className="text-center">
          {ds.terrain?.label}
        </td>
        
        <td className="text-center">
          {ds.water?.label}
        </td>

        <td className="text-center">
          {a.author_name}
        </td>

        <td className="text-center">
          <span onClick={e=>routeActivityForm({sid: 'assess'}, a.id)} className="fas fa-edit m-r-15 font-18 text-muted" /> 
          <span onClick={e=>onClickCard(a)} className="fas fa-file m-r-15 font-18 text-muted" />   
        </td>
      </tr>
    )
  })

  const AcquireTable = () =>(
    <div className="table-responsive">
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right">
        <i className="fas fa-download" />
      </span>
      <span className="bg-highlight round-btn highlight font-20 m-b-5 pull-right m-r-15" onClick={e=>routeActivityForm({sid: 'acquire'})} >
        <i className="fas fa-plus" />
      </span>
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th onClick={e=>sort('record_id')} className='p-2 bold-400 text-center miw-95'>Id 
              <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('updated_at')} className='p-2 bold-400 text-center miw-95'>Date 
              <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.name')} className='p-2 bold-400 text-center miw-95'>Name
              <SortDirection sort_column={requestParams.sort_column} column="data.name" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.uploads')} className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="data.uploads" reverse={requestParams.reverse}/>
                Uploads
            </th>
            <th onClick={e=>sort('data.urls')}className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="data.urls" reverse={requestParams.reverse}/>
                Links
            </th>
            <th onClick={e=>sort('updated_by')} className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="updated_by" reverse={requestParams.reverse}/>
                By
            </th>
            <th onClick={e=>sort('created_at')} className='p-2 bold-400 text-center miw-95'>Option
              <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
            </th>
          </tr>
        </thead>
        <tbody>
          {acquireTableData}
        </tbody>
      </table>
    </div>
  )

  const acquireTableData = activities.map((a, k) => {
    let ds = a.data_source || {}
    let data = a.data || {}

    return(
      <tr key={k}>
        <td className="text-center" data-title="Id">{a.record_id}</td>

        <td className="text-center">
          {a.id
            ?Cs.formatUpdateDate(data.visit_date_formatted_date, 'MMMM DD YYYY')
            :Cs.formatUpdateDate(a.scheduled_date, 'MMMM DD YYYY')
          }
        </td>
       
        <td className="text-center">
          <div>{data.name}</div>
        </td>

        <td className="text-center">
          <div>{data.upload}</div>
        </td>
        
        <td className="text-center">
        <div>{data.urls}</div>
        </td>

        <td className="text-center">
          {a.author_name}
        </td>

        <td className="text-center">
          <span onClick={e=>routeActivityForm({sid: 'acquire'}, a.id, a.pre_schedule_id)} className="fas fa-edit m-r-15 font-18 text-muted" />  
          {a.notifications_count > 0 && <span onClick={e=>openNotification(a)} className="fas fa-envelope m-r-15 font-18 text-muted" />}          
          {<span className="fas fa-paperclip m-r-15 font-18 text-muted" />}   
          <span onClick={e=>onClickCard(a)} className="fas fa-file m-r-15 font-18 text-muted" />                          
        </td>
      </tr>
    )
  })

  const DesignTable = () =>(
    <div className="table-responsive">
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right">
        <i className="fas fa-download" />
      </span>
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right m-r-15" onClick={e=>routeActivityForm({sid: 'design'})}>
         <i className="fas fa-plus" />
      </span>
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th onClick={e=>sort('record_id')} className='p-2 bold-400 text-center miw-95'>Id 
              <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('updated_at')} className='p-2 bold-400 text-center miw-95'>Date 
              <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.name')} className='p-2 bold-400 text-center miw-95'>Name
              <SortDirection sort_column={requestParams.sort_column} column="data.mother_training" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.urls')} className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="data.first_hour" reverse={requestParams.reverse}/>
                Links
            </th>
            <th onClick={e=>sort('data.upload')}className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="data.crawl_at_delivery" reverse={requestParams.reverse}/>
                Uploads
            </th>
            <th onClick={e=>sort('updated_by')} className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="updated_by" reverse={requestParams.reverse}/>
                By
            </th>
            <th onClick={e=>sort('created_at')} className='p-2 bold-400 text-center miw-95'>Option
              <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
            </th>
          </tr>
        </thead>
        <tbody>
          {designTableData}
        </tbody>
      </table>
    </div>
  )

  const designTableData = activities.map((a, k) => {
    let ds = a.data_source || {}
    let data = a.data || {}

    return(
      <tr key={k}>
        <td className="text-center" data-title="Id">{a.record_id}</td>

        <td className="text-center">
          {Cs.formatUpdateDate(data.date_cf_formatted_date, 'MMMM DD YYYY')}
        </td>
       
        <td className="text-center">
          <div>{data.name}</div>
        </td>

        <td className="text-center">
          <div>{data.urls}</div>
        </td>
        
        <td className="text-center">
          <div>{data.upload}</div>
        </td>



        <td className="text-center">
          {a.author_name}
        </td>

        <td className="text-center">
          <span onClick={e=>routeActivityForm({sid: 'design'}, a.id)} className="fas fa-edit m-r-15 font-18 text-muted" />   
        </td>
      </tr>
    )
  })

  const PilotTable = () =>(
    <div className="table-responsive">
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right">
        <i className="fas fa-download" />
      </span>
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right m-r-15" onClick={e=>routeActivityForm({sid: 'pilot'})}>
         <i className="fas fa-plus" />
      </span>
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th onClick={e=>sort('record_id')} className='p-2 bold-400 text-center miw-95'>Id 
              <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('updated_at')} className='p-2 bold-400 text-center miw-95'>Date 
              <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.name')} className='p-2 bold-400 text-center miw-95'>Name
              <SortDirection sort_column={requestParams.sort_column} column="data.mother_training" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.urls')} className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="data.first_hour" reverse={requestParams.reverse}/>
                Links
            </th>
            <th onClick={e=>sort('data.upload')}className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="data.crawl_at_delivery" reverse={requestParams.reverse}/>
                Uploads
            </th>
            <th onClick={e=>sort('updated_by')} className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="updated_by" reverse={requestParams.reverse}/>
                By
            </th>
            <th onClick={e=>sort('created_at')} className='p-2 bold-400 text-center miw-95'>Option
              <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
            </th>
          </tr>
        </thead>
        <tbody>
          {pilotTableData}
        </tbody>
      </table>
    </div>
  )

  const pilotTableData = activities.map((a, k) => {
    let ds = a.data_source || {}
    let data = a.data || {}

    return(
      <tr key={k}>
        <td className="text-center" data-title="Id">{a.record_id}</td>

        <td className="text-center">
          {Cs.formatUpdateDate(data.date_cf_formatted_date, 'MMMM DD YYYY')}
        </td>
       
        <td className="text-center">
          <div>{data.name}</div>
        </td>

        <td className="text-center">
          <div>{data.urls}</div>
        </td>
        
        <td className="text-center">
          <div>{data.upload}</div>
        </td>



        <td className="text-center">
          {a.author_name}
        </td>

        <td className="text-center">
          <span onClick={e=>routeActivityForm({sid: 'pilot'}, a.id)} className="fas fa-edit m-r-15 font-18 text-muted" />   
          <span onClick={e=>onClickCard(a)} className="fas fa-file m-r-15 font-18 text-muted" />   
        </td>
      </tr>
    )
  })

  const DevelopTable = () =>(
    <div className="table-responsive">
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right">
        <i className="fas fa-download" />
      </span>
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right m-r-15" onClick={e=>routeActivityForm({sid: 'develop'})}>
         <i className="fas fa-plus" />
      </span>
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th onClick={e=>sort('record_id')} className='p-2 bold-400 text-center miw-95'>Id 
              <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('updated_at')} className='p-2 bold-400 text-center miw-95'>Date 
              <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.name')} className='p-2 bold-400 text-center miw-95'>Name
              <SortDirection sort_column={requestParams.sort_column} column="data.mother_training" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.urls')} className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="data.first_hour" reverse={requestParams.reverse}/>
                Links
            </th>
            <th onClick={e=>sort('data.upload')}className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="data.crawl_at_delivery" reverse={requestParams.reverse}/>
                Uploads
            </th>
            <th onClick={e=>sort('updated_by')} className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="updated_by" reverse={requestParams.reverse}/>
                By
            </th>
            <th onClick={e=>sort('created_at')} className='p-2 bold-400 text-center miw-95'>Option
              <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
            </th>
          </tr>
        </thead>
        <tbody>
          {developTableData}
        </tbody>
      </table>
    </div>
  )

  const developTableData = activities.map((a, k) => {
    let ds = a.data_source || {}
    let data = a.data || {}

    return(
      <tr key={k}>
        <td className="text-center" data-title="Id">{a.record_id}</td>

        <td className="text-center">
          {Cs.formatUpdateDate(data.date_cf_formatted_date, 'MMMM DD YYYY')}
        </td>
       
        <td className="text-center">
          <div>{data.name}</div>
        </td>

        <td className="text-center">
          <div>{data.urls}</div>
        </td>
        
        <td className="text-center">
          <div>{data.upload}</div>
        </td>



        <td className="text-center">
          {a.author_name}
        </td>

        <td className="text-center">
          <span onClick={e=>routeActivityForm({sid: 'develop'}, a.id)} className="fas fa-edit m-r-15 font-18 text-muted" />   
          <span onClick={e=>onClickCard(a)} className="fas fa-file m-r-15 font-18 text-muted" />   
        </td>
      </tr>
    )
  })


  const OperateTable = () =>(
    <div className="table-responsive">
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right">
        <i className="fas fa-download" />
      </span>
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right m-r-15" onClick={e=>routeActivityForm({sid: 'operate'})}>
         <i className="fas fa-plus" />
      </span>
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th onClick={e=>sort('record_id')} className='p-2 bold-400 text-center miw-95'>Id 
              <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('updated_at')} className='p-2 bold-400 text-center miw-95'>Date 
              <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.name')} className='p-2 bold-400 text-center miw-95'>Name
              <SortDirection sort_column={requestParams.sort_column} column="data.mother_training" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.urls')} className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="data.first_hour" reverse={requestParams.reverse}/>
                Links
            </th>
            <th onClick={e=>sort('data.upload')}className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="data.crawl_at_delivery" reverse={requestParams.reverse}/>
                Uploads
            </th>
            <th onClick={e=>sort('updated_by')} className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="updated_by" reverse={requestParams.reverse}/>
                By
            </th>
            <th onClick={e=>sort('created_at')} className='p-2 bold-400 text-center miw-95'>Option
              <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
            </th>
          </tr>
        </thead>
        <tbody>
          {operateTableData}
        </tbody>
      </table>
    </div>
  )

  const operateTableData = activities.map((a, k) => {
    let ds = a.data_source || {}
    let data = a.data || {}

    return(
      <tr key={k}>
        <td className="text-center" data-title="Id">{a.record_id}</td>

        <td className="text-center">
          {Cs.formatUpdateDate(data.date_cf_formatted_date, 'MMMM DD YYYY')}
        </td>
       
        <td className="text-center">
          <div>{data.name}</div>
        </td>

        <td className="text-center">
          <div>{data.urls}</div>
        </td>
        
        <td className="text-center">
          <div>{data.upload}</div>
        </td>



        <td className="text-center">
          {a.author_name}
        </td>

        <td className="text-center">
          <span onClick={e=>routeActivityForm({sid: 'operate'}, a.id)} className="fas fa-edit m-r-15 font-18 text-muted" />   
          <span onClick={e=>onClickCard(a)} className="fas fa-file m-r-15 font-18 text-muted" />   
        </td>
      </tr>
    )
  })

  const TransactTable = () =>(
    <div className="table-responsive">
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right">
        <i className="fas fa-download" />
      </span>
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right m-r-15" onClick={e=>routeActivityForm({sid: 'transact'})}>
         <i className="fas fa-plus" />
      </span>
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th onClick={e=>sort('record_id')} className='p-2 bold-400 text-center miw-95'>Id 
              <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('updated_at')} className='p-2 bold-400 text-center miw-95'>Date 
              <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.name')} className='p-2 bold-400 text-center miw-95'>Name
              <SortDirection sort_column={requestParams.sort_column} column="data.mother_training" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.urls')} className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="data.first_hour" reverse={requestParams.reverse}/>
                Links
            </th>
            <th onClick={e=>sort('data.upload')}className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="data.crawl_at_delivery" reverse={requestParams.reverse}/>
                Uploads
            </th>
            <th onClick={e=>sort('updated_by')} className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="updated_by" reverse={requestParams.reverse}/>
                By
            </th>
            <th onClick={e=>sort('created_at')} className='p-2 bold-400 text-center miw-95'>Option
              <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
            </th>
          </tr>
        </thead>
        <tbody>
          {transactTableData}
        </tbody>
      </table>
    </div>
  )

  const transactTableData = activities.map((a, k) => {
    let ds = a.data_source || {}
    let data = a.data || {}

    return(
      <tr key={k}>
        <td className="text-center" data-title="Id">{a.record_id}</td>

        <td className="text-center">
          {Cs.formatUpdateDate(data.date_cf_formatted_date, 'MMMM DD YYYY')}
        </td>
       
        <td className="text-center">
          <div>{data.name}</div>
        </td>

        <td className="text-center">
          <div>{data.urls}</div>
        </td>
        
        <td className="text-center">
          <div>{data.upload}</div>
        </td>



        <td className="text-center">
          {a.author_name}
        </td>

        <td className="text-center">
          <span onClick={e=>routeActivityForm({sid: 'transact'}, a.id)} className="fas fa-edit m-r-15 font-18 text-muted" />   
          <span onClick={e=>onClickCard(a)} className="fas fa-file m-r-15 font-18 text-muted" />   
        </td>
      </tr>
    )
  })

  const TransitionTable = () =>(
    <div className="table-responsive">
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right">
        <i className="fas fa-download" />
      </span>
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right m-r-15" onClick={e=>routeActivityForm({sid: 'transition'})}>
         <i className="fas fa-plus" />
      </span>
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th onClick={e=>sort('record_id')} className='p-2 bold-400 text-center miw-95'>Id 
              <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('updated_at')} className='p-2 bold-400 text-center miw-95'>Date 
              <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.name')} className='p-2 bold-400 text-center miw-95'>Name
              <SortDirection sort_column={requestParams.sort_column} column="data.mother_training" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.urls')} className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="data.first_hour" reverse={requestParams.reverse}/>
                Links
            </th>
            <th onClick={e=>sort('data.upload')}className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="data.crawl_at_delivery" reverse={requestParams.reverse}/>
                Uploads
            </th>
            <th onClick={e=>sort('updated_by')} className='p-2 bold-400 text-center miw-95'>
              <SortDirection sort_column={requestParams.sort_column} column="updated_by" reverse={requestParams.reverse}/>
                By
            </th>
            <th onClick={e=>sort('created_at')} className='p-2 bold-400 text-center miw-95'>Option
              <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
            </th>
          </tr>
        </thead>
        <tbody>
          {transitionTableData}
        </tbody>
      </table>
    </div>
  )

  const transitionTableData = activities.map((a, k) => {
    let ds = a.data_source || {}
    let data = a.data || {}

    return(
      <tr key={k}>
        <td className="text-center" data-title="Id">{a.record_id}</td>

        <td className="text-center">
          {Cs.formatUpdateDate(data.date_cf_formatted_date, 'MMMM DD YYYY')}
        </td>
       
        <td className="text-center">
          <div>{data.name}</div>
        </td>

        <td className="text-center">
          <div>{data.urls}</div>
        </td>
        
        <td className="text-center">
          <div>{data.upload}</div>
        </td>



        <td className="text-center">
          {a.author_name}
        </td>

        <td className="text-center">
          <span onClick={e=>routeActivityForm({sid: 'transition'}, a.id)} className="fas fa-edit m-r-15 font-18 text-muted" />   
          <span onClick={e=>onClickCard(a)} className="fas fa-file m-r-15 font-18 text-muted" />   
        </td>
      </tr>
    )
  })

  if(loading) return(<PreLoadSpinner/>);

  let components = {
    526:AssessTable,
    527:AcquireTable,
    528:DesignTable,
    529:PilotTable,
    530:DevelopTable,
    531:OperateTable,
    532:TransactTable,
    533:TransitionTable,
  }

  const TagName = components[formId];  

  if(TagName){
    return (
      <>
        <TagName {...props}/>
        
        {isEmissionsActivityFormOpen && <GenericModal toggleModal={toggleEmissionsActivityModal} 
          isPopupMode="true" onFormSubmit={onFormSubmit} {...actFormProps} {...props} />}

        {isHelpMessageOpen && (
          <GenericModal
            title="Activity Card"
            component={ActivityCard}
            selectedItem={selectedItem}
            onSuccess={toggleHelpMessage}
            isOpen={isHelpMessageOpen}
            toggleModal={toggleHelpMessage}/>
        )}
      </>
    )
  }else{
    return <div>None</div>
  }

}

export default EmissionsActivityTable;