import React, {useState, Fragment} from 'react';
import CueTooltip from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import CommonService from '../../../services/CommonService';
import LottieEditor from "./LottieEditor";
import GenericModal from "../../Modals/GenericModal";
import useModal from "../../../hooks/useModal";
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel} from '../FieldLabel'

const InputLottieSvg = ({field, formData, readOnly, errors, formFn, isFormWizard, formId, openFieldLabelModal, currentLocale}) => {
  const [model, setModel] = useState(formData[field.client_id] || null);
  const [showError, setShowError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const { isOpen:isLottieEditorOpen, toggleModal:toggleLottieEditorModal } = useModal();

  let inputAttributes = {
    'type':'file',
    'id': 'file_selector_'+field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only)
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let validation = {required: true}
  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  let privacyType = field.privacy_type || 'private';

  const onChange=event=>{ 
    uploadFileToUrl(event.target.files, event.target)  
  }

  const uploadFileToUrl = (files, ele) => {
    try {
      let file = null;
      if(files){
        let FileSize = files[0].size / 1024 / 1024; // in MB
        if (FileSize > 2) {
          ele.value = null
          setFileSizeError(true)
          return;
        }else{
          file = files[0]
          setFileSizeError(false)
        }  
      }

      if(file){
        setShowUploadProgress(true)
        let imageData = {
          'fileable_type':formFn.form_type, 
          'fileable_id':formId,
          'journey_category_id':formFn.journey_category_id
        }
        CommonService.uploadFileToUrl(file, privacyType, imageData).then((res)=> {
          const data = res.data
          setModel({'file_url':data.file_upload.url})
          formData[field.client_id] = {'file_url':data.file_upload.url}
          ele.value = null
          setShowUploadProgress(false)
          setError()
        }, (err)=>{
          setShowUploadProgress(false)
        })
      }
    } catch (e) {
      setShowUploadProgress(false)
      console.log(e)
    }
  }

  const removeImage= (file, e) => {
    try {
      e.stopPropagation();
      CommonService.deleteFileFromUrl(file).then(function(res) {
        if(res.status === 204 || res.status === 404){
          setModel(null)
          formData[field.client_id] = null;
          setError();
        }
      })
    } catch (e) {
      alert(e);
    }
  }

  FormHelper.setDisabled(field, inputAttributes);

  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[field.client_id]==null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  const MaxFileSizeError = () => {
    if(fileSizeError){
      return(
        <div className="errormsg">
          <div>File Size is Large. Max Size 2MB.</div>
        </div>
      )
    }
    return null;
  }

  const Image = () =>  {
    return(   
    <div className="upload-sm-card">
      <span className="upload-delete" onClick={e => removeImage(model.file_url, e)}>×</span>
      <div>
        <a href="#" onClick={e=>toggleLottieEditorModal(e)}>
          <div>
            <img className="w-85" src={CommonService.getIconByType(model.file_url)} alt="img"/>
            <strong>{CommonService.getFileName(model.file_url)}</strong>
          </div>
        </a>
      </div>
    </div>
  )}

  return (
    <Fragment>
    <div {...parentAttributes}>
      <FieldLabel field={field} labelAttributes={labelAttributes} 
        currentLocale={currentLocale}
        openFieldLabelModal={openFieldLabelModal}/>
      { model && model.file_url ? <div><Image/></div> :
        <React.Fragment>
          {!showUploadProgress &&
            <label className={`btn btn-default w-100p bg-highlight color-white ${readOnly && 'input-readonly'}`} htmlFor={`file_selector_${field.client_id}`}
            data-tip data-for={`tooltip_${field.client_id}`}>   
             Click to Upload Lottie File
            </label>
          }
          {showUploadProgress &&
            <label className="btn btn-default w-100p bg-highlight color-white" >   
             Uploading...
            </label>
          }
          <input {...inputAttributes} className="hidden" onChange={e => onChange(e)} />
        </React.Fragment> 
      }
      <span className="fts-13">{field.description}</span>
      <Error/>
      <MaxFileSizeError/>
      <CueTooltip id={field.client_id} description={field.tooltip}/>
    </div> 
    {isLottieEditorOpen && (
      <GenericModal component={LottieEditor} title="Lottie Editor"
        config={model} setConfig={setModel}
        isOpen={isLottieEditorOpen} toggleModal={toggleLottieEditorModal}/>
    )}
    </Fragment>
  )
}

export default InputLottieSvg;