import React, {useState} from "react";
import {Link} from "react-router-dom";

const ProjectPath = (props) => {
  let {
    statics: { report },
  } = {
    statics: {
      id: null,
      report: {
        activities: 6,
        calendars: 0,
        invited_journey_members: 4,
        planners: 9,
        roles: 2,
        schedules: 0,
      },
    },
  };
  let p = {...props.projectPath.options}
  let arr = Object.keys(p).map((k) => p[k])
  
  let disableIconStyle = {}
  let disableClickEvent = {}
  console.log('props projectpath',arr,report,p,report[arr[3].sid])

  /*if(props.currentUser.current_admin.admin_role_name == 'demo_user'){
    disableIconStyle.color = 'grey'
    disableClickEvent.pointerEvents = 'none'
  }*/

  if(report && arr){
  return (
    <div className="row margin-unset">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <div className="edu-path">
          <div className="head">PLAN</div> 
          <div className="icons">
            <div className="three-half" onClick={e=>props.routePath(e, p[0])}>
              <i className="fas fa-clipboard-check red"/>
              <div>Service Requests</div>
              <span className="notification">{report[arr[0].sid] || 0}</span>
            </div>
            <div className="three-half" onClick={e=>props.routePath(e, p[4])}>
              <i className="far fa-calendar-alt blue"/>
              <div>Milestones</div>
              <span className="notification">{report[arr[4].sid] || 0}</span>
            </div>
            <div className="three-half" onClick={e=>props.routePath(e, p[2])}>
              <i className="fas fa-medal gold"/>
              <div>Contracts</div>
              <span className="notification">{report[arr[2].sid] || 0}</span>
            </div>
          </div>     
        </div>
      </div>

      <div className="col-xs-12 col-sm-6 col-md-4">
        <div className="edu-path">
          <div className="head">TRACK</div>   
          <div className="icons">
            <div className="three-half" onClick={e=>props.routePath(e, p[1])}>
              <i className="far fa-clipboard l-green"/>
              <div>Activities</div>
              <span className="notification">{report[arr[1].sid] || 0}</span>
            </div>
            <div className="three-half" onClick={e=>props.routePath(e, p[3])}>
              <i className="fas fa-landmark grey"/>
              <div>Colleges</div>
              <span className="notification">{report[arr[3].sid] || 0}</span>
            </div>
            <div className="three-half" onClick={e=>props.routePath(e, p[5])}>
              <i className="fas fa-lightbulb gold"/>
              <div>Suggestions</div>
              <span className="notification">{ 0}</span>
            </div>
          </div>      
        </div>
      </div>

    </div>
  )}else{
    return(
      <div></div>
    )
  }
}

export default ProjectPath;

/*
<div className="col-xs-12 col-sm-6 col-md-4">
        <div className="edu-path">
          <div className="head">ANALYZE</div> 
          <div className="icons">
            <div style={disableClickEvent} className="three-half" onClick={e=>props.routePath(e, p[6])}>
              <i style={disableIconStyle} className="far fa-map l-green"/>
              <div>Paths</div>
              <span className="notification">{ 0}</span>
            </div>
            <div style={disableClickEvent} className="three-half" onClick={e=>props.routePath(e, p[7])}>
              <i style={disableIconStyle} className="fas fa-poll red"/>
              <div>Comparison</div>
              <span className="notification">{0}</span>
            </div>
          </div>      
        </div>
      </div>
*/