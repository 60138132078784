import React, {useState, Fragment} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import Pagination from '../../components/Pagination/Pagination'
import useStyle from '../../hooks/useStyle';
import CommonService from '../../services/CommonService'

let requestParams = {}

const IncomingMails = (props) => {

  let params = props.match.params;

  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext);
  const [mailList, setMailList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);

  React.useEffect(() => {
    requestParams = {
      'page': currentpage,
      'paginate': 1,
      'per_page': 9,
      'sort_column': '',
      'sort_direction': 'desc',
      'search': null,
      'mailable_type': params.type,
      'mailable_id': params.id
    }

   // getIncomingMails();
  }, [currentUser.id, currentpage]);

  /*
  let getIncomingMails = () => { 
    CommonService.getIncomingMails(requestParams).then((res)=>{
     if(res.status == 200){
       setMailList(res.data.incoming_mails)
       //requestParams.totalItems = res.data.meta.total;
      }
    })
  }

  const List =  mailList && mailList.map((m, i) => (
    <div className="col-xs-12 m-t-5" key={i}>
      <div className="font-15 mail">
        <h4>{m.subject}</h4>
        <div>{CommonService.formatUpdateDate(m.updated_at)}</div>
        <div dangerouslySetInnerHTML={{ __html: m.body_html_string }}></div>
        <div>Sender - {m.sender}</div>
        <div>Receiver - {m.receiver}</div>
      </div>
    </div>
  ))
  */

  return (
    <React.Fragment>
      <div className="content top-10">
        <h5 className="float-left font-500">Incoming Mails</h5>
        <div className="clear"></div>
      </div>
      <div className="page-content row">
      </div>
    </React.Fragment>
  )

}

export default IncomingMails;