import React, {useState} from 'react';
import CueTooltip from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel} from '../FieldLabel'

const InputRating = ({field, formData, readOnly, errors, formFn, isFormWizard, openFieldLabelModal, currentLocale}) => {
  let [model, setModel] = useState(formData[field.client_id]);
  let [showError, setShowError] = useState(false);
  
  let inputAttributes = {
    'name': field.client_id,
    'disabled':(readOnly || field.read_only)
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip,
    'id': field.client_id
  }
  
  let validation = {
    required: field.required
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  let op_kv = field.options_key_value;

  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const toggle = (value) => {
    if(model == value){
      setModel(0)
    }else{
      setModel(value);
    }
    formData[field.client_id] = value;
    setError();
  }

  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[field.client_id]==null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  const Rating = () =>{  
    return <div>
      <span className="font-20">
        <i className={`${1<=model?'fas green':'far'} fa-star`} onClick={e => toggle(1)}></i>
        <i className={`${2<=model?'fas green':'far'} fa-star`} onClick={e => toggle(2)}></i>
        <i className={`${3<=model?'fas green':'far'} fa-star`} onClick={e => toggle(3)}></i>
        <i className={`${4<=model?'fas green':'far'} fa-star`} onClick={e => toggle(4)}></i>
        <i className={`${5<=model?'fas green':'far'} fa-star`} onClick={e => toggle(5)}></i>
      </span>
    </div>
  }

  return (
  	<div {...parentAttributes} data-tip data-for={`tooltip_${field.client_id}`}>
  	  <FieldLabel field={field} labelAttributes={labelAttributes} 
        currentLocale={currentLocale}
        openFieldLabelModal={openFieldLabelModal}/>
      <Rating/>
  	  <span className="fts-13">{field.description}</span>
      <Error/>
      <CueTooltip id={field.client_id} description={field.tooltip}/>
 	  </div> 
  )

}

export default InputRating;