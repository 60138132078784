import React, {useState, Fragment, useEffect, useContext} from "react"
import moment from 'moment'
import { useHistory, Link } from 'react-router-dom'
import StepActivitiesService from "./StepActivitiesService"
import useModal from "../../../../hooks/useModal"
import Cs from '../../../../services/CommonService'
import CheckAccess from '../../../Roles/CheckAccess'
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import StepActivityStatusForm from "../StepActivityStatus/StepActivityStatusForm"
import GenericModal from "../../../Modals/GenericModal"

const StepActivityModal = (props) => {
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)

  let [loading, setLoading] = useState(true)
  let [stepActivity, setStepActivity] = useState({})

  const { isOpen:isStatusFormOpen, toggleModal:toggleStatusForm } = useModal()

  useEffect(() =>{
  	getStepActivity()
  }, [])

  const getStepActivity = () =>{
  	StepActivitiesService.getAll({id: props.stepActivityId}).then(({status, data})=>{
  	  if(status == 200){
        const sa = data.step_activities[0]
  	  	if (typeof sa.data.upload === 'string') {
      		sa.data.upload = [sa.data.upload]
      	}
      	setStepActivity(sa)	
      	setLoading(false)
  	  }
  	})
  }

  const getStatusFormProps = () =>{
    return {
      isPopupMode:true,
      onSubmit:onStatusUpdate,
      match:{
        params:{
          id:stepActivity.status?.id || null,
          journey_profile_id:stepActivity.journey_profile_id,
          activity_id:stepActivity.activity_id,
          step_activity_id:stepActivity.id
        }
      },
    }
  }

  const onStatusUpdate = (status) =>{
    stepActivity.status = status
    setStepActivity({...stepActivity})
  }

  const FileList = ({files}) => {
    return(
      <Fragment>
        {files && files.map((file, k) =>  (
          <a key={k} className="m-t-5 brbd-grey" target="_blank" href={Cs.getFileUrl(file)}>
            <img src={Cs.getIconByType(file)} width="100%" height="100%"/>
            <span className="m-l-5">{Cs.getFileName(file)}</span>
          </a>
        ))}
      </Fragment>
    )
  }

  const MilestoneImpacted = ({list}) => list.map((m, i) => (
    <div className="lred">{m.name}</div>
  ))

  const Menu = ({step_activity}) => {
    return( 
      <div className="pull-right">
        {(true || CheckAccess.hasDeleteAccess(currentUser, props.journeyProfile, step_activity)) &&
          <Link 
            className="bg-lgrey round-btn brr-50p m-r-5"
            to={`/step_activity/form/${step_activity.journey_profile_id}/${step_activity.activity_id}/${step_activity.id}?form_id=${step_activity.form_id}&title=${step_activity?.activity?.data?.name}`}>
            <i className="far fa-edit font-16"/> 
          </Link>
        }
        <span 
          onClick={toggleStatusForm}
          className="bg-lgrey round-btn brr-50p m-r-5">
          <i className={step_activity.status?'far fa-check-circle':'far fa-circle'}/>  
        </span>
      </div>
    )
  }

  const Card = ({stepActivity, data}) => {
    let milestone_impacted = null
    try{
      milestone_impacted = stepActivity.status.data.milestone_impacted_array
    }catch(e){}

    return(
      <div className="row p-10 font-14">
        <div className="col-xs-6">
          <span className="font-10" style={{border:"1px solid", padding:"2px"}}>
            {stepActivity.author_name}-{Cs.formatUpdateDate(stepActivity.created_at)}
          </span>
        </div>
        <div className="col-xs-6">
          <Menu step_activity={stepActivity}/>
        </div>
        <div className="col-xs-12">
          <a href={data.reference_url} target="_blank">{data.reference_url}</a>
          <div className="m-t-5 brbd-grey" href="#">{data.start_date} to {data.end_date}</div>
          <div className="m-t-5 brbd-grey" dangerouslySetInnerHTML={{ __html: data.notes }}/>
          <FileList files={data.upload}/>
          {milestone_impacted && 
            <Fragment>
              <u>Milestone Impacted</u> 
              <MilestoneImpacted list={milestone_impacted}/>
            </Fragment>  
          }
        </div>
      </div>
    )
  }

  if(loading)
  	return <div className='spinner'/>

  if(isStatusFormOpen)
    return (
      <GenericModal {...getStatusFormProps()}
        component={StepActivityStatusForm} 
        isOpen={isStatusFormOpen} 
        toggleModal={toggleStatusForm} />
    )

  return(
  	<Card stepActivity={stepActivity} data={stepActivity.data} />
  )

}

export default StepActivityModal;