import React, {useState} from "react";
import {Link} from "react-router-dom";
import CommonService from '../../../services/CommonService';

const Feedback = (props) => {
  let feedback = props.feedback;
  const [data, setData] = useState(feedback.data);
  
  const cardStyle = {
    borderTop: '3px solid ',
    height: '60px'
  }

  const onStatusUpdate = (data) =>{
    setData({...data})
  }

  if(data !== null){
    return (
      <div className="card-container shadow-small" >
        <div className="card-header p-5" style={cardStyle}>
          <div className="font-16 black-light-1">
            {data.title}
          </div>
          <div className="lred">
            {CommonService.formatUpdateDate(feedback.updated_at)}
          </div> 
        </div>

        <div className="ht-200 scroll-auto p-5 bg-white">
          <div className="vcard-field">
            <strong>Type of Feedback</strong>
            <div className="font-15">{data.type_of_feedback}</div>
          </div>

          <div className="vcard-field">
            <strong>Notes</strong>
            <div className="font-15">{data.note_explain_feedback}</div>
          </div>
        </div>

        <div className="card-footer p-5">
          <div className="m-l-5 pull-left">
            <span onClick={e=>props.updateStatus(feedback, onStatusUpdate)}>
              <i className={`far ${data.status==1?'fa-check-circle':'fa-circle'} font-18`}/>
            </span>
          </div>
          <div className="pull-right">
            <props.menu f={feedback} />
          </div>
        </div>
      </div>
    )
  }else{
   return null;
  }
  
}

export default Feedback;