let route = {}

route.routeNotification = (n, props) => {
  route.props = props;
  switch (n.notifiable_type){
    case 'comment':
      route[n.item_type](n, 'comment');
      break; 
    case 'share_journey':
      route.props.history.push(`/journey/portal/${n.journey_profile_id}`)
      break;
    default:
      route[n.notifiable_type](n);
      break;
  }
}

route.planner = function(n, type){
  let url = `/goals/${n.journey_profile_id}?id=${n.item_id}`
  url = (type == 'comment')?url+`&comment_id=${n.notifiable_id}`:url;
  route.props.history.push(url)
}

route.activity = function(n, type){
  let url = `/baseline/${n.journey_profile_id}?id=${n.item_id}`
  url = (type == 'comment')?url+`&comment_id=${n.notifiable_id}`:url;
  route.props.history.push(url)
}

route.education_journal_entry = function(n, type){
  if(type == 'comment'){
    route.props.history.push(`/highschool/activity/${n.journey_category_id}/${n.education_journey_profile_id}?id=${n.item_id}&comment_id=${n.notifiable_id}`)
  }else{
    route.props.history.push(`/highschool/activity/${n.journey_category_id}/${n.education_journey_profile_id}?id=${n.notifiable_id}`)
  }
}

export default route;