import React, { useState, Fragment, useEffect, useContext, useRef } from "react";
import ReactTooltip from "react-tooltip";
import querystringify from "querystringify";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import Activity from "./Activity";
import TrackActivity from "./TrackActivity";
import ActivityService from "../ActivityService";
import Paginations from "../../../../Pagination";
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import ProjectMenu from "../../../../Modals/ProjectMenu";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import CommentsModal from "../../../../Modals/CommentsModal";
import useModal from "../../../../../hooks/useModal";
import ChecklistsModal from "../../../../Modals/ChecklistsModal";
import useStyle from "../../../../../hooks/useStyle";
import CheckAccess from "../../../../Roles/CheckAccess";
import ListMenuModal from "../../ListMenuModal";
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import JourneyTitle from "../../JourneyTitle/JourneyTitle";
import SideMenuBar from "../../../../../components/Common/SideMenuBar";
import NotificationPopup from "../../../../../components/Common/NotificationPopup";
import {MenuItem, CardMenuInfo, NavIcon, JourneyHome} from '../../../../Common/MenuItem'
import HelpMessageList from '../../../../../constants/HelpMessageList'
import CommonService from "../../../../../services/CommonService";
import {SwiperContainer, SwiperSlide} from "../../../../Common/Swiper"
import Pagination from "../../../../Pagination"

let requestParams = {}
let selectedActivity = {}
let data_source = {}
let journey_profile = null
let cardViewCount = 1

const ActivityList = (props) => {
  useStyle("card")
  useStyle("card_table")
  useStyle("add_role_dialog")

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  let cardGridCss = "col-xs-12 col-sm-6 col-md-4"
  let cardHeightCss = "ht-400"
  if (queryParam.id) {
    cardGridCss = "col-sm-12 col-sm-6 col-md-8"
    cardHeightCss = "ht-400"
  }

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)

  const [activityList, setActivityList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [pagemeta, setPagemeta] = useState([]);

  const { isOpen: isCommentOpen, toggleModal: toggleComment } = useModal();
  const { isOpen: isDeleteModalOpen, toggleModal: toggleDelete,} = useModal();
  const { isOpen: isActivityMenuOpen, toggleModal: toggleActivityMenu } = useModal();
  const { isOpen: isChecklistOpen, toggleModal: toggleChecklist,} = useModal();
  const { isOpen: isMenuOpen, toggleModal: toggleMenu } = useModal();
  const { isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage } = useModal();
  const indexEle = useRef(null);

  useEffect(() => {
    requestParams = {
      data_source_params: { 
        data_source_id: [3196], 
        response_type: "object" 
      },
      journey_profile_id: params.journey_profile_id,
      page: currentpage,
      paginate: 1,
      per_page: 15,
      sort_column: "updated_at",
      sort_direction: "desc",
      totalItems: 0,
      search: props.searchString,
      form_ds_id:queryParam.menu,
      id:queryParam.id
    }

    getActivityList()
  }, [props.searchString, currentpage, props.location])

  let getActivityList = () => {
    setLoading(true)
    ActivityService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        data_source = res.data.data_source_list;
        requestParams.totalItems = res.data.meta.total;
        requestParams.totalPage = res.data.meta.last_page;
        journey_profile = res.data.journey_profile;
        CheckAccess.userAccess(currentUser, journey_profile, res.data.share_journey);
        if(requestParams.search){
          setActivityList(res.data.activities);
        }else if(queryParam.id){
          setActivityList(res.data.activities);
        }else{
          setActivityList([...activityList, ...res.data.activities]);  
          res.data.activities.length == 0 && toggleHelpMessage()
        }
        setLoading(false);
      }
    })
  }

  const addComment = (e, a) => {
    selectedActivity = a;
    toggleComment(e)
  }

  const addChecklist = (e, a) => {
    selectedActivity = a;
    toggleChecklist(e)
  }

  const deleteActivity = (e) => {
    e.preventDefault();
    ActivityService.delete(selectedActivity).then((res) => {
      if (res.status == 204) {
        setActivityList(activityList.filter((i) => i.id !== selectedActivity.id))
      }
    })
    toggleDelete(e)
  }

  const showMenu = (e, a) => {
    selectedActivity = a;
    toggleMenu(e)
  }

  const routeActivity = (activity, url) => {
    props.history.push({
      pathname:`/${url}/${params.journey_profile_id}`,
      search:`id=${activity.id}&menu=3521`,
      state:journey_profile,
    })
  }
  
  let routeForm = (e, k) =>{
    e.preventDefault()
    props.history.push(`/form/baseline/${params.journey_profile_id}?activity_form_id=${k.child_form}&label=${k.label}`)
  }

  const menuItems = ({ item }) => {
    const title = item.data && item.data.name.replace(/\s/g, "_");
    const editAccess = CheckAccess.hasEditAccess(currentUser, journey_profile, item);

    return (
      <Fragment>
      
        {props.match.path.includes('track') && 
          <MenuItem id="add_act_icon" 
            link={`/step_activity/form/${params.journey_profile_id}/${item.id}?form_id=${FormTemplateId.ProjectMilestone}&title=${item.data.name}`}
            icon="far fa-sticky-note"
            action="Add Activity"/>
        }

        {editAccess &&
          <MenuItem id="share_icon" 
            link={{pathname:`/share/items/${params.journey_profile_id}/activity/${item.id}`, state:{journeyProfile:journey_profile, activity:item} }}
            icon="fas fa-user"
            action="Share Item"/>
        }

        {editAccess && (
          <MenuItem id="edit_icon" 
            link={`/form/baseline/${item.journey_profile_id}/${item.id}?edit=${editAccess}`}
            icon="far fa-edit"
            action="Edit"/>
        )}

        {CheckAccess.hasDeleteAccess(currentUser, journey_profile, item) && (
          <MenuItem id="delete_icon" 
            icon="far fa-trash-alt"
            action="Delete"
            onSelect={(e) => {toggleDelete(e)}}/>
        )}

        <MenuItem id="mail_icon" 
            link={`mailto:${title}_activity_${item.record_id}@cuetree.com`}
            icon="far fa-envelope"
            action={`${title}_activity_${item.record_id}@cuetree.com`}
          />

        <CardMenuInfo formId={item.form_id} recordId={item.record_id} 
          createdAt={item.created_at} userName={item.created_user.name}/>

      </Fragment>
    )
  }

  const Menu = ({ activity }) => (
    <i data-tip="Menu" 
      onClick={(e) => showMenu(e, activity)} 
      className="fas fa-bars m-r-15 font-18"/>
  )

  const ActivityCard = activityList && activityList.map((a, i) => {
    return(
      <SwiperSlide key={i}>
        <div className={cardGridCss}>
          <Activity queryParam={queryParam} activity={a} addComment={addComment}
            addChecklist={addChecklist} dataSource={data_source}
            menu={Menu} journeyProfile={journey_profile}
            cardHeightCss={cardHeightCss} routeActivity={routeActivity} 
            index={i}/>
            {selectedActivity.id === a.id && 
              <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenu}
                item={selectedActivity} type="activity"
                MenuItems={menuItems} menuHeight="200px"/>
            }
        </div>
      </SwiperSlide>
    )
  })

  const ActivityTrackCard = activityList && activityList.map((a, i) => {
    return(
      <div className={cardGridCss} key={i}>
        <TrackActivity queryParam={queryParam} activity={a} addComment={addComment}
          addChecklist={addChecklist} dataSource={data_source}
          menu={Menu} journeyProfile={journey_profile}
          cardHeightCss={cardHeightCss} routeActivity={routeActivity} 
          index={i}/>
          {selectedActivity.id == a.id && 
            <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenu}
              item={selectedActivity} type="activity"
              MenuItems={menuItems} />
          }
      </div>
    )
  })

  const comment = (
    <Fragment>
      {queryParam.id && activityList.length > 0 &&
        <SwiperSlide>
          <div className="col-xs-12">
            <CommentsModal isOpen="true" toggleModal={toggleComment} 
              item={activityList[0]}
              type="activity" showInline="true"/>
          </div>
        </SwiperSlide>
      }
    </Fragment>
  )

  if (queryParam.id && !loading && activityList.length == 0) {
    return (
      <NotificationPopup iconClass="fa fa-times-circle lred font-24"
        header="Not Found"
        message={HelpMessageList.new_activity}/>
    )
  }

  if(loading)
    return(<div className="spinner"/>)

  return (
    <Fragment>  
      <div className="content top-10 clearfix">
        <div className="col-xs-4">
          {journey_profile && <JourneyTitle title={props.match.path.includes('baseline') ? "Baseline":"Track"} project={journey_profile}/>}
        </div>
        
        {!queryParam.id && 
          <div className="col-xs-4 text-center">
            <span>{currentpage}</span>/{requestParams.totalPage} Pages
          </div>
        }
        
        <div className="col-xs-4 float-right">
          {props.match.path.includes('baseline') && 
            <NavIcon id="add_baseline" dataTip="Create New Activity" 
              onSelect={toggleActivityMenu}
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fas fa-plus"/>
          }
              
          <JourneyHome id="home_icon" journey_profile={journey_profile}
            link={`/journey/portal/${params.journey_profile_id}`}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home" dataTip="Back to Journey Home" />

          {queryParam.id  && 
            <NavIcon id="back_icon" dataTip="Back to Activity" 
              onSelect={e=>{props.history.goBack()}}
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fas fa-arrow-left"/>
          }

          <ReactTooltip place="left" />
        </div>
      </div>

      <div className="page-content row">
        {props.match.path.includes('baseline') && !queryParam.id &&
          <Fragment>
            {ActivityCard}
            {comment}
            <Paginations totalItems={requestParams.totalItems}
              currentpage={currentpage} setCurrentpage={setCurrentpage}
              itemsPerPage={requestParams.per_page}/>
          </Fragment>           
        }

        {props.match.path.includes('baseline') && queryParam.id &&
          <Fragment>
            {ActivityCard}
            <div className="col-sm-12 col-sm-6 col-md-4">
              { activityList.length > 0 && <CommentsModal isOpen="true" toggleModal={toggleComment} item={activityList[0]} type="activity" showInline="true"/> }
            </div>
          </Fragment>           
        }
        
        {props.match.path.includes('track') && !queryParam.id &&
          <Fragment>
            {ActivityTrackCard}
            {comment}
            <Paginations totalItems={requestParams.totalItems}
              currentpage={currentpage} setCurrentpage={setCurrentpage}
              itemsPerPage={requestParams.per_page}/>
          </Fragment>           
        }

        {props.match.path.includes('track') && queryParam.id && 
          <Fragment>
            {ActivityTrackCard}
            <div className="col-sm-12 col-sm-6 col-md-4">
              { activityList.length > 0 && <CommentsModal isOpen="true" toggleModal={toggleComment} item={activityList[0]} type="activity" showInline="true"/> }
            </div>
          </Fragment>}
      </div>

      {isCommentOpen && (
        <CommentsModal
          isOpen={isCommentOpen}
          toggleModal={toggleComment}
          item={selectedActivity}
          type="activity"/>
      )}

      {isChecklistOpen && (
        <ChecklistsModal
          isOpen={isChecklistOpen}
          toggleModal={toggleChecklist}
          item={selectedActivity}
          type="activity"/>
      )}
      
      {isActivityMenuOpen && (
        <ListMenuModal title={props.match.path.includes('baseline') ? "Record a Baseline" : "Gather Requirements" } 
          params={params} isOpen={isActivityMenuOpen} toggleModal={toggleActivityMenu} 
          dataSourceId={queryParam.menu} onSelect={routeForm}/>
      )}
      
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDelete} 
        success={deleteActivity}/>
      
      {isHelpMessageOpen && (
        <NotificationPopup header="Note" message={HelpMessageList.new_activity} 
          onSuccess={toggleHelpMessage} isOpen={isHelpMessageOpen} 
          toggleModal={toggleHelpMessage}/>
      )}
      
      <SideMenuBar helpId="2"/>
    </Fragment>
  )
}

export default ActivityList;