import React ,{Fragment,useState} from "react";
import moment from '../../../../node_modules/moment/min/moment.min.js'
//import Skills from '../../../components/ServiceProvider/List/Skills';
import Rating from "../../../components/Rating"
import {Link} from "react-router-dom";
import CommonService from '../../../services/CommonService';
import env from "../../../env"
import LottieLight from "../../Common/LottieLight"

const DigitalContent = (props) => {
  let digitalContent = props.digitalContent;
  let {data, data_source} = digitalContent;
  let socialContent =[]
  
  const [activeIndex, setActiveIndex] = useState();
  const [activeView, setActiveView] = useState();

  const cardStyle = {
    backgroundColor: "#607D8B"
  }

  const toggleView = (id) => {
    if(activeView == id){
      setActiveView(null);    
    }else{
      setActiveView(id);
    }
  }

  const RenderCard = ({label, details, id}) =>{
    return(
      <Fragment>
        <div className="step-activity" onClick={e=>toggleView(id)}>
          {label}
        </div>
        {activeView == id && 
          <div className="vcard-field p-5">
            <div dangerouslySetInnerHTML={{ __html: details }}  />
          </div>}
      </Fragment>
    )
  }

  const List = (list) => list && list.map((k,i) => (
    <span className="pill m-r-5" key={i}>{k.data.label}</span>
  ))

  const WelcomeTour = () =>{
    let title
    
    if(data.image){
     title = JSON.parse(data.image.area_io)
    }
  
    let titleCard = title && title.areas.map((c, k) =><li key={k}>{c.attributes.description}</li>)
    
    return(
      <div className="content shadow-small" >
        <div className="white card-header p-5" style={cardStyle}>
          <div className="font-16 ellipsis">
            {data.title || data.heading}
          </div>
          <div className="font-14">
            {CommonService.formatUpdateDate(digitalContent.created_at)}
          </div>
        </div>
        <div className="container ht-220 bg-white">
          <table className="table card-table">
            <tbody>
                <tr>
                  <td>
                    Web-Page: <span className="pill text-cap">
                    {digitalContent.content_type.replace(/_/g, ' ')}
                    </span>
                  </td>
                  <td>
                    <span className="pill">
                    {titleCard}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                  <span dangerouslySetInnerHTML={{ __html: data.notes}}></span>
                  </td>
                </tr>
            </tbody>
          </table>
         </div>
         <div className="card-footer">
          <div className="pull-left lgrey">
            <span className="m-l-15">
              {digitalContent.content_form_id} - {digitalContent.record_id}
            </span>
            <span id={`comment_${props.index}`} className="m-l-25"
              onClick={(e) => props.addNotes(e, digitalContent)}>
              <i className="far fa-comment font-18 text-muted"></i>
              <span className="badge up bg-lgrey">{digitalContent.comments_count || 0}</span>
            </span>
            
          </div>
          <div className="pull-right">
            <props.menu ja={digitalContent} />
          </div>
        </div>
      </div>
    )
  }

  const SocialContent = () =>{
    return(
      <div className="content-boxed shadow-small bg-rose" style={cardStyle}>
      <div className="content">
        <h3 className="vcard-title color-highlight bold">{data_source.category && data_source.category.label }</h3>
         
        <div className="vcard  clearfix">
          <div></div>
          <div className="pull-right">{CommonService.formatUpdateDate(data.created_at)}</div>
        </div> 

        <div className="vcard">
          <div>{data.journey}</div>
          <a href={data.url}>{data.url}</a>
        </div> 

        <div className="vcard scroll-x ht-220">
          {
            data.twitter_content &&  <RenderCard label="Twitter/In-App" details={data.twitter_content} id="twitter" />
          } 
          {
            data.facebook_content && <RenderCard label="Facebook" details={data.facebook_content} id="facebook"/>
          } 
          {
            data.instagram_content && <RenderCard label="Instagram" details={data.instagram_content} id="instagram"/>
          } 
          {
            data.linkedin_content &&  <RenderCard label="LinkedIn" details={data.linkedin_content} id="linkedin"/> }
          {
            data.website_content && <RenderCard label="Website" details={data.website_content} id="website"/>
          }
        </div> 

        <div className="m-t-10" style={{'height':'20px'}}>
          <props.details ja={digitalContent} />
          <span className="m-l-25" onClick={e=>props.addNotes(e, digitalContent)}>
          <i className="far fa-comment font-18 text-muted"></i>
          <span className="badge up bg-lgrey">0</span>
        </span>
          <props.menu ja={digitalContent} />
        </div>

      </div> 
    </div>
    )
  }

  const MarketingContent = () =>{
    let title
    let webPage

    if(digitalContent.content_form_id == 462){
      webPage = 'Cue Tips'
    }else if(digitalContent.content_form_id == 461){
      webPage = 'Marketing Image'
    }else{
      webPage = ''
    }
    
    if(data.image){
     title = JSON.parse(data.image.area_io)
    }
  
    
    return(
      <div className="content shadow-small" >
        <div className="white card-header p-5" style={cardStyle}>
          <div className="font-16 ellipsis">
            {data.title || data.heading}
          </div>
          <div className="font-14">
            {CommonService.formatUpdateDate(digitalContent.created_at)} 
          </div>
        </div>
        <div className="container ht-220 bg-white">
          <table className="table card-table">
            <tbody>
                <tr>
                  <td>
                    Web-Page:  <span className="pill text-cap">{webPage}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="">
                      {List(digitalContent.data_source.user_type)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="font-12" dangerouslySetInnerHTML={{ __html: data.details }}></p>
                    {data.heading_1 && <li>{data.heading_1}</li>}
                    {data.heading_2 && <li>{data.heading_2}</li>}
                    {data.heading_3 && <li>{data.heading_3}</li>}
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer">
          <div className="pull-left lgrey">
            <span className="m-l-15">
              {digitalContent.content_form_id} - {digitalContent.record_id}
            </span>
            <span id={`comment_${props.index}`} className="m-l-25"
              onClick={(e) => props.addNotes(e, digitalContent)}>
              <i className="far fa-comment font-18 text-muted"></i>
              <span className="badge up bg-lgrey">{digitalContent.comments_count || 0}</span>
            </span>
            
          </div>
          <div className="pull-right">
            <props.menu ja={digitalContent} />
          </div>
        </div>
      </div>
    )
  }

  const ServiceContent = () =>{ 
    let webPage
    if(digitalContent.content_form_id == 456){
      webPage = 'Terms of Service'
    }else if(digitalContent.content_form_id == 455){
      webPage = 'Privacy Policy'
    }else{
      webPage = ''
    }

    let arr = Object.keys(digitalContent.data).map((k) => digitalContent.data[k])
   
    return(
      <div className="content shadow-small" >
        <div className="white card-header p-5" style={cardStyle}>
          <div className="font-16 ellipsis">
            {data.title}
          </div>
          <div className="font-14">
            {CommonService.formatUpdateDate(digitalContent.created_at)} 
          </div>
        </div>
        <div className="container ht-220 bg-white">
          <table className="table card-table">
            <tbody>
                <tr>
                  <td>
                   Web-Page: <span className="pill text-cap">{webPage}</span>
                  </td>
                  <td>
                  </td>
                </tr>
                <tr>
                  <td>
                  <span dangerouslySetInnerHTML={{ __html: data.details}}></span>
                  </td>
                </tr>
            </tbody>
          </table>
         </div>
         <div className="card-footer">
          <div className="pull-left lgrey">
            <span className="m-l-15">
              {digitalContent.content_form_id} - {digitalContent.record_id}
            </span>
            <span id={`comment_${props.index}`} className="m-l-25"
              onClick={(e) => props.addNotes(e, digitalContent)}>
              <i className="far fa-comment font-18 text-muted"></i>
              <span className="badge up bg-lgrey">{digitalContent.comments_count || 0}</span>
            </span>
            
          </div>
          <div className="pull-right">
            <props.menu ja={digitalContent} />
          </div>
        </div>
      </div>
    )
  }



  const DataContent = () =>{ 
    let webPage
    if(digitalContent.content_form_id == 464){
      webPage = 'Subscription Terms'
    }else if(digitalContent.content_form_id == 458){
      webPage = 'Whats New'
    }else{
      webPage = ''
    }

    let arr = Object.keys(digitalContent.data).map((k) => digitalContent.data[k])
   
    return(
      <div className="content shadow-small" >
      <div className="white card-header p-5" style={cardStyle}>
        <div className="font-16 ellipsis">
          {data.title}
        </div>
        <div className="font-14">
          {CommonService.formatUpdateDate(digitalContent.created_at)} 
        </div>
      </div>
      <div className="container ht-220 bg-white">
        <table className="table card-table">
          <tbody>
              <tr>
                <td>
                  Web-Page:
                  <span className="pill">
                  {webPage}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                <span dangerouslySetInnerHTML={{ __html: data.details}}></span>
                </td>
              </tr>
          </tbody>
        </table>
       </div>
       <div className="card-footer">
        <div className="pull-left lgrey">
          <span className="m-l-15">
            {digitalContent.content_form_id} - {digitalContent.record_id}
          </span>
          <span id={`comment_${props.index}`} className="m-l-25"
            onClick={(e) => props.addNotes(e, digitalContent)}>
            <i className="far fa-comment font-18 text-muted"></i>
            <span className="badge up bg-lgrey">{digitalContent.comments_count || 0}</span>
          </span>
        </div>
        <div className="pull-right">
          <props.menu ja={digitalContent} />
        </div>
      </div>
    </div>
    )
  }

  const GenericContent = () =>(
    <div className="content shadow-small" >
      <div className="white card-header p-5" style={cardStyle}>
        <div className="font-16 ellipsis">
          {data.title}
        </div>
        <div className="font-14">
          {CommonService.formatUpdateDate(digitalContent.created_at)} 
        </div>
      </div>
      <div className="container ht-220 bg-white">
        <table className="table card-table">
          <tbody>
              <tr>
                <td>
                </td>
                <td>
                </td>
              </tr>
              <tr>
                <td>
                <span dangerouslySetInnerHTML={{ __html: data.details}}></span>
                </td>
              </tr>
          </tbody>
        </table>
      </div>
      <div className="card-footer">
        <div className="pull-left lgrey">
          <span className="m-l-15">
            {digitalContent.content_form_id} - {digitalContent.record_id}
          </span>
          <span id={`comment_${props.index}`} className="m-l-25"
            onClick={(e) => props.addNotes(e, digitalContent)}>
            <i className="far fa-comment font-18 text-muted"></i>
            <span className="badge up bg-lgrey">{digitalContent.comments_count || 0}</span>
          </span>
          <span id={`check_list_${props.index}`} className="m-l-25"
            onClick={(e) => props.addChecklist(e, digitalContent)}>
            <i className="far fa-check-circle font-18 text-muted"></i>
            <span className="badge up bg-lgrey">{digitalContent.check_list_count || 0}</span>
          </span>
        </div>
        <div className="pull-right">
          <props.menu ja={digitalContent} />
        </div>
      </div>
    </div>
  )

  const InAppContent = () =>{
    let webPage ="In App Notification"

    return(
    <div className="content shadow-small" >
        <div className="white card-header p-5" style={cardStyle}>
          <div className="font-16 ellipsis">
            {data.title}
          </div>
          <div className="font-14">
            {CommonService.formatUpdateDate(digitalContent.created_at)} 
          </div>
        </div>
        <div className="container ht-220 bg-white">
          <table className="table card-table">
            <tbody>
                <tr>
                  <td>
                  Web-Page:
                  <span className="pill">
                  {webPage}
                  </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    Screens:
                    <span>{List(digitalContent.data_source.in_app_page)}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                  <span dangerouslySetInnerHTML={{ __html: data.details}}></span>
                  </td>
                </tr>
            </tbody>
          </table>
         </div>
         <div className="card-footer">
          <div className="pull-left lgrey">
            <span className="m-l-15">
              {digitalContent.content_form_id} - {digitalContent.record_id}
            </span>
            <span id={`comment_${props.index}`} className="m-l-25"
              onClick={(e) => props.addNotes(e, digitalContent)}>
              <i className="far fa-comment font-18 text-muted"></i>
              <span className="badge up bg-lgrey">{digitalContent.comments_count || 0}</span>
            </span>
            
          </div>
          <div className="pull-right">
            <props.menu ja={digitalContent} />
          </div>
        </div>
      </div>
    )
  }
  if(!data)return null;

  if(digitalContent.content_form_id == 463){
    return <SocialContent/>
  }else if(digitalContent.content_form_id == 462 || digitalContent.content_form_id == 461){
    return <MarketingContent/>
  }else if(digitalContent.content_form_id == 459){
    return <WelcomeTour/>
  }else if(digitalContent.content_form_id == 455 || digitalContent.content_form_id == 456){
    return <ServiceContent />
  }else if(digitalContent.content_form_id == 464 || digitalContent.content_form_id == 458){
    return <DataContent/>
  }else if(digitalContent.content_form_id == 457){
    return <InAppContent />
  }else{
    return <GenericContent/>
  }
  
}

export default DigitalContent;