import React, { Fragment, useState, useEffect, useContext, useCallback } from "react";
import { useHistory, useLocation, Prompt } from "react-router-dom";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import CommonService from "../../services/CommonService";
import InputTextField from "../../components/FormBuilder/InputComponents/InputTextField";
import InputTextArea from "../../components/FormBuilder/InputComponents/InputTextArea";
import InputSelect from "../../components/FormBuilder/FormWizardComponents/InputSelect";
import InputRadio from "../../components/FormBuilder/InputComponents/InputRadio";
import InputCheckbox from "../../components/FormBuilder/InputComponents/InputCheckbox";
import InputDataTimePicker from "../../components/FormBuilder/InputComponents/InputDataTimePicker";
import InputColorPicker from "../../components/FormBuilder/InputComponents/InputColorPicker";
import InputMultiSelect from "../../components/FormBuilder/FormWizardComponents/InputMultiSelect";
import InputMultiFileUpload from "../../components/FormBuilder/InputComponents/InputMultiFileUpload";
import InputFileUpload from "../../components/FormBuilder/InputComponents/InputFileUpload";
import InputMultiRating from "../../components/FormBuilder/InputComponents/InputMultiRating";
import InputRating from "../../components/FormBuilder/InputComponents/InputRating";
import InputTags from "../../components/FormBuilder/InputComponents/InputTags";
import InputRichText from "../../components/FormBuilder/InputComponents/InputRichText";
import InputExplanatoryText from "../../components/FormBuilder/InputComponents/InputExplanatoryText";
//import InputMapLocation from '../../components/FormBuilder/InputComponents/InputMapLocation'
import InputSlider from "../../components/FormBuilder/InputComponents/InputSlider";
import InputAutoComplete from "../../components/FormBuilder/InputComponents/InputAutoComplete";
import InputSignaturePad from "../../components/FormBuilder/InputComponents/InputSignaturePad";
import InputMultiSelectSearch from '../../components/FormBuilder/InputComponents/InputMultiSelectSearch'
import FormHelper from "../../components/FormBuilder/FormHelpers";
import FormSubmit from "../../components/FormBuilder/FormSubmit";
import NotificationPopup from "../Common/NotificationPopup";
import useStyle from "../../hooks/useStyle";
import querystringify from "querystringify"

let errors = { invalid: {} }
let templateFields = []
let post = {}
let currentIndex = 0;
let successDataSourceField = {}
let progressPercentage = 0;

const FormWizard = (props) => {
  useStyle("filter_dialog")

  const {state: { screen, user: currentUser }, dispatch, } = useContext(AuthContext)
  const [count, setCount] = useState(0)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [formTemplate, setFormTemplate] = useState({})
  const [showFormFields, setShowFormFields] = useState(true)

  let location = useLocation()
  let queryParam = querystringify.parse(location.search)

  const forceUpdate = () => {
    setCount((value) => ++value)
  }

  useEffect(() => {
    if (props.form_wizard_schedule_id || queryParam.unfilled_client_id) {
      getFormWizardSchedules()
    } else if (props.form_wizard_id) {
      getFormWizardFields()
    }else if (props.assessment_id) {
      getFormAssessmentFields()
    }else if(props.user_form_id){
      getUserForm()
    }
    return () => {
      errors = { invalid: {} }
      successDataSourceField = {}
      templateFields = []
      post = {}
      currentIndex = 0;
	    progressPercentage = 0;

      window.removeEventListener("contextmenu", contextmenu);

    }
  }, [])

  const contextmenu = useCallback((e) => {
    e.preventDefault()
  }, [])

  const onSubmit = (draft) => {
    props.form.updated_by = currentUser.id;
    props.form.draft = draft ? true : false;
    props.form.data = props.data;

    if(props.confirmSubmissionAlert){
      confirmSubmission()
      return
    }

    save()
    //setIsFormSubmitted(true)
  }

  const confirmSubmission = () =>{
    let propmt = `You are now on the last item. 
        If you click “OK,” your responses will be submitted. 
        If you want to review your responses before submitting, click “Cancel” now.`

    var r = window.confirm(propmt)
    if (r == true) {
      save()
    }
  }

  const save = () =>{
    if (!props.form.id) {
      props.form.created_by = currentUser.id;
      props.onCreate(props.form)
    } else {
      props.onUpdate(props.form)
    }
  }

  const getFormWizardFields = function () {
    let req = { form_wizard_id: props.form_wizard_id }
    CommonService.getFormWizardFields(req).then((res) => {
      templateFields = res.data.form_wizard_fields;
      post = res.data.post;
      let template = res.data.form_template;
      if (template.submit_action && template.submit_action.success_msg_field) {
        successDataSourceField.success_msg_field =
          template.submit_action.success_msg_field;
      }
      setFormTemplate(template)
      init()
      calFormCompletePercentage()
    })
  }

  const getFormWizardSchedules = function () {
    let req = {
      form_template_id: queryParam.form_template_id,
      unfilled_client_id: queryParam.unfilled_client_id,
      empty_field_params: queryParam.item_type?{item_id:queryParam.item_id, item_type:queryParam.item_type}:null
    }
    
    CommonService.getFormWizardSchedules(props.form_wizard_schedule_id, req).then(
      (res) => {
        templateFields = res.data.form_wizard_scheduler;
        post = res.data.post;
        init()
        calFormCompletePercentage()
      }
    )
  }

  const getFormAssessmentFields = function () {
    let req = { assessment_id: props.assessment_id, journey_category_id: props.journey_category_id }
    CommonService.getFormAssessmentFields(req).then((res) => {
      templateFields = res.form_wizard_fields;
      post = res.post;
      post.unselectCss = post?.browser_controls?.includes(1)?'unselectable':''

      if(post?.browser_controls?.includes(2)){
        window.addEventListener('contextmenu', contextmenu, false)
      }

      let template = res.form_template;
      setFormTemplate(template)
      init()
      calFormCompletePercentage()
    })
  }

  const getUserForm = function(){
    let req = { id: props.user_form_id, is_form_wizard:true }
    CommonService.getUserForm(req).then((res) => {
      templateFields = res.form_wizard_fields;
      post = res.post;
      let template = res.form_template;
      setFormTemplate(template)
      init()
      calFormCompletePercentage()
    })
  }

  const init = () => {
    if (templateFields.length == 0) return null;
    let form_template_id = templateFields[0].id;
    let template = { user_id: templateFields[0].user_id }
    templateFields.push({ id: form_template_id, component: "submit" })
    forceUpdate()
  }

  const next = (i) => {
    let current_component = templateFields[currentIndex]
    if (errors.invalid[current_component.client_id]) {
      errors.isSubmitted = true;
      forceUpdate()
      return;
    }

    errors.isSubmitted = false;

    let next_index = currentIndex + i;
    let next_component = templateFields[next_index]

    if (next_component.component == "submit") {
      onSubmit(true)
      return;
    }

    if (next_component.visibility_logic) {
      let r = FormHelper.checkFieldConditionality(next_component, props.data)
      currentIndex = next_index;
      if (r) {
        //Show the field
        forceUpdate()
      } else {
        //Check show hide for next field
        next(1)
      }
    } else {
      //Show the field
      currentIndex = next_index;
      forceUpdate()
    }
    calFormCompletePercentage()
  }

  const back = (i) => {
    if (currentIndex != 0) {
      let next_index = currentIndex - i;
      let next_component = templateFields[next_index]

      if (next_component.visibility_logic) {
        let r = FormHelper.checkFieldConditionality(next_component, props.data)
        currentIndex = next_index;
        if (r) {
          //Show the field
          forceUpdate()
        } else {
          //Check show hide for next field
          back(1)
        }
      } else {
        //Show the field
        currentIndex = next_index;
        forceUpdate()
      }
    }
    calFormCompletePercentage()
  }

  const position = (i) =>{
    currentIndex = i
    forceUpdate()  
  }

  const calFormCompletePercentage = () => {
    progressPercentage = ((currentIndex/(templateFields.length-2)) * 100)
    console.log(progressPercentage)
  }

  props.formFn.refreshFormSubmit = () => {
    setCount((value) => ++value)
  }

  props.formFn.setIsFormSubmitted = () => {
    setIsFormSubmitted(true)
  }

  const ChildElementList = () => {
    let field = templateFields[currentIndex]

    if (field.accesses && field.accesses.roles) {
      let r = FormHelper.checkFieldConditionality(field, props.data)
      if (!r) {
        field.component = "default";
      }
    }

    if (field.required)
      FormHelper.checkFieldValidity(field, props.data, errors, 1)

    if (successDataSourceField.success_msg_field == field.client_id) {
      successDataSourceField = field;
    }

    let fieldProps = {
      field: field,
      formData: props.data,
      formFn: props.formFn,
      readOnly: false,
      isFormWizard: true,
      position: currentIndex,
    }

    const validationProps = {
      forceUpdate: forceUpdate,
      errors: errors,
    }

    field.placeholder = field.cue_prompt_description;

    switch (field.component) {
      case "textInput":
        FormHelper.onChangeExp(field, props.data)
        return <InputTextField {...fieldProps} {...validationProps} />;
        break;
      case "textArea":
        return <InputTextArea {...fieldProps} {...validationProps} />;
        break;
      case "timepicker":
        return <InputDataTimePicker {...fieldProps} {...validationProps} />;
        break;
      case "datepicker":
        return <InputDataTimePicker {...fieldProps} {...validationProps} />;
        break;
      case "checkbox":
        return <InputCheckbox {...fieldProps} {...validationProps} />;
        break;
      case "radio":
        return <InputSelect {...fieldProps} {...validationProps} />;
        break;
      case "select":
        return <InputSelect {...fieldProps} {...validationProps} />;
        break;
      case "multi_select":
        if(field.is_dynamic_data){
          return <InputMultiSelectSearch {...fieldProps} {...validationProps} />  
        }

        return <InputMultiSelect {...fieldProps} {...validationProps} />;
        break;
      case "slider":
        return <InputSlider {...fieldProps} {...validationProps} />;
        break;
      case "autocomplete":
        return <InputAutoComplete {...fieldProps} {...validationProps} initDataLoad={true}/>;
        break;
      case "imageupload":
        return <InputFileUpload {...fieldProps} {...validationProps} />;
        break;
      case "multi_file_upload":
        return <InputFileUpload {...fieldProps} {...validationProps} />;
        break;
      /*return <InputMultiFileUpload {...fieldProps} {...validationProps} />*/
      case "richtext":
        return <InputRichText {...fieldProps} {...validationProps} />;
        break;
      case "explanatory_text":
        return <InputExplanatoryText {...fieldProps} {...validationProps} />;
        break;
      case "color_picker":
        return <InputColorPicker {...fieldProps} {...validationProps} />;
        break;
      case "rating":
        if (field.data_source_id) {
          return <InputMultiRating {...fieldProps} {...validationProps} />;
        } else {
          return <InputRating {...fieldProps} {...validationProps} />;
        }
        break;
      case "input_tags":
        return <InputTags {...fieldProps} {...validationProps} />;
        break;
      case "signature":
        return <InputSignaturePad {...fieldProps} {...validationProps} />;
        break;
      default:
        return null;
        break;
    }
  }

  if (templateFields.length == 0) {
    return null;
  }

  if (isFormSubmitted) {
    try {
      let submitMessage = "";
      if(props.formFn.successMessage){
        submitMessage = props.formFn.successMessage
      }else if (successDataSourceField.options_key_value) {
        let opkv = successDataSourceField.options_key_value.options;
        let selectedValue = props.data[successDataSourceField.client_id]
        selectedValue = selectedValue.toString()
        selectedValue = selectedValue.split(",").map(Number)
        opkv.map((o) => {
          if (selectedValue.includes(o.value)) submitMessage += o.label;
        })
      } else {
        submitMessage =
          formTemplate &&
          formTemplate.submit_action &&
          formTemplate.submit_action.success_message;
      }
      return (
        <NotificationPopup message={submitMessage} onSuccess={props.onCancel} />
      )
    } catch (e) {
      props.onCancel()
    }
  }

  const FieldList = () =>  templateFields.map((m, i) => {
    return(
      <Fragment key={i}>
        {m.component != 'submit' && <Fragment>
            <div className={currentIndex == i ? 'element-form p-10 bg-ltint highlight' : 'element-form p-10'} onClick={e=>{position(i);setShowFormFields(false)}}>
              <div className="p-2 font-17" dangerouslySetInnerHTML={{ __html: m.label }}></div>
              <p className="lgrey rich-text-img r-img-200" dangerouslySetInnerHTML={{ __html: m.description }} />
            </div>
          </Fragment>
        }
      </Fragment>  
    )
  })

  let questionListMenuStyle = {
    left: showFormFields?'0px':'-150px', 
    width: showFormFields?'unset':'150px',
    height: showFormFields?'100%':'unset'
  }

  return (
    <Fragment>
    
    {props.showFieldMenu && <Fragment>
      <div className="m-l-25 badge bg-highlight" onClick={e=>setShowFormFields(true)}>Preview Questions</div>
      <div className={`side-menu-bar field-menu bg-white black br-grey zIndex-99 ${post.unselectCss}`} style={questionListMenuStyle}>
        <div className="element-form" onClick={(e) => setShowFormFields(!showFormFields)}>
          <span className="m-r-10">{(templateFields && templateFields.length - 1)} Questions</span>
          <i className={'pull-right fas '+(showFormFields?'fa-times red':'')}></i>
        </div>
        <div className="scroll-auto">
          {showFormFields && <FieldList/>}
        </div>
      </div></Fragment>
    }
      
    <div>
		<div className={`col-xs-12 ${!props.isInsideChatBot && 'col-sm-8 push-20'} m-b-120 m-t-40 ${post.unselectCss}`}>
		<div className={`${!props.isInsideChatBot && 'portlet top-0'}  bg-white br-grey`}>
			<div className={`${!props.isInsideChatBot && 'portlet top-0'}`}>
      <div className="p-5 m-l-5">
				<div className="text-uppercase font-16 bold-600 p-5">{post.title}</div>
				  <div className="progress-bar m-t-5">
				  <div className="active" style={{'width':progressPercentage+'%'}}></div>
				</div>
			</div>
			<div className="clearfix p-5">
				<ChildElementList />
				<div className="clerfix"/>
				<div className="col-xs-12 zIndex-99 m-t-25">
				{ currentIndex != 0 ? <button type="button" onClick={(e) => {back(1)}} className="btn btn-rounded w-l-btn">
					<i className="fa fa-chevron-left white" aria-hidden="true"></i>
				</button>:''}
        <div className="font-14 black-light-1 text-center">Item {currentIndex + 1} of {templateFields.length-1}</div>
				{<button type="button bg-highlight" onClick={(e) => {next(1)}} className="btn btn-rounded w-r-btn">
					<i className="fa fa-chevron-right white" aria-hidden="true"></i>
				</button>}
				</div>
        <span className="lgrey"> {props.form_wizard_id}</span>
			</div>
			</div>
		</div>
		</div>
	  </div>
	  </Fragment>
  )
}

export default FormWizard;

/*
{ !isFormSubmitted && <Prompt message="Are you sure you want to leave?"/>}
*/