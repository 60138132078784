import React, {useState, Fragment} from "react";
import { Link, useHistory } from "react-router-dom";
import ProjectMilestoneService from "../../../components/Organizations/ProjectMilestones/ProjectMilestoneService";
import CommonService from '../../../services/CommonService';
import useModal from "../../../hooks/useModal";
import CommentsModal from "../../Modals/CommentsModal";
import ProjectMenu from "../../Modals/ProjectMenu"
import FormTemplateList from "../../../constants/FormTemplateList"

let selectedMileStone = {};

const MilestoneGrid = (props) => {
  const history = useHistory()
  let contract = props.contract;
  let data = contract.data;
  let data_source = contract.data_source;
  
  const [milestones, setMilestones] = useState(props.milestones.rows || []);
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal, success:commentSuccess } = useModal();
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();

  const deleteMilestone = (e, milestone) => {
    ProjectMilestoneService.delete(milestone).then((res)=>{
      if(res.status==204){
        setMilestones(milestones.filter(i => i.id !== milestone.id))
      }
    })
  }

  const openMilestoneStatusForm = (e, milestone) =>{
    history.push(`/milestone/status/${milestone.organization_id}/${props.project.id}/${contract.id}/${milestone.id}/${milestone.milestone_status && milestone.milestone_status.id}`)
  }

  const openMilestoneVerificationForm = (e, milestone) =>{
    history.push(`/milestone/verifications/${milestone.organization_id}/${props.project.id}/${contract.id}/${milestone.id}/${milestone.milestone_verification && milestone.milestone_verification.id}`)
  }

  const openMenu = (e, milestone) => {
    selectedMileStone = milestone;
    toggleMenuModal(e)    
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <Link to={`/project/milestone/form/${props.project.organization_id}/${contract.project_id}/${contract.id}/${item.id}`}>
          <i className="far fa-edit font-15" aria-hidden="true"></i>
          <span className="font-13">Edit</span>
          <i className="fa fa-angle-right"></i>
        </Link>

        <a href="#" onClick={e=>deleteMilestone(e, item)}>
          <i className="far fa-trash-alt font-15" aria-hidden="true"></i>
          <span className="font-13">Delete</span>
          <i className="fa fa-angle-right"></i>
        </a>

        <Link to={`/milestone/task/form?project_id=${props.project.id}&organization_id=${props.project.organization_id}&service_provider_id=${item.service_provider_id}&contract_id=${contract.id}&milestone_id=${item.id}`}>
          <i className="far fa-sticky-note font-15" aria-hidden="true"></i>
          <span className="font-13">Add Milestone Task</span>
          <i className="fa fa-angle-right"></i>
        </Link>
      </Fragment>
    )
  }

  const Milestone = ({milestone}) => {
    let style = {'backgroundColor':(milestone.data_source.status && milestone.data_source.status.bg_color)}
    return (
    <div className="card-container bg-white shadow-small">
      <div className="white card-header p-5 bg-lgrey" style={style}>
        <div className="font-16">
          Milestone {milestone.data.number}
        </div>
        <div>
          {milestone.updated_at}
        </div> 
        <Link to={`/milestone/tasks/${milestone.id}`} className="pull-right white link">
            <i className="fas fa-share"></i>
          </Link>
      </div>
      <div className="scroll-auto">
        <table className="table card-table">
          <tbody>
              <tr>
                <td>
                  <span className="pull-right">{milestone.data.milestone_description}</span>
                  <strong>Notes</strong>
                  <div>{milestone.data.notes}</div>
                </td>
              </tr>
              <tr>
                <td>
                  {props.page=='milestone' && <span className="milestone-btn" onClick={e=>openMilestoneStatusForm(e, milestone)}>VENDOR MILESTONE REPORT</span>}
                  {props.page=='milestone' && <span className="milestone-btn" onClick={e=>openMilestoneVerificationForm(e, milestone)}>COMPANY VERIFICATION</span>}
                </td>
              </tr>
            </tbody>
        </table>
      </div>
      <div className="card-footer">
        <div className="pull-left">
          <span className="m-l-15">{FormTemplateList.ProjectMilestone}-{milestone.record_id}</span>
        </div>
        <div className="pull-right">
          <span className="m-r-15" >
            <i onClick={e=>openMenu(e, milestone)} class="fas fa-bars" />     
          </span>
        </div>
      </div>
      {selectedMileStone.id == milestone.id &&  
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} MenuItems={menuItems} item={milestone}/>
      }
    </div>
    )
  }

  const milestoneList = milestones.map((m, i) => (
    <Milestone milestone={m}/>
  ))

  return (
    <div className="col-xs-12 m-t-5">
      <h5>Milestones Details</h5>
      {milestoneList}  
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedMileStone} type="milestone"/>}
    </div>
  )

};

export default MilestoneGrid;

/*<div className="widget-panel-3 col-xs-12 col-sm-4" key={i} style={{'backgroundColor':(m.data_source.status && m.data_source.status.bg_color)}}>
          <h5>
            <Link className="white" to={`/project/milestone/form/${props.project.organization_id}/${contract.project_id}/${contract.id}/${m.id}`}>
              Milestone {m.data.number} </Link>
          </h5>
          <div>{m.data.milestone_description}</div>
          <div className="font-12">{m.data.start_date} &emsp; {m.data.end_date}</div>
          <div className="font-12"><label>Notes</label>: {m.data.notes}</div>
          
          {props.page=='milestone' && <span className="milestone-btn" onClick={e=>openMilestoneStatusForm(e, m)}>VENDOR MILESTONE REPORT</span>}
          {props.page=='milestone' && <span className="milestone-btn" onClick={e=>openMilestoneVerificationForm(e, m)}>COMPANY VERIFICATION</span>}

          {props.page=='contract' &&
            <Fragment> 
              <Link className="r-40" to={`/project/milestone/form/${props.project.organization_id}/${contract.project_id}/${contract.id}/${m.id}`}>
                <i className="far fa-edit" aria-hidden="true"></i>
              </Link>

              <a onClick={e => {deleteMilestone(e, m)}}>
                <i className="far fa-trash-alt" aria-hidden="true"></i>
              </a>

              <a className="r-80" onClick={e => {selectedMileStone = m;toggleCommentModal(e, m)}}>
                <i className="far fa-comment" aria-hidden="true"></i>
              </a>
            </Fragment>
          }
        </div>*/
