import env from '../../../env';
import axios from 'axios';
const url = `${env.ecn_backend_api}milestone/tasks`

class MilestoneTaskService {
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}`, {params:req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(task){
    return new Promise((resolve, reject) => {
      axios.post(`${url}`, task).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(task){
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${task.id}`, task).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(task) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${task.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

}

export default new MilestoneTaskService();