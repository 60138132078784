import React, {Fragment,useEffect,useState} from "react";
import {Link} from "react-router-dom";
import moment from '../../../../node_modules/moment/min/moment.min.js'
import Rating from "../../../components/Rating"

export const Marketing = (props) => {
  console.log('marketing props',props);
  let market = props.marketing;
  let data = market.data;
  const [profiles, setSkillProfile] = useState(false);


  useEffect(() => {


},[])

  return (
    <div>
      <div className="clearfix">
      <div className="content-boxed shadow-small" >
      <div className="content">
        <h1 className="vcard-title color-highlight bold">
          {data.heading}
        </h1>

        <div className="vcard">
          <div dangerouslySetInnerHTML = {{ __html: data.notes }}></div>
          </div>
        
        <div className="vcard-field">
        <p>{data.question}</p>
        <div className="vcard">
          <img src={data.image}/>
        </div>
        </div>
      
        <div className="vcard">
        <button type="button" className="btn btn-inverse btn-rounded pull-left">{data.button_text}</button>

        </div>
      </div>

    </div>
      </div>
      </div>
  )

};

export default Marketing;
/*
<button type="button" onClick={(e) => {props.toggleModal(e,org)}} className="btn btn-inverse btn-rounded pull-left">Delete</button>
*/