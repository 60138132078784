import env from '../../env';
import axios from 'axios';
const credential_url = `${env.ecn_backend_api}zoom/credential`;
const url = `${env.ecn_backend_api}zoom/`;

class ZoomMeetingService {
  
  get_credential(req){
    return new Promise((resolve, reject) => {
      axios.get(`${credential_url}/${req.id}`, { params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create_credential(req){
    return new Promise((resolve, reject) => {
      axios.post(credential_url,req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update_credential(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${credential_url}/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete_credential(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${credential_url}/${id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get_meetings(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}meetings`, { params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get_meeting(id){
    return new Promise((resolve, reject) => {
      axios.get(`${url}meeting/${id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create_meeting(req){
    return new Promise((resolve, reject) => {
      axios.post(`${url}meeting`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update_meeting(req){
    return new Promise((resolve, reject) => {
      axios.put(`${url}meeting/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete_meeting(id){
    return new Promise((resolve, reject) => {
      axios.delete(`${url}meeting/${id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new ZoomMeetingService();