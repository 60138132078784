import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import moment from '../../../../../node_modules/moment/min/moment.min.js'
import Rating from "../../../../components/Rating"
import useStyle from '../../../../hooks/useStyle';
import useSort from '../../../../hooks/useSorting';
import SortDirection from '../../../../components/Common/SortDirection'

export const OrganizationsTable = ({organizationList, getOrganizationList, requestParams, addMenu}) => {
  useStyle('table');  
  const {sort} = useSort(requestParams, getOrganizationList);

  const tableData = organizationList.map((o, k) =>
    <tr key={k}>
      <td data-title="Id">{o.record_id}</td>
      <td data-title="Company">{o.data.name}</td>
      <td data-title="Industry">{o.data_source.industry && o.data_source.industry.label}</td>
      <td data-title="Office">
        {o.data.street_1}<br/>{o.data.street_2}<br/>{o.data.city}<br/> {o.data.state}
      </td>
      <td data-title="Personal">
        <a href="{o.data.mobile}">{o.data.principal_contact}</a>
      </td>
      <td data-title="Mobile">
        <a href="tel:{o.data.telephone}">{o.data.telephone}</a>
      </td>
      <td>
        <button className="dropbtn" onClick={e=>{addMenu(e, o)}}>Menu</button>
      </td>
    </tr>
  )

  return (
    <div id="no-more-tables" className="col-xs-12 table-responsive">
      <table className="shadow-small">
        <thead>
          <tr className="white bg-highlight">
            <th onClick={e=>sort('record_id')}>Id 
              <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('data.name')}>Company 
              <SortDirection sort_column={requestParams.sort_column} column="data.name" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.industry')}>Industry 
              <SortDirection sort_column={requestParams.sort_column} column="data.industry" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.street_1')}>Office 
              <SortDirection sort_column={requestParams.sort_column} column="data.street_1" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.mobile')}>Personal 
              <SortDirection sort_column={requestParams.sort_column} column="data.mobile" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.telephone')}>Mobile 
              <SortDirection sort_column={requestParams.sort_column} column="data.telephone" reverse={requestParams.reverse}/>
            </th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </table>
    </div>
  )

};

export default OrganizationsTable;