import React, {useState, Fragment, useEffect, useContext} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import User from './User'
import UserGroupService from "./UserGroupService"
import ConfirmMenu from '../Modals/ConfirmMenu'
import GenericModal from '../Modals/GenericModal'
import {Link} from "react-router-dom"
import useModal from '../../hooks/useModal'
import useStyle from '../../hooks/useStyle'
import {NavIcon,CardMenuInfo,MenuItem} from '../../components/Common/MenuItem'
import ProjectMenu from "../../components/Modals/ProjectMenu"
import Cs from "../../services/CommonService"
import Pagination from "../Pagination"
import SideMenuBar from "../../components/Common/SideMenuBar"
import UserForm from "./UserForm"
import AddUser from "./AddUser"

let requestParams = {}
let selectedUser = {};
let data_source = {};

const UserList = (props) => {
  useStyle('card')  
  useStyle('card_table')
  useStyle('add_role_dialog')

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [users, setUsers] = useState([])
  const [currentpage, setCurrentpage] = useState(1)

  const { isOpen, toggleModal: toggleDeleteModal } = useModal()
  const { isOpen: isUserModalOpen, toggleModal: toggleUserModal } = useModal()
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();

  useEffect(() => {  
    requestParams = {
      'page': currentpage,
      'per_page': 30,
      'sort_column': 'updated_at',
      'sort_direction': 'desc',
      'data_source_params': {"data_source_id":[3293]}
      //'accelerator_id':currentUser.user.accelerator.id
    }

    getUserGroupList()
  }, [props.searchString, currentpage])

  let getUserGroupList = () => { 
    UserGroupService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        data_source = res.data.data_source_list;
        requestParams.totalItems = res.data.meta.total;
        setUsers(res.data.user_groups)
      }
    })
  }
  
  const onDelete = (e, user) =>{
    selectedUser = user
    toggleDeleteModal(e)
    toggleMenuModal()
  }
  
  const deleteUser = (e) => {
    e.preventDefault()
    UserGroupService.delete(selectedUser.id).then((res) => {
      if (res.status == 204) {
        setUsers(users.filter(r => r.id !== selectedUser.id))
        selectedUser = {}
      }
    })
    toggleDeleteModal(e)
  }

  /*const addUser = (data, action_type) => {
    if(action_type == 'create'){
      setUsers([data, ...users])
    }else if(action_type == 'update'){

    }
    selectedUser = {}
    toggleUserFormModal()
  }*/
  const addUser = (e, user) =>{
    if(user.id){
      UserGroupService.update(user).then((res) => {
        
      })
    }else{
      user.email = user.email
      user.group_id = currentUser.accelerator.id
      user.group_type = 'individual'

      UserGroupService.create(user).then((res) => {
        setUsers([res.data.user_group, ...users])
      })
    }
    toggleUserModal(e)
  }

  const menuItems = ({ item }) => {
    return (
      <Fragment>
        <MenuItem id="delete_icon" 
          icon="far fa-trash-alt"
          action="Delete"
          onSelect={(e) => onDelete(e, item)}/>
        <MenuItem id="edit_icon" 
          onSelect={(e) => {toggleUserModal(e)}}
          icon="far fa-edit"
          action="Edit"/>
      </Fragment>
    )
  }

  const Menu = ({ item}) => (
    <i data-tip="Open Tile Menu" onClick={(e) => {selectedUser = item;toggleMenuModal(e)}}
      className="fas fa-bars font-18 "/>
  )
    
  return (
    <Fragment>
      <div className="content bottom-10">
        <h5 className="float-left font-500">User Groups</h5>
        
        <NavIcon id="add_new_member" dataTip="Add New Member" 
          className="bg-highlight round-btn float-right m-r-5 m-t-4" 
          onSelect={e=>{selectedUser = {};toggleUserModal(e)}} 
          icon="fas fa-plus"/>

        <div className="clear"></div>
      </div>

      <div className="page-content clearfix">
        { users && users.map((u, i) => (
          <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
            <User user={u} menu={Menu} toggleModal={toggleDeleteModal}/>
            {selectedUser.id == u.id && (
              <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal}
                item={selectedUser} onDelete={onDelete}
                type="user_group" MenuItems={menuItems}
                menuHeight="275px"/>
            )}
          </div>
        ))}

        <Pagination totalItems={requestParams.totalItems}
          currentpage={currentpage} setCurrentpage={setCurrentpage}
          itemsPerPage={requestParams.per_page}/>
      </div>

      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteUser}/>
      
      {isUserModalOpen && (
        <GenericModal title="Add Member" component={AddUser} toggleModal={toggleUserModal} 
          userObj={selectedUser} addUser={addUser} permissions={data_source.Industry.options}/>
      )}
      
      <SideMenuBar helpId="2" />
    </Fragment>
  )

}

export default UserList;