import React, {useState, Fragment, useEffect} from "react";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import Help from './Help';
import DigitalContentService from '../../DigitalContent/DigitalContentService';
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu";
import FormTemplateId from '../../../constants/FormTemplateList';
import CommentsModal from "../../Modals/CommentsModal";
import ProjectMenu from "../../Modals/ProjectMenu"
import {Link} from "react-router-dom";
import useModal from "../../../hooks/useModal";
import useStyle from '../../../hooks/useStyle';
import env from '../../../env'

let requestParams = {}
let selectedHelp = {};
let data_source = {};

const HelpList = (props) => {
  useStyle('card');
  useStyle('card_table');
  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext);

  const [helpList, setHelpList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);

  const deleteJourney = (e) => {
    e.preventDefault();
    DigitalContentService.delete(selectedHelp).then((res) => {
      if (res.status == 204) {
        setHelpList(helpList.filter(i => i.id !== selectedHelp.id))
      }
    })
    toggleDeleteModal(e);
  }

  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal, success:commentSuccess } = useModal();
  const { isOpen, toggleModal:toggleDeleteModal } = useModal();
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();

  useEffect(() => {
    requestParams = {
      'content_type':'help',
      'filter_favourites': false,
      'is_active': true,
      'is_user_suggested': false,
      'page': currentpage,
      'paginate': 1,
      'per_page': 9,
      'sort_column': '',
      'sort_direction': 'desc',
      'search': null,
    }

    getHelpList();
  }, [currentUser.id, currentpage]);

  let getHelpList = () => { 
    DigitalContentService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       setHelpList(res.data.digital_contents)
       requestParams.totalItems = res.data.meta.total;
       data_source = res.data.data_source_list;
      }
    })
  }

  const addDelete = (e, help) => {
    selectedHelp = help;
    toggleDeleteModal(e);
  }

  const addMenu = (e, help) => {
    selectedHelp = help;
    toggleMenuModal(e);
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <Link to={`/help/create/${item.id}?`}>
          <i className="far fa-edit"></i>
          <span className="font-13">Edit</span>
          <i className="fa fa-angle-right"></i>
        </Link>
        <Link to="/" onClick={(e) => {addDelete(e, item);toggleMenuModal(e);}}>
          <i className="far fa-trash-alt"></i>
          <span className="font-13">Delete</span>
          <i className="fa fa-angle-right"></i>
        </Link>
      </Fragment>
    )
  }

  const Menu = ({ help, fn }) => (
    <i onClick={e=>{addMenu(e, help)}} className="fas fa-bars" />     
  )
     
  const helpListCard =  helpList && helpList.map((help, i) => (
    <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
      <Help helpList={help}  menu={Menu} toggleModal={toggleDeleteModal} />
        {selectedHelp.id == help.id && 
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedHelp} addDelete={addDelete} type="journey" MenuItems={menuItems} menuHeight="150px"/>
        }
    </div>
  ))
  
  return (
    <Fragment>
      <div className="content top-10">
        <h5 className="float-left font-500">Help List</h5>
        <Link className="bg-highlight round-btn float-right m-r-5 m-t-4" to="/help/create/">  
          <i className="fas fa-plus"></i>
        </Link>
        <div className="clear"></div>
      </div>
      <div className="page-content row">
        {helpListCard}
        <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
      </div>
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteJourney}/>
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedHelp} type="cue_news"/>}
    </Fragment>
  )

}

export default HelpList;