import React from 'react'
import PrivateRoute from "../../routes/PrivateRoute"
import AdvisorList from '../../components/Advisor/Activities/List/AdvisorList'
import AdvisorProfileForm from '../../components/Advisor/Activities/Form/AdvisorProfileForm'

const AdvisorRouter = (props) => (
  <React.Fragment>	
    <PrivateRoute path="/advisor" component={AdvisorList} searchString={props.searchString} exact />
	<PrivateRoute path="/advisor/profile/:id?" component={AdvisorProfileForm} exact />
  <PrivateRoute path="/advisor/details/:id?" component={AdvisorProfileForm} exact />
  </React.Fragment>
)

export default AdvisorRouter;
