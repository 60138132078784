import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import DynamicForm from '../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../constants/FormTemplateList'
import AssessmentService from "./AssessmentService";

let formMode = null; 
let assessment = {}; 
let data = {}; 
let formFn = {};  

function AssessmentForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext);

  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;
  
  let currentUser = authState.user;

  const get = () => {
    AssessmentService.get(params).then((res) => {
      if(res.status==200){
        assessment = res.data.risk_assessment;
        data = assessment.data || {};
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
      assessment = null;
    })
  }
  
  useEffect(() => { 
    assessment = {}; 
    data = {}; 
    formFn = {}; 
    formMode = 'create-form-submissions';
    data = {}; 
    if(params.id && params.id!='null'){
      formMode = 'edit-form-submissions';
      get()
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, [params.id]);

  const create = (assessment) => {
    assessment.accelerator_id = currentUser.accelerator.id;
    assessment.assessment_id = params.service_provider_id;
    assessment.assessment_type = 'assessment';
    assessment.form_id = FormTemplateId.ServiceProviderAssessment;
    AssessmentService.create(assessment).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (assessment) => {
    AssessmentService.update(assessment).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.goBack();
  }

  if(isFormRendered){
    return (
    <div className="portlet">
      <div className="portlet-heading bg-highlight color-white">
        <h4 className="portlet-title text-uppercase">
          {post.title}
        </h4>
         <div className="clearfix"></div>
      </div>
      <div id="portlet1" className="panel-collapse collapse in">
        <div className="portlet-body p-t-0">
          <div className="form-details" dangerouslySetInnerHTML={{ __html: post.details }}></div>
          <DynamicForm formMode={formMode} formId={FormTemplateId.ServiceProviderAssessment} form={assessment} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
        </div>
      </div>
    </div>
    );  
  }else{
    return('')
  }
  
}

export default AssessmentForm;