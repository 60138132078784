import React, {Fragment, useState, useEffect} from "react";
import {Link} from "react-router-dom";
import CheckAccess from "../../../../components/Roles/CheckAccess";
import CommonService from "../../../../services/CommonService";
import ReactTooltip from "react-tooltip";

const PortalPath = (props) => {
  let report = props.statics || {};
  
  let group = CommonService.groupBy(props.portalPath.options, 'group')
  let journeyProfile = props.journeyProfile
  let disableIconStyle = {}
  let disableClickEvent = {}
  let admin_list = [
    'roles',
    'users'
  ]
  let {access_list, access_type} = props.share

  if(!access_type)access_list=['prepare', 'implement', 'calendar', 'goals'];
  /*if(props.currentUser.current_admin.admin_role_name == 'demo_user'){
    disableIconStyle.color = 'grey'
    disableClickEvent.pointerEvents = 'none'
  }*/

  useEffect(() => {
    setTimeout(function() {
      for (const g in group) {
        document.getElementById(`path_icons_${g}`).childElementCount == 0 && document.getElementById(g).remove()
      }
    }, 0);
  }, [])

  const Icon = ({icon, text, icon_color, path}) =>{
    const hasAccess = CheckAccess.hasPathAccess(journeyProfile, access_type, access_list, admin_list, path.sid)
    if(hasAccess){
      return(
        <Fragment>
            <div className="three-half"  onClick={e=>props.routePath(e, path)}>
            <i className={icon} data-tip={text} style={{color:icon_color}}/>
            <div>{path.label}</div>
            <span className="notification">{report[path.sid] || 0}</span>
          </div>
          <ReactTooltip place="left" />
        </Fragment>
      )  
    }else{
      return null
    }
  }
  
  const Icons = ({paths}) => paths.map((p, i) => (
    <Icon key={i} icon={p.icon} text={p.hover_text} icon_color={p.icon_color} path={p}/>
  ))

  const PortalView = () => Object.keys(group).map((g, i) => {
    const p = group[g]
    return(
      <div className="col-xs-12 col-sm-6 col-md-4" key={i} id={g}>
        <div className="edu-path">
          <div className="head">{g}</div> 
          <div className="icons" id={`path_icons_${g}`}>
            <Icons paths={p}/>
          </div>     
        </div>
      </div>
    )
  })

  if(report && group){
    return(
      <div className="row margin-unset">
        <PortalView/>
      </div>
    )
  }else{
    return null;
  }

}

export default PortalPath;