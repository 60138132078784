import React from "react";
import OperationalJourney from "./Operational";
import CommercialJourney from "./Commercial";
import ProductDevelopment from "./ProductDevelopment";
import {EmissionsReduction, EmissionsReductionBlock} from './EmissionsReduction'
import Cs from "../../../services/CommonService";
import moment from "../../../../node_modules/moment/min/moment.min.js";

const Journey = (props) => {
  let {journey} = props;
  let {data} = journey;
  journey.updated_at = Cs.formatUpdateDate(journey.updated_at);
  journey.created_at = Cs.formatUpdateDate(journey.created_at);

  const cardStyle = {
    borderTop: "5px solid #27a1fb",
    height: "360px",
  }

  const listView = (list, label_key) =>
    list && list.map((o, k) => <span className="pill" key={k}>{o.data[label_key]} </span>);

  if(journey.form_id == 321) {
    return <OperationalJourney listView={listView} cardStyle={cardStyle} {...props} />
  }else if(journey.form_id == 320) {
    return <CommercialJourney listView={listView} cardStyle={cardStyle} {...props} />
  }else if(journey.form_id == 322) {
    return <ProductDevelopment listView={listView} cardStyle={cardStyle} {...props} />
  }else if(journey.form_id == 525){
    return <EmissionsReduction listView={listView} cardStyle={cardStyle} {...props} />
  }else{
    return null
  }

  return null;
}

const JourneyBlock = (props) => {
  let components = {
    525:EmissionsReductionBlock,
  }

  const TagName = components[props.formId];  

  if(TagName){
    return (
      <>
        <TagName {...props}/>
      </>
    )
   }else{
    return (
      <div>None</div>
    )
  }   
  
}

export {Journey, JourneyBlock};