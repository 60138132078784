import React, { useEffect, useState, useContext } from "react";
import querystringify from "querystringify";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../PreLoadSpinner";
import DynamicForm from "../../../../FormBuilder/DynamicForm";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import PlannerService from "../../../PlannerService";
import FormContainer from '../../../../../components/FormBuilder/FormContainer'

let formMode = null;
let planner = {};
let data = {};
let formFn = {};
let formId = null;

function PlannerForm(props) {
  const { state: authState, dispatch } = useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    PlannerService.get({id:params.id}).then((res) => {
      if (res.status == 200) {
        planner = res.data.planner;
        formId = planner.form_id
        data = planner.data || {};
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function (res) {
      planner = null;
    })
  }

  useEffect(() => {
    planner = {}
    data = {}
    formFn = {form_type: "planner", journey_profile_id: params.journey_profile_id, editPermission:queryParam.edit}
    formMode = "create-form-submissions"
    formId = queryParam.form_id
    data = {}

    if (params.id) {
      get();
      formMode = "edit-form-submissions";
    } else if (!isFormRendered) {
      setFormRendered(true);
    }
  }, [])

  const create = (planner) => {
    planner.created_by = authState.user.id
    planner.journey_profile_id = params.journey_profile_id
    planner.planner_type_id = queryParam.type_id
    planner.planner_type = 'goal'
    planner.form_id = FormTemplateId.GoalsProfileForm
    PlannerService.create(planner).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect();
      }
    })
  }

  const update = (planner) => {
    planner.updated_by = authState.user.id
    PlannerService.update(planner).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect();
      }
    })
  }

  const onSubmitPageRedirect = () => {
    //props.history.push(`/goals/${params.journey_profile_id}`)
    props.history.goBack()
  }

  if(isFormRendered){
    return (
      <FormContainer post={post} formId={FormTemplateId.GoalsProfileForm} showFormUpdateLink={params.journey_profile_id}>
         <DynamicForm formMode={formMode} formId={FormTemplateId.GoalsProfileForm} form={planner} data={data}
            formFn={formFn} onCreate={create} onUpdate={update} post={post}
            onCancel={onSubmitPageRedirect} setPost={setPost}/>
      </FormContainer> 
    )  
  }else{
    return null
  }

}

export default PlannerForm;