import env from '../../env';
import axios from 'axios';
const url = `${env.ecn_backend_api}user_groups`;

class UserGroupService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(id){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(member){
    return new Promise((resolve, reject) => {
      axios.post(url, member).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(member) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${member.id}`, member).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new UserGroupService();