import React from "react";
import {Link} from "react-router-dom";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import AuthenticationService from "../../services/AuthService";
import useStyle from '../../hooks/useStyle';

export const GetStarted = (props) => {
  useStyle('signup');
  
  const { state: authState, dispatch } = React.useContext(AuthContext);

  return (
    <React.Fragment>
      <div>
        <div className="page-title-bg opacity-90 bg-highlight h-150"></div>
        <div className="page-title-small color-white">
          <h1>Welcome!</h1>
        </div>

        <div className="content-boxed shadow-small">
            <div className="content bottom-0">
                <h4>Let's get started</h4>
                <p className="bottom-20">
                  Follow the steps below in the sequence .You can come back to this page anytime.
                </p>
            </div>
        </div>
        
        <div className="content">
            <div className="col-xs-12 col-sm-6">
                <Link to="/accelerator/profile" className="caption round-medium shadow-large bg-white m-b-15 h-160">
                    <div className="caption-center">
                        <h1 className="center-text">
                            <i className="far fa-user-circle"></i>
                        </h1>
                        <h4 className="center-text color-theme">STEP 1</h4>
                        <h4 className="center-text color-theme">Profile</h4>
                        <p className="under-heading color-highlight center-text font-11">
                            Set up your profile
                        </p>
                    </div>
                </Link>       
            </div>
            <div className="col-xs-12 col-sm-6">
                <Link href="/profile/189" className="caption round-medium shadow-large bg-white m-b-15 h-160">
                    <div className="caption-center">
                        <h1 className="center-text">
                            <i className="far fa-building">
                            </i>
                        </h1>
                        <h4 className="center-text color-theme">STEP 2</h4>
                        <h4 className="center-text color-theme">Business Details</h4>
                        <p className="under-heading color-highlight center-text font-11">
                            Enter Business info
                        </p>
                    </div>
                </Link>       
            </div>
            <div className="col-xs-12 col-sm-6">
                <Link to="/roles" className="caption round-medium shadow-large bg-white m-b-15 h-160">
                    <div className="caption-center">
                        <h1 className="center-text">
                            <i className="fas fa-user-lock">
                            </i>
                        </h1>
                        <h4 className="center-text color-theme">STEP 3</h4>
                        <h4 className="center-text color-theme">Roles</h4>
                        <p className="under-heading color-highlight center-text font-11">
                            Create Roles
                        </p>
                    </div>
                </Link>       
            </div>
            <div className="col-xs-12 col-sm-6">
                <Link to="/invite/users" className="caption round-medium shadow-large bg-white m-b-15 h-160">
                    <div className="caption-center">
                        <h1 className="center-text">
                            <i className="fas fa-user-plus">
                            </i>
                        </h1>
                        <h4 className="center-text color-theme">STEP 4</h4>
                        <h4 className="center-text color-theme">Team</h4>
                        <p className="under-heading color-highlight center-text font-11">
                            Invite others
                        </p>
                    </div>
                </Link>       
            </div>
            <div className="clear"></div>     
        </div>

      </div>
    </React.Fragment>
  );
};

export default GetStarted;