import env from '../env';
import axios from 'axios';
const url = `${env.ecn_backend_api}comments`

class CommentsService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(id){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }
  
  add(note, is_public){
    let u = env.ecn_backend_api + (is_public?'public_comments':'comments') 
    return new Promise((resolve, reject) => {
      axios.post(u, note).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(note){
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${note.id}`, note).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new CommentsService();