import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import DynamicForm from '../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../constants/FormTemplateList'
import CommonService from "../../services/CommonService";
import FormContainer from '../../components/FormBuilder/FormContainer'


let formMode = null; 
let formId = null; 
let suggestion = {}; 
let data = {}; 
let formFn = {};  

function DataSourceSuggestionForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;
  let currentUser = authState.user;

  /*TODO: Move API to Router to load data before view*/
  useEffect(() => { 
    suggestion = {}; 
    data = {}; 
    formFn = {}; 
    formMode = 'create-form-submissions';
    if(params.id){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, [params.data_source_id]);

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    CommonService.get(params).then((res) => {
      if(res.status==200){
        suggestion = res.data.suggestion;
        data = suggestion.data || {};
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
       suggestion = null;
    })
  }
  
  const create = (suggestion) => {
    suggestion.created_by = authState.user.id;
    suggestion.accelerator_id = currentUser.accelerator.id;
    CommonService.create(suggestion).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (suggestion) => {
    suggestion.accelerator_id = currentUser.accelerator.id;
    CommonService.update(suggestion).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push("/list/data_sources")
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.DataSourceSuggestion} form={suggestion} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
      </FormContainer> 
    )  
  }else{
    return null
  }
  
}

export default DataSourceSuggestionForm;