import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import FormTemplateId from '../../constants/FormTemplateList';
import useStyle from '../../hooks/useStyle'

const CardMenu = ({ isOpen, toggleModal, item ,addChecklist ,addNotes, onDelete, type, MenuItems}) => { 
    
    useStyle('action_sheet');

    if(!isOpen) return null;

    return(
      <React.Fragment> 
        <div className="menu-inline menu-box-bottom menu-box-detached round-medium menu-active" style={{height: '330px',opacity: 1,display: 'block'}}>
        <div className="ml-center">
            <br/>
            <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal" 
                style={{position: 'absolute',top: '3px'}}
                onClick={e=>toggleModal(e)}><i className="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div className="link-list link-list-1 content bottom-0">
            <Link to={`/${type}/profile/${item.id}`} >
                <i className="fa fa-edit" aria-hidden="true"></i>
                <span className="font-13">Edit</span>
                <i className="fa fa-angle-right"></i>
            </Link>
            <Link to="/" onClick={(e) => {onDelete(e, item);toggleModal(e);}}>
                <i className="fa fa-trash"></i>
                <span className="font-13">Delete</span>
                <i className="fa fa-angle-right"></i>
            </Link>
            <Link to="/" onClick={e=>{addChecklist(e, item);toggleModal(e)}}>
                <i className="fa fa-check"></i>
                <span className="font-13">Checklists</span>
                <i className="fa fa-angle-right"></i>
            </Link>
            <Link to="/"onClick={e=>{addNotes(e, item);toggleModal(e)}}>
                <i className="fa fa-notes-medical"></i>
                <span className="font-13">Notes</span>
                <i className="fa fa-angle-right"></i>
            </Link>
            {MenuItems && <MenuItems item={item}/>}
        </div>
        </div>
      </React.Fragment>);
}

export default CardMenu;