import env from '../../env';
import axios from 'axios';

class OrganizationService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}organizations`, {params: req}).then((res) => {
        // handle success
        console.log('companies get all',res)
        resolve(res);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}organizations/${req.id}`, { params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(profile){
    return new Promise((resolve, reject) => {
      axios.post(env.ecn_backend_api+'organizations',profile).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(profile) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.ecn_backend_api}organizations/${profile.id}`, profile).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.ecn_backend_api}organizations/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  activities(req){
    return new Promise((resolve, reject) => {
      axios.post(env.ecn_backend_api+'organizations/activities', req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  getMembers(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}organization/members`, { params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  getMember(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}organization/members/${req.id}`, { params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  createMembers(req){
    return new Promise((resolve, reject) => {
      axios.post(env.ecn_backend_api+'organization/members', req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  updateMembers(profile) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.ecn_backend_api}organization/members/${profile.id}`, profile).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  deleteMember(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.ecn_backend_api}organization/members/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new OrganizationService();