import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import DynamicForm from '../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../constants/FormTemplateList'
import ProjectMilestoneService from "../../../components/Organizations/ProjectMilestones/ProjectMilestoneService";

let formMode = null; 
let milestone = {}; 
let data = {};
let formFn = {};

function ProjectMilestoneForm(props) {

  const { state:currentUser, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;

  const get = () => {
    ProjectMilestoneService.get(params).then((res) => {
      if(res.status==200){
        milestone = res.data.project_milestone;
        data = milestone.data || {};

        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
      milestone = null;
    })
  }
  
  useEffect(() => { 
    milestone = {}; 
    data = {};
    formFn = {};
    formMode = 'create-form-submissions';
    data = {}; 
    if(params.id && params.id != 'null'){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, [params.id]);

  const create = (milestone) => {
    milestone.accelerator_id = currentUser.user.accelerator.id;
    milestone.project_id = params.project_id;
    milestone.organization_id = params.organization_id;
    milestone.form_id = FormTemplateId.ProjectMilestone;
    milestone.service_provider_id = params.service_provider_id;
    milestone.contract_id = params.contract_id;
    ProjectMilestoneService.create(milestone).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (milestone) => {
    ProjectMilestoneService.update(milestone).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.goBack();
  }

  if(isFormRendered){
    return (
    <div className="portlet">
      <div className="portlet-heading bg-highlight color-white">
        <h4 className="portlet-title text-uppercase">
          {post.title}
        </h4>
         <div className="clearfix"></div>
      </div>
      <div id="portlet1" className="panel-collapse collapse in">
        <div className="portlet-body p-t-0">
          <div className="form-details" dangerouslySetInnerHTML={{ __html: post.details }}></div>
          <DynamicForm formMode={formMode} formId={FormTemplateId.ProjectMilestone} form={milestone} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
        </div>
      </div>
    </div>
    );  
  }else{
    return('')
  }
  
}

export default ProjectMilestoneForm;