import React, { useState, Fragment, useEffect, useContext } from "react";
import ReactTooltip from "react-tooltip";
import querystringify from "querystringify";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import Management from "./Management";
import ManagementService from "../ManagementService";
import Pagination from "../../../../Pagination";
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import ProjectMenu from "../../../../Modals/ProjectMenu";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import CommentsModal from "../../../../Modals/CommentsModal";
import useModal from "../../../../../hooks/useModal";
import ChecklistsModal from "../../../../Modals/ChecklistsModal";
import useStyle from "../../../../../hooks/useStyle";
import CheckAccess from "../../../../Roles/CheckAccess";
import ListMenuModal from "../../ListMenuModal";
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import JourneyTitle from "../../JourneyTitle/JourneyTitle";
import SideMenuBar from "../../../../../components/Common/SideMenuBar";
import NotificationPopup from "../../../../../components/Common/NotificationPopup";
import {MenuItem,CardMenuInfo,NavIcon,JourneyHome} from '../../../../Common/MenuItem'
import HelpMessageList from '../../../../../constants/HelpMessageList'

let requestParams = {};
let selectedManagement = {};
let dataSource = {};

const ManagementList = (props) => {
  useStyle("card");
  useStyle("card_table");
  useStyle("add_role_dialog");

  let params = props.match.params;
  let journeyProfile = props.location.state;
  let queryParam = querystringify.parse(props.location.search);

  let cardGridCss = "col-xs-12 col-sm-6 col-md-4";
  let cardHeightCss = "ht-227";
  if (queryParam.id) {
    cardGridCss = "col-sm-12 col-sm-6 col-md-8";
    cardHeightCss = "mih-380";
  }

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [managementList, setManagementList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pagemeta, setPagemeta] = useState([]);

  const { isOpen: isCommentOpen, toggleModal: toggleComment } = useModal();
  const { isOpen: isDeleteModalOpen, toggleModal: toggleDelete,} = useModal();
  const { isOpen: isManagementMenuOpen, toggleModal: toggleManagementMenu } = useModal();
  const { isOpen: isChecklistOpen, toggleModal: toggleChecklist,} = useModal();
  const { isOpen: isMenuOpen, toggleModal: toggleMenu } = useModal();

  useEffect(() => {
    requestParams = {
      dataSource_params: { dataSource_id: 3196, response_type: "object" },
      journey_profile_id: params.journey_profile_id,
      page: currentpage,
      paginate: 1,
      per_page: 10,
      sort_column: "updated_at",
      sort_direction: "desc",
      totalItems: 0,
      search: props.searchString,
      id:queryParam.id
    }

    getManagementList()
  }, [props.searchString, currentpage, props.location])

  let getManagementList = () => {
    setLoading(true)
    ManagementService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        //dataSource = res.data.dataSource_list;
        requestParams.totalItems = res.data.meta.total;
        //journeyProfile = res.data.journey_profile;
        CheckAccess.userAccess(currentUser, journeyProfile, res.data.share_journey);
        setManagementList(res.data.managements);
        setLoading(false);
      }
    })
  }

  const addComment = (e, a) => {
    selectedManagement = a;
    toggleComment(e);
  }

  const addChecklist = (e, a) => {
    selectedManagement = a;
    toggleChecklist(e);
  }

  const deleteItem = (e) => {
    e.preventDefault();
    ManagementService.delete(selectedManagement.id).then((res) => {
      if (res.status == 204) {
        setManagementList(managementList.filter((i) => i.id !== selectedManagement.id))
      }
    })
    toggleDelete(e);
  }

  const showMenu = (e, a) => {
    selectedManagement = a;
    toggleMenu(e);
  }

  const routeActivity = (item) => {
    props.history.push({
      pathname:`/management/${params.journey_profile_id}`,
      search:`id=${item.id}`,
      state:journeyProfile,
    })
  }

  let routeForm = (e, k) =>{
    e.preventDefault()
    props.history.push(`/form/management/${params.journey_profile_id}?form_id=${k.child_form}&type_id=${k.value}&type=${k.label}`)
  }

  const menuItems = ({ item }) => {
    const title = item.data && item.data.name//.replace(/\s/g, "_");
    const editAccess = CheckAccess.hasEditAccess(currentUser, journeyProfile, item);
    
    return (
      <Fragment>
        <CardMenuInfo formId={item.form_id} recordId={item.record_id} 
          createdAt={item.created_at} userName={item.created_user.name}/>

        {editAccess && (
          <MenuItem id="edit_icon" 
            link={`/form/management/${item.journey_profile_id}/${item.id}?edit=${editAccess}`}
            icon="far fa-edit"
            action="Edit"
          />
        )}

        {CheckAccess.hasDeleteAccess(currentUser, journeyProfile, item) && (
          <MenuItem id="delete_icon" 
            icon="far fa-trash-alt"
            action="Delete"
            onSelect={(e) => {toggleDelete(e);}}
          />
        )}

        <MenuItem id="mail_icon" 
          link={`mailto:${title}_planner_${item.record_id}@cuetree.com`}
          icon="far fa-envelope"
          action={`${title}_planner_${item.record_id}@cuetree.com`}
        />
      </Fragment>
    )
  }

  const Menu = ({ management }) => (
    <i data-tip="Menu" onClick={(e) => showMenu(e, management)} className="fas fa-bars m-r-15"/>
  )

  const journeyActivityCard = managementList && managementList.map((a, i) => (
    <div className={cardGridCss} key={i}>
      <Management
        management={a}
        addComment={addComment}
        addChecklist={addChecklist}
        dataSource={dataSource}
        menu={Menu}
        journeyProfile={journeyProfile}
        cardHeightCss={cardHeightCss}
        routeActivity={routeActivity}
        index={i}/>
      {selectedManagement.id == a.id && (
        <ProjectMenu
          isOpen={isMenuOpen}
          toggleModal={toggleMenu}
          item={selectedManagement}
          type="planner"
          MenuItems={menuItems}
          menuHeight="200px"/>
      )}
    </div>
  ))

  if (queryParam.id && !loading && managementList.length == 0) {
    return (
      <NotificationPopup iconClass="fa fa-times-circle lred font-24"
        header="Not Found"
        message={HelpMessageList.new_activity}/>
    )
  }

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-8">
          {journeyProfile && <JourneyTitle title={"Governance"} project={journeyProfile}/>}
        </div>
        
        <div className="col-xs-4 float-right">

          <NavIcon id="add_new_management" dataTip="Create New Agreement" 
            onSelect={toggleManagementMenu}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-plus"/>

          <JourneyHome id="home_icon" journey_profile={journeyProfile}
            link={`/journey/portal/${params.journey_profile_id}`}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home" dataTip="Back to Journey Home" />

          <ReactTooltip place="left" />
        </div>
      </div>

      <div className="page-content row">
        {journeyActivityCard}
        {queryParam.id && managementList.length > 0 && (
          <div className="col-sm-12 col-sm-6 col-md-4">
            <CommentsModal isOpen="true" toggleModal={toggleComment} item={managementList[0]}
              type="management" showInline="true"/>
          </div>
        )}
        <Pagination totalItems={requestParams.totalItems}
          currentpage={currentpage}
          setCurrentpage={setCurrentpage}
          itemsPerPage={requestParams.per_page}/>
      </div>

      {isCommentOpen && (
        <CommentsModal
          isOpen={isCommentOpen}
          toggleModal={toggleComment}
          item={selectedManagement}
          type="management"/>
      )}
      {isChecklistOpen && (
        <ChecklistsModal
          isOpen={isChecklistOpen}
          toggleModal={toggleChecklist}
          item={selectedManagement}
          type="management"/>
      )}
      {isManagementMenuOpen && (
        <ListMenuModal title="Manage Agreements" params={params} isOpen={isManagementMenuOpen} 
        toggleModal={toggleManagementMenu} dataSourceId="3520" onSelect={routeForm}/>
      )}
      <ConfirmMenu
        isOpen={isDeleteModalOpen}
        toggleModal={toggleDelete}
        success={deleteItem}/>
      
      <SideMenuBar helpId="2"/>
    </Fragment>
  )
}

export default ManagementList;