import React, {useState} from "react";
import {Link} from "react-router-dom";

const ProjectPath = (props) => {
  let {statics} = props;

  let p = {...props.projectPath.options}
  let arr = Object.keys(p).map((k) => p[k])
  
  let disableIconStyle = {}
  let disableClickEvent = {}

  /*if(props.currentUser.current_admin.admin_role_name == 'demo_user'){
    disableIconStyle.color = 'grey'
    disableClickEvent.pointerEvents = 'none'
  }*/

  if(statics && arr){
  return (
    <div className="row margin-unset">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <div className="edu-path">
          <div className="head">PLAN</div> 
          <div className="icons">
            <div className="three-half" onClick={e=>props.routePath(e, p[0])}>
              <i className="far fa-handshake red"/>
              <div>Service Requests</div>
              <span className="notification">{statics.planners || 0}</span>
            </div>
            
            <div className="three-half" onClick={e=>props.routePath(e, p[2])}>
              <i className="fas fa-clipboard-check red"/>
              <div>Contracts</div>
              <span className="notification">{statics.contract || 0}</span>
            </div>
          </div>     
        </div>
      </div>

      <div className="col-xs-12 col-sm-6 col-md-4">
        <div className="edu-path">
          <div className="head">TRACK</div>   
          <div className="icons">
            <div className="three-half" onClick={e=>props.routePath(e, p[1])}>
              <i className="far fa-clipboard l-green"/>
              <div>Assessments</div>
              <span className="notification">{statics.assessments || 0}</span>
            </div>
            <div className="three-half" onClick={e=>props.routePath(e, p[3])}>
              <i className="far fa-list-alt blue"/>
              <div>Milestones</div>
              <span className="notification">{statics.activities || 0}</span>
            </div>
            <div className="three-half" onClick={e=>props.routePath(e, p[4])}>
              <i className="far fa-chart-bar l-green"/>
              <div>Metrics</div>
            </div>
          </div>      
        </div>
      </div>
      
    </div>
  )}else{
    return(
      <div></div>
    )
  }
}

export default ProjectPath;

/*
<div className="col-xs-12 col-sm-6 col-md-4">
        <div className="edu-path">
          <div className="head">ANALYZE</div> 
          <div className="icons">
            <div style={disableClickEvent} className="three-half" onClick={e=>props.routePath(e, p[6])}>
              <i style={disableIconStyle} className="far fa-map l-green"/>
              <div>Paths</div>
              <span className="notification">{ 0}</span>
            </div>
            <div style={disableClickEvent} className="three-half" onClick={e=>props.routePath(e, p[7])}>
              <i style={disableIconStyle} className="fas fa-poll red"/>
              <div>Comparison</div>
              <span className="notification">{0}</span>
            </div>
          </div>      
        </div>
      </div>
*/