import React, { useEffect, useState, useContext, Fragment} from 'react'
import querystringify from "querystringify"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import DynamicForm from '../../../../components/FormBuilder/DynamicForm'
import StepActivitiesService from "./StepActivitiesService"
import FormContainer from '../../../../components/FormBuilder/FormContainer'

let formMode = null
let stepActivity = {} 
let data = {}
let formFn = {}  
let formId = null

function StepActivityForm(props) {
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})
  
  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  /*formFn.school_name_pagenate = {page:1, per_page:10}
  formFn.school_name_path = 'name';*/

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    StepActivitiesService.get(params).then((res) => {
      if(res.status==200){
        stepActivity = res.data.step_activity
        data = stepActivity.data || {}
        formId = stepActivity.form_id
        setFormRendered(true)
      }
    })
  }  
  
  useEffect(() => { 
    stepActivity = {} 
    data = {}
    formFn = {
      'form_type':'step_activity', 
      'journey_profile_id':params.journey_profile_id, 
      editPermission:true
    } 
    formMode = 'create-form-submissions'
    formId = queryParam.form_id
    data = {} 
  
    if(params.id){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, [params.id]);

  const create = (req) => {
    req.created_by = currentUser.id;
    req.updated_by = currentUser.id;
    req.form_id = formId
    req.step_activity_type = null
    req.step_activity_id = null
    req.activity_id = params.activity_id
    req.journey_profile_id = params.journey_profile_id
    //req.parent_step_activity_id = data.parent_step_activity
    StepActivitiesService.create(req).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    })
  }

  const update = (req) => {
    req.updated_by = currentUser.id;
    StepActivitiesService.update(req).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.goBack()
  }


  /*formFn.get_school_name = function(search, on_success, on_failure){
    let req = {'journey_profile_id':params.journey_profile_id}
    StepActivitiesService.get(req).then((res) => {
      if(res.status == 200){
         formFn.school_name_list = res.data.schools;
        if(res.data.meta.total && formFn.school_name_pagenate.per_page){
          formFn.school_name_pagenate.pageSize = Math.ceil(res.data.meta.total/formFn.school_name_pagenate.per_page);
        }
        on_success(formFn.school_name_list);
      }
    })
  }

  formFn.set_school_name = function(obj){
    data.school_zipcode = obj.zip;
    data.school_name = obj.school_name;
    formFn.school_name_list = [];
  }*/

  if(isFormRendered){
    return (
      <Fragment>
        <h4 className="m-l-20 m-b-n6">{queryParam.title}</h4>
        <FormContainer post={post}>
          <DynamicForm formMode={formMode} formId={formId} 
            form={stepActivity} data={data} 
            formFn={formFn} onCreate={create} onUpdate={update} 
            post={post} onCancel={onSubmitPageRedirect} 
            setPost={setPost}/>
        </FormContainer> 
      </Fragment>
    )  
  }else{
    return null
  }
  
}

export default StepActivityForm;