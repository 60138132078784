import React from 'react'
import PrivateRoute from "../../routes/PrivateRoute"
import CompanyList from '../Company/List/CompanyList'
import CompanyActivityList from '../Company/Activities/List/CompanyActivityList'
import CompanyActivityForm from '../Company/Activities/Form/CompanyActivityForm'
import CompanyProfileForm from '../Company/Form/CompanyProfileForm'
import ProjectDetails from './ProjectPortalPage/ProjectDetails'
import StepActivityForm from '../Company/StepActivities/StepActivityForm'

const CompanyRouter = (props) => (
  <React.Fragment>	
    <PrivateRoute path="/company" component={CompanyList} searchString={props.searchString} exact />
    <PrivateRoute path="/company_profile/:id?" component={CompanyProfileForm} exact />
    <PrivateRoute path="/company/details/:id?" component={CompanyProfileForm} exact />
    <PrivateRoute path="/company/portal/:project_id?" component={ProjectDetails} exact />
    <PrivateRoute path="/company/activities/:id?" component={CompanyActivityList} searchString={props.searchString} exact />
    <PrivateRoute path="/company/activities/profile/:activity_type/:form_id/:value/:id?" component={CompanyActivityForm} searchString={props.searchString} exact />
    <PrivateRoute path="/company/step_activity/form/:accelerator_id/:activity_id/:activityId/:activity_type/:id?" component={StepActivityForm} exact />
  </React.Fragment>
)

export default CompanyRouter;
