import React, { useState ,useRef, Fragment} from 'react';
import UserAdminService from "./UserAdminService"

const AddPermissions = ({ toggleModal, editPermission }) => {

  const permissionsList = [
    'role_admin',
    'role_master',
    'role_form',
    'role_content',
    'role_topic',
    'role_rating',
    'role_search',
    'role_data',
    'role_connector',
    'role_report',
    'role_experience',
    'role_match',
    'role_tile',
    'role_subscription',
    'role_read_form',
    'role_csv',
    'role_calendar',
    'role_data_builder',
    'role_submit_form',
    'role_chat_bot',
    'role_data_source',
    'role_form_wizard',
  ]

  const [permissions, setPermissions] = useState(editPermission);
  const [loading, setLoading] = useState(false);

  const toggle = (access_type) => {
    let req = {'id': editPermission.id, 'role_name': access_type, 'role_value':!permissions[access_type]}
    UserAdminService.update(req).then((res)=>{
      if(res.status == 204){
        setPermissions({...permissions, [access_type]: !permissions[access_type]});  
      }
    })
  }

  return(
    <Fragment>
      {loading ? <div className="spinner"></div> : <form className="ml-container">
        <div className="ml-section">

          <label className="req-field"><b>Permissions</b></label>
          <span className="checkbox-container" onClick={e => toggle('full_access')}>
            <span>Full Access (Admin level permission)</span>
            <input type="checkbox" checked={permissions.access_type == 'full_access'} readOnly/>
            <span className="checkbox-checkmark"></span>
          </span>
            
          <Fragment>
            <label><b>Manage permission to access</b></label>
            {permissionsList.map((r, k) => (
              <span key={k} className="checkbox-container" onClick={e => toggle(r)}>
                <span>{r.replace('_', ' ')}</span>
                <input type="checkbox" checked={permissions[r]} readOnly/>
                <span className="checkbox-checkmark"></span>
              </span>
            ))}
          </Fragment>

        </div>
      </form>
    }
     
  </Fragment>)
}

export default AddPermissions;