import React, {Fragment, useState, useEffect, useContext} from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import AuthenticationService from "../../services/AuthService";
import useStyle from '../../hooks/useStyle';
import CommonService from "../../services/CommonService";

export const Signup = (props) => {
  useStyle('signup');
  
  const { state: authState, dispatch } = useContext(AuthContext);
  const [count, setCount] = React.useState(Math.random())

  const initialState = {
    email: "",
    password: "",
    cpassword: "",
    isSubmitting: false,
    isChecked:false,
    errorMessage: {},
    serverErrorMessage: "",
    errorSign: {
      email: false,
      password: false,
      confirmpassword: false,
    },
  };

  const [data, setData] = useState(initialState);
  const [isRegistered, setIsRegistered] = useState(false);

  const handleTerms = (event) => {
    setData({
      ...data,
      isChecked: event.target.checked
    });
  }

  const handleInputChange = (event) => {
    if (event.target.value === "") {
      let errorMessage = data.errorMessage;
      errorMessage[event.target.name] = [event.target.name] + " is required";
      let errorSign = data.errorSign;
      errorSign[event.target.name] = false;
      setData({
        ...data,
        errorMessage: errorMessage,
        errorSign: errorSign,
      });
    } else {
      if (data.errorMessage[event.target.name]) {
        delete data.errorMessage[event.target.name];
      }
      let errorSign = data.errorSign;
      errorSign[event.target.name] = true;
      setData({
        ...data,
        [event.target.name]: event.target.value,
        errorMessage: data.errorMessage,
        errorSign: errorSign,
      });
    }
  };

  const checkError = () => {
    for (const property in data.errorSign) {
      if (data.errorSign[property] === false) return false;
    }
    if(data.isChecked === false){
      return false
    }
    return true;
  };

  useEffect(() => {
    if(authState.isAuthenticated){
      props.history.push('/')
    }
  }, [authState.isAuthenticated]);

  const handleFormSubmitSignup = (event) => {
    event.preventDefault();
    setData({
      ...data,
      isSubmitting: true,
    });
    let req = {
      user: {
        email: data.email,
        password: data.password,
        password_confirmation: data.confirmpassword,
        invitation_id:props.match.params.invitation_id
      },
    };
    AuthenticationService.signup(req)
      .then((res) => {
        if (res.status == 201) {
          dispatch({
            type: "Signup",
            payload: res.data.data,
          });
          setIsRegistered(true)
        } else {
          setData({
            ...data,
            isSubmitting: false,
            serverErrorMessage:res.data.error
          });
        }
      })
      .catch((error) => {
        setData({ ...data, serverErrorMessage: error.response.data.error });
      });
  };
  
  return (
    <Fragment>
      <div className="header header-fixed header-logo-app header-auto-show">
        <a href="index.html" className="header-subtitle">
          Back to Pages
        </a>
        <a href="#" data-back-button className="header-icon header-icon-1">
          <i className="fas fa-arrow-left" />
        </a>
        <a href="#" data-toggle-theme className="header-icon header-icon-2">
          <i className="fas fa-lightbulb" />
        </a>
        <a href="settings.html" className="header-icon header-icon-3">
          <i className="fas fa-cog" />
        </a>
      </div>
      <div className="page-content">
        <div className="page-title-bg bg-20" style={{ height: "150px" }}>
          {/* image */}
        </div>
        <div
          className="page-title-bg dark-mode-tint"
          style={{ height: "150px" }}
        >
          {/* contrast for dark mode */}
        </div>
        <div
          className="page-title-bg opacity-90 bg-highlight"
          style={{ height: "150px" }}
        >
          {/* background color */}
        </div>
        <div className="page-title-small color-white bottom-30">
          <h1>
            Sign Up
          </h1>
          
        </div>
        <form className="signform">
        <div className="content-boxed content-boxed-full left-20 right-20 shadow-large">
          <div className="content bottom-0 top-10">
            {isRegistered &&
              <div className="ht-600">
                <div id='card' class="animated fadeIn">
                  <div id='upper-side'>
                    <i className="fa fa-check bg-green1-dark success-ico" />
                    <h3 id='status'>Success</h3>
                  </div>
                  <div id='lower-side'>
                    <p>Congratulations! An account has been created for you.</p>
                    <p>To sign in and start using cueTree ecn_backend, please click
                      the verification link that you should received in the email
                      used to register your account. Check the email spam
                      folder in case the email was delivered there.
                    </p>
                  </div>
                </div>
              </div>
            }
            {!isRegistered &&  
              <Fragment>
                <div className="input-style has-icon input-style-1 input-required">
                  <i className="input-icon fa fa-at" />
                  <span>Email</span>
                  <em>(required)</em>
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    onChange={handleInputChange}
                    required
                  />
                </div>
                {data.errorMessage["email"] && (
                  <span className="error">{data.errorMessage["email"]}</span>
                )}
                <div className="input-style has-icon input-style-1 input-required">
                  <i className="input-icon fa fa-lock font-11" />
                  <span>Password</span>
                  <em>(required)</em>
                  <input
                    type="password"
                    placeholder="Choose a Password"
                    name="password"
                    onChange={handleInputChange}
                    required
                  />
                </div>
                {data.errorMessage["password"] && (
                  <span className="error">
                    {data.errorMessage["password"]}
                  </span>
                )}
                <div className="input-style has-icon input-style-1 input-required">
                  <i className="input-icon fa fa-lock font-11" />
                  <span>Password</span>
                  <em>(required)</em>
                  <input
                    type="password"
                    placeholder="Confirm your Password"
                    name="confirmpassword"
                    onChange={handleInputChange}
                    required
                  />
                </div>
                {data.errorMessage["confirmpassword"] && (
                  <span className="error">
                    {data.errorMessage["confirmpassword"]}
                  </span>
                )}
                <div className="input-style has-icon">
                  <div className="d-flex">
                    <input type="checkbox"
                        name="terms"
                        onChange={handleTerms}
                        required />
                        <label className="m-l-5">
                          I Accept the 
                          <Link to="/about_us/terms">&nbsp;<u>Terms of Use</u></Link> and 
                          <Link to="/about_us/privacy">&nbsp;<u>Privacy Policy</u></Link>.
                        </label>
                    </div>                   
                </div>
                {data.errorMessage["tc"] && (
                  <span className="error">
                    {data.errorMessage["tc"]}
                  </span>
                )}
                
                <div className="clear" />

                <div className="error">{data.serverErrorMessage}</div>

                <button onClick={handleFormSubmitSignup} disabled={checkError() ? false : true}
                  className="signup button button-full button-m shadow-large button-round-small bg-green1-dark top-30 bottom-30">
                  {data.isSubmitting ? "Loading..." : "CREATE ACCOUNT"}
                </button>
              </Fragment>
            }
            <div className="divider bottom-20" />
              <div className="bottom-20">
                <a href="/signin" className="back-button center-text font-11 color-highlight">
                  Already Registered? Sign In Here.
                </a>
              </div>
            </div>
          </div>
        </form>

        <div className="signinbg">
      <div className="signincon">
        <img
          src={"./../../../images/cueTree_logo.png"}
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            width: "50%",
          }}
        />
        <h1 className="m-t-10">ECN</h1>
        <h3 className="m-t-10">Essential Community Networks</h3>
        <p className="m-t-10 f-16">
          Transformative outcomes from journey collaboration
        </p>
        <p className="m-t-10 f-16">
          Enabling unique connections among organizations, accelerators, investors, services providers and other digital transformers        
        </p>
        <div className="font-18 m-t-10 button button-full button-m shadow-large button-round-small bg-black white top-30 bottom-0">
          {CommonService.displayRandomPhrase(Math.floor(4*count))}
        </div>
      </div>

      <div className="signcopy hidden-xs">
        <div className="content bottom-10 social-sharing">
          <a className="shareToTwitter icon icon-xs icon-round bg-twitter regularbold">
            <i className="fab fa-twitter"></i>
          </a>
          <a className="shareToTwitter icon icon-xs icon-round bg-instagram regularbold">
            <i className="fab fa-instagram"></i>
          </a>
          <a className="shareToFacebook icon icon-xs icon-round bg-facebook regularbold">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a className="shareToWhatsApp icon icon-xs icon-round bg-linkedin regularbold">
            <i className="fab fa-linkedin"></i>
          </a>
          <a className="shareToWhatsApp icon icon-xs icon-round bg-whatsapp regularbold">
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
          <i className="far fa-copyright"></i> {new Date().getFullYear()} IPIPAL INC. All Rights Reserved.
          <label className="m-l-5 text-muted">
            <Link className="font-12 text-muted m-r-10" to="/about_us/terms_of_service">&nbsp;Terms of Service</Link> 
            <Link className="font-12 text-muted" to="/about_us/privacy_policy">&nbsp;Privacy Policy</Link>
          </label>
        </div>
      </div>
        <div className="footer" data-footer-load="menu-footer.html" />
      </div>
    </Fragment>
  );
};

export default Signup;