import env from '../../env';
import axios from 'axios';
const url = `${env.ecn_backend_api}members`;

class MemberService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`, { params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(member){
    return new Promise((resolve, reject) => {
      axios.post(url, member).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(member) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${member.id}`, member).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new MemberService();