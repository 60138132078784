import React, { useState } from "react";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import Forum from "../../../components/Forums/List/Forum";
import ForumService from "../../../components/Forums/ForumService";
import Pagination from "../../Pagination";
import { Link } from "react-router-dom";
import useModal from "../../../hooks/useModal";
import FormTemplateId from "../../../constants/FormTemplateList";
import useStyle from "../../../hooks/useStyle";

let requestParams = {};

export const ForumList = (props) => {
  useStyle('forum');
  const { state: authState, dispatch } = React.useContext(AuthContext);
  console.log("forums", props, authState);

  const [forumList, setForumList] = useState([]);

  React.useEffect(() => {
    requestParams = {
      "created_by": authState.user.id,
      "updated_by": authState.user.id,
    };

    getForumsList();
  }, [authState.user.accelerator]);

  let getForumsList = () => {
    ForumService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        console.log("res data forums", res);
        setForumList(res.data.discussion_requests);
      }
    });
  };

  const Details = ({ forum, fn }) => (
    <div className="dropup card-menu-left">
            <Link to={`/forums/details/${forum.id}`}>
            <p>{ forum.discussion_responses  && Object.keys(forum.discussion_responses).length }</p>
            <button>Details</button>
              </Link>
    </div>
  );

  const Reply = ({ forum, fn }) => (
    <div className="dropup card-menu-right">
      <Link to={`/forums/reply/${forum.id}`}>
      <button type="button" className="btn btn-inverse btn-rounded pull-left"><i className="fa fa-reply" aria-hidden="true"></i></button>
      </Link>
    </div>
  );

  return (
    <React.Fragment>
      <div className="content top-10">

        <div className="content-boxed shadow-small">
          <div className="vcard">
            <div className="input-style has-icon input-style-1 ">
              <div class="page-title-small color-white bottom-30">
                <a
                  href="#"
                  data-menu="menu-main"
                  className="page-title-small-forumLogo"
                ></a>
                <div className="vcard-field">
                <p className="title-forum">Your ECN Forum</p>
                <p className="title-desc">Add and respond to entries. Access to the Forum is set by the account administrator</p>
                </div>
              <div className="vcard-field">
              <input
                type="text"
                placeholder="title"
                name="title"
                value=""
              />
                  <Link to="/forums/create" style={{float:"left"}}>
              <i className="fa fa-search"></i>
            </Link>
              </div>
            </div>
            </div>
            <h1 className="vcard-title color-highlight bold"></h1>

            <div className="vcard">
              <strong></strong>
            </div>

            <div className="vcard"></div>
          </div>
        </div>

        <div className="page-content row">
          {forumList.map((form, i) => (
            <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
              <Forum forum={form} reply={Reply} details={Details} />
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForumList;

/*<Link to={`/track/relationship/${org.id}/organization`}>Track Relationship</Link>*/
