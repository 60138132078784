import React, { useEffect, useState, Fragment } from 'react';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import DynamicForm from '../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../constants/FormTemplateList'
import ContractsService from "../../components/Contracts/ContractsService";

let formMode = null; 
let signature = {}; 
let data = {};  
let contract = {};

function ContractVerificationForm(props) {
  let formFn = {editPermission:true}; 
  const { state:currentUser, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;

  const get = () => {
    ContractsService.get(params).then((res) => {
      if(res.status==200){
        contract = res.data.contract;
        contract.data = contract.data || {};
        contract.data.signatures = contract.data.signatures || [];

        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
      contract = null;
    })
  }
  
  useEffect(() => { 
    signature = {}; 
    data = {};  
    contract = {};
    
    if(params.id && params.id != 'null'){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, [params.id]);

  const update = (signature) => {
    contract.data.signatures.push(signature.data);
    ContractsService.update(contract).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    setIsFormSubmitted(true);
  }

  if(isFormRendered){
    return (
    <div className="portlet">
      <div className="portlet-heading bg-highlight color-white">
        <h4 className="portlet-title text-uppercase">
          {post.title}
        </h4>
         <div className="clearfix"></div>
      </div>
      <div id="portlet1" className="panel-collapse collapse in">
        <div className="portlet-body p-t-0">
          {isFormSubmitted?
            <div className="text-center"><h4>Thank you..</h4></div>
            :
            <Fragment>
            <div className="form-details" dangerouslySetInnerHTML={{ __html: post.details }}></div>
            <DynamicForm formMode={formMode} formId={FormTemplateId.ContractVerification} form={signature} data={data} 
            formFn={formFn} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
            setPost={setPost}/>
            </Fragment>
          }
        </div>
      </div>
    </div>
    );  
  }else{
    return('')
  }
  
}

export default ContractVerificationForm;