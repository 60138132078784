import React, { useState } from 'react';
import ReactDOM from 'react-dom';

const SendContractVerificationEmail = ({ member, isOpen, toggleModal, sendContractVerification}) => {

  const [count, setCount] = useState(0);

  if(!isOpen)return null;
  member = member || {};
    
  const forceUpdate = () =>{
    setCount(value => ++value)
  }

  return(ReactDOM.createPortal(
    <React.Fragment>
      <div className="ml-modal">
      <div className="ml-modal-content ml-card-4 ml-animate-zoom">
      
      <div className="ml-center">
        <br/>
        <h4>Send Contract Verification</h4>
        <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal"
        onClick={e=>toggleModal(e)}>×
        </span>
      </div>
    
    <form className="ml-container">
      <div className="ml-section">
        <label><b>Member</b></label>
        <input onChange={e=>{member.email = e.target.value}} 
          className="ml-input ml-border ml-margin-bottom"
          type="email" placeholder="User Email..." name="email"  
          defaultValue={member.email} required/>
        
        <label><b>Message</b></label>
        <textarea onChange={e=>{member.message = e.target.value}}
          className="ml-input ml-border ml-margin-bottom" placeholder="Message" 
          defaultValue={member.message} required>
        </textarea>

        <button className="ml-button ml-block ml-green ml-section ml-padding" onClick={e=>{sendContractVerification(e, member)}}>
          <b>{member.id?'Update':'Add'}</b>
        </button>
        
      </div>
    </form>
    
  </div>
</div>
</React.Fragment>, document.body))
};

export default SendContractVerificationEmail;