import React, {useState,Fragment} from "react";
import querystringify from "querystringify";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import Advisor from '../../../Advisor/Activities/List/Advisor';
import AdvisorService from "../../../Advisor/AdvisorService";
import Pagination from '../../../Pagination'
import {Link} from "react-router-dom";
import useModal from "../../../../hooks/useModal";
import CommentsModal from "../../../Modals/CommentsModal";
import CardMenu from "../../../Modals/CardMenu"
import MenuModal from "../../../Modals/Menu";
import ConfirmMenu from "../../../Modals/ConfirmMenu";
import FormTemplateId from '../../../../constants/FormTemplateList'
import NotesModal from "../../../Modals/NotesModal";
import ChecklistsModal from "../../../Modals/ChecklistsModal"
import useStyle from '../../../../hooks/useStyle';
import FilterModal from "../../../Organizations/Activities/List/OrganizationsFilterModal";

let requestParams = {}
let selectedAdvisor = {}
let data_source = {};

export const AdvisorList = (props) => {
  useStyle('card');
  useStyle('card_table');


  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  let cardGridCss = 'col-xs-12 col-sm-6 col-md-4'
  let cardHeightCss = 'ht-227'
  if(queryParam.id){
    cardGridCss = 'col-sm-12 col-sm-6 col-md-8'
    cardHeightCss = 'mih-380'
  }

  const { state:authState, dispatch } = React.useContext(AuthContext);

  const [advisorList, setAdvisorList] = useState([]);
  const [currentpage,setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);

  const fn = {}; //common shareable object for all child element

  fn.deleteOrg = (sp) => {
    //Delete
    deleteServiceprovider(sp)
    console.log('sp',sp)
  };

  const { isOpen, toggleModal:toggleDeleteModal, success } = useModal(fn.deleteOrg);
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal, success:menuSuccess } = useModal();
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal, success:commentSuccess } = useModal();
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal, success:checklistSuccess } = useModal();
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal();


  React.useEffect(() => {
    requestParams = {
      'page': currentpage, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'asc',
      'accelerator_id': authState.user.accelerator.id
    }

    getAdvisorList();
  }, [authState.user.accelerator,props.searchString,currentpage]);

  let getAdvisorList = () => { 
    AdvisorService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       console.log('res data advi',res)
       setPagemeta(res.data.meta)
       setAdvisorList(res.data.advisors)
       data_source = res.data.data_source_list;

      }
    })
  }

  let deleteServiceprovider = (sp) => {
    AdvisorService.delete(sp).then((res) => {
      if (res.status == 200) {
        console.log("delete adviosr", res);
        setPagemeta(res.data.meta);
        setAdvisorList(res.data.advisors);

      }
    });
  }

  const Menu = ({ ad, fn }) => (
    <span className="m-r-15" >
      <i onClick={e=>{addMenu(e, ad)}} class="fas fa-bars" />  
    </span>
  );

  const onDelete = (e, ad) => {
    selectedAdvisor = ad;
    toggleDeleteModal(e);
  }

  const addMenu = (e, ad) => {
    selectedAdvisor = ad;
    toggleMenuModal(e);
  }

  const addNotes = (e, ad) => {
    selectedAdvisor = ad;
    toggleCommentModal(e);
  }

  const addChecklist = (e, ad) => {
    selectedAdvisor = ad;
    toggleChecklistModal(e);
  }

  const Details = ({ ad, fn }) => (
    <Link className="m-l-15 pull-left text-muted" to={ `/advisor/details/${ad.id}`}  >
    <i className="fa fa-info-circle" />
    
    </Link>
  );

  const routeActivity = (ad) =>{
    props.history.push(`/advisor?id=${ad.id}`)
    setAdvisorList(advisorList.filter(i => i.id == ad.id))
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <Link to={`/contract/registration/advisor/${item.id}/${FormTemplateId.AdvisorContract}/${item.contract_id}`} >
        <i className="fa fa-file-contract"></i>
        <span className="font-13">Standard Contract</span>
        <i className="fa fa-angle-right"></i>
            </Link>
            <Link to={`/list/relationship/tracking/${item.id}/advisor`} >
         <i className="fa fa-retweet"></i>
         <span className="font-13">Track Relationship</span>
         <i className="fa fa-angle-right"></i>
             </Link>
      </Fragment>
    )
  }


  const advisorCard = advisorList.map((ad, i) => (
    <div className={cardGridCss} key={i}>
      <Advisor cardHeightCss={cardHeightCss} routeActivity={routeActivity}  advisor={ad}  menu={Menu} addNotes={addNotes} addChecklist={addChecklist}
        details={Details}
        toggleModal={toggleDeleteModal} />
        {selectedAdvisor.record_id == ad.record_id && 
          <CardMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedAdvisor} addChecklist={addChecklist} addNotes={addNotes} onDelete={onDelete} type="advisor" MenuItems={menuItems}/>
        }
    </div>
  ))

  return (
    <React.Fragment>
     <div className="content top-10">
        <h5 className="float-left font-500">Advisor List</h5>

        <Link to="/advisor/profile" className="bg-highlight round-btn float-right m-r-5">  <i className="fas fa-plus"></i></Link>

        <Link to="/advisor/profile" className="bg-highlight round-btn float-right m-r-5" onClick={e=>toggleFilterModal(e)}>
          <i className="fas fa-sliders-h"></i>
        </Link>

        <div className="clear"></div>
    </div>

    <div className="page-content row">
        {advisorCard}
        {queryParam.id && advisorList.length>0 && <div className="col-sm-12 col-sm-6 col-md-4">
        <CommentsModal isOpen="true" toggleModal={toggleCommentModal} item={selectedAdvisor} type="advisor" showInline="true" /> 
        </div>}
        <Pagination pagemeta={pagemeta} currentpage={currentpage} setCurrentpage={setCurrentpage} />
    </div>
    <ConfirmMenu
        isOpen={isOpen}
        toggleModal={toggleDeleteModal}
        success={success}
      />
    {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedAdvisor} type="advisor"/>}
    {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} item={selectedAdvisor} type="advisor"/>}
    {isFilterOpen && <FilterModal filterData={requestParams.filter} filterDataSources={data_source} isOpen={isFilterOpen} toggleModal={toggleFilterModal} onSearch={getAdvisorList}/>}

    </React.Fragment>
  )

};

export default AdvisorList;
