import React from 'react';
import ReactDOM from 'react-dom';
import useStyle from '../../hooks/useStyle';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";

const ImageMapModal = ({ imageMapData, onClose }) => {
  useStyle('add_role_dialog');
  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext);
  
  imageMapData.user_id = currentUser.id

  /*let KEY_NAME = 'SummerHTMLImageMapCreator';
  let item = {
    "area_io": {"areas":[],"img":"https://life.admin.cuetree.com/files?url=https://selftree-images.s3.amazonaws.com/uploads/file_upload/fj_file/17454/images (1).jpeg"},
    image_url: "https://selftree-images.s3.amazonaws.com/uploads/file_upload/fj_file/17454/images (1).jpeg",
    user_id: 189
  }

  window.sessionStorage.setItem(KEY_NAME, JSON.stringify(item));*/

  return(
  	ReactDOM.createPortal(
  	<div className="ml-modal">
    	<div className="ml-modal-content full-width ml-card-4 ml-animate-zoom">
      
      		<div className="ml-center bg-safforn white p-5">
        		<h4>Image Map</h4>
        		<span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal"
        		onClick={e=>onClose(e)}>×
        		</span>
      		</div>
    
      		<div className="ml-container">
        		<div className="clearfix m-t-5">
  		 			  <object data="scripts/image_map_creator/summer_image_map.html" 
  		 			  width="100%" height={`${screen.height} px`}></object>
  	    		</div>
  	  		</div>
    	</div>
    </div>
  , document.body
 ))

}

export default ImageMapModal;