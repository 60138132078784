import React, {Fragment} from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "./../../routes/PrivateRoute";
import UsersList from "./UsersList"

const UserAdminRouter = (props) => (
  <Fragment>
    <Switch>
      <PrivateRoute path="/user/admins" component={UsersList} searchString={props.searchString} exact/>
    </Switch>
  </Fragment>
)

export default UserAdminRouter;