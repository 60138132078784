import React, {useRef, useEffect, useState, useContext} from 'react'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import SortDirection from '../Common/SortDirection'
import useSort from '../../hooks/useSorting';
import Cs from '../../services/CommonService'
import {RecordAudioSafari} from '../Common/RecordAudio'
import FileUploadService from '../../services/FileUploadService'
import env from "../../env"

let requestParams = null

const AudioRecordingList = ({journeyProfileId}) => {
  const { state:{user:currentUser} } = useContext(AuthContext);

  let [list, setList] = useState([])
  const [selectedItem, setSelectedItem] = useState()

  useEffect(() => {
    requestParams = {
      page:1,
      per_page:100,
      fileable_type:'journey_profile_audio',
      journey_profile_id:journeyProfileId,
    }

    getAll()
  }, []);

  const getAll = () =>{
    FileUploadService.getAll(requestParams).then((res)=>{
      setList(res.data.file_uploads)
    })
  }

  const {sort} = useSort(requestParams, getAll)

  const onUploadSuccess = (data) =>{
    data.file_upload.author = {name:currentUser.name}
    list.unshift(data.file_upload)
    setList([...list])
  }

  const onDelete = (file) => {
    Cs.deleteFileFromUrl(file.url).then((res)=>{
      if(res.status == 204){
        setList(list.filter((i) => i.id !== file.id))
      }
    })
  }

  const AudioRecordingTable = () =>(
    <div className="table-responsive">
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th onClick={e=>sort('record_id')} className='p-2 bold-400 text-center miw-95'>
              Id 
            </th>
            <th onClick={e=>sort('record_id')} className='p-2 bold-400 text-center miw-95'>
              Audio
            </th>
            <th onClick={e=>sort('updated_at')} className='p-2 bold-400 text-center miw-95'>
              Date
            </th>
            <th onClick={e=>sort('created_by')} className='p-2 bold-400 text-center miw-95'>
              User
            </th>
            <th onClick={e=>sort('record_id')} className='p-2 bold-400 text-center miw-95'>
              Options
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </table>
    </div>
  )

  const tableData = list.map((s, k) => {
    return(
      <tr key={k}>
        <td className="text-center" data-title="Id">{s.id}</td>

        <td className="text-center">
          <audio controls autoplay>
            <source src={env.file_url + s.url} type="audio/wav"/>
          </audio>
        </td>

        <td className="text-center">
          {Cs.formatUpdateDate(s.updated_at, 'MMMM DD YYYY')}
        </td>
       
        <td className="text-center">
          {s?.author?.name}
        </td>

        <td className="text-center">
          <span className="fas fa-trash m-r-15 font-18 text-muted" onClick={e=>onDelete(s)}/> 
        </td>
      </tr>
    )
  })

  return (
    <>
      <RecordAudioSafari journeyProfileId={journeyProfileId} onUploadSuccess={onUploadSuccess}/>
      <AudioRecordingTable/>
    </>
  )
}

export default AudioRecordingList;