import React, { useEffect, useState, useContext, Fragment } from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import JourneyProfileService from "../JourneyProfileService"
import CommonService from "../../../services/CommonService"
import useStyle from "../../../hooks/useStyle"
import { Link } from "react-router-dom"
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import env from "../../../env"
import SideMenuBar from "../../../components/Common/SideMenuBar"
import PortalDetails from "../CollegeJourney/PortalPage/PortalDetails"
import GenericModal from "../../Modals/GenericModal"
import useModal from "../../../hooks/useModal"

//import NotificationRoute from "../../Notifications/NotificationRoute";

let journeyPaginate = null
let cueNewsPaginate = null
let notificationsPaginate = null
let schedulesPaginate = null
let dataSource = null
let users = {}
let profileImage = "images/avatars/2s.png"
let journeyProps = null
const JourneyDashboard = (props) => {
  useStyle("dashboard")
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)

  const [visible, setVisible] = useState(true)
  const [journeyList, setJourneyList] = useState([])
  const [notifications, setNotifications] = useState([])
  const [favJourneyList, setFavJourneyList] = useState([])
  const [newsList, setNewsList] = useState([])
  const [schedulesList, setScheduleList] = useState([])

  const { isOpen:isJourneyPortalOpen, toggleModal:toggleJourneyPortal } = useModal()

  /*const activities = () =>{
    let req = {'accelerator_id': currentUser.accelerator.id}
    AcceleratorService.activities(req).then((res)=>{
      setData(res.data.activities);
    })
  }*/

  useEffect(() => {
    journeyPaginate = {
      page: 1,
      paginate: 1,
      per_page: 4,
      search: null,
      sort_column: "updated_at",
      sort_direction: "desc",
    };

    getJourneyDashboard()
  }, [currentUser.id]);

  let getJourneyDashboard = () => {
    JourneyProfileService.getAllDashboard().then((res) => {
      if (res.status == 200) {
        users = res.data.users;
        dataSource = res.data.data_source_list;
        setJourneyList(res.data.journey_profiles || []);
        setFavJourneyList(res.data.favourites || []);
        //setNewsList(res.data.cue_newses);
        setScheduleList(res.data.schedules || []);
      }
    })
  }

  const routeNotification = (n) => {
    //NotificationRoute.routeNotification(n, props);
  };

  let routeCueNews = (news) => {
    props.history.push(`/news?id=${news.id}`);
  };

  let routeJourney = (journey) => {
    if(journey.form_id == 321){
      props.history.push(`/journey/portal/${journey.id}?menu=3475`,journey);
    }else if(journey.form_id == 322){
      props.history.push(`/journey/portal/${journey.id}?menu=3522`,journey);
    }else if(journey.form_id == 525){
      props.history.push(`/journey/tabs/${journey.id}?type=emissions_reduction`);
    }else{

    }
  };

  let routeSchedules = (schedule) => {
    //TODO
    // if(schedule.data.form_id){
    //   props.history.push(`/track/${schedule.journey_profile_id}?id=${schedule.activity_id}`);

    // }else{

    // }
  };

  const toggleShowView = () => {
    setVisible(!visible);
  };

  const onDelete = (e, notification) => {
    CommonService.deleteNotification({ id: notification.id }).then((res) => {
      if (res.status == 204) {
        setNotifications(notifications.filter((r) => r.id !== notification.id));
      }
    })
  };

  const handleClick =(id)=> {
    console.log('scroll',document.getElementById('info_'+id))
    //document.getElementById('info').scrollTop -= 10;
    document.getElementById('info_'+id).scrollTop += 10;

  }

  const openJourneyPortal = (item) =>{
    const querystring = {
      321:'?menu=3475',
      322:'?menu=3522',
      525:'type=emissions_reduction'
    }
      
    journeyProps = {
      title: item.data.record_title || item.data.name,
      match:{
        params:{
          journey_profile_id:item.id
        }
      },
      location:{
        search:querystring[item.form_id]
      },
      history:props.history
    }
    toggleJourneyPortal()
  }

  /*const getProfileImg = (n) => {
    if (n.sender && n.sender.profile_image_url) {
      const userImg = env.file_url + n.sender.profile_image_url;
      return userImg;
    }
    return profileImage;
  };*/

  const notificationView = notifications.map((n, k) => (
    <Fragment key={k}>
      <i className="pull-right fa fa-times lred" onClick={(e) => onDelete(e, n)}/>
      <div className="d-flex pointer" onClick={(e) => routeNotification(n)}>
        <div></div>
        <div className="m-l-10">
          <h5 className="d-title">
            {n.journey_profile_name} - {n.email_data && n.email_data.type}
          </h5>
          <p className="d-description">
            {n.email_data && n.email_data.title.substring(0, 30) + "...."}
          </p>
          <p className="d-description ">
            {n.sender_name}
            <span className="lred">
              {CommonService.formatUpdateDate(n.updated_at)}
            </span>
          </p>
        </div>
        <div className="ml-auto"></div>
      </div>
      <div className="divider" />
    </Fragment>
  ))

  const cueNewView = newsList.map((n, k) => (
    <Fragment key={k}>
      <div className="d-flex pointer" onClick={(e) => routeCueNews(n)}>
        <div>
          <img src="images/news-50.png" className="rounded-circle" width="50" />
        </div>
        <div className="m-l-10">
          <h5 className="d-title">{n.data.title_news_item}</h5>
          <p className="d-description">
            {n.data.prompt_user.substring(0, 30) + "...."}
          </p>
          <p className="d-description lred">
            {CommonService.formatUpdateDate(
              n.data.date_published_article_formatted_date
            )}
          </p>
        </div>
        <div className="ml-auto"></div>
      </div>
      <div className="divider" />
    </Fragment>
  ))

  const JourneyTitle = ({j}) =>{
    let title = null;
    try{
      title = (j.data.org_name || j.data.name_org)
    }catch(e){

    }
    
    return(
      <h5 className="d-title ellipsis">
        {title}
      </h5>
    )
  }

  const journeyView = (list) => list && list.map((j, k) => {
   let profileImage = CommonService.getIconByType(j.created_user.image, 'profile_image')

    return (
      <Fragment key={k}>
        <div className="d-flex pointer" onClick={(e) => openJourneyPortal(j)}>
          <div>
            <img src={profileImage} className="rounded-circle bg-lgrey-2" width="50"/>
          </div>
          <div className="m-l-10 w-100p">
            <JourneyTitle j={j}/>
            <p className="d-description ellipsis"> {j.data.name}</p>
            <p className="d-description font-11">Last by {j.updated_user.name} {CommonService.formatUpdateDate(j.updated_at)}</p>
            <p className="d-description lred">
              since {CommonService.formatUpdateDate(j.created_at)} by {j.created_user.name}
            </p>
          </div>
          <div className="m-l-20  pull-right">
            <i className="fas fa-chevron-right lgrey"></i>
          </div>
          <div className="ml-auto"></div>
        </div>

        <div className="divider" />
      </Fragment>
    )
  })

  const schedulesView = schedulesList && schedulesList.map((s, k) => {
    return (
      <Fragment key={k}>
        <div className="d-flex pointer" onClick={(e) => routeSchedules(s)}>
          <div>
            <img
              src="images/avatars/2s.png"
              className="rounded-circle bg-highlight"
              width="50"
            />
          </div>
          <div className="m-l-10 w-100p">
            <h5 className="d-title">{s.data.title}</h5>
            <p className="d-description font-11">Last by {s.updated_user?.name} {CommonService.formatUpdateDate(s.updated_at)}</p>
            <p className="d-description lred">
              since {CommonService.formatUpdateDate(s.created_at)} by {s.created_user?.name}
            </p>
          </div>
          <div className="ml-auto"></div>
        </div>
        <div className="divider" />
      </Fragment>
    )
  })

  /*if(newsList.length == 0 && schedulesList.length == 0 && journeyList.length == 0 && notifications.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/

  return (
    <Fragment>
      <div className="page-content row">
        { favJourneyList.length > 0 ?
          <div className="col-xs-12 col-md-6">
            <div className="card card-style">
              <div className="d-content m-b-0" id="info_1">
                <Link to="/journey" className="head-boder br-btm-grey">
                  <h3 className="p-10 p-b-0">
                    <i className="fas fa-thumbtack  m-r-10 font-20 bg-black white p-7" />
                    Pinned Journeys
                    <p className="font-10 lgrey">Tap to view more.</p>
                  </h3>
                </Link>
                <div className="p-10 ht-home scroll-auto ">
                  {journeyView(favJourneyList)}
                </div>
              </div>
              <div className="p-5 ht-30">
                {favJourneyList.length > 3 && <p className="scroll-more pull-right highlight" onClick={(e)=>handleClick(1)}></p>}
              </div>
            </div>
          </div>:''
        }

        {schedulesList.length > 0 ?
          <div className="col-xs-12 col-md-6">
            <div className="card card-style">
              <div className="d-content m-b-0" id="info_2">
                <Link to="/journey" className="head-boder br-btm-grey">
                  <h3 className="p-10 p-b-0">
                    <i className="far fa-calendar-alt  m-r-10 font-20 bg-black white p-7" /> Upcoming
                    Activities
                    <p className="font-10 lgrey">Tap to view more.</p>
                  </h3>
                </Link>
                <div className="p-10 ht-home scroll-auto ">
                  {schedulesView}
                </div>
              </div>
              <div className="p-5 ht-30">
                {schedulesList.length > 3 && <p className="scroll-more pull-right highlight" onClick={(e)=>handleClick(2)}></p>}
              </div>
            </div>
          </div>:''
        }

        { journeyList.length > 0 ?
          <div className="col-xs-12 col-md-6">
            <div className="card card-style">
              <div className="d-content m-b-0" id="info_3">
                <Link to="/journey" className="head-boder br-btm-grey">
                  <h3 className="p-10 p-b-0">
                    <i className="fas fa-home  m-r-10 font-20 bg-black white p-7" /> Recent
                    Journeys
                    <p className="font-10 lgrey">Tap to view more.</p>
                  </h3>
                </Link>
                <div className="p-10 ht-home scroll-auto ">
                  {journeyView(journeyList)}
                </div>
              </div>
              <div className="p-5 ht-30">
                {journeyList.length > 3 && <p className="scroll-more pull-right highlight" onClick={(e)=>handleClick(3)}></p>}
              </div>
            </div>
          </div> :''
        }

        { newsList.length > 0 ?
          <div className="col-xs-12 col-md-6">
            <div className="card card-style">
              <div className="d-content m-b-0" id="info_4">
                <Link to="/news" className="head-boder br-btm-grey">
                  <h3 className="p-10 p-b-0">
                    <i className="fas fa-globe  m-r-10 font-20 bg-black white p-7" /> News
                    <p className="font-10 lgrey">Tap to view more.</p>
                  </h3>
                </Link>
                <div className="p-10 ht-home scroll-auto ">
                  {cueNewView}
                </div>
              </div>
              <div className="p-5 ht-30">
                {newsList.length > 3 && <p className="scroll-more pull-right highlight" onClick={(e)=>handleClick(4)}></p>}
              </div>
            </div>
          </div>:''
        }
        <SideMenuBar helpId="2"/>
      </div>
      
      {isJourneyPortalOpen &&
        <GenericModal {...journeyProps}
          widthCss="w-100p"
          component={PortalDetails}
          isOpen={isJourneyPortalOpen}
          toggleModal={toggleJourneyPortal}
        />
      }
    </Fragment>
  )
}

export default JourneyDashboard;