export default {
  HelpRegistration:462,
  FeedbackForm: 454,
  GoalsProfileForm:444,
  AcceleratorRegistration:317,
  OrganizationRegistration:402,
  OrganizationMemberRegistration:469,
  ServiceProviderRegistration:407,
  AdvisorRegistration:403,
  ProjectRegistration:404,
  ProjectServiceRequest:409,
  ServiceProviderSkillRegistration:412,
  Skill:412,
  AdvisorContract : 416,
  OrganizationContract : 416,
  ServiceProviderAssessment : 410,
  ProjectWorkOrderForm : 415,
  ContractVerification : 421,
  AssessRisk : 465,
  TrackRelationship:419,
  ProjectMilestone: 427,
  MilestoneTask: 429,
  MilestoneStatus: 418,
  MilestoneVerification: 428,
  ForumNewDiscussion:424,
  ForumReplyDiscussion:425,
  MarketingForm:411,
  DataSourceSuggestion:426,
  CompanyStepActivity:328,
  ZoomMeeting:468,
  Schedule:993,
 /* Planner:323,
  Enrichment:324,
  DiscussionRequest:325,
  DiscussionResponse:326,
  StepActivity:232*/
}