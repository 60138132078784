import React, {useState} from 'react';
import CueTooltip from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel, OptionLabel} from '../FieldLabel'

const InputRadio = ({field, formData, readOnly, errors, forceUpdate, isFormWizard, openDataSourceModal, openFieldLabelModal, currentLocale}) => {
  let [model, setModel] = useState(formData);

  let inputAttributes = {
    'type':'radio',
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only)
  }

  let parentAttributes = {
    'tooltip': field.tooltip,
    'id': field.client_id,
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);

  let validation = {
    required: field.required
  }
  
  let op_kv = field.options_key_value;

  const handleChange = (event) =>{
    const { target } = event;
    const { name } = target;
    formData[name] = FormHelper.isNumeric(target.value);
    setModel(target.value);
    setError();
    forceUpdate();
    //target.checked = true;
  }

  const selected = (value) => {
    return formData[field.client_id] == value;
  }

  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[name]==null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);
  
  return (
  	<div {...parentAttributes} data-tip data-for={`tooltip_${field.client_id}`}>
      <FieldLabel field={field} labelAttributes={labelAttributes} currentLocale={currentLocale}
        openDataSourceModal={openDataSourceModal}
        openFieldLabelModal={openFieldLabelModal}/>
 	    {(op_kv && op_kv.options) 
        ? <OptionsObjList list={op_kv.options} handleChange={handleChange} inputAttributes={inputAttributes} selected={formData[field.client_id]} currentLocale={currentLocale}/> 
        : <OptionsStringList list={field.options} handleChange={handleChange} inputAttributes={inputAttributes} selected={formData[field.client_id]}/>
      }     
  	  <span className="fts-13">{field.description}</span>
      <Error/>
      <CueTooltip id={field.client_id} description={field.tooltip}/>
 	  </div> 
  )
}

const OptionsObjList = ({list, inputAttributes, handleChange, selected, currentLocale}) => list.map((o, k) =>
  <label className={`radio-container ${o.read_only=='yes'?'input-disabled':''}` } key={k}>
    <span>{OptionLabel({option:o, currentLocale:currentLocale})}</span>
    <input type="radio" onChange={handleChange} {...inputAttributes}  defaultValue={o.value} checked={selected==o.value}/>
    <span className="radio-checkmark"></span>
  </label>
);

const OptionsStringList = ({list, inputAttributes, handleChange, selected}) => list.map((o,k) =>
  <label className="radio-container pointer" key={k}>
    <span>{o}</span>
    <input type="radio" onChange={handleChange} {...inputAttributes}  defaultValue={o} checked={selected==o}/>
    <span className="radio-checkmark"></span>
  </label>
)

export default InputRadio;