import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Cs from "../../services/CommonService";

const User = (props) => {
  const {user} = props
  let data = user.data
  let ds = user.data_source
  let bodyStyle = { height: "238px", overflowY: "auto" }

  const cardStyle = {
    borderTop: "5px solid #27a1fb",
    height: "360px",
  }

  return (
    <Fragment>
      <div className="content-boxed shadow-small" style={cardStyle}>
        <div className="content vcard-header">
          <ul className="d-flex">
            <li>
              <img src={Cs.getIconByType(null, 'profile_image')} className="rounded-circle bg-highlight" width="50"/>
            </li>
            <li className="m-l-15">
              <div className="vcard-title black-light-1 ellipsis">
               {user.email}
              </div>
              <span>since {Cs.formatUpdateDate(user.created_at)}</span>
            </li>
          </ul>
        </div>
        <div className="p-2" style={bodyStyle}>
          <table className="table card-table">
            <tbody>
              <tr>
                <td>

                </td>
              </tr>
            </tbody>
          </table>       
        </div>
        <div className="card-footer">
          <div className="pull-left m-l-15">
            
          </div>
          <div className="pull-right m-r-15">
            <props.menu item={user} />
          </div>
        </div>   
      </div>
    </Fragment>
  )
}

export default User;