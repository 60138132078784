import React, {Fragment} from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./../../routes/PrivateRoute";
import JourneyList from "./List/JourneyList";
import JourneyDashboard from "./Dashboard/JourneyDashboard";
import JourneyProfileForm from "./Form/JourneyProfileForm";
import CollegeRouter from "./CollegeJourney/CollegeRouter"
import GoalsRouter from "./CollegeJourney/Goals/GoalsRouter"
import Schedules from "./CollegeJourney/Schedules/Schedules"
import ScheduleForm from '../Calendars/Form/ScheduleForm'
import JourneyFilesLinks from '../JourneyFilesLinks/JourneyFilesLinkPage'
import LayoutFlow from './CollegeJourney/TreePath/LayoutFlow'

const JourneyRouter = (props) => (
  <Fragment>
    <Switch>
      <PrivateRoute path="/home" component={JourneyDashboard} searchString={props.searchString} exact/>
      <PrivateRoute path="/journey" component={JourneyList} searchString={props.searchString} exact/>
      <PrivateRoute path="/journey_profile/form/:id?" component={JourneyProfileForm} exact/>
      <PrivateRoute path="/calendar/:journey_profile_id" component={Schedules} exact/>
      <PrivateRoute path="/calendar/schedule/:journey_profile_id/:journey_type/:id?" component={ScheduleForm} exact />
      <PrivateRoute path="/collection/:journey_profile_id" component={JourneyFilesLinks} searchString={props.searchString} exact/>
      <PrivateRoute path="/path/:journey_profile_id" component={LayoutFlow} searchString={props.searchString} exact/>
    </Switch>
    <Switch>
      <CollegeRouter app={props.app} searchString={props.searchString} />
    </Switch>
    <Switch>
      <GoalsRouter app={props.app} searchString={props.searchString} />
    </Switch>
  </Fragment>
);

export default JourneyRouter;