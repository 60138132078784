import React, { Fragment,useContext } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import env from "../../../env";
import useModal from "../../../hooks/useModal";
import CommonService from "../../../services/CommonService";
import AddToFavourite from "../../Common/AddToFavourite"
import {UserImage} from '../../Common/Image'

const CommercialJourney = (props) => {
  let { journey, users, currentUser, cardStyle } = props;
  let data = journey.data;
  let ds = journey.data_source;
  let bodyStyle = { height: "238px" };
  const {isOpen: isActivityMenuOpen, toggleModal: toggleActivityMenu,} = useModal();
  
  const SpanField = ({name, label, styleName}) =>(
    <span className={styleName}>{ds[name] && ds[name][label]}</span>
  )

  const SpanList = ({name, label, styleName}) => ds[name] && ds[name].map((d, i) => (
    <span className={styleName +' m-l-5'} key={i}>{d.data[label]}</span>
  ))

  return (
    <Fragment>
      <div className="content-boxed shadow-small" style={cardStyle}>
        <div className="content vcard-header">
          <ul className="d-flex">
            <li>
                <UserImage url={journey.created_user.image}/>
            </li>
            <li className="m-l-15">
              <Link className="black-light-1" to={`/journey/portal/${journey.id}`}>
                <div className="vcard-title black-light-1 ellipsis">
                  {data.name}
                </div>
              </Link>
              <div>
                <span className="pull-left ellipsis">{data.org_name}</span>
              </div>
              <div>
                <span className="pull-left font-11">Last by {journey.updated_user.name} {journey.updated_at}</span>
              </div>
            </li>
            <li>
              <AddToFavourite favourite_id={journey.favourite_id} favorable_type="journey_profile" 
              favorable_id={journey.id} class_name_active="pin-0 green" class_name_disable="pin-0 lwhite"
              journey_profile_id={journey.id}/>
            </li>
          </ul>
        </div>
        <div className="content" style={bodyStyle}>
          <table className="table card-table">
            <tbody>
                <tr>
                  <td>
                    <SpanField name="stage" label="label" styleName="pill text-left"/>
                  </td>
                  <td>
                    <SpanField name="commercial_category" label="label" styleName="pill pull-right font-12"/>
                  </td>
                </tr>
                <tr>
                  <td>
                    Type : {journey.journey_type}
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    Details : {data.detail}
                  </td>
                </tr>
            </tbody>
          </table>
         </div>
          <ul className="vard-footer color-highlight">
            <li className="pull-right m-r-15">
              <props.menu ja={journey} />
            </li>
            <li className="pull-right m-r-15 font-18 lh-0" data-tip="View this Journey">
              <i className="fas fa-arrow-up" />
            </li>
          </ul>
        </div>
        <ReactTooltip place="left" />
    </Fragment>
  )
}

export default CommercialJourney;