import React, { Fragment } from "react";
import {Link} from "react-router-dom";
import Cs from "../../services/CommonService";

const Meeting = (props) => {
  let {meeting} = props;
  meeting.updated_at = Cs.formatUpdateDate(meeting.updated_at);
  meeting.created_at = Cs.formatUpdateDate(meeting.created_at);

  let {data, zoom_data, created_user} = meeting
  zoom_data = zoom_data || {}
  data = data || {}
  
  const cardStyle = {backgroundColor: "#607D8B"}

  const Header = () => (
    <div className="white card-header p-5" style={cardStyle}>
      <div className="font-16 ellipsis">
        {data.title}
      </div>
      <div className="font-13">
        {meeting.updated_at}
      </div>
    </div>
  )

  const Footer = () => (
    <div className="card-footer">
      <div className="pull-right">
         <props.menu item={meeting} />
      </div>
    </div>
  )

  const Card = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <Header/>
        <div className="scroll-auto ht-180">
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                  <span>{created_user.name}</span>
                  <span className="pull-right">{data.start}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <a href={zoom_data.start_url} target="_blank" className="pull-right">
                    <span className="pill">Click here to start meeting</span>
                  </a>

                  Join Url (Share this url to meeting attendees)
                  <a href={zoom_data.join_url}>{zoom_data.join_url}</a>
                </td>
              </tr>
              <tr>
                <td>
                 {data.agenda}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Footer />
      </div>
    )
  }

  return <Card/>;

}

export default Meeting;