import React, {Fragment} from "react";
import { Link } from "react-router-dom";

const JourneyTitle = (props) => {
  let project = props.project;
  let title = props.title;

  return (
    <Fragment>
      <div className="font-16 bold-600 ellipsis">
        {project && (project.data.org_name || project.data.name_org)} <br/>
        {project && (project.data.name)}
      </div>
      <div className="font-15">
        {title}
      </div>
    </Fragment>
  )
}

export default JourneyTitle;
