import React, {useState} from 'react';
import CueTooltip from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel} from '../FieldLabel'

const InputSlider = ({field, formData, modelarray, readOnly, errors, forceUpdate, formFn, isFormWizard, openFieldLabelModal, currentLocale}) => {

  let [showError, setShowError] = useState(false);

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'type':'range',
    'min':0,
    'max':field.max?field.max:'255',
    'disabled':(readOnly || field.read_only),
    'required':field.required
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  
  let validation = {
    required: field.required
  }

  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const handleChange = (event) =>{
    const { target } = event;
    const { name } = target;
    formData[name] = target.value;
    
    let ratio = ((target.value/ field.max) * 100).toFixed(3);
    target.style.background = 'linear-gradient(to right, #82CFD0 0%, #82CFD0 ' + ratio + '%, #fff ' + ratio + '%, white 100%)'
    forceUpdate();
  }

  const setError = (event) => {
    if(field.required){
      const { target:{name, validity:{valueMissing, typeMismatch}} } = event;
      errors[name].invalid = valueMissing;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  };

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  return (
  	<div {...parentAttributes}>
  	  <FieldLabel field={field} labelAttributes={labelAttributes} 
        currentLocale={currentLocale}
        openFieldLabelModal={openFieldLabelModal}/>
      <span>{field.min}</span>
      <span className="pull-right">{field.max}</span>
 	    <div className="slidecontainer">
        <input {...inputAttributes} onChange={e => handleChange(e)} onBlur={e => {setError(e)}}
        defaultValue={formData[field.client_id]} className="slider"
        data-tip data-for={`tooltip_${field.client_id}`}/>
      </div> 	 
  	  <span className="fts-13">{field.description}</span>
      <Error/>
      <CueTooltip id={field.client_id} description={field.cue_prompt_description}/>
 	  </div> 
  )
}

export default InputSlider;