import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import DynamicForm from '../../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../../constants/FormTemplateList'
import ProjectService from "../../../../components/Organizations/Projects/ProjectService";

let formMode = null; 
let project = {}; 
let data = {}; 
let formFn = {};  

function ProjectForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext);

  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;
  
  let currentUser = authState.user;

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    ProjectService.get(params).then((res) => {
      if(res.status==200){
        project = res.data.project;
        data = project.data || {};
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
       project = null;
    })
  }
  
  useEffect(() => { 
    project = {}; 
    data = {}; 
    formFn = {}; 
    formMode = 'create-form-submissions';
    data = {}; 
    if(params.id){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, [params.id]);

  const create = (project) => {
    project.accelerator_id = currentUser.accelerator.id;
    project.organization_id = params.organization_id;
    ProjectService.create(project).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (project) => {
    project.accelerator_id = currentUser.accelerator.id;
    project.organization_id = params.organization_id;
    ProjectService.update(project).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push(`/organization/projects/${params.organization_id}`)
  }

  if(isFormRendered){
    return (
    <div className="portlet">
      <div className="portlet-heading bg-highlight color-white ">
        <h4 className="portlet-title text-uppercase">
          {post.title}
        </h4>
         <div className="clearfix"></div>
      </div>
      <div id="portlet1" className="panel-collapse collapse in">
        <div className="portlet-body p-t-0">
          <div className="form-details" dangerouslySetInnerHTML={{ __html: post.details }}></div>
          <DynamicForm formMode={formMode} formId={FormTemplateId.ProjectRegistration} form={project} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
        </div>
      </div>
    </div>
    );  
  }else{
    return('')
  }
  
}

export default ProjectForm;