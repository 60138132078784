import React, {useState,Fragment} from "react";
import querystringify from "querystringify";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import Company from '../../Company/List/Company';
import CompanyProfileService from "../../Company/CompanyProfileService";
import Pagination from '../../Pagination'
import {Link} from "react-router-dom";
import useModal from "../../../hooks/useModal";
import CommentsModal from "../../Modals/CommentsModal";
import ProjectMenu from "../../Modals/ProjectMenu"
import MenuModal from "../../Modals/Menu";
import ConfirmMenu from "../../Modals/ConfirmMenu";
import FormTemplateId from '../../../constants/FormTemplateList'
import NotesModal from "../../Modals/NotesModal";
import ChecklistsModal from "../../Modals/ChecklistsModal"
import useStyle from '../../../hooks/useStyle';
import FilterModal from "../../Organizations/Activities/List/OrganizationsFilterModal";
import CompanyTypeMenu from "./CompanyTypeMenu";

let requestParams = {}
let selectedCompany = {}
let data_source = {};

export const CompanyList = (props) => {
  console.log('company list props',props)
  useStyle('card');
  useStyle('card_table');


  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  let cardGridCss = 'col-xs-12 col-sm-6 col-md-4'
  let cardHeightCss = 'ht-227'
  if(queryParam.id){
    cardGridCss = 'col-sm-12 col-sm-6 col-md-8'
    cardHeightCss = 'mih-380'
  }

  const { state:authState, dispatch } = React.useContext(AuthContext);

  const [companyList, setCompanyList] = useState([]);
  const [currentpage,setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const {isOpen: isJourneyMenuOpen, toggleModal: toggleJourneyMenu,} = useModal();


  const fn = {}; //common shareable object for all child element

  fn.deleteOrg = (sp) => {
    //Delete
    deleteServiceprovider(sp)
    console.log('sp',sp)
  };

  const { isOpen, toggleModal:toggleDeleteModal, success } = useModal(fn.deleteOrg);
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal, success:menuSuccess } = useModal();
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal, success:commentSuccess } = useModal();
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal, success:checklistSuccess } = useModal();
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal();


  React.useEffect(() => {
    requestParams = {
      'data_source_params': {"data_source_id":[3417,3418]},
      'page': currentpage, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'asc',
      'user_id': authState.user.id
    }

    getCompanyList();
  }, [authState.user.accelerator,props.searchString,currentpage]);

  let getCompanyList = () => { 
    CompanyProfileService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       console.log('res data advi',res)
       setPagemeta(res.data.meta)
       setCompanyList(res.data.journey_profiles)
       data_source = res.data.data_source_list;

      }
    })
  }

  let deleteServiceprovider = (sp) => {
    CompanyProfileService.delete(sp).then((res) => {
      if (res.status == 200) {
        console.log("delete adviosr", res);
        setPagemeta(res.data.meta);
        setCompanyList(res.data.advisors);

      }
    });
  }

  const addCompanyProfile = (e) => {
    e.preventDefault();
    let profile = authState.user.domain_info;
    /*if(profile.data.type_of_use == 1){
      //Personal
    }else if(profile.data.type_of_use == 2){
      //Organization Use
    }*/
    toggleJourneyMenu(e);
  };

  const Menu = ({ com, fn }) => (
    <span className="m-r-15" >
      <i onClick={e=>{addMenu(e, com)}} className="fas fa-bars" />  
    </span>
  );

  const onDelete = (e, com) => {
    selectedCompany = com;
    toggleDeleteModal(e);
  }

  const addMenu = (e, com) => {
    selectedCompany = com;
    toggleMenuModal(e);
  }

  const addNotes = (e, com) => {
    selectedCompany = com;
    toggleCommentModal(e);
  }

  const addChecklist = (e, com) => {
    selectedCompany = com;
    toggleChecklistModal(e);
  }

  const Details = ({ com, fn }) => (
    <Link className="m-l-15 pull-left text-muted" to={ `/advisor/details/${com.id}`}  >
    <i className="fa fa-info-circle" />
    
    </Link>
  );

  const routeActivity = (com) =>{
    props.history.push(`/advisor?id=${com.id}`)
    setCompanyList(companyList.filter(i => i.id == com.id))
  }

  const menuItems = ({item}) =>{
    console.log('item',item)
    return(
      <Fragment>
        <Link
              to={`/company_profile/${item.id}?form_id=${item.form_id}`}
            >
              <i className="far fa-edit"></i>
              <span className="font-13">Edit</span>
              <i className="fa fa-angle-right"></i>
            </Link>
      </Fragment>
    )
  }


  const companyCard = companyList.map((com, i) => (
    <div className={cardGridCss} key={i}>
      <Company cardHeightCss={cardHeightCss} routeActivity={routeActivity}  advisor={com}  menu={Menu} addNotes={addNotes} addChecklist={addChecklist}
        details={Details}
        toggleModal={toggleDeleteModal} />
        {selectedCompany.record_id == com.record_id && 
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedCompany} addChecklist={addChecklist} addNotes={addNotes} onDelete={onDelete} type="company_profile" MenuItems={menuItems}/>
        }
    </div>
  ))

  return (
    <React.Fragment>
     <div className="content top-10">
        <h5 className="float-left font-500">Company Journeys </h5>

        <Link to='' onClick={(e) => addCompanyProfile(e)} className="bg-highlight round-btn float-right m-r-5">  <i className="fas fa-plus"></i></Link>


        <div className="clear"></div>
    </div>

    <div className="page-content row">
        {companyCard}
        {queryParam.id && companyList.length>0 && <div className="col-sm-12 col-sm-6 col-md-4">
        <CommentsModal isOpen="true" toggleModal={toggleCommentModal} item={selectedCompany} type="company_profile" showInline="true" /> 
        </div>}
        </div>
    <ConfirmMenu
        isOpen={isOpen}
        toggleModal={toggleDeleteModal}
        success={success}
      />
    {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedCompany} type="company_profile"/>}
    {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} item={selectedCompany} type="company_profile"/>}
    {isJourneyMenuOpen && (
        <CompanyTypeMenu
          isOpen={isJourneyMenuOpen}
          toggleModal={toggleJourneyMenu}
          data_source={data_source}
        />
      )}
    </React.Fragment>
  )

};

export default CompanyList;
