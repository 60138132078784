import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import DynamicForm from '../../../FormBuilder/DynamicForm';
import FormTemplateId from '../../../../constants/FormTemplateList'
import AdvisorService from "../../../Advisor/AdvisorService";

let formMode = null; 
let formId = null; 
let advisor = {}; 
let data = {}; 
let formFn = {};  

function AdvisorProfileForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext);

  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;
  
  let currentUser = authState.user;

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    AdvisorService.get(params).then((res) => {
      console.log('advisor get result',res)
      if(res.status==200){
        advisor = res.data.advisor;
        data = advisor.data || {};
        console.log('get data advisor',data)
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
      advisor = null;
    })
  }
  
  useEffect(() => { 
    advisor = {}; 
    data = {}; 
    formFn = {};
    formMode = 'create-form-submissions';
    if(props.match.path){
      if(props.match.path.includes('details')){
        console.log('details')
        get()
        formMode = 'view-form-submissions';
      }else if(params.id){
        console.log('edit')
        get()
        formMode = 'edit-form-submissions';
      }else if(!isFormRendered){
        setFormRendered(true);
      }
    }
  }, [params.id]);

  const create = (advisor) => {
    advisor.accelerator_id = currentUser.accelerator.id;
    AdvisorService.create(advisor).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (advisor) => {
    advisor.accelerator_id = currentUser.accelerator.id;
    AdvisorService.update(advisor).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push("/advisor")
  }

  if(isFormRendered){
    return (
    <div className="portlet">
      <div className="portlet-heading bg-highlight color-white">
        <h4 className="portlet-title text-uppercase">
          {post.title}
        </h4>
         <div className="clearfix"></div>
      </div>
      <div id="portlet1" className="panel-collapse collapse in">
        <div className="portlet-body p-t-0">
          <div className="form-details" dangerouslySetInnerHTML={{ __html: post.details }}></div>
          <DynamicForm formMode={formMode} formId={FormTemplateId.AdvisorRegistration} form={advisor} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
        </div>
      </div>
    </div>
    );  
  }else{
    return('')
  }
  
}

export default AdvisorProfileForm;