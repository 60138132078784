import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import Rating from "../../../../components/Rating"
import useStyle from '../../../../hooks/useStyle';
import useSort from '../../../../hooks/useSorting';
import SortDirection from '../../../../components/Common/SortDirection'

const AnalyzeTable = ({list, getStepActivities, requestParams}) => {
  useStyle('table');  

  const {sort} = useSort(requestParams, getStepActivities);

  const tableData = list && list.map((o, k) =>
    <tr key={k}>
      <td data-title="Id">{o.record_id}</td>
      <td data-title="Title">{o.data.name}</td>
      <td data-title="No">{o.data.number}</td>
      <td data-title="Start Date">{o.data.start_date}</td>
      <td data-title="End Date">{o.data.end_date}</td>
      <td data-title="Progress">{o.status && o.status.data.progress}0%</td>
    </tr>
  )

  return (
    <div id="no-more-tables" className="col-xs-12 p-5 table-responsive">
      <table className="shadow-small">
        <thead>
          <tr className="white bg-highlight">
            <th onClick={e=>sort('record_id')}>Id 
              <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('data.title')}>Title
              <SortDirection sort_column={requestParams.sort_column} column="data.title" reverse={requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('data.number')}>No 
              <SortDirection sort_column={requestParams.sort_column} column="data.number" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.start_date')}>Start Date 
              <SortDirection sort_column={requestParams.sort_column} column="data.start_date" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.end_date')}>End Date 
              <SortDirection sort_column={requestParams.sort_column} column="data.end_date" reverse={requestParams.reverse}/>
            </th>
            <th>Progress</th>
          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </table>
    </div>
  )

};

export default AnalyzeTable;