import React, { useEffect, useState } from 'react';
import querystringify from "querystringify"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../components/PreLoadSpinner";
import DynamicForm from '../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../constants/FormTemplateList'
import FeedbackService from "../Feedback/FeedbackService";
import FormContainer from '../../components/FormBuilder/FormContainer'


let formMode = null; 
let formId = null; 
let feedback = {}; 
let data = {}; 
let formFn = {};  

function FeedbackForm(props) {
  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  useEffect(() => { 
    formMode = 'create-form-submissions';
    data = {}; 
    feedback = {}; 
    formFn = {};

    if(props.match.path){
      if(props.match.path.includes('details')){
        //getById(params.education_journey_profile_id)
        formMode = 'view-form-submissions';
      }else{
        setFormRendered(true);
      }
    }
  }, []);


  const create = (feed_back) => {
    feed_back.created_by = currentUser.id;
    feed_back.data.page_url = queryParam.url; 
    FeedbackService.create(feed_back).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push(queryParam.url);
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.FeedbackForm} form={feedback} data={data} 
            formFn={formFn} onCreate={create} post={post} onCancel={onSubmitPageRedirect} setPost={setPost} />
      </FormContainer> 
    )  
  }else{
    return null
  }
  
}

export default FeedbackForm;