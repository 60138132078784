import React, { Fragment, useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import JourneyProfileService from "../../JourneyProfileService"
import useStyle from "../../../../hooks/useStyle"
import CheckAccess from "../../../Roles/CheckAccess"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../PreLoadSpinner"
import Cs from "../../../../services/CommonService"
import querystringify from "querystringify"
import useModal from "../../../../hooks/useModal"
import Tabs, { TabPane } from "rc-tabs"
import JourneyTitle from "../JourneyTitle/JourneyTitle"
import GenericModal from "../../../Modals/GenericModal"
import {PortalToggleMenu} from "./Common"
import Components from './Components'
import ScribblePadList from '../../../ScribblePad/ScribblePadList'
import AudioRecordingList from '../../../AudioRecordings/List'

let dataSource = {};
let formProps = null;
let extraProps = {};
const PortalTabs = (props) => {
  useStyle("card");
  useStyle("card_table");
  useStyle("add_role_dialog");
  useStyle("panel");

  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  const {state: { user: currentUser }, dispatch,} = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [journey, setJourney] = useState({});
  const { isOpen: isSignaturePadOpen, toggleModal:toggleSignaturePad } = useModal()
  const { isOpen: isAudioRecorderOpen, toggleModal:toggleAudioRecorder } = useModal()

  useEffect(() => {
    let req = {
      id: params.journey_profile_id,
      data_source_params:{
        data_source_id: [3624, 3681, 3682]
      }
    }

    JourneyProfileService.get(req).then((res)=>{
      setLoading(true)
      if(res.status == 200){
        res = res.data
        dataSource = res.data_source_list
        if(queryParam.type == 'emissions_reduction'){
          dataSource.firstTabs = dataSource.cc_ppt_items.options
          dataSource.secondTabs = dataSource.ccdj_nodes.options
        }
        
        setJourney(res.journey_profile)
        setLoading(false)
      }
    })
  }, [])

  const openAudioRecorder = (e) =>{
    toggleAudioRecorder(e)
  }

  const openSignaurePad = (e) => {
    toggleSignaturePad(e)
  }

  extraProps.roles = {
    match:{
      params:{
        journey_profile_id: params.journey_profile_id,
      }
    },
    location:{
      search: `?component_id=3475`,
      state:journey
    }
  }
    
  extraProps.users = {
    match:{
      params:{
        journey_profile_id: params.journey_profile_id,
      }
    },
    location:{
      search: `?permission_list_id=3475`,
      state:{journeyProfile:journey}
    }
  }

  const Menu = () => (
    <span id="portal_menu" className="portlet-dropdown">
      <a data-tip="Journey Menu" className="p-dropbtn">
        <i className="fas fa-bars white"></i>
      </a>
      <div className="p-dropdown-content">
        {!journey.is_admin_user && <Fragment>
            <Link to={`/journey_profile/form/${journey.id}?form_id=${journey.form_id}`}>
              <i className="far fa-edit"/> Edit
            </Link>
            <Link to={{
                pathname: `/invite/users/${journey.id}`,
                state: {'journeyProfile':journey, 'share':null},
                search:"?permission_list_id=3475"
              }}>
              <i className="fa fa-user"></i> Invite User
            </Link>
            <Link to={{
                pathname: `/path/${journey.id}`,
                state: journey,
                search:"?menu=3475"
              }}>
              <i className="fa fa-path"/> Path
            </Link>
          </Fragment>
        }
      </div>
    </span>
  )

  if (loading) return <PreLoadSpinner />

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-8">
          <JourneyTitle title="Overview" project={journey} />
        </div>
        <div className="col-xs-4">
          <span className="bg-highlight round-btn float-right m-r-5 m-t-4">
              <Menu />
          </span>
          <Link data-tip="Back to Journey List" className="bg-highlight round-btn float-right m-r-5 m-t-4" to={{pathname: '/journey'}}>
            <i className="fas fa-home"></i>
          </Link>
          <span onClick={e => openAudioRecorder()} className="bg-highlight round-btn float-right m-r-5 m-t-4">
            <i className="fas fa-microphone" />
          </span>
          <span onClick={e => openSignaurePad()} className="bg-highlight round-btn float-right m-r-5 m-t-4">
            <i className="fas fa-marker" />
          </span>
          <span className="bg-highlight round-btn float-right m-r-5 m-t-4">
            <i className="fas fa-paperclip" />
          </span>
        </div>
      </div>

      <div className="content row" style={{position:"static"}}>
        <Tabs defaultActiveKey="0" moreIcon="+">
          {dataSource.firstTabs.map((tab, key) =>
              <TabPane tab={tab.label} key={key}>
                <Components componentName={tab.sid} journey={journey} formId={journey.form_id} isPopUpMode="true" {...extraProps[tab.sid]} />      
              </TabPane>
            )
          }
        </Tabs>
      </div>

      <div className="content">
        <div className="bg-white">
          <div className="row m-t-15 bg-white">
            <Tabs defaultActiveKey="0">
              {dataSource.secondTabs.map((tab, key) =>
                  <TabPane tab={tab.label} key={key}>
                    <Components componentName={tab.sid} journeyProfile={journey} dataSource={tab} {...extraProps}/>      
                  </TabPane>
                )
              }  
            </Tabs>
          </div>
        </div>
      </div>
      
      {isSignaturePadOpen && 
        <GenericModal component={ScribblePadList} toggleModal={toggleSignaturePad} 
        title="Notes" journeyProfileId={journey.id}/>
      }

      {isAudioRecorderOpen && 
        <GenericModal component={AudioRecordingList} toggleModal={toggleAudioRecorder} 
        title="Record Audio" journeyProfileId={journey.id}/>
      }

      <div className="m-b-80" />
    </Fragment>
  )
}

export default PortalTabs;