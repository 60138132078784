import React, {useRef, useEffect, useState} from 'react'
import SignaturePad from '../FormBuilder/InputComponents/signature_pad'
import ScribblePadService from '../../services/ScribblePadService'

let signaturePad = null;

const SignaturePadView = ({canvasData, onSave}) => {
  //importScript("/node_modules/signature_pad/dist/signature_pad.min.js");
  const inputEl = useRef(null)

  useEffect(() => {
    setCanvas()
  }, [canvasData])

  const save = () => {
    onSave(signaturePad.toData())
  }

  const setCanvas = () => {
    signaturePad = new SignaturePad(inputEl.current, {
      backgroundColor: 'rgb(255, 255, 255)',
    })

    if(canvasData)signaturePad.fromData(canvasData);
  }

  const clear = (e) =>{
    e.stopPropagation();
    signaturePad.clear();
  }

  /*const dataURLtoFile = (dataurl, filename) => {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
  }*/

  return (
    <>
      <div className="row p-5">
        <div className="col-xs-12 p-5">
          <div className="wrapper">
            <canvas ref={inputEl} className="signature-pad br-grey center-block" width="400px" height="200px"/>
            <button type="button" onClick={e=>clear(e)} className="btn btn-inverse">Clear</button>
            <button type="button" onClick={e=>save()} className="btn btn-inverse m-l-15">Save</button>
          </div> 	
        </div>
      </div> 
    </>
  )
}

export default SignaturePadView;