import React, {useState, Fragment} from "react";
import ReactTooltip from 'react-tooltip';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import DigitalContent from '../../../components/DigitalContent/List/DigitalContent';
import DigitalContentService from "../../../components/DigitalContent/DigitalContentService";
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu";
import FormTemplateId from '../../../constants/FormTemplateList';
import CommentsModal from "../../Modals/CommentsModal";
import ProjectMenu from "../../Modals/ProjectMenu"
import {Link} from "react-router-dom";
import useModal from "../../../hooks/useModal";
import NotesModal from "../../Modals/NotesModal";
import MenuModal from "../../Modals/Menu";
import ChecklistsModal from "../../Modals/ChecklistsModal"
import useStyle from '../../../hooks/useStyle';
import FilterModal from "../List/DigitalContentFilterModal";
import {MenuItem,NavIcon} from '../../Common/MenuItem'
import SideMenuBar from "../../Common/SideMenuBar";

let requestParams = {}
let selectedDigitalContent = {};
let data_source = {};

const DigitalContentList = (props) => {
  useStyle('card');
  useStyle("card_table");

  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext);
  const [digitalContentList, setDigitalContentList] = useState([]);
  const [currentpage,setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);

  const deleteJourney = (e) => {
    e.preventDefault();
    DigitalContentService.delete(selectedDigitalContent).then((res) => {
      if (res.status == 204) {
        setDigitalContentList(digitalContentList.filter(i => i.id !== selectedDigitalContent.id))
      }
    })
    toggleDeleteModal(e);
  }

  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal, success:commentSuccess } = useModal();
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal();
  const { isOpen:isFormFilterOpen, toggleModal:toggleFormFilterModal } = useModal();
  const { isOpen, toggleModal:toggleDeleteModal } = useModal();
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();

  React.useEffect(() => {
    requestParams = {
      'data_source_params': {
        "data_source_id":[3511],
        "response_type":"object"
      },
      'content_type': 'eduation',
      'page': currentpage,
      'paginate': 1,
      'per_page': 10,
      'sort_column': 'updated_at',
      'sort_direction': 'desc',
      'totalItems': 0,
      'search': null,
    }

    getJourneyList();
  }, [currentUser.id, currentpage]);

  let getJourneyList = (content_type) => {
    requestParams.content_type = content_type 
    DigitalContentService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
      data_source = res.data.data_source_list;
       //setPagemeta(res.data.meta)
       requestParams.totalItems = res.data.meta.total
       setDigitalContentList(res.data.digital_contents)
      }
    })
  }

 const addDelete = (e, ja) => {
    selectedDigitalContent = ja;
    toggleDeleteModal(e);
  }

  const addMenu = (e, ja) => {
    selectedDigitalContent = ja;
    toggleMenuModal(e);
  }

  const addNotes = (e, ja) => {
    selectedDigitalContent = ja;
    toggleCommentModal(e);
  }

  const updateStatus = (e, item) =>{
    e.preventDefault()
    item.is_active = !item.is_active; 
    DigitalContentService.update(item).then((res) => {
      if(res.status==204){
        setDigitalContentList([...digitalContentList])
      }
    })
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <MenuItem id="edit_icon" 
          link={`/digital_contents/edit/${item.id}?`}
          icon="far fa-edit"
          action="Edit"
        />
        <MenuItem id="delete_icon" 
          icon="far fa-trash-alt"
          action="Delete"
          onSelect={(e) => {addDelete(e, item);toggleMenuModal(e);}}
        />
        <MenuItem id="chat_icon" 
          icon="fa fa-notes-medical"
          action="Comments"
          onSelect={(e) => {addNotes(e, item);}}
        />
        <a href="/" onClick={e=>updateStatus(e, item)}>
          <i className={item.is_active ? "far fa-check-circle": "far fa-circle"}></i>
          <span className="font-13">Pub Active</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const Menu = ({ ja, fn }) => (
    <div className="pull-right m-r-15">
      <i  onClick={e=>{addMenu(e, ja)}} className="fas fa-bars" />     
    </div>
  );

  const Details = ({ ja, fn }) => (
    <div className="m-l-15">
      <Link data-tip="Details" to={`/digital_contents/details/${ja.id}`}  ><i className="fa fa-info-circle" /></Link>
    </div>
  );
     
  const newsListCard =  digitalContentList && digitalContentList.map((ja, i) => (
    <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
      <DigitalContent digitalContent={ja}  menu={Menu} details={Details}
        toggleModal={toggleDeleteModal} addNotes={addNotes}/>
        { selectedDigitalContent.id == ja.id && 
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedDigitalContent} addDelete={addDelete} type="journey" MenuItems={menuItems}/>
        }
    </div>
  ))
  
  return (
    <React.Fragment>
      <div className="content top-10">
        <h4 className="float-left font-500 m-t-5">Digital Content</h4>
        
        <NavIcon id="filter_items" dataTip="Filter Items" 
          className="bg-highlight round-btn float-right m-r-5 m-t-4" 
          onSelect={toggleFilterModal} icon="fas fa-sliders-h"/>

          <NavIcon id="create_items" dataTip="Create New Item" 
          className="bg-highlight round-btn float-right m-r-5 m-t-4" 
          onSelect={toggleFormFilterModal} icon="fas fa-plus"/>

        <ReactTooltip place="left" id='dg_tooltip' />

        <div className="clear"></div>
      </div>
      <div className="page-content row">
        {newsListCard}
        <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} 
        setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
      </div>
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteJourney}/>
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} 
      item={selectedDigitalContent} type="digital_content"/>}
      {isFilterOpen && <FilterModal isOpen={isFilterOpen} toggleModal={toggleFilterModal} 
      filterType="data_filter" onSelect={getJourneyList} menuList={data_source}/>}
      {isFormFilterOpen && <FilterModal isOpen={isFormFilterOpen} toggleModal={toggleFormFilterModal} 
      menuList={data_source} />}
      <SideMenuBar helpId="2"/>
    </React.Fragment>
  )

}

export default DigitalContentList;