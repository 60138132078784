import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

// Increase pixel density for crop preview quality on retina screens.
const pixelRatio = window.devicePixelRatio || 1;

// We resize the canvas down when saving on retina devices otherwise the image
// will be double or triple the preview size.
function getResizedCanvas(canvas, newWidth, newHeight) {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext("2d");
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  )

  return tmpCanvas;
}

function generateDownload(previewCanvas, crop, saveImage, fileName, selectedFile) {
  if (!crop || !previewCanvas) {
    return;
  }

  const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);

  canvas.toBlob(
    (blob) => {
      var file = new File([blob], `${fileName}.png`, {type:"image/png", lastModified:new Date()})
      saveImage(null, selectedFile, file)
    },
    "image/png",
    1
  )
}

export default function App(props) {
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: 'px', x: 0, y: 0, width: 200, height: 200 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [fileName, setFileName] = useState(null);
  //const [selectedFile, setSelectedFile] = useState(props.selectedFile);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      //setSelectedFile(e)
      readFile(e.target.files[0])
    }
  }

  const readFile = (file) =>{
    const reader = new FileReader();
    reader.addEventListener("load", () => setUpImg(reader.result));
    reader.readAsDataURL(file);
    setFileName(file.name)
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if(props.selectedFile){
      setTimeout(function() {
        readFile(props.selectedFile);
      }, 500)
    }
  },[])

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  return (
    <div className="App p-12">
      <div className="m-10">
        <label className="btn btn-default w-100p bg-highlight color-white" htmlFor="file_selector">   
          Click to Upload File
        </label>
        <input id="file_selector" className="hidden" type="file" accept="image/*" onChange={onSelectFile} />
      </div>
      <ReactCrop
        src={upImg}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => setCompletedCrop(c)}
        minWidth = {props.minWidth}
        minHeight = {props.minWidth}
        maxWidth = {props.maxWidth}
        maxHeight = {props.maxHeight}
        locked="true"
        style={{padding:"10px",margin:"10px"}}
      />
      <div className="m-10">
        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0)
          }}
        />
      </div>
      <button className="btn btn-inverse white m-10" type="button" disabled={!completedCrop?.width || !completedCrop?.height}
        onClick={() =>
          generateDownload(previewCanvasRef.current, completedCrop, props.saveImage, fileName, imgRef.current)
        }
      >
        SAVE
      </button>
    </div>
  )
}