import React, {useContext, Fragment} from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import Cs from "../../../../../services/CommonService";
import FormTemplateId from "../../../../../constants/FormTemplateList";

const Management = (props) => {
  const { state: authState, dispatch } = useContext(AuthContext);

  let management = props.management;
  management.updated_at = Cs.formatUpdateDate(management.updated_at);
  management.created_at = Cs.formatUpdateDate(management.created_at);

  let data = management.data;
  let ds = management.data_source || {};

  const SpanField = ({name, label, styleName}) =>(
    <span className={styleName}>{ds[name] && ds[name][label]}</span>
  )

  const SpanList = ({name, label, styleName}) => {
    if(ds[name]){
      return(
        <Fragment>
          {ds[name].map((d, i) => (
            <span className={styleName +' m-l-5'} key={i}>{d.data[label]}</span>
          ))}
        </Fragment>
      )
    }else{
      return null
    }
  }
    
  const cardStyle = {backgroundColor: "#607D8B"}

  if (!data) return null;

  let incomingMailLink = {
    pathname: `/journey/incoming/emails/management/${management.id}`,
    state: { journey: props.journeyCategory, management: management },
  }

  const MailTag = () => (
    <a href={`mailto: ${management.id}-management@reply.cuetree.com?subject=Management-${data.name}-${management.record_id}`}
      target="_blank">
      <i className="far fa-envelope font-18 text-muted" aria-hidden="true"></i>
    </a>
  )

  const Header = () => (
    <div className="white card-header p-5" style={cardStyle}>
      <div className="font-14 lht-14 m-7 ellipsis">
        {management.management_type}
        <i className="fas fa-expand pull-right white expand p-3" onClick={(e) => props.routeActivity(management)}></i>
      </div>
    </div>
  )

  const FileList = ({files}) => {
    return(
      <Fragment>
          <a className="m-t-5 inline-files-list" target="_blank" href={Cs.getFileUrl(files)}>
            <img src={Cs.getIconByType(files)} width="35" height="35"/>
            <span className="m-l-5">{Cs.getFileName(files)}</span>
          </a>
      </Fragment>
    )
  }

  const Footer = () => (
    <div className="card-footer">
      <div className="pull-left lgrey">
        <span id={`comment_${props.index}`} className="m-l-25"
          onClick={(e) => props.addComment(e, management)}>
          <i className="far fa-comment font-18 text-muted" data-tip="Chat"></i>
          <span className="badge up bg-lgrey">{management.comments_count || 0}</span>
        </span>
        <span id={`check_list_${props.index}`} className="m-l-25"
          onClick={(e) => props.addChecklist(e, management)}>
          <i className="far fa-check-circle font-18 text-muted" data-tip="Checklist"></i>
          <span className="badge up bg-lgrey">{management.check_list_count || 0}</span>
        </span>
        <span id={`mail_${props.index}`} className="m-l-25">
          <Link onClick={(e) => e.stopPropagation()} to={incomingMailLink}>
            <i className="far fa-envelope font-18 text-muted" data-tip="Mail"></i>
            <span className="badge up bg-lgrey">
              {management.mail_count || 0}
            </span>
          </Link>
        </span>
      </div>
      <div className="pull-right">
        <props.menu management={management} />
      </div>
    </div>
  )

  const Agreement = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <Header/>
        <div className={`scroll-auto ${props.cardHeightCss}`}>
          <span className="pull-right font-11 lgrey m-l-15 p-7">
            Last by {management.updated_user.name} {management.updated_at}
          </span>
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                <strong>Party 1</strong>
                  <div className="font-15"><SpanField name="party_1" label="label" styleName="pill text-left"/></div>
                </td>
              </tr>
              <tr>
                <td>
                <strong>Party 2</strong>
                <div className="font-15"><SpanField name="party_2" label="label" styleName="pill text-left"/></div>
              </td>
              </tr> 
              <tr>
               <td>
              <strong>Date</strong>
                <div className="font-15">{data.start_date}</div>
                <div className="font-15">{data.end_date}</div>
                </td>
                </tr>
                <tr>
                  <td>
                  <strong>Purpose</strong>
                  <div className="font-15">{data.purpose}</div>      
                  </td>
                </tr>
                <tr>
                  <td>
                  <strong>Fees</strong>
                    <div className="font-15">{data.fees}</div>
                  </td>
                </tr>
                <tr>
                  <td>     
                  <strong>Link</strong>
                  <a href={data.url}>{data.url}</a>
                  </td>
                  <td>
                  {data.upload && data.upload.length>0 && <span className="pointer pull-left m-r-15">
                    <i className="fas fa-paperclip color-highlight"></i><a href={data.upload} target="_blank">
                    {data.upload}
                    </a>
                  </span>}
                </td>
                </tr>
            </tbody>
          </table>
        </div>
        <Footer />
        <ReactTooltip place="left" />
      </div>
    )
  }

  const WorkOrder = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <Header/>
        <div className={`scroll-auto ${props.cardHeightCss}`}>
          <span className="pull-right font-11 lgrey m-l-15 p-7">
            Last by {management.updated_user.name} {management.updated_at}
          </span>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                  {data.party_1}
                </td>
                <td>
                  {data.party_2}
                </td>
              </tr>
              <tr>
                <td>
                <SpanField name="choice_standard_terms" label="label" styleName="pill text-left"/>
                </td>
                <td>
                  {data.url}
                </td>
              </tr>
              <tr>
              <td>
                  {data.upload && data.upload.length>0 && <span className="pointer pull-left m-r-15">
                    <i className="fas fa-paperclip color-highlight"></i><a href={data.upload} target="_blank">
                    {data.upload}
                    </a>
                  </span>}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Footer />
        <ReactTooltip place="left" />
      </div>
    )
  }

  const Resource = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <Header/>
        <div className={`scroll-auto ${props.cardHeightCss}`}>
          <span className="pull-right font-11 lgrey m-l-15 p-7">
            Last by {management.updated_user.name} {management.updated_at}
          </span>
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                  {data.name}
                </td>
              </tr>
              <tr>
                <td>
                  {data.url}
                </td>
              </tr>
              <tr>
                <td>
                <SpanField name="choice_standard_terms" label="label" styleName="pill text-left"/>
                </td>
              </tr>
              <tr>
                <td>
                  <FileList files={data.upload}/>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <Footer />
        <ReactTooltip place="left" />
      </div>
    )
  }


  if( management.form_id == 415) {
    return <Agreement/>;
  }else if(management.form_id == 416){
    return <WorkOrder/>;
  }else if(management.form_id == 466){
    return <Resource/>;
  }else{
    return null
  }
  
}

export default Management;