import React, { useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import querystringify from "querystringify";
import PreLoadSpinner from "../../../components/PreLoadSpinner";
import DynamicForm from "../../../components/FormBuilder/DynamicForm";
import FormTemplateId from "../../../constants/FormTemplateList";
import CompanyProfileService from "../CompanyProfileService";
import CommonService from "../../../services/CommonService";
import FormWizard from "../../../components/FormBuilder/FormWizard";
import env from "../../../env";

let formMode = null;
let formWizardId = null;
let journeyProfile = {};
let data = {};
let formFn = {};
let requestParams = {};

function CompanyProfileForm(props) {
  const { state: authState, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  formFn.school_name_pagenate = {page:1, per_page:10}
  formFn.school_name_path = 'school_name';

  formFn.school_zipcode_pagenate = {
    page: 1,
    per_page: 10,
    unique: true,
    fields: "zip",
    sort_column: "zip",
  };
  formFn.school_zipcode_path = "zip";

  let currentUser = authState.user;

  /*TODO: Move API to Router to load data before view*/
  const getById = (id) => {
    console.log('id',id)
    CompanyProfileService.get(id).then((res) => {
      if (res.status == 200) {
        journeyProfile = res.data.journey_profile;
        data = journeyProfile.data || {};
        setFormRendered(true);
      }
    }).catch((res)=> {
      journeyProfile = null;
    });
  }

  useEffect(() => {
    formMode = "create-form-submissions";
    data = {};
    journeyProfile = {};
    formFn = { form_type: "education_journey_profile" };
    requestParams = {};
    if (queryParam.view_mode) {
      getById(params.id);
      formMode = "view-form-submissions";
    } else if (params.id) {
      formMode = "edit-form-submissions";
      getById(params.id);
    } else {
      setFormRendered(true);
    }
  }, [])

  const create = (journey_profile) => {
    journey_profile.created_by = authState.user.id
    journey_profile.updated_by = authState.user.id
    journey_profile.profile_id = authState.user.accelerator.id
    journey_profile.form_id = queryParam.form_id
    journey_profile.journey_type = queryParam.fj_type_edu_jou
    journey_profile.journey_type_id = parseInt(queryParam.fj_type_edu_jou);
    journey_profile.data.fj_type_edu_jou = parseInt(queryParam.fj_type_edu_jou);
    journey_profile.form_id = queryParam.form_id;
    CompanyProfileService.create(journey_profile).then((res) => {
      if (res.status == 201) {
        props.history.push(
          `/company/portal/${res.data.journey_profile.id}`
        );
      }
    })
  }

  const update = (journey_profile) => {
    CompanyProfileService.updateJourneyProfile(journey_profile).then((res) => {
      if (res.status == 204) {
        journeyProfile = journey_profile;
        props.history.push(
          `/company/portal/${journeyProfile.id}`
        );
      }
    })
  }

  const onSubmitPageRedirect = (show_success_message) => {
    props.history.push(
      `/company/portal/${journeyProfile.id}`
    );
  };

  const back = () => {
    props.history.goBack();
  };

  formFn.get_school_zipcode = function (search, on_success, on_failure) {
    if (data.school_location == "Other") return;
    formFn.school_zipcode_pagenate.zip = search;

    let req = {
      method: "get",
      url: `${env.family_tree_backend}schools`,
      params: formFn.school_zipcode_pagenate,
    };

    CommonService.useAxios(req).then((res) => {
        formFn.school_zipcode_list = res.data.schools;
        if (res.data.meta.total && formFn.school_zipcode_pagenate.per_page) {
          formFn.school_zipcode_pagenate.pageSize = Math.ceil(
            res.data.meta.total / formFn.school_zipcode_pagenate.per_page
          );
        }
        on_success(formFn.school_zipcode_list);
    }).catch((err) => {
        console.log(err);
    });
  };

  formFn.set_school_zipcode = function (obj) {
    data.school_zipcode = obj.zip;
    //formFn.get_school_name(obj.zip, true);
    formFn.school_zipcode_list = [];
  };

  formFn.get_school_name = function(search, on_success, on_failure){
    if(data.school_location=='Other')return;
    formFn.school_name_pagenate.school_name = search;  

    var req = {
      method: 'get',
      url: `${env.family_tree_backend}schools`,
      params: formFn.school_name_pagenate
    }
    
    CommonService.useAxios(req).then((res) => {
      formFn.school_name_list = res.data.schools;
      if(res.data.meta.total && formFn.school_name_pagenate.per_page){
        formFn.school_name_pagenate.pageSize = Math.ceil(res.data.meta.total/formFn.school_name_pagenate.per_page);
      }
      on_success(formFn.school_name_list);
    }).catch((err) => {
      console.log(err);
    })
  }

  formFn.set_school_name = function(obj){
    data.school_zipcode = obj.zip;
    data.school_name = obj.school_name;
    formFn.school_name_list = [];
  }

  if (queryParam.show_form_wizard) {
    return (
      <FormWizard
        form_wizard_id={queryParam.form_wizard_id}
        form={journeyProfile}
        data={data}
        formFn={formFn}
        onCreate={create}
        onUpdate={update}
        onCancel={onSubmitPageRedirect}
      />
    );
  }

  if (isFormRendered) {
    return (
      <div className="portlet">
        <div className="portlet-heading bg-highlight color-white">
          <h4 className="portlet-title text-uppercase">{post.title}</h4>
          <div className="clearfix"></div>
        </div>
        <div id="portlet1" className="panel-collapse collapse in">
          <div className="portlet-body p-t-0">
            <div
              className="form-details m-t-10"
              dangerouslySetInnerHTML={{ __html: post.details }}
            ></div>
            <DynamicForm
              formMode={formMode}
              formId={queryParam.form_id}
              form={journeyProfile}
              data={data}
              formFn={formFn}
              onCreate={create}
              onUpdate={update}
              post={post}
              onCancel={back}
              setPost={setPost}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default CompanyProfileForm;
