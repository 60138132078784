import React from 'react';
import PrivateRoute from "../../routes/PrivateRoute";
import HelpList from '../../components/Help/List/HelpList'
import HelpForm from '../../components/Help/Form/HelpForm'


const HelpRouter = (props) => (
  <React.Fragment>	
    <PrivateRoute path="/help" exact >
      <HelpList searchString={props.searchString}/>
    </PrivateRoute>
    <PrivateRoute path="/help/create/:id?" component={HelpForm} exact />
  </React.Fragment>
)

export default HelpRouter;


/*
    <PrivateRoute path="/news/activity/create/:journey_category_id?/:education_journey_profile_id?" component={JourneyActivityDashboard} exact />
    */