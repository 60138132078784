import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, Prompt, useParams} from 'react-router-dom'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import InputTextField from './InputComponents/InputTextField';
import InputTextArea from './InputComponents/InputTextArea';
import InputSelect from './InputComponents/InputSelect';
import InputRadio from './InputComponents/InputRadio';
import InputCheckbox from './InputComponents/InputCheckbox';
import InputDataTimePicker from './InputComponents/InputDataTimePicker';
import InputColorPicker from './InputComponents/InputColorPicker'
import InputMultiSelect from './InputComponents/InputMultiSelect'
import InputMultiSelectSearch from './InputComponents/InputMultiSelectSearch'
import InputMultiFileUpload from './InputComponents/InputMultiFileUpload'
import InputFileUpload from './InputComponents/InputFileUpload'
import InputMultiRating from './InputComponents/InputMultiRating'
import InputRating from './InputComponents/InputRating'
import InputTags from './InputComponents/InputTags'
import InputExplanatoryText from './InputComponents/InputExplanatoryText'
//import InputMapLocation from '../../components/FormBuilder/InputComponents/InputMapLocation'
import InputSlider from './InputComponents/InputSlider'
import InputAutoComplete from './InputComponents/InputAutoComplete'
import InputSignaturePad from './InputComponents/InputSignaturePad'
import InputRichText from './InputComponents/InputRichText'
import InputRichImageMap from './InputComponents/InputRichImageMap'
import InputLottieSvg from './InputComponents/InputLottieSvg'
import FormHelper from './FormHelpers';
import CommonService from '../../services/CommonService';
//import { useForm } from "react-hook-form";
import Tabs, { TabPane } from 'rc-tabs';
import FormSubmit from './FormSubmit'
import ErrorsList from './ErrorsList'
import ExitForm from "../Modals/ExitForm";
import PreLoadSpinner from "../PreLoadSpinner";
import DataSourceListModal from "../DataSources/DataSourceListModal"
import useModal from "../../hooks/useModal";
import useStyle from "../../hooks/useStyle";

//let template = {};
let readOnly = true;
let errors = {invalid:{}};
let noOfTabs = 0;
let template = {};
let isFormSubmitted = false;
let selectedFormField = {}

const DynamicForm = (props) => {
  useStyle("add_role_dialog");
  
  const { state:currentUser, dispatch } = React.useContext(AuthContext);
  currentUser.user = currentUser.user || {};
  const screen = currentUser.screen
  //const { handleSubmit, register, errors, setValue} = useForm();
  const [activeKey, setActiveKey] = useState('0');
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const params = useParams()
  const history = useHistory()
  const {isOpen: isDataSourceModalOpen, toggleModal: toggleDataSourceModal,} = useModal();

  const forceUpdate = () =>{
    setCount(value => ++value)
  }

  props.formFn.refreshForm = () => forceUpdate()

  const validationProps = {
    'forceUpdate':forceUpdate,
    'errors':errors
  }

  const onSubmit = (draft, event) => {
    setLoading(true)
    console.log('onSubmit')
    //ct_form.$valid = false;
    event.stopPropagation();
    isFormSubmitted = true;

    props.form.updated_by = currentUser.user.id;
    props.form.draft = draft?true:false;
    props.form.data = props.data;

    if(props.formMode == 'create-form-submissions'){
      props.form.created_by = currentUser.user.id;
      props.onCreate(props.form);
     // setLoading(false)
    }else{
      props.onUpdate(props.form);
      //setLoading(false)
    }
  }

  useEffect(() => {
    getFormTemplate();
    return () => {
      template = {};
      noOfTabs = 0;
      readOnly = true;
	  errors = {invalid:{}};
	  isFormSubmitted = false;
    }
  }, [props.formId]);

  const getFormTemplate = () => {
    if(props.formId) {
      let req = {id:props.formId, journey_profile_id: props.formFn.journey_profile_id}
      CommonService.getFormTemplateById(req).then(function (tf) {
        //template = tf;
        noOfTabs = tf.template_fields.length;
        tf.fill_info = tf.fill_info || 'Please fill out the following fields';
        if(props.formMode == 'create-form-submissions'){
           readOnly = false;
        }else if(props.formMode == 'edit-form-submissions'){
          readOnly = !(props.form.created_by == currentUser.user.id || props.formFn.editPermission);
        }else if(props.formMode == 'view-form-submissions'){
          tf.fill_info = 'View out the following fields';
          tf.post.details ='View Details'
          readOnly = true;
        }
        template = tf;
        props.setPost(tf.post)
      })
    }
    setLoading(false)
  }

  let callback = function(key) {
    setActiveKey(key);
  }

  let nextTab = () =>{
    let i = (parseInt(activeKey) +1).toString();
    setActiveKey(i);
  }

  let previousTab = () =>{
    let i = (parseInt(activeKey)-1).toString();
    setActiveKey(i);
  }

  let openDataSourceModal = (field) =>{
    selectedFormField = field
    toggleDataSourceModal()
  }

  const ChildElementList = ({childField, pkey}) => childField.map( (field, key )=> {

    let result = FormHelper.checkFieldConditionality(field, props.data);
    if(!result){
      delete errors[field.client_id]
      delete errors.invalid[field.client_id]
      return;
    }

    if(field.required)FormHelper.checkFieldValidity(field, props.data, errors, pkey);

    if(activeKey!=pkey)return; 

    let fieldProps = {
      'field':field, 
      'formData':props.data, 
      'formFn':props.formFn,
      'readOnly':readOnly,
      'position': key,
      'openDataSourceModal':openDataSourceModal
    }

    switch (field.component) {
      case 'textInput':
        FormHelper.onChangeExp(field, props.data);
        return <InputTextField key={key} {...fieldProps} {...validationProps} />
        break;
      case 'textArea':
        return <InputTextArea key={key} {...fieldProps} {...validationProps} />
        break;
      case 'timepicker':
        return <InputDataTimePicker key={key} {...fieldProps} {...validationProps} />
        break;
      case 'datepicker':
        return <InputDataTimePicker key={key} {...fieldProps} {...validationProps} />
        break;
      case 'checkbox':
        return <InputCheckbox key={key} {...fieldProps} {...validationProps} />
        break;
      case 'radio':
        return  <InputRadio key={key} {...fieldProps} {...validationProps} />
        break;
      case 'select':
        return <InputSelect key={key} screen={screen} {...fieldProps} {...validationProps}/>
        break;
      case 'multi_select':
        if(field.is_dynamic_data){
          return <InputMultiSelectSearch key={key} {...fieldProps} {...validationProps} />  
        }else{
          return <InputMultiSelect key={key} {...fieldProps} {...validationProps} />
        }
        break;
      case 'slider':
        return <InputSlider key={key} {...fieldProps} {...validationProps} />
        break;
      case 'autocomplete':
        return <InputAutoComplete key={key} {...fieldProps} {...validationProps} />
        break;
      case 'imageupload':
        return <InputFileUpload key={key} formId={props.form.id} {...fieldProps} {...validationProps} />
        break;
      case 'multi_file_upload':
        return <InputMultiFileUpload key={key} formId={props.form.id} {...fieldProps} {...validationProps} />
        break;
      case 'richtext':
        return <InputRichText key={key} formId={props.form.id} {...fieldProps} {...validationProps} />
        break;
      case 'explanatory_text':
        return <InputExplanatoryText key={key} {...fieldProps} {...validationProps} />
        break;
      case 'color_picker':
        return <InputColorPicker key={key} {...fieldProps} {...validationProps} />
        break;
      case 'rating':
        if(field.data_source_id){
          return <InputMultiRating key={key} {...fieldProps} {...validationProps} />
        }else{
          return <InputRating key={key} {...fieldProps} {...validationProps} />
        }
        break;
      case 'input_tags':
        return <InputTags key={key} {...fieldProps} {...validationProps} />
        break;
      case 'signature':
        return <InputSignaturePad key={key} {...fieldProps} {...validationProps} />
        break;
      case 'rich_image_map':
        return <InputRichImageMap key={key} user={currentUser.user} {...fieldProps} {...validationProps} />
        break;
      case 'lottie_svg':
         return <InputLottieSvg key={key} user={currentUser.user} {...fieldProps} {...validationProps} />
        break;
      default:
        return <Fragment key={key}></Fragment>
      }
    }
  )

  return (
    <Fragment>
      <form className="row">
        <ErrorsList errors={errors} formFn={props.formFn} setActiveKey={setActiveKey}/>
        {!readOnly && !isFormSubmitted && <Prompt message="Are you sure you want to leave?"/>}
        <Tabs activeKey={activeKey} onChange={callback} moreIcon="+">
          {template.id && 
            template.template_fields.map((parentField, key) =>(
              <TabPane tab={parentField.label} key={key} forceRender="true">
                <ChildElementList pkey={key} childField={parentField.child_template_fields}/>
              </TabPane>
            ))
          }
        </Tabs>
        <div className="clerfix"></div>
        { loading ? <PreLoadSpinner /> : <FormSubmit errors={errors} form={props.form} readOnly={readOnly} onCancel={props.onCancel} onSubmit={onSubmit} activeKey={activeKey} previousTab={previousTab} nextTab={nextTab} noOfTabs={noOfTabs} formFn={props.formFn} forceUpdate={forceUpdate}>
        </FormSubmit>}
        <small className="m-l-5">cT{template.updated_at}-{template.id}</small><br/>
        <small className="m-l-5 lgrey"><i className="m-r-5 far fa-copyright" aria-hidden="true"></i>{new Date().getFullYear()} IPIPAL Inc. All rights reserved.</small>
      </form>

      {isDataSourceModalOpen && <DataSourceListModal form_template_id={template.id} 
      data_source_id={selectedFormField.data_source_id} 
      journey_profile_id={params.journey_profile_id}
      toggleModal={toggleDataSourceModal}/>}
    </Fragment>
  )
    
}

export default DynamicForm;