import React, {Fragment,useEffect,useState} from "react";
import {Link} from "react-router-dom";
import moment from '../../../../node_modules/moment/min/moment.min.js'
import Rating from "../../../components/Rating"
import ForumService from "../../../components/Forums/ForumService";


let data = {}
let organization = {}
export const Forum = (props) => {
  let org = props.organization;
  let params = props.match.params.id;
  const [details, setDetails] = useState({});



  const get = () => {
    ForumService.get(params).then((res) => {
      if(res.status==200){
        organization = res.data.discussion_request;
        setDetails(organization);
      }
    }).catch(function(res){
       organization = null;
    })
  }


  useEffect(() => {

    let skillProfile = [];
    get();


    /*if (props.organization && props.organization.data_source){
      console.log('props forums',props.organization,props.organization.data_source);
      for (const key in props.organization.data_source) {
        console.log('has keys datasource',key,props.organization.data_source.hasOwnProperty(key))
        if (props.organization.data_source.hasOwnProperty(key)) {
          console.log('keys2',key)
          const element = props.organization.data_source[key];          
          if (parseInt(data[key])){    
            console.log('data key',data[key])                              
          console.log('element',(element));
          skillProfile.push(element.label);
                 
        }
        }

      }
    }
    console.log('forummapping',skillProfile,props.organization.discussion_responses,)
    setSkillProfile(skillProfile);*/


},[])
  console.log('form details',details)
  return (
    <div>
      <div className="clearfix">
      <div className="content-boxed shadow-small" >
      <div className="content">
        <h1 className="vcard-title color-highlight bold">
        <i className="fa fa-comments" aria-hidden="true"></i>
        </h1>

        <div className="vcard">
        </div>
        
        <div className="vcard-field">
        <div className="vcard">

        </div>
        </div>
      
        <div className="vcard">


        </div>
      </div>
 
    </div>
      </div>

      </div>
  )

};

export default Forum;
