import env from '../../env';
import axios from 'axios';

class MarketingService {
  
  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_marketing}event/digital_contents`, { params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_marketing}event/digital_contents`, {params: req}).then((res) => {
        // handle success
        console.log('forums get all',res)
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  getById(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_marketing}event/digital_contents/${req.id}`, { params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(profile){
    return new Promise((resolve, reject) => {
      axios.post(env.ecn_marketing+'event/digital_contents',profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(req) {
    console.log('req delete',req)
    return new Promise((resolve, reject) => {
      axios.delete(`${env.ecn_marketing}event/digital_contents/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }


}

export default new MarketingService();