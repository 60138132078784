import React, { useState, useContext} from 'react';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../components/PreLoadSpinner";
import DynamicForm from '../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../constants/FormTemplateList'
import AcceleratorService from "../../components/AcceleratorProfile/AcceleratorService";
import SelectMenu from "../../components/Modals/SelectMenu"
import FormContainer from '../../components/FormBuilder/FormContainer'

/*Store initial field setting before function to avoid reinitating while re-render*/
let formMode = null; /*Mode New Form or Edit Existing Form*/
let formId = null; /*Form Template id to get form from backend*/
let formType = null;
let accelerator = {}; /*Store  user_id, org_id to send to backend*/
let data = {}; /*Store json form field value */
let formFn = {}; /*To share use case related common code to form field component*/

function AcceleratorProfileForm(props) {
  const { state:authState, dispatch } = useContext(AuthContext);

  /*Set form title, description*/
  const [post, setPost] = useState({});
  let params = props.match.params;
  formId = FormTemplateId.AcceleratorRegistration
  
  formFn.handleChange = (value) =>{
    formFn.value = value;
  }

  formMode = 'create-form-submissions';
  data = {}; 
  if(authState.user.accelerator){
    formMode = 'edit-form-submissions';
    accelerator = authState.user.accelerator;
    data = authState.user.accelerator.data || {};
  }

  const create = (accelerator) => {
    accelerator.form_id = formId;
    accelerator.form_type = 'organization';
    AcceleratorService.create(accelerator).then(function(res){
      if(res.status==201){
        authState.user.accelerator = res.data.accelerator;
        updateUserOrg();
        props.history.push(`/user/profile?tab=5`)
      }
    })
  }

  const update = (accelerator) => {
    accelerator.form_id = formId;
    accelerator.form_type = 'organization';
    AcceleratorService.update(accelerator).then(function(res){
      if(res.status==204){
       authState.user.accelerator = accelerator;
       updateUserOrg();
       onSubmitPageRedirect()
      }
    })
  }

  const updateUserOrg = () => {
    dispatch({
      type: 'UPDATE',
      payload: authState
    })
  }

  const onSubmitPageRedirect = () => {
      props.history.push("/journey")
  }

    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={formId} form={accelerator} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
      </FormContainer> 
    )  

}

export default AcceleratorProfileForm;