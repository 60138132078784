import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import useStyle from '../../hooks/useStyle';
import useSort from '../../hooks/useSorting';
import SortDirection from '../../components/Common/SortDirection'
import Cs from "../../services/CommonService";

const UserTable = (props) => {
  useStyle('table');
  let {userList, requestParams, getUsersList, openPermissionModal, onDelete} = props;  
  const {sort} = useSort(requestParams, getUsersList);
  
  const tableData = userList.map((user, k) =>
    <tr key={k}>
      <td data-title="Id">{k}</td>
      <td data-title="Email">
        {user.email}
      </td>
      <td data-title="Name">
        {user.name}
      </td>
      <td data-title="Admin Role">
        {user.admin_role_name}
      </td>
      <td data-title="Total Journey">{user.total_journeys}</td>
      <td data-title="Join Date">{Cs.formatUpdateDate(user.user_created_at)}</td>
      <td data-title="Last Activity">{Cs.formatUpdateDate(user.current_sign_in_at)}</td>
      <td>
        <span className="far fa-edit m-r-15" onClick={e=>{openPermissionModal(user)}}></span>
        <span className={user.is_active?'far fa-check-circle':'far fa-circle'} onClick={e=>{onDelete(user)}}></span>
      </td>
    </tr>
  )

  return (
    <div className="table-responsive">
      <table className="shadow-small">
        <thead>
          <tr className="white bg-highlight">
            <th onClick={e=>sort('user_admins.record_id')}>Id 
              <SortDirection sort_column={props.requestParams.sort_column} column="record_id" reverse={props.requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('users.email')}>Email 
              <SortDirection sort_column={props.requestParams.sort_column} column="email" reverse={props.requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('users.name')}>Name
              <SortDirection sort_column={props.requestParams.sort_column} column="name" reverse={props.requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('user_admins.admin_role_name')}>Admin Role
              <SortDirection sort_column={props.requestParams.sort_column} column="admin_role_name" reverse={props.requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('total_journeys')}>Total Journey
              <SortDirection sort_column={props.requestParams.sort_column} column="total_journeys" reverse={props.requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('current_sign_in_at')}>Last Activity
              <SortDirection sort_column={props.requestParams.sort_column} column="current_sign_in_at" reverse={props.requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('user_created_at')}>Join Date
              <SortDirection sort_column={props.requestParams.sort_column} column="user_created_at" reverse={props.requestParams.reverse}/>
            </th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </table>
    </div>
  )

}

export default UserTable;