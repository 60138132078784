import React, {useState, Fragment, useEffect} from 'react';
import ReactTooltip from "react-tooltip";

function FormSubmit({form, onCancel, onSubmit, activeKey, previousTab, nextTab, noOfTabs, formFn, readOnly, errors, forceUpdate}) {
  const [count, setCount] = useState(0);

  formFn.refreshFormSubmit = () =>{
   	setCount(value => ++value)
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const handleSubmit = (e) =>{
      if(Object.keys(errors.invalid).length === 0){
        onSubmit(false, e)
      }else{
        errors.isSubmitted = true;
        forceUpdate();
        setTimeout(function() {
          let ele = document.getElementById('error_list')
          ele.scrollIntoView();
        }, 0); 
        //formFn.refreshErrorsList();
      }
    }

    return (
    	<Fragment> { !readOnly && 
        <div className="col-xs-12 text-center">
          <button type="button" data-tip="Canceling will lose all entered data" onClick={onCancel} className="rect-btn bg-cancel" >CANCEL</button>
          <button type="button" data-tip="Submit when all required fields are filled" onClick={e=>handleSubmit(e)} className="rect-btn bg-submit">

            {form.id?'UPDATE':'SUBMIT'} 
          </button>
        </div>}
        <div className="col-xs-12">
          {activeKey>0 && <button type="button" onClick={e=>{previousTab()}} className="btn btn-inverse btn-rounded pull-left">
          <i className="fa fa-chevron-left" aria-hidden="true"></i></button>}
          {(activeKey < (noOfTabs-1)) && <button type="button" onClick={e=>{nextTab()}} className="btn btn-inverse btn-rounded pull-right">
          <i className="fa fa-chevron-right" aria-hidden="true"></i></button>}
        </div>
      </Fragment>
  	)
}

export default FormSubmit;