import React, { Fragment, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {Link, useHistory} from "react-router-dom";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import useStyle from '../../../hooks/useStyle';

const FilterModal = ({ params, isOpen, toggleModal, filterType, onSelect, menuList}) => {
  useStyle("add_role_dialog");
  useStyle("filter_dialog");
  
  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext);
  let dataSourceId = menuList.menu_digital_content.id
  let activity_types = Object.values(menuList.menu_digital_content.options)
  const history = useHistory();

  const handleClick = (e, o) =>{
    e.preventDefault()
    if(filterType == 'data_filter'){
      onSelect(o.sid)
    }else{
      history.push(`/digital_contents/create/${o.sid}/${o.child_form}`)
    }
  }

  const FormFilters = () =>{
    //let permissions = currentUser.current_admin.permissions || {}
    //let content_permission = permissions.role_content || {}

    return(
      <Fragment>
      <div className="popup-menu-grid">
        {activity_types.map((o, k) =>
          <Fragment> 
            <a className="ver-icons" onClick={e=>handleClick(e, o)}>
              <a href="/">
                <i className={`${o.icon || "fas fa-plus"}`} style={{ color: o.icon_color }}/>
              </a>
              <div className="popup-menu-title">{o.label}</div>
              <i className="fas fa-chevron-right lgrey pull-right" />
            </a>
          </Fragment> 
        )}
      </div>
      <div className="row"><p className="font-9 p-5 lgrey lh-16">{dataSourceId}</p></div>
      </Fragment>  
    )
  }

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">
          
          <div className="ml-center bg-black white p-5">
            <h4>Please select a activity</h4>
            <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal" onClick={e=>toggleModal(e)}>×
            </span>
          </div>
          
          <div className="p-5 scroll-x">
            <FormFilters/>
          </div>
            
        </div>
      </div>
    </Fragment>, document.body)
  )

}

export default FilterModal;