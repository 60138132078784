import env from '../../env';
import axios from 'axios';
let url = env.ecn_backend_api+'journey_profiles';

class JourneyProfileService {

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`, {params:req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params:req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  getPathNodes(req){
    return new Promise((resolve, reject) => {
      axios.get(env.ecn_backend_api+'journey_profile/paths', {params:req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(profile){
    return new Promise((resolve, reject) => {
      axios.post(url, profile).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(profile){
    return new Promise((resolve, reject) => {
      axios.put(url+'/'+profile.id, profile).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(profile){
    return new Promise((resolve, reject) => {
      axios.delete(url+'/'+profile.id, profile).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  createDataSource(req) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.ecn_backend_api}data_source_list`, req).then((res) => {
          // handle success
          resolve(res);
        })
    })
  }

  updateDataSource(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.ecn_backend_api}data_source_list/${req.id}`, req).then((res) => {
          // handle success
          resolve(res);
        })
    })
  }

  deleteDataSource(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.ecn_backend_api}data_source_list/${id}`).then((res) => {
          // handle success
          resolve(res);
        })
    })
  }

  getAllDashboard(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}journey_profile/dashboard`, {params:req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  getJourneyHyperLinks(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}links`, {params:req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  getFileUploads(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}file_uploads`, {params:req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new JourneyProfileService();