import React, { useState, Fragment, useEffect, useContext } from "react";
import ReactTooltip from "react-tooltip";
import querystringify from "querystringify";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import useStyle from "../../../../hooks/useStyle";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import JourneyTitle from "../JourneyTitle/JourneyTitle";
import {NavIcon,JourneyHome} from '../../../../components/Common/MenuItem'
import CalendarView from '../../../Calendars/Calendar'
import useModal from "../../../../hooks/useModal";
import CalendarCardView from "../../../Calendars/CalendarCardView"
import SideMenuBar from "../../../../components/Common/SideMenuBar";

const Schedules = (props) => {
	useStyle("card_table");

	let params = props.match.params;
  	let journey_profile = props.location.state;
  	let queryParam = querystringify.parse(props.location.search);
  	
  	const { isOpen: isScheduleTypeOpen, toggleModal: toggleScheduleType } = useModal();
	const [showCalendarView, setShowCalendarView] = useState(false);

	const toggleCalendarView = () => {setShowCalendarView(!showCalendarView)};

	return (
	    <Fragment>
	      <div className="p-5 top-10 clearfix">
	        <div className="col-xs-8">
	          {journey_profile && <JourneyTitle title={"Schedule"} project={journey_profile}/>}
	        </div>
	        
	        <div className="col-xs-4 pull-right">
	          <NavIcon id="add_schedule_type" dataTip="Create New Schedule Type" 
	            onSelect={toggleScheduleType}
	            className="bg-highlight round-btn pull-right m-r-5 m-t-4" 
	            icon="fas fa-plus"/>

			  <NavIcon id="toggle_icon" dataTip="Toggle View" 
				onSelect={toggleCalendarView}
				className="bg-highlight round-btn pull-right m-r-5 m-t-4" 
				icon="far fa-check-square"/>

			  <JourneyHome id="home_icon" journey_profile={journey_profile}
				link={`/journey/portal/${params.journey_profile_id}`}
				className="bg-highlight round-btn float-right m-r-5 m-t-4" 
				icon="fas fa-home" dataTip="Back to Journey Home" />

	          <ReactTooltip place="left" />
	        </div>
	      </div>

	      <div className="page-content row m-b-35">	
			{!showCalendarView && 
			  <div className="container">
			   	<CalendarView {...props} 
			   	isScheduleTypeOpen={isScheduleTypeOpen} 
			   	toggleScheduleType={toggleScheduleType}/> 
			   </div>
			}

			{showCalendarView && 
			  <>
			  	<CalendarCardView setShowCalendarView={setShowCalendarView}  {...props} />
			  </>	
			}
	      </div>
		  <SideMenuBar helpId="2"/>
	    </Fragment>	
	)
}

export default Schedules;