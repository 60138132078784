import React, {Fragment, useState,useEffect} from 'react';
import {Link} from "react-router-dom";
import DigitalContentService from "../DigitalContent/DigitalContentService";

let msgRequestParams = {}
const HeaderMsgBar = (props) => {

	const [msgs, setMsgs] = useState();

	useEffect(() => {
		msgRequestParams = {
		  content_type: "in_app_notif",
		  page: 1,
		  paginate: 1,
		  per_page: 10,
		  sort_column: "updated_at",
		  sort_direction: "asc",
		};
	
		getMessages();
	}, []);
	
	let getMessages = () => {
		DigitalContentService.getAll(msgRequestParams).then((res) => {
		  if (res.status == 200) {
			setMsgs(res.data.digital_contents)
		  }
		})
	}
	
	const TemplateCard = ({msg}) =>{
		const [show, setShow] = useState(true);
		if(!show)return null;
		return(
		  <div className="p-7 font-16 bold-600 white" style={{backgroundColor: msg.data.color,margin: '5px 5px 5px 15px'}} >
			{msg.data.title} <Link className="white d-contents" to="/user/profile?tab=10">..More</Link>
			<i className="fas fa-window-close pull-right " onClick={e=>setShow(!show)}></i>
		  </div>
		)
	}

	const MsgCard = ({msg, page})=> {
		if(props.location.pathname.includes(page.url)){
			return(<TemplateCard msg={msg} />)
		}else{
			return null
		}
	}
	
	const msgList = msgs && msgs.map((msg, i) => {
		if(msg.data.status == "Display"){
			let pages = msg.data_source.in_app_page
			return(
				<Fragment key={i}>
				{pages && pages.map((p, i) => (	
					<MsgCard msg={msg} page={p.data} key={i}/>
				))}
				</Fragment>
			)	
		}
	})

	if(msgs && msgs.length > 0){
		return(msgList)
	}else{
		return null
	}
}

export default HeaderMsgBar;