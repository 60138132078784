import React, {useState,Fragment} from "react";
import ReactTooltip from 'react-tooltip';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import Assessment from '../../../components/ServiceProvider/Assessments/Assessment';
import ServiceProviderService from "../../../components/ServiceProvider/ServiceProviderService";
import FavouritesService from "../../../services/FavouritesService"
import PortalHeader from "../../../components/Organizations/ProjectPortalPage/PortalHeader";
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu";
import ProjectMenu from '../../Modals/ProjectMenu'
import {Link} from "react-router-dom";
import useModal from "../../../hooks/useModal";
import FormTemplateId from '../../../constants/FormTemplateList'
import useStyle from '../../../hooks/useStyle';

let requestParams = {};
let project = null;
let organization = null;
let selectedAssessment = {};

export const AssessmentsList = (props) => {
  useStyle('panel');
  useStyle('card');  
  useStyle('card_table');

    
  const { state:currentUser, dispatch } = React.useContext(AuthContext);
  const [serviceProviderList, setServiceProviderList] = useState([]);
  const [currentpage,setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);

  const deleteOrg = (sp) => {
    //Delete
    deleteServiceprovider(sp)
    console.log('sp',sp)
  };

  const { isOpen, toggleModal, success } = useModal(deleteOrg);
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal, success:menuSuccess } = useModal();


  React.useEffect(() => {
    requestParams = {
      'page': currentpage, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'asc',
      'accelerator_id': currentUser.user.accelerator.id,
      'favourable_type': 'service_providers',
      'project_id':props.match.params.project_id
    }

    getServiceproviderList();
  }, [currentUser.user.accelerator, props.searchString,currentpage]);

  let getServiceproviderList = () => { 
    FavouritesService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       project= res.data.project;
       organization = res.data.organization;
       setPagemeta(res.data.meta)
       setServiceProviderList(res.data.favourites)
      }
    })
  }

  let deleteServiceprovider = (sp) => {
    FavouritesService.delete(sp.id).then((res) => {
      if (res.status == 204) {
        setServiceProviderList(serviceProviderList.filter(i => i.id !== sp.id))
      }
    });
  }

  const onDelete = (e, sp) => {
    console.log('project delete',e,sp)
    selectedAssessment = sp;
    toggleModal(e,sp);
  }

  const Menu = ({ sp, fn }) => {
    console.log('sp, favourite 1',sp,project)
    return (
    <span className="m-r-15" >
      <i onClick={e=>{addMenu(e, project)}} class="fas fa-bars" />  
    </span>
  )}

  const addMenu = (e, sp) => {
    console.log('e,sp',e,sp);
    selectedAssessment = sp;
    toggleMenuModal(e);
  }

const menuItems = ({sp,favourite}) =>{
  console.log('sp, favourite 2',sp,favourite)
  return(
    <Fragment>
        <Link to="/" onClick={(e) => {toggleModal(e, favourite);}}>
        <i className="fa fa-trash"></i>
        <span className="font-13">Delete</span>
        <i className="fa fa-angle-right"></i>
            </Link>
            <Link to={`/serviceprovider/assessments/form/${favourite.id}/${favourite.risk_assessment && favourite.risk_assessment.id}`}>
          <i className="fa fa-file-contract"></i>
          <span className="font-13">Qualify Vendor</span>
          <i className="fa fa-angle-right"></i>
        </Link>
        <Link to={`/contract/registration/project_work_order/${favourite.id}/${FormTemplateId.ProjectWorkOrderForm}/${favourite.contract_id}/${project.id}/${organization.data.name}`}>
          <i className="fa fa-retweet"></i>
          <span className="font-13"> Move To Contract</span>
          <i className="fa fa-angle-right"></i>
        </Link>
        <Link>
          <i className="fa fa-retweet"></i>
          <span className="font-13"> Notes</span>
          <i className="fa fa-angle-right"></i>
        </Link>
    </Fragment>
  )
}

/*const Menu = ({sp, favourite}) => 
    <div className="dropup card-menu-right">
      <button className="dropbtn">Menu</button>
      <div className="dropup-content">
        <a to="#" onClick={(e) => {toggleModal(e, favourite);}}>Delete</a>
        <Link to={`/serviceprovider/assessments/form/${favourite.id}/${favourite.risk_assessment && favourite.risk_assessment.id}`}>Qualify Vendor</Link> 
        <Link to={`/contract/registration/project_work_order/${favourite.id}/${FormTemplateId.ProjectWorkOrderForm}/${favourite.contract_id}/${project.id}/${organization.data.name}`}>
          Move To Contract
        </Link>
        <a href="#">Notes</a>
      </div>
    </div>*/

const assessmentCard = serviceProviderList.map((sp, i) =>
  <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
    <Assessment serviceProvider={sp} menu={Menu}/> 
      {selectedAssessment.record_id == sp.record_id && 
        <ProjectMenu isOpen={isMenuOpen}  toggleModal={toggleMenuModal} item={selectedAssessment} 
        addDelete={onDelete} type="assessment" 
        MenuItems={menuItems}/>}
  </div>
)

  if(!project){
    return null;
  } else { 
  return (
    <React.Fragment>
     <div className="content top-10 clearfix">
        <div className="col-xs-8">
        <div className="font-16 bold-600">
  	    {project.data.name}
   		</div>
    	<div className="font-15">
      Service Provider Assessment
        <span><i className="fa fa-question-circle m-l-5" ></i></span>
      </div>        
        </div>
        <div className="col-xs-4"> 
        <Link to={`/organization/project/portal/${project.id}`} className="bg-highlight round-btn float-right m-r-5">
            <i className="fas fa-home"/>
          </Link>
          <ReactTooltip place="left" />
        </div>
      </div>

      <div className="page-content row">
              {assessmentCard}
              <Pagination pagemeta={pagemeta} currentpage={currentpage} setCurrentpage={setCurrentpage} />
          </div>
    <ConfirmMenu isOpen={isOpen} toggleModal={toggleModal} success={success}/>
    </React.Fragment>
  )
  }
};

export default AssessmentsList;
