import React, {Fragment} from "react";
import useStyle from '../../hooks/useStyle';

const NotificationPopup = (props) => {
  useStyle('dashboard');      
  let iconClass = props.iconClass || "fa fa-check bg-green1-dark success-ico"

  return(
    <div className="col-xs-12">
        <div className="card card-style">
          <div className="success d-flex">
            <div>
                <h3><i className={`${iconClass}`}/></h3>
            </div>
            <div className="success-txt">
                <h3>{props.header || 'Success'}</h3>
                <p dangerouslySetInnerHTML={{ __html: props.message }}>
                </p>
            </div>
          </div>
          <div>
            <span onClick={e=>props.onSuccess()} className="success-btn">OK</span>
          </div>  
        </div>
    </div>
  )
}

export default NotificationPopup;