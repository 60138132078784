import React, { Fragment, useState, useContext} from 'react';
import { AuthContext } from "../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../components/PreLoadSpinner";
import PrivateRoute from "../routes/PrivateRoute";
import PublicRoute from "../routes/PublicRoute"
import { Route, Switch } from 'react-router-dom';
import Header from '../components/Header/Header'
import SideMenu from '../components/SideMenu/SideMenu'
import Form from '../components/FormBuilder/Form'
import Footer from "../components/Footer/Footer";
import AcceleratorProfileForm from '../components/AcceleratorProfile/AcceleratorProfileForm'
import ServiceProviderProfileForm from '../components/ServiceProvider/Activities/Form/ServiceProviderProfileForm'
import ServiceProviderList from '../components/ServiceProvider/Activities/List/ServiceProviderList'
import OrganizationProfileForm from '../components/Organizations/Activities/Form/OrgProfileForm'
import OrganizationsList from '../components/Organizations/Activities/List/OrganizationList'
import AdvisorProfileForm from '../components/Advisor/Activities/Form/AdvisorProfileForm'
import AdvisorList from '../components/Advisor/Activities/List/AdvisorList'
import DemoUserProfile from "../components/UserProfile/DemoUserProfile";
import AcceleratorDashboard from '../components/Dashboard/Accelerator'
import CompanyDashboard from '../components/Dashboard/Company'
import CompanyRouter from '../components/Company/CompanyRouter'
import OrganizationRouter from '../components/Organizations/OrganizationRouter'
import ServiceProviderRouter from '../components/ServiceProvider/ServiceProviderRouter'
import AdvisorRouter from '../components/Advisor/AdvisorRouter'
import ForumRouter from '../components/Forums/ForumRouter'
import DigitalContentRouter from "../components/DigitalContent/DigitalContentRouter";
import Roles from '../components/Roles/RoleList'
import MemberList from '../components/ShareJourney/MemberList'
import ContractForm from '../components/Contracts/ContractForm'
import ContractsList from '../components/Contracts/ContractsList'
import IncomingMails from '../components/IncomingMails/IncomingMails'
import TrackRelationshipForm from '../components/TrackRelationship/TrackRelationshipForm'
import TrackRelationshipList from '../components/TrackRelationship/List/TrackRelationshipList'
import DataSourceList from '../components/DataSources/DataSourceList'
import DataSourceSuggestionForm from '../components/DataSources/DataSourceSuggestionForm'
import AddToHomeScreen from '../components/Common/AddToHomeScreen'
import GetStarted from '../components/GetStarted/GetStarted'
import LightSailReport from '../components/CloudReports/Aws/LightSailReport'
import LightSailInstanceList from '../components/CloudReports/Aws/LightSailInstanceList'
import CostExplorerReport from '../components/CloudReports/Aws/CostExplorerReport'
import JourneyRouter from '../components/Journey/JourneyRouter'
import FeedbackForm from "../components/Feedback/FeedbackForm";
import FeedbackList from "../components/Feedback/List/FeedbackList";
import PublicComment from '../components/Comments/PublicComment'
import HelpRouter from "../components/Help/HelpRouter";
import HeaderMsgBar from "../components/Common/HeaderMsgBar";
import ShareItemsList from "../components/SharedItems/SharedItemsList"
import UserAdminRouter from '../components/UserAdmins/UserAdminRouter'
import ZoomMeetingList from '../components/Zoom/ZoomMeetingList'
import NotificationList from "../components/Notifications/NotificationList";
import UserGroupList from '../components/UserGroups/UserGroupList'

const Home = (props) => {
  const { state:{screen, user:currentUser, isAuthenticated:isAuthenticated}, dispatch } = useContext(AuthContext);
  const [openSideNav, setOpenSideNav] = useState(false);
  const [searchString, setSearchString] = useState("");

    return (
      <Fragment>
        {isAuthenticated && <Header openSideNav={openSideNav} setOpenSideNav={setOpenSideNav} setSearchString={setSearchString} {...props}/>}
        {isAuthenticated && <HeaderMsgBar {...props} />}
        {isAuthenticated && <SideMenu openSideNav={openSideNav} setOpenSideNav={setOpenSideNav}/>}
        {screen.xs && <AddToHomeScreen/>}
        <Switch>
          <PrivateRoute path="/feedback" component={FeedbackForm} exact />
          <PrivateRoute path="/list/feedback" component={FeedbackList} exact />
       	  <PrivateRoute path="/acceleratordashboard" component={AcceleratorDashboard} exact />	
          <PrivateRoute path="/companydashboard" component={CompanyDashboard} exact />	
          <PrivateRoute path="/form/:form_template_id" component={Form} exact />
          <PrivateRoute path="/accelerator/profile" component={AcceleratorProfileForm} exact />
          <PrivateRoute path="/roles/:journey_profile_id?" component={Roles} exact />
          <PrivateRoute path="/user/profile" component={DemoUserProfile} exact />
          <PrivateRoute path="/invite/users/:journey_profile_id" component={MemberList} exact/>
          <PrivateRoute path="/share/items/:journey_profile_id/:shareable_item_type/:shareable_item_id" component={ShareItemsList} exact/>
          <PrivateRoute path="/contract/registration/:contract_type/:contract_id/:form_id/:id?/:project_id?/:organization_name?" component={ContractForm} exact />
          <PrivateRoute path="/list/project/contracts/:project_id/:service_provider_id?" component={ContractsList} exact style="organization"/>
          <PrivateRoute path="/track/relationship/:tracking_id/:tracking_type/:id?" component={TrackRelationshipForm} exact />
          <PrivateRoute path="/list/relationship/tracking/:tracking_id/:tracking_type" component={TrackRelationshipList} exact style="organization" />
          <PrivateRoute path="/list/data_sources" component={DataSourceList} exact />
          <PrivateRoute path="/data_source_list/suggestion/:data_source_id" component={DataSourceSuggestionForm} exact />    
          <PrivateRoute path="/welcome" component={GetStarted} exact />   
          <PrivateRoute path="/notifications" component={NotificationList} exact/>
          <PrivateRoute path="/incoming/emails/:type/:id" component={IncomingMails} exact />
          <PrivateRoute path="/aws/lightsail/report/:instance_name" component={LightSailReport} exact />
          <PrivateRoute path="/aws/lightsail/list" component={LightSailInstanceList} exact />
          <PrivateRoute path="/aws/costexplorer" component={CostExplorerReport} exact />
          <PublicRoute
          path="/YXBwL21vZHVsZXMvZmFtaWx5X2pvdXJuZXkvY29tbWVudC9wdWJsaWNfcmVwbHkuaHRtbA==/:comment_id/:email"
          component={PublicComment} exact/>
          <PrivateRoute path="/zoom/meetings/:journey_profile_id" component={ZoomMeetingList} exact />
          <PrivateRoute path="/user/groups" component={UserGroupList} exact />
        </Switch>
        <Switch><CompanyRouter searchString={searchString}/></Switch>
        <Switch><OrganizationRouter searchString={searchString}/></Switch>
        <Switch><ServiceProviderRouter searchString={searchString}/></Switch>
        <Switch><AdvisorRouter searchString={searchString} /></Switch>
        <Switch><ForumRouter /></Switch>
        <Switch><JourneyRouter searchString={searchString}/></Switch>
        <Switch>
          <DigitalContentRouter searchString={searchString} />
        </Switch>
        <Switch>
          <HelpRouter searchString={searchString} />
        </Switch>
        <Switch>
          <UserAdminRouter searchString={searchString} />
        </Switch>
        {isAuthenticated && <Footer/>}
      </Fragment>
    )
}

export default Home;                                                                                                                                                                                                                                                                                       