import React, { useState, useEffect, Fragment, useContext} from "react";
import { Link } from "react-router-dom";
import querystringify from "querystringify";
import PortalHeader from "./PortalHeader";
import ReactTooltip from "react-tooltip";
import PortalPath from "./PortalPath";
import JourneyProfileService from "../../JourneyProfileService";
import useStyle from "../../../../hooks/useStyle";
import CheckAccess from "../../../../components/Roles/CheckAccess";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import SideMenuBar from "../../../../components/Common/SideMenuBar";
import CommonService from "../../../../services/CommonService";

let portalPath = null;
let userTypeList = null;
let dataSources = null;

const PortalDetails = (props) => {
  useStyle("panel");
  useStyle("path");

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);
  
  const [journeyProfile, setJourneyProfile] = useState({});
  const [statics, setStatics] = useState({});
  const [share, setShare] = useState({});

  useEffect(() => {
    let req = {
      id: params.journey_profile_id,
      data_source_params: {
        data_source_id: [queryParam.menu], 
        //response_type:"object",
        group_by:"sid"
      }
    }

    JourneyProfileService.get(req).then((res) => {
      if (res.status === 200) {
        res = res.data;
        res.journey_profile = res.journey_profile
        dataSources = res.data_source_list 
        const sector = res.journey_profile.data_source.sector
        portalPath = CommonService.findObjById(dataSources, 'id', queryParam.menu)//sector.portal_ds
        CheckAccess.userAccess(currentUser, res.journey_profile, res.share_journey)
        setShare(res.share_journey)
        setJourneyProfile(res.journey_profile)
        setStatics(res.report)
      }
    })
  }, [])


  const routePath = (e, path) => {
    //3354
    e.preventDefault();
    if (path.sid == "prepare") {
      props.history.push(`/baseline/${params.journey_profile_id}?menu=${path.menu_ds}`, journeyProfile)
    }else if (path.sid == "goals") {
      props.history.push(`/goals/${params.journey_profile_id}`, journeyProfile)
    }else if (path.sid == "activities") {
      
    }else if (path.sid == "users") {
      props.history.push(`/invite/users/${journeyProfile.id}?permission_list_id=3475`, {'journeyProfile':journeyProfile, 'share':share})
    }else if (path.sid == "calendar") {
      props.history.push('/calendar/'+journeyProfile.id+'?item_id='+journeyProfile.id+'&item_type=operations', journeyProfile)
    }else if (path.sid == "interviews") {
      
    }else if (path.sid == "data") {
      let form_ids = path?.form_templates?.map(d => d.id)?.join(",");
      props.history.push(`/list/data_sources?journey_profile_id=${params.journey_profile_id}&form_ids=${form_ids}`, journeyProfile)
    }else if (path.sid == "roles") {
      props.history.push(`/roles/${params.journey_profile_id}?component_id=${portalPath.id}`, journeyProfile)
    }else if (path.sid == "implement") {
      props.history.push(`/track/${params.journey_profile_id}?form=${path.form_id}`, journeyProfile)
    }else if(path.sid == 'analyze'){
      props.history.push(`/analyze/${params.journey_profile_id}`, journeyProfile)
    }else if(path.sid == 'collections'){
      props.history.push(`/collection/${params.journey_profile_id}`, journeyProfile)
    }else if(path.sid == 'authorize'){
      props.history.push(`/governance/${params.journey_profile_id}`, journeyProfile)
    }else if(path.sid == 'meetings'){
      props.history.push(`/zoom/meetings/${params.journey_profile_id}`, journeyProfile)
    }
  }
  if (!journeyProfile.id) return <PreLoadSpinner />;

  return (
    <Fragment>
      <div className="p-5">
        <div className="portlet">
          
          <PortalHeader journeyProfile={journeyProfile} statics={statics} app={props.app} share={share}/>
          
          <div id="bg-info" className="panel-collapse collapse in" style={{minHeight: "260px"}}>
            <div className="portlet-body">
              <PortalPath currentUser={currentUser} portalPath={portalPath} statics={statics}
                routePath={routePath} share={share} journeyProfile={journeyProfile}/>
              <small className="font-12 p-5 lh-16">{queryParam.menu}</small>
            </div>
          </div>
          <ReactTooltip place="left" />
        </div>
      </div>
      <SideMenuBar helpId="2"/>
    </Fragment>
  )
}

export default PortalDetails;