import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import useStyle from '../../hooks/useStyle';
import CommonService from "../../services/CommonService";
import useEscape from '../../hooks/useEscape';

const GenericModal = ({ component: Component, ...props }) => {
  console.log(props,'generi')
  useStyle('add_role_dialog');
  useStyle('filter_dialog');
  useEscape(props.toggleModal);
  
  return(ReactDOM.createPortal(
    <Fragment>
       <div className="ml-modal">
        <div className={`ml-modal-content ml-card-4 ml-animate-zoom ${props.widthCss}`}>
          {props.title &&
          <div className={`ml-center white p-5 m-b-5 ${props.HeaderColor ? props.HeaderColor : `bg-black`}`}>
            <h5>{props.title}</h5>
            <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal" onClick={e=>props.toggleModal(e)}>×
            </span>
          </div>}
          {props.isPopupMode && <span className="ml-button ml-xlarge ml-hover-red ml-display-topright zIndex" title="Close Modal" onClick={e=>props.toggleModal(e)}>×
            </span>}
          {Component && <Component {...props} />}
        </div>
      </div>
    </Fragment>, document.body)
  )

}

export default GenericModal;