import React, { useState, Fragment } from "react";
import querystringify from "querystringify";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import CompanyActivity from "../../../Company/Activities/List/CompanyActivity";
import CompanyActivityService from "./../CompanyActivityService";
import Pagination from "../../../Pagination";
import { Link } from "react-router-dom";
import useModal from "../../../../hooks/useModal";
import CommentsModal from "../../../Modals/CommentsModal";
import ProjectMenu from "../../../Modals/ProjectMenu";
import MenuModal from "../../../Modals/Menu";
import ConfirmMenu from "../../../Modals/ConfirmMenu";
import FormTemplateId from "../../../../constants/FormTemplateList";
import NotesModal from "../../../Modals/NotesModal";
import ChecklistsModal from "../../../Modals/ChecklistsModal";
import useStyle from "../../../../hooks/useStyle";
import FilterModal from "../../../Organizations/Activities/List/OrganizationsFilterModal";
import ActivityTypeMenu from "./ActivityTypeMenu";

let requestParams = {};
let selectedActivity = {};

export const CompanyActivityList = (props) => {
  useStyle("card");
  useStyle("card_table");

  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);
  console.log("queryParam", queryParam, params);
  let cardGridCss = "col-xs-12 col-sm-6 col-md-4";
  let cardHeightCss = "ht-227";
  if (queryParam.id) {
    cardGridCss = "col-sm-12 col-sm-6 col-md-8";
    cardHeightCss = "mih-380";
  }

  const { state: authState, dispatch } = React.useContext(AuthContext);

  const [activityList, setActivityList] = useState([]);
  const [dsList, setDsList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  //const [pagemeta, setPagemeta] = useState([]);

  const fn = {}; //common shareable object for all child element

  fn.deleteOrg = (sp) => {
    //Delete
    deleteServiceprovider(sp);
    console.log("sp", sp);
  };

  const { isOpen, toggleModal: toggleDeleteModal, success } = useModal(
    fn.deleteOrg
  );
  const {
    isOpen: isMenuOpen,
    toggleModal: toggleMenuModal,
    success: menuSuccess,
  } = useModal();
  const {
    isOpen: isCommentOpen,
    toggleModal: toggleCommentModal,
    success: commentSuccess,
  } = useModal();
  const {
    isOpen: isChecklistOpen,
    toggleModal: toggleChecklistModal,
    success: checklistSuccess,
  } = useModal();
  const {
    isOpen: isJourneyMenuOpen,
    toggleModal: toggleJourneyMenu,
  } = useModal();

  React.useEffect(() => {
    requestParams = {
      data_source_params: { data_source_id: [3294, 3418] },
      user_id: authState.user.id,
      page: 1,
      paginate: 1,
      per_page: 10,
      accelerator_id: authState.user.accelerator.id,
    };

    getAdvisorList();
  }, [authState.user.accelerator, props.searchString, currentpage]);

  let getAdvisorList = () => {
    CompanyActivityService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        console.log("res data advi", res, res.data.data_source_list);
        //setPagemeta(res.data.meta)
        setDsList(res.data.data_source_list);
        setActivityList(res.data.activities);
      }
    });
  };

  let deleteServiceprovider = (sp) => {
    CompanyActivityService.delete(sp).then((res) => {
      if (res.status == 200) {
        console.log("delete adviosr", res);
        //setPagemeta(res.data.meta);
        setActivityList(res.data.advisors);
      }
    });
  };

  const Menu = ({ ad, fn }) => (
    <span className="m-r-15">
      <i
        onClick={(e) => {
          addMenu(e, ad);
        }}
        class="fas fa-bars"
      />
    </span>
  );

  const onDelete = (e, ad) => {
    selectedActivity = ad;
    toggleDeleteModal(e);
  };

  const addMenu = (e, ad) => {
    selectedActivity = ad;
    toggleMenuModal(e);
  };

  const addNotes = (e, ad) => {
    selectedActivity = ad;
    toggleCommentModal(e);
  };

  const addChecklist = (e, ad) => {
    selectedActivity = ad;
    toggleChecklistModal(e);
  };

  const Details = ({ ad, fn }) => (
    <Link
      className="m-l-15 pull-left text-muted"
      to={`/advisor/details/${ad.id}`}
    >
      <i className="fa fa-info-circle" />
    </Link>
  );

  const addActivity = (e) => {
    e.preventDefault();
    let profile = authState.user.domain_info;
    /*if(profile.data.type_of_use == 1){
      //Personal
    }else if(profile.data.type_of_use == 2){
      //Organization Use
    }*/
    toggleJourneyMenu(e);
  };

  const routeActivity = (ad) => {
    props.history.push(`/advisor?id=${ad.id}`);
    setActivityList(activityList.filter((i) => i.id == ad.id));
  };

  const menuItems = ({ item }) => {
    console.log('menuitems',item)
    return (
      <Fragment>
        <Link
          to={`/contract/registration/advisor/${item.id}/${FormTemplateId.AdvisorContract}/${item.contract_id}`}
        >
          <i className="fa fa-file-contract"></i>
          <span className="font-13">Standard Contract</span>
          <i className="fa fa-angle-right"></i>
        </Link>
        <Link to={`/list/relationship/tracking/${item.id}/advisor`}>
          <i className="fa fa-retweet"></i>
          <span className="font-13">Track Relationship</span>
          <i className="fa fa-angle-right"></i>
        </Link>
        <Link to={`/company/step_activity/form/${item.accelerator_id}/${item.activity_id}/${item.id}/${item.activity_type}`}>
          <i className="far fa-sticky-note font-15" aria-hidden="true"></i>
          <span className="font-13">Add Activity</span>
          <i className="fa fa-angle-right"></i>
        </Link>
      </Fragment>
    );
  };

  const activityCard =
    dsList &&
    activityList.map((ad, i) => (
      <div className={cardGridCss} key={i}>
        <CompanyActivity
          cardHeightCss={cardHeightCss}
          dataSources={dsList}
          routeActivity={routeActivity}
          activities={ad}
          menu={Menu}
          addNotes={addNotes}
          addChecklist={addChecklist}
          details={Details}
          toggleModal={toggleDeleteModal}
        />
        {selectedActivity.record_id == ad.record_id && (
          <ProjectMenu
            isOpen={isMenuOpen}
            toggleModal={toggleMenuModal}
            item={selectedActivity}
            addChecklist={addChecklist}
            addNotes={addNotes}
            onDelete={onDelete}
            type="advisor"
            MenuItems={menuItems}
          />
        )}
      </div>
    ));

  return (
    <React.Fragment>
      <div className="content top-10">
        <h5 className="float-left font-500">Company Activities List </h5>

        <Link
          to=""
          onClick={(e) => addActivity(e)}
          className="bg-highlight round-btn float-right m-r-5"
        >
          {" "}
          <i className="fas fa-plus"></i>
        </Link>

        <div className="clear"></div>
      </div>

      <div className="page-content row">
        {activityCard}
        {queryParam.id && activityList.length > 0 && (
          <div className="col-sm-12 col-sm-6 col-md-4">
            <CommentsModal
              isOpen="true"
              toggleModal={toggleCommentModal}
              item={selectedActivity}
              type="advisor"
              showInline="true"
            />
          </div>
        )}
      </div>
      <ConfirmMenu
        isOpen={isOpen}
        toggleModal={toggleDeleteModal}
        success={success}
      />
      {isCommentOpen && (
        <CommentsModal
          isOpen={isCommentOpen}
          toggleModal={toggleCommentModal}
          item={selectedActivity}
          type="advisor"
        />
      )}
      {isChecklistOpen && (
        <ChecklistsModal
          isOpen={isChecklistOpen}
          toggleModal={toggleChecklistModal}
          item={selectedActivity}
          type="activity"
        />
      )}
      {isJourneyMenuOpen && (
        <ActivityTypeMenu
          isOpen={isJourneyMenuOpen}
          toggleModal={toggleJourneyMenu}
          data_source={dsList}
          item={selectedActivity}
        />
      )}
    </React.Fragment>
  );
};

export default CompanyActivityList;
