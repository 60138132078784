import env from '../env';
import axios from 'axios';

class AuthenticationService {
  
  get(){
    return new Promise((resolve, reject) => {
      axios.get(env.ecn_backend+'user').then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  logon(user){
    return new Promise((resolve, reject) => {
      axios.post(env.ecn_backend+'users.json',user).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  signin(user) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.ecn_backend}users/sign_in`, user).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  signup(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.ecn_backend}user/sign_up`, data).then((res) => {
        // handle success
        resolve(res);
        console.log('signup',res)
      })
    })
  }

  registrationConfirmation(req){
    return new Promise((resolve, reject) => {
      axios.put(`${env.ecn_backend}user/registration_confirmation/${req.verification_id}`, req).then((res) => {
        resolve(res);
      })
    }) 
  }
  
  forgotPassword(user) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.ecn_backend}users/forgot_password`, user).then((res) => {
        // handle success
        console.log('forgot success',res)
        resolve(res);
      })
    })
  }

  ResetPassword(userData,userId,token) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.ecn_backend}users/`+userId+`/password_reset/`+token+``, userData).then((res) => {
        // handle success
        console.log('reset success',res)
        resolve(res);
      })
    })
  }

  logout(){
    return new Promise((resolve, reject) => {
      axios.delete(env.ecn_backend+'sign_out').then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  getPrivacyList(data){
    return new Promise((resolve, reject) => {
      axios.get(`${env.terms_backend}form_submissions`, {params: data}).then((res) => {
        resolve(res);
      })
    }) 
  }

  updateProfileImage(file){
    return new Promise((resolve, reject) => {
      let postImageUploadurl = env.ecn_backend+'user/profile_image';
      let fd = new FormData();
      fd.append('file', file);
      //fd.append('crop', JSON.stringify(file.crop));
      axios.post(postImageUploadurl, fd, {headers: {'Content-Type': undefined}}).then((res)=>{
          resolve(res);
      })
    }) 
  }

  updatePassword(user) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.ecn_backend}users/update_password`, user).then((res) => {
        resolve(res);
      })
    })
  }

  updateUserName(user){
    return new Promise((resolve, reject) => {
      axios.put(`${env.ecn_backend}user`, user).then((res) => {
        resolve(res);
      })
    }) 
  }

  getAllSecretQuestion(data){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}secret_questions`, {params: data}).then((res) => {
        resolve(res);
      }).catch((e) => {
        reject(e);
      })
    }) 
  }

  createSecretQuestion(data){
    return new Promise((resolve, reject) => {
      axios.post(`${env.ecn_backend_api}secret_questions`, data).then((res) => {
        resolve(res);
      }).catch((e) => {
        reject(e);
      })
    }) 
  }

  updateSecretQuestion(data, id){
    return new Promise((resolve, reject) => {
      axios.put(`${env.ecn_backend_api}secret_questions/${id}`, data).then((res) => {
        resolve(res);
      }).catch((e) => {
        reject(e);
      })
    }) 
  }

}

export default new AuthenticationService();