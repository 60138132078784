import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import DynamicForm from '../../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../../constants/FormTemplateList'
import ServiceRequestService from "../../../../components/Organizations/ProjectServiceRequests/ServiceRequestService";

let formMode = null; 
let serviceRequest = {}; 
let data = {}; 
let formFn = {};  

function ServiceRequestForm(props) {
  const { state:{user:currentUser}, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    ServiceRequestService.get(params).then((res) => {
      if(res.status==200){
        console.log('requestform service',res.data)
        serviceRequest = res.data.project_service_request;
        data = serviceRequest.data || {};
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
       serviceRequest = null;
    })
  }
  
  useEffect(() => { 
    serviceRequest = {}; 
    data = {}; 
    formFn = {};  
    formMode = 'create-form-submissions';
    data = {}; 
    if(params.id){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, [params.id]);

  const create = (service_request) => {
    service_request.accelerator_id = currentUser.accelerator.id;
    service_request.organization_id = params.organization_id;
    service_request.project_id = params.project_id;
    ServiceRequestService.create(service_request).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (service_request) => {
    service_request.accelerator_id = currentUser.accelerator.id;
    service_request.organization_id = params.organization_id;
    service_request.project_id = params.project_id;
    ServiceRequestService.update(service_request).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push(`/project/service_requests/${params.project_id}`)
  }

  if(isFormRendered){
    return (
    <div className="portlet">
      <div className="portlet-heading bg-highlight color-white ">
        <h4 className="portlet-title text-uppercase">
          {post.title}
        </h4>
         <div className="clearfix"></div>
      </div>
      <div id="portlet1" className="panel-collapse collapse in">
        <div className="portlet-body p-t-0">
          <div className="form-details" dangerouslySetInnerHTML={{ __html: post.details }}></div>
          <DynamicForm formMode={formMode} formId={FormTemplateId.ProjectServiceRequest} form={serviceRequest} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
        </div>
      </div>
    </div>
    );  
  }else{
    return null;
  }
  
}

export default ServiceRequestForm;