import React from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import AuthenticationService from "../../services/AuthService";
import useStyle from '../../hooks/useStyle';
import { Link } from "react-router-dom";
import CommonService from "../../services/CommonService";

export const ForgetPassword = (props) => {
  useStyle('signup');

  const { state: authState, dispatch } = React.useContext(AuthContext);

  const initialState = {
    email: "",
    isSubmitting: false,
    errorMessage: null,
  };

  const [data, setData] = React.useState(initialState);
  const [count, setCount] = React.useState(Math.random())

  const handleInputChange = (event) => {
    console.log("datafp", data);
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  React.useEffect(() => {
    if(authState.isAuthenticated){
      props.history.push('/')
    }
  }, [authState.isAuthenticated]);

  const handleForgetSubmit = (event) => {
    event.preventDefault();
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });
    console.log("handle state forsubmit", data);
    let req = { user: { email: data.email } };
    AuthenticationService.forgotPassword(req)
      .then((res) => {
        console.log("resforgot", res);
        if (res.status == 200) {
          dispatch({
            type: "FORGET",
            payload: res.data.data,
          });
          props.history.push('/signin')
        } else {
          setData({
            ...data,
            isSubmitting: false,
            errorMessage: res.message || res.statusText,
          });
        }
      })
      .catch((error) => {
        console.log("error fp", error);
        setData({ ...data, errorMessage: error.response.data.error });
      });
  };

  return (
    <React.Fragment>
      <div className="header header-fixed header-logo-app header-auto-show">
        <a href="index.html" className="header-subtitle">
          Back to Pages
        </a>
        <a href="#" data-back-button className="header-icon header-icon-1">
          <i className="fas fa-arrow-left" />
        </a>
        <a href="#" data-toggle-theme className="header-icon header-icon-2">
          <i className="fas fa-lightbulb" />
        </a>
        <a href="settings.html" className="header-icon header-icon-3">
          <i className="fas fa-cog" />
        </a>
      </div>
      <div className="page-content">
        <div className="page-title-bg bg-20" style={{ height: "150px" }}>
          {/* image */}
        </div>
        <div
          className="page-title-bg dark-mode-tint"
          style={{ height: "150px" }}
        >
          {/* contrast for dark mode */}
        </div>
        <div
          className="page-title-bg opacity-90 bg-highlight"
          style={{ height: "150px" }}
        >
          {/* background color */}
        </div>
        <div className="page-title-small color-white bottom-30">
          <h1>
            Forgot{" "}
          </h1>
        </div>
        <form className="signform">
        <div className="content-boxed shadow-small">
          <div className="content">
            <h4>Reset your Account</h4>
            <p>
              Simply enter your email name which you registered your account
              under and will send you the password reset instructions.
            </p>
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-at" />
              <span>Email</span>
              <em>(required)</em>
              <input
                type="email"
                placeholder="Email"
                name="email"
                onChange={handleInputChange}
              />
            </div>
            <button
              onClick={handleForgetSubmit}
              className="button button-full button-m shadow-large button-round-small bg-highlight top-30 bottom-0"
            >
              SEND RESET INSTRUCTIONS
            </button>
          </div>
        </div>
        <a
          href="signin"
          className="button button-m button-full round-small button-margins bg-green1-dark bottom-30 shadow-samll"
        >
          Want to Sign In? Click Here
        </a>
        </form>
       
        {/* Footer */}
        <div className="signinbg">
        <div className="signincon">
        <img src={'./../../../images/cueTree_logo.png'} style={{display: 'block',marginLeft: 'auto',marginRight: 'auto',width: '50%'}} />
			    <h1 className="m-t-10">Essential Community Networks</h1>
          <p className="m-t-10 f-16">
          Transformative outcomes from journey collaboration         
          </p>
          <div className="font-18 m-t-10 button button-full button-m shadow-large button-round-small bg-black white top-30 bottom-0">
            {CommonService.displayRandomPhrase(Math.floor(4*count))}
          </div>
		    </div>
        <div className="signcopy hidden-xs">
        <div className="content bottom-10 social-sharing">
          <a className="shareToTwitter icon icon-xs icon-round bg-twitter regularbold">
            <i className="fab fa-twitter"></i>
          </a>
          <a className="shareToTwitter icon icon-xs icon-round bg-instagram regularbold">
            <i className="fab fa-instagram"></i>
          </a>
          <a className="shareToFacebook icon icon-xs icon-round bg-facebook regularbold">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a className="shareToWhatsApp icon icon-xs icon-round bg-linkedin regularbold">
            <i className="fab fa-linkedin"></i>
          </a>
          <a className="shareToWhatsApp icon icon-xs icon-round bg-whatsapp regularbold">
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
          <i className="far fa-copyright"></i> {new Date().getFullYear()} IPIPAL INC. All Rights Reserved.
          <label className="m-l-5 text-muted">
            <Link className="font-12 text-muted m-r-10" to="/about_us/terms_of_service">&nbsp;Terms of Service</Link> 
            <Link className="font-12 text-muted" to="/about_us/privacy_policy">&nbsp;Privacy Policy</Link>
          </label>
        </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgetPassword;
