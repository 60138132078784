import React, {Fragment, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import LineChart from "../../Charts/LineChart"
import CommonService from "../../../services/CommonService";
import env from "../../../env";

const CostExplorerReport = (props) => {

  let [metricData, setMetricData] = useState({})
  let params = props.match.params;

  useEffect(() => {
    getCost();
  }, []);

  let getCost = () => { 
    let req = {
      method: "post",
      url: `${env.ecn_backend_api}aws/get_cost_and_usage`,
      data: {},
    }

    CommonService.useAxios(req).then((res) => {
      setMetricData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }

  let cost = {
    id:"chart_1",
    series: [{data: metricData.ResultsByTime}],
    type : 'area',
    title : {text: 'Aws Cost', align: 'left'},
    xaxis : {type: 'datetime'},
    yaxis : {
      title: {
        text: 'USD'
      },
      type: 'numeric',
      min:1,
      max:500,
      labels: {
        show: true,
        formatter: function (val, opts) {
          return val.toFixed(0);
        }
      }
    },
    stroke: {curve: 'smooth'}
  }
  
  return (
    <Fragment>
      <div className="content top-10">
        <h5 className="float-left font-500">Aws Usage Report</h5>
        <div className="clear"></div>
      </div>

      <div className="page-content row">
        {metricData.ResultsByTime && <LineChart {...cost}/>}
      </div>

    </Fragment>
  )

}

export default CostExplorerReport;