let AcceleratorRole = {}
let role = {};

AcceleratorRole.initRole = (currentUser) =>{
  if(currentUser.accelerator){
  	role.role_master = currentUser.accelerator.created_by == currentUser.id;
  	role.accelerator_permissions = currentUser.accelerator_permissions || {};
  	role.manage_organizations = role.accelerator_permissions.manage_organizations || {};
  	role.manage_service_providers = role.accelerator_permissions.manage_service_providers || {};
  	role.manage_advisors = role.accelerator_permissions.manage_advisors || {};
  	role.manage_projects = role.accelerator_permissions.manage_projects || {};
  	
  	role.hasOrgAccess = AcceleratorRole.hasOrgAccess();
  	role.hasServiceProviderAccess = AcceleratorRole.hasServiceProviderAccess();
  	role.hasAdvisorAccess = AcceleratorRole.hasAdvisorAccess();
  	role.hasProjectAccess = AcceleratorRole.hasProjectAccess();
  	role.isRoleMaster = AcceleratorRole.isRoleMaster();
  }
  return role
}

AcceleratorRole.hasOrgAccess = () =>{
	return (role.role_master || role.manage_organizations.role_read || role.manage_organizations.role_forms)	
}

AcceleratorRole.hasServiceProviderAccess = () => {
	return (role.role_master || role.manage_service_providers.role_read || role.manage_service_providers.role_forms)
}

AcceleratorRole.hasAdvisorAccess = () =>{
	return (role.role_master || role.manage_advisors.role_read || role.manage_advisors.role_forms)	
} 

AcceleratorRole.isRoleMaster = () =>{
	return role.role_master
}

AcceleratorRole.hasProjectAccess = () =>{
	return (role.role_master || role.manage_projects.role_read || role.manage_projects.role_forms)	
}

export default AcceleratorRole;