import React, {Fragment, useContext, useState, useEffect} from "react";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import useStyle from '../../../../hooks/useStyle';
import Gantt from '../../../../components/FormBuilder/InputComponents/InputFrappeGantt';
import {NavIcon,JourneyHome} from '../../../Common/MenuItem'
import querystringify from "querystringify";
import JourneyTitle from "../JourneyTitle/JourneyTitle";
import ReactTooltip from "react-tooltip";
import AnalyzeTable from "./AnalyzeTable"
import StepActivitiesService from "../StepActivities/StepActivitiesService"
import StepActivityStatusService from "../StepActivityStatus/StepActivityStatusService";
import CommonService from "../../../../services/CommonService";
import FormTemplateId from '../../../../constants/FormTemplateList'
import GenericModal from "../../../Modals/GenericModal";
import useModal from "../../../../hooks/useModal";
import SideMenuBar from "../../../../components/Common/SideMenuBar";
import NotificationPopup from "../../../../components/Common/NotificationPopup";
import HelpMessageList from '../../../../constants/HelpMessageList'

let requestParams = {}
let gantt = null;

const Analyze = (props) => {
  useStyle('/scripts/GanttChart/frappe-gantt.css',true);	
  useStyle("card");
  useStyle("card_table");

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [stepActivities, setStepActivities] = useState(null);
  const [activityList, setActivityList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const [tableView, setTableView] = useState(false);

  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal();

  let params = props.match.params;
  let journey_profile = props.location.state;
  let queryParam = querystringify.parse(props.location.search);

  const resetParams = () =>{
    requestParams = {
      journey_profile_id: params.journey_profile_id,
      page: currentpage,
      paginate: 1,
      per_page: 100,
      sort_column: "updated_at",
      sort_direction: "desc",
      totalItems: 0,
      search: props.searchString
    }
  }

  useEffect(() => {
    resetParams()	
    getStepActivities()
  }, [])

  const getStepActivities = (params) => {
    resetParams() 
    if(params){
      requestParams = {...params, ...requestParams};
    }
    StepActivitiesService.getAll(requestParams).then((res)=>{
      if(res.status===200){
        let tasks = []
        setActivityList(res.data.activity)
        res.data.step_activities.forEach((e, i)=>{
          let status_bg_color = null;
          try{
            status_bg_color = e.status.data_source.status.bg_color
          }catch(e){

          }
          let d = {
            id: `${e.record_id}`,
            name: e.data.name,
            start: new Date(e.data.start_date_formatted_date),
            end: new Date(e.data.end_date_formatted_date || e.data.start_date_formatted_date),
            progress: e.status && (e.status.data.progress*10) || 0.2,
            custom_class: 'bar-milestone',
            task_style: `fill:${status_bg_color}`,
            /*progress_style:"fill:green",*/
            step_activity:e
          }
          tasks.push(d)
        })
        setStepActivities(res.data.step_activities)
        gantt && gantt.clear()
        if(tasks.length > 0){
          gantt = new Gantt("#gantt", tasks, {
            on_click: function (task) {
              console.log(task);
              //let step_activity = task.step_activity
              //props.history.push(`/step_activity/form/${step_activity.journey_profile_id}/${step_activity.activity_id}/${step_activity.id}`)
            },
            on_date_change: function(task, start, end) {
              console.log(task, start, end);
            },
            on_progress_change: function(task, progress) {
              let step_activity = task.step_activity
              let status_form_link = `/form/step_activity_status/${step_activity.journey_profile_id}/${step_activity.activity_id}/${step_activity.id}`
              if(step_activity.status) status_form_link += `/${step_activity.status.id}`
              //if(task.status){
                props.history.push(status_form_link)
                //update(status, progress)
              //}else{
                //create(task.step_activity, progress)
              //}
            },
            on_view_change: function(mode) {
              console.log(mode);
            },
            custom_popup_html: function(task) {
              const step_activity = task.step_activity
              const activity = step_activity.activity
              const status = step_activity.status
              const start_date = CommonService.formatUpdateDate(task._start)
              const end_date = CommonService.formatUpdateDate(task._end)
              
              setTimeout(()=>{
                document.getElementById(`edit_sa_${step_activity.record_id}`).addEventListener("click", (e)=>{
                  props.history.push(`/step_activity/form/${step_activity.journey_profile_id}/${step_activity.activity_id}/${step_activity.id}`, journey_profile)
                })

                document.getElementById(`close_sa_${step_activity.record_id}`).addEventListener("click", (e)=>{
                  document.getElementById('gantt_popup').remove()
                })   
              }, 0);
              
              let milestone_impacted = '<div id="link">'
              try{
                status.data.milestone_impacted_array.forEach((d)=>{
                  milestone_impacted = `<a target="_blank" href="/track/${activity.journey_profile_id}?id=${activity.id}" class="subtitle lred">${d.name}</a>`
                })
              }catch(e){

              } 
              milestone_impacted += '</div>'

              return `<div style="width:300px" id="gantt_popup">
                <div class="title bg-highlight white">
                  <div class="row">
                    <div class="pull-left bold font-16">${activity.data.name}</div>                 
                    <div class="pull-right">
                    <i class="fa fa-times font-15" id="close_sa_${step_activity.record_id}" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="row">
                    <div class="pull-left">
                      <div class="subtitle">${start_date} - ${end_date}</div>
                    </div>
                    <div class="pull-right">
                    <i class="far fa-edit font-15" id="edit_sa_${step_activity.record_id}" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
                <div class="container ht-180 scroll-auto p-7">
                  <div class="ht-60">
                    <div class="subtitle bold">${task.name}</div>
                    <div class="subtitle pull-right lred">${(status && status.data_source.status.label) || ''}</div>
                    <div class="subtitle pull-left bold">${(status && status.data.progress) || ''}0% completed!</div>
                  </div>
                  <div class="divider bottom-20" style={{ backgroundColor: 'grey'}} />
                  <div class="ht-60 lht-14 m-t-5 subtitle pull-left">${(status && status.data.notes) || ''}</div>
                  <div class="ht-60">
                  <div class="divider bottom-20" style={{ backgroundColor: 'grey'}} />
                    ${milestone_impacted !='' && '<u class="bold">Milestone Impacted</u>'}
                    ${milestone_impacted}
                  </div>
                </div>
              </div>`;
            }
          })
        }
      }
    })
  }

  const changeViewMode = (view) =>{
    gantt.change_view_mode(view)
  }


  const Filters = ({handleClick, list, toggle}) =>{
    const onSelect = (e, p) =>{
      e.preventDefault()
      handleClick(p)
    }

    const customFilters = [
      {'label':'All', 'type':'all'},
      {'label':'Meeting Targets', 'type':'not_completed_within_time'},
      {'label':'Missing Targets', 'type':'completed_within_time'}
    ]

    return(
      <Fragment>
        <div className="popup-menu-grid">
          {customFilters.map((o, k) =>
            <a className="ver-icons" onClick={(e)=>onSelect(e, {date_filter:o.type})}>
              <a href="/">
                <i className="fas fa-plus"/>
              </a>
              <div className="popup-menu-title">{o.label}</div>
              <i className="fas fa-chevron-right lgrey pull-right" />
            </a>
          )}

          {list.map((o, k) =>
            <div className="ver-icons" onClick={(e) => onSelect(e, {activity_id:o.id})} key={k}>
              <a href="/">
                <i className="fas fa-plus"/>
              </a>
              <div className="popup-menu-title">{o.name}</div>
              <i className="fas fa-chevron-right lgrey pull-right" />
            </div>
          )}
        </div>
      </Fragment>  
    )
  }

  const create = (step_activity, progress) => {
    let status = {data:{}}
    status.form_id = FormTemplateId.ProjectMilestone;
    status.journey_profile_id = step_activity.journey_profile_id;
    status.activity_id = step_activity.activity_id;
    status.step_activity_id = step_activity.id;
    status.data.progress = progress
    console.log(status.data.progress)
   /* StepActivityStatusService.create(status).then((res) => {
      if(res.status==201){
        
      }
    })*/
  }

  const update = (status, progress) => {
    status.data.progress = progress+0
    /*StepActivityStatusService.update(status).then((res) => {
      if(res.status==204){
       
      }
    })*/
  }

  const toggleTableView = () => {setTableView(!tableView)};

  if (queryParam.id && activityList.length == 0) {
    return (
      <NotificationPopup iconClass="fa fa-times-circle lred font-24"
        header="Not Found"
        message={HelpMessageList.new_activity}/>
    )
  }

  return(
  	 <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-8">
          {journey_profile && <JourneyTitle title={"Analyze"} project={journey_profile}/>}
        </div>
        
        <div className="col-xs-4 float-right">

          <JourneyHome id="home_icon" journey_profile={journey_profile}
            link={`/journey/portal/${params.journey_profile_id}`}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home" dataTip="Back to Journey Home" />


          <NavIcon id="toggle_icon" dataTip="Toggle View" 
            onSelect={toggleTableView}
            className="bg-highlight round-btn pull-right m-r-5 m-t-4" 
            icon="far fa-check-square"/>

          <NavIcon id="filter_icon" dataTip="Show Filter" 
            onSelect={toggleFilterModal}
            className="bg-highlight round-btn pull-right m-r-5 m-t-4" 
            icon="fas fa-sliders-h"/>

          <ReactTooltip place="left" />
        </div>
      </div>	

      {tableView && 
        <AnalyzeTable list={stepActivities} getStepActivities={getStepActivities} requestParams={requestParams}/>
      }

      <div className={`col-xs-12 m-t-5 ${tableView && 'hidden'}`}>
        <div className="btn-group" role="group">
          <button type="button" onClick={e=>changeViewMode('Quarter Day')} className="btn btn-sm btn-light">Quarter Day</button>
          <button type="button" onClick={e=>changeViewMode('Half Day')} className="btn btn-sm btn-light">Half Day</button>
          <button type="button" onClick={e=>changeViewMode('Day')} className="btn btn-sm btn-light">Day</button>
          <button type="button" onClick={e=>changeViewMode('Week')} className="btn btn-sm btn-light">Week</button>
          <button type="button" onClick={e=>changeViewMode('Month')} className="btn btn-sm btn-light active">Month</button>
        </div>
        <svg id="gantt" height="500px"></svg>
      </div>			
      
      {isFilterOpen && (
        <GenericModal component={Filters} isOpen={isFilterOpen} toggleModal={toggleFilterModal}
        handleClick={getStepActivities} list={activityList} title="Filter"/>
      )} 
      <SideMenuBar helpId="2"/>
    </Fragment>
  )
}

export default Analyze;