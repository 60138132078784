import React, {Fragment, useState, useEffect, useCallback} from "react";
import {Link} from "react-router-dom";
import importScript from '../../hooks/importScript'
import useStyle from '../../hooks/useStyle';
import Cs from '../../services/CommonService'
import env from "../../env"

const SummerNoteEditor = ({id, content, onContentChange, required, dataType, dataId, formData, restrictMediaFiles}) => {
  
  useStyle('/fonts/css/summernote-lite.min.css', true);

  const [isOpen, setIsOpen] = useState(false);
  const [retry, setRetry] = useState(0);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() =>{
    return ()=>{
      try{
        console.log('destroy  '+id)
        window.jQuery('#summernote_'+id).summernote('destroy');
      }catch(e){
        
      }
    }
  }, [])

  useEffect(() => {
    if(isOpen && !isInitialized){
      importScript('/scripts/summernote/jquery-3.4.1.slim.min.js', setEditor, window.jQuery);
      importScript('/scripts/summernote/summernote-lite.min.js', setEditor, true);
    }

    if(isOpen){
      //console.log('add click')
      window.addEventListener('click', blur)
    }else{
      //console.log('remove click')
      try{
        isInitialized && onContentChange(window.jQuery('#summernote_'+id).summernote('code'), id)
      }catch(e){
        console.log(e)
      }
      window.removeEventListener('click', blur)
    }

    return ()=>{
      window.removeEventListener('click', blur)
    }
  }, [isOpen, retry])

  /*
    window.jQuery('#summernote').summernote('disable');
  */

  const onFileUpload = (files) =>{
    try {
      var FileSize = files[0].size / 1024 / 1024; // in MB
      if (FileSize > 2) {
        return
      }else{
        
      }

     if(files.length>0){
        let imageData = {'fileable_type':dataType, 'fileable_id':dataId, 'is_rich_text':true}
        Cs.uploadFileToUrl(files[0], null, imageData).then((res)=> {
          let data = res.data;
          let fileImage = Cs.getIconByType(data.file_upload.url)
          const img = window.jQuery('<img>').attr({ src: fileImage })
          window.jQuery('#summernote_'+id).summernote('insertNode', img[0]);
        }, (err)=>{

        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const removeImage= (file) => {
    try {
      file = file.replace(env.file_url, "");
      Cs.deleteFileFromUrl(file).then(function(res) {
        if(res.status === 204 || res.status === 404){

        }
      })
    } catch (e) {
      console.log(e);
    }
  }

  const setEditor = () =>{
    setTimeout(() => {
      try{  
        if(window.jQuery && window.jQuery('#summernote_'+id).summernote){
          console.log('isInitialized '+id)
          const insert = restrictMediaFiles?['link','hr']:['link','picture','video','hr']
          window.jQuery('#summernote_'+id).summernote({
            height: 100,
            focus: true,     
            toolbar: [
              ['headline', ['style']],
              ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript','strikethrough', 'clear']],
              ['fontface', ['fontname']],
              ['textsize', ['fontsize']],
              ['color', ['color']],
              ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
              ['height', ['height']],
              ['table', ['table']],
              ['insert', insert],
              ['view', ['fullscreen', 'codeview']] //, 'help'
            ],
            fontNames: [],
            fontNamesIgnoreCheck: [],
            dialogsInBody: true,
            fontSizes: ['4','6','8', '9', '10', '11', '12', '14', '16', '18', '20', '22', '24', '36', '48' , '64', '82', '150'],
            callbacks: {
              onImageUpload: function(files) {
                // upload image to server and create imgNode...
                //$summernote;
                onFileUpload(files)
              },
              onMediaDelete : function(target) {
                // alert(target[0].src) 
                removeImage(target[0].src);
              },
              onChange: function(contents, $editable) {
                //Commented because causing validation issue in add question modal
                //console.log('onChange:', contents, $editable);
                 onContentChange(contents, id)
              },
              /*onBlur: function(e) {
                console.log(e)
                
                console.log('Editable area loses focus');
              }*/
            } 
          })

          window.jQuery('#summernote_'+id).summernote('code', (formData[id] || null) )
          window.jQuery('#summernote_'+id).summernote('fontName', "Roboto, sans-serif");
          window.jQuery('#summernote_'+id).summernote('lineHeight', 1.2);
          window.jQuery('#summernote_'+id).summernote('fontSize', '16');
          setIsInitialized(true)
        }

      }catch(e){
        console.log(e.message)
      }finally{
        console.log('retry '+retry)
        retry < 3 && setRetry(retry+1)
      }  
    }, 0);
  } 

  const blur = useCallback((e) => {
    try{
      //console.log(!document.getElementById('summernote_parent_'+id).contains(e.target))
      if(!document.getElementById('summernote_parent_'+id).contains(e.target)){
        //window.jQuery('#summernote_'+id).summernote('destroy');  
          setIsOpen(false)
      }
    }catch(e){
      console.log(e.message)
    }
  }, [])

  console.log(id +'  '+ isOpen)

  return(
    <>
      <div id={'summernote_parent_'+id}>
        <input name={id} required={required} className="hidden"/>
        
        <div onClick={e=>setIsOpen(true)} 
        className="form-control plainTxt2 ht-50 scroll-y ml-margin-bottom" 
        style={{display:isOpen?'none':'block'}}
        dangerouslySetInnerHTML={{ __html: formData[id] }}/>

        <div style={{display:isOpen?'block':'none'}}>
          <div id={'summernote_'+id}/>
        </div>
      </div>
    </>
  )
 
}

export default SummerNoteEditor;