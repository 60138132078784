import React, {Fragment, useState, useEffect, useContext} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import Member from '../../components/ShareJourney/Member'
import MemberService from "../../components/ShareJourney/ShareJourneyService";
import ConfirmMenu from '../Modals/ConfirmMenu'
import AddMember from '../../components/ShareJourney/AddMember'
import {Link} from "react-router-dom";
import useModal from '../../hooks/useModal';
import useStyle from '../../hooks/useStyle';
import JourneyTitle from "../../components/Journey/CollegeJourney/JourneyTitle/JourneyTitle";
import ReactTooltip from "react-tooltip";
import NotificationPopup from '../Common/NotificationPopup'
import HelpMessageList from '../../constants/HelpMessageList'
import Pagination from "../Pagination";
import SideMenuBar from "../Common/SideMenuBar";
import {JourneyHome,NavIcon} from '../Common/MenuItem'
import useSort from '../../hooks/useSorting'
import SortDirection from '../../components/Common/SortDirection'
import Cs from "../../services/CommonService";

let requestParams = {}
let newMember = {};
let rolesList = [];
const MemberList = (props) => {
  useStyle('card');  
  useStyle('card_table');
  useStyle('user_lists');
  useStyle('add_role_dialog');

  const { state:currentUser, dispatch } = useContext(AuthContext);
  const [members, setMembers] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);

  let params = props.match.params;
  let state = props.location.state;

  const {isOpen:isDeletePopupOpen, toggleModal:toggleDeletePopup} = useModal();
  const {isOpen:isNewMemberPopupOpen, toggleModal:toggleNewMemberPopup} = useModal();
  const {isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage} = useModal();
  const [showTableView, setShowTableView] = useState(false);

  useEffect(() => {  
    requestParams = {
      'page': currentpage, 
      'per_page' :10,
      'totalItems': 0,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'accelerator_id':currentUser.user.accelerator.id,
      'journey_profile_id':params.journey_profile_id,
    }

    getMembersList();
  }, [currentUser.user.accelerator, props.searchString]);

  let getMembersList = () => { 
    MemberService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
        rolesList = res.data.roles;
        requestParams.totalItems = res.data.meta.total;
        requestParams.page = res.data.meta.current_page;
        setMembers(res.data.members)
        res.data.members.length == 0 && toggleHelpMessage()
      }
    })
  }

  const {sort} = useSort(requestParams, getMembersList)

  const deleteMember = (e) => {
    e.preventDefault();
    MemberService.delete(newMember).then((res) => {
      if(res.status==204){
        setMembers(members.filter(r => r.record_id !== newMember.record_id));
        newMember = null;
      }
    })    
    toggleDeletePopup(e)
  }

  const setDelete = (e, member) =>{
    newMember = member;
    toggleDeletePopup(e)
  }

  const setEdit = (e, member) => {
    newMember = member;
    toggleNewMemberPopup(e);
  }
  
  const addNewMember = (e, new_member) => {
    e.preventDefault();
    if(new_member.id){
      MemberService.update(new_member).then((res) => {
        if(res.status==204){
          restModal(e);
        }
      })  
    }else{
      new_member.created_by = currentUser.user.id;
      new_member.accelerator_id = currentUser.user.accelerator.id;
      new_member.journey_profile_id = params.journey_profile_id;

      MemberService.create(new_member).then((res) => {
        if(res.status==201){
          new_member.updated_user = {name:currentUser.name}
          restModal(e);
        }
      })  
    }
  }

  const restModal = (e) =>{
    newMember = {};
    toggleNewMemberPopup(e);
    getMembersList();
  }

  const switchTableView = (e) =>{
    setShowTableView(!showTableView)
  }

  const tableData = members.map((o, k) => {
    return(
      <tr key={k}>
        <td className="text-center">
          {o.record_id}
        </td>
        <td className="ellipsis-lg text-center">
          {o.email}
        </td>
        <td className="ellipsis-lg text-center">
          <span className={o.is_email_sent ? "far fa-check-circle" : "far fa-circle"}></span>
        </td>
        <td className="ellipsis-lg text-center">
          <span className={o.is_invite_link_visited? "far fa-check-circle": "far fa-circle"}></span>
        </td>
        <td className="ellipsis-lg text-center">
          <span className={o.joined_from_invite? "far fa-check-circle": "far fa-circle"}></span>
        </td>
        <td className="text-center">
          {Cs.formatUpdateDate(o.created_at)}
        </td>
        <td className="">
          <span className="fas fa-edit m-l-15" onClick={e => {setEdit(e, o);} }/>
          <span className="fas fa-trash m-l-15" onClick={e => {setDelete(e, o);} }/>
        </td>
      </tr>
    )
  })

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-8">
          {state.journeyProfile && <JourneyTitle title={"Users"} project={state.journeyProfile}/>}
        </div>
        
        <div className="col-xs-4 float-right">

          <NavIcon id="add_user" dataTip="Create New User" 
            onSelect={restModal}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-plus"/>

          {!props.isPopupMode  &&
            <JourneyHome id="home_icon" journey_profile={state.journeyProfile}
              link={`/journey/portal/${params.journey_profile_id}`}
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fas fa-home" dataTip="Back to Journey Home" />
          }

          <span data-tip="Toggle Table View" className="bg-highlight round-btn float-right m-r-5 m-t-4" onClick={e=>switchTableView(e)}>
            <i className="fas fa-table"></i>
          </span>

          <ReactTooltip place="left" />
        </div>
      </div>

      <div className="page-content clearfix">
        {!showTableView && members.map((member, i) => (
          <div className="col-xs-12 col-sm-6 col-md-4 user-list-container" key={i}>
            <Member member={member} setEdit={setEdit} setDelete={setDelete} 
            currentUser={currentUser.user} journeyProfile={state.journeyProfile}
            share={state.share}/>
          </div>
        ))}
        {!showTableView && <Pagination totalItems={requestParams.totalItems}
          currentpage={currentpage} setCurrentpage={setCurrentpage}
          itemsPerPage={requestParams.per_page}/>}

        { showTableView &&
          <div className="table-responsive">
            <table className="shadow-small">
              <thead>
                <tr className="white bg-black">
                  <th onClick={e=>sort('record_id')} className="p-2 bold-400 text-center miw-95">
                    ID 
                    <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/>
                  </th>
                  <th onClick={e=>sort('email')} className="p-2 bold-400 text-center miw-95">
                    Email Id
                    <SortDirection sort_column={requestParams.sort_column} column="email" reverse={requestParams.reverse}/>
                  </th>
                  <th onClick={e=>sort('is_email_sent')} className="p-2 bold-400 text-center miw-95">
                    Email 
                    <SortDirection sort_column={requestParams.sort_column} column="is_email_sent" reverse={requestParams.reverse}/>
                  </th>
                  <th onClick={e=>sort('is_invite_link_visited')} className="p-2 bold-400 text-center miw-95">
                    Visit 
                    <SortDirection sort_column={requestParams.sort_column} column="is_invite_link_visited" reverse={requestParams.reverse}/>
                  </th>
                  <th onClick={e=>sort('joined_from_invite')} className="p-2 bold-400 text-center miw-95">
                    Join 
                    <SortDirection sort_column={requestParams.sort_column} column="joined_from_invite" reverse={requestParams.reverse}/>
                  </th>
                  <th onClick={e=>sort('updated_at')} className="p-2 bold-400 text-center miw-95">
                    Date
                    <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
                  </th>
                  <th>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData}
              </tbody>
            </table>
          </div>
        }
      </div>

      <ConfirmMenu isOpen={isDeletePopupOpen} toggleModal={toggleDeletePopup} success={deleteMember}/>
      {isNewMemberPopupOpen && <AddMember member={newMember} toggleModal={toggleNewMemberPopup} 
      addNewMember={addNewMember} rolesList={rolesList} currentUser={currentUser.user}/>}
      {isHelpMessageOpen && (
        <NotificationPopup header="Note" message={HelpMessageList.new_user} 
          onSuccess={toggleHelpMessage} isOpen={isHelpMessageOpen} 
          toggleModal={toggleHelpMessage}/>
      )} 
      <SideMenuBar helpId="2"/>
    </Fragment>
  )

};

export default MemberList;