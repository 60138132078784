import React, { useState, Fragment} from 'react';
import ReactDOM from 'react-dom';

const AddEvent = ({newEvent, isOpen, toggleModal, addNewEvent}) => {

  const [event, setEvent] = useState(newEvent);

  return(
    ReactDOM.createPortal(
      <Fragment>
        <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">
        
        <div className="ml-center bg-black white p-5">
          <h4>{event.id?'Update':'Add'} Event</h4>
          <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" 
            title="Close Modal"
            onClick={e=>toggleModal(false)}>×
          </span>
        </div>
      
        <form className="ml-container">
          <div className="ml-section">

          <label><b>Event Name</b></label>
          <input onChange={e=>{event.data.title = e.target.value}} 
            className="ml-input ml-border ml-margin-bottom"
            type="text" placeholder="Event name" name="role_name" 
            required defaultValue={event.data.title}/>
          
          <label><b>Description</b></label>
          <textarea onChange={e=>{event.data.description = e.target.value}}
            className="ml-input ml-border ml-margin-bottom" placeholder="Description" 
            defaultValue={event.data.description} required>
          </textarea>

          <div 
            onClick={e=>{addNewEvent(e, event)}}
            className="ml-button ml-block ml-section ml-padding bg-highlight white" >
              <b>{event.id?'Update':'Create'}</b>
          </div>
          
          </div>
        </form>
      
      </div>
    </div>
    </Fragment>, 
    document.body)
  )
};

export default AddEvent;