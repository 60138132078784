import React from "react";
import Administrative from "./Administrative";
import Commercial from "./Commercial";
import Corporate from "./Corporate";
import IntellectualProperty from "./IntellectualProperty";
import Operation from "./Operation";
import Research from "./Research";
import Investment from "./Investment"
import CommonService from "../../../../services/CommonService";
import moment from "../../../../../node_modules/moment/min/moment.min.js";

const CompanyActivity = (props) => {
  console.log('companyactivity',props)
  let activities = props.activities;
  //let companyJourneyType = props.dataSources.fj_type_edu_jou.options;
  let borderColor = null;
  let data = activities.data;

  activities.updated_at = CommonService.formatUpdateDate(activities.updated_at);

 /* try {
    borderColor =
      companyJourneyType[parseInt(data.fj_type_edu_jou)].ribbon_edge_color;
  } catch (e) {}
*/
  const cardStyle = {
    borderTop: "5px solid #27a1fb",
    height: "360px",
  };
  
  const listView = (list, label_key) =>
    list && list.map((o, k) => <span key={k}>{o.data[label_key]}, </span>);

  if (!activities) return null;

  if (activities) {
    if (activities.activity_id == 1) {
      /*Administrative journey */
      //journey.journey_type = activities.label;
      return <Administrative cardStyle={cardStyle} {...props} />;
    } else if (activities.activity_id == 2) {
      /*Commercial journey*/
      //journey.journey_type = activities.label;
      return <Commercial cardStyle={cardStyle} {...props} />;
    } else if (activities.activity_id == 3) {
      /*Corporate journey*/
      //journey.journey_type = activities.label;
      return <Corporate cardStyle={cardStyle} {...props} />;
    } else if (activities.activity_id == 4) {
      /*IntellectualProperty journey*/
      //journey.journey_type = activities.label;
      return (
        <IntellectualProperty
          listView={listView}
          cardStyle={cardStyle}
          {...props}
        />
      );
    }else if (activities.activity_id == 5) {
      /*Investment journey*/
      //journey.journey_type = activities.label;
      return (
        <Investment listView={listView} cardStyle={cardStyle} {...props} />
      );
    }else if (activities.activity_id == 6) {
      /*Operation journey*/
      //journey.journey_type = activities.label;
      return (
        <Operation listView={listView} cardStyle={cardStyle} {...props} />
      );
    } else if (activities.activity_id == 7) {
      /*Research journey*/
      //journey.journey_type = activities.label;
      return (
        <Research listView={listView} cardStyle={cardStyle} {...props} />
      );
    }
  }

  return null;
};

export default CompanyActivity;
