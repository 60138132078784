import React, {Fragment, useContext, useState, useEffect} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import AcceleratorService from "../../components/AcceleratorProfile/AcceleratorService";
import useStyle from '../../hooks/useStyle';
import Gantt from '../../components/FormBuilder/InputComponents/InputFrappeGantt';

const GanttChart = (props) => {
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [data, setData] = useState(null);

  useStyle(null, 'scripts/GanttChart/frappe-gantt.css');

  useEffect(() => {
    var gantt = new Gantt("#gantt", tasks);
  }, [])

  var temp_tasks = [
    {
      id: 'Task 1',
      name: 'Redesign website',
      start: '2020-12-28',
      end: '2020-12-31',
      progress: 20,
      dependencies: 'Task 2, Task 3',
      custom_class: 'bar-milestone' // optional
    },
  ]

  let tasks = props.tasks || temp_tasks;

  return (<svg id="gantt"></svg>)

}

export default GanttChart;