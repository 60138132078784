import React, {useState, useContext, useEffect, Fragment} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import UserAdminService from "./UserAdminService"
import ConfirmMenu from '../Modals/ConfirmMenu'
import {Link} from "react-router-dom"
import useModal from '../../hooks/useModal'
import useStyle from '../../hooks/useStyle'
import PreLoadSpinner from "../PreLoadSpinner"
import SideMenuBar from "../Common/SideMenuBar"
import NotificationPopup from '../Common/NotificationPopup'
import HelpMessageList from '../../constants/HelpMessageList'
import GenericModal from "../Modals/GenericModal"
import ProjectMenu from "../Modals/ProjectMenu"
import CheckAccess from "../Roles/CheckAccess"
import UserTable from "./UserTable"
import Pagination from "../Pagination";
import PermissionsList from './PermissionsList'

let requestParams = {}
let dataSources = {}
let selectedUser = null

const UsersList = (props) => {
  useStyle('card');  
  useStyle('card_table');
  useStyle('add_role_dialog');
  
  const params = props.match.params;
  
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);

  const {isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage} = useModal();
  const {isOpen:isDeletePopupOpen, toggleModal:toggleDeletePopup} = useModal();
  const {isOpen:isMenuOpen, toggleModal: toggleMenu} = useModal();
  const {isOpen:isPermissionsOpen, toggleModal: togglePermissionsModal} = useModal();

  useEffect(() => {  
    requestParams = {
      'page': currentpage, 
      'per_page' : 15,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc'
    }

    getUsersList();
  }, [props.searchString, currentpage, props.location]);

  let getUsersList = () => { 
    setLoading(true)
    UserAdminService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        setLoading(false)
        requestParams.totalItems = res.data.meta.total;
        res.data.user_admins.length == 0 && toggleHelpMessage()
        setUserList(res.data.user_admins) 
      }
    })
  }

  const deleteUser = (e) => {
    let req = {'id': selectedUser.id, 'role_name': 'is_active', 'role_value':!selectedUser.is_active}
    UserAdminService.update(req).then((res)=>{
      if(res.status == 204){
        selectedUser.is_active = !selectedUser.is_active
        setUserList([...userList]);  
      }
    })
    toggleDeletePopup(e)
  }

  const setDelete = (user) =>{
    selectedUser = user;
    toggleDeletePopup();
  }

  const openPermissionModal = (user) =>{
    selectedUser = user
    togglePermissionsModal()
  }

  return (
    <Fragment>
      <div className="content m-t-10">
        <div className="font-16">User Admin</div>
        <div className="font-14">{requestParams.totalItems} Users</div>
      </div>

      <div className="page-content row m-b-35">
        <div className="col-xs-12 p-12">
          {loading ? <div className="spinner"></div>: <UserTable requestParams={requestParams} userList={userList} getUsersList={getUsersList}
            openPermissionModal={openPermissionModal} onDelete={setDelete}/>}          
        </div>

        <Pagination totalItems={requestParams.totalItems}
          currentpage={currentpage} setCurrentpage={setCurrentpage}
          itemsPerPage={requestParams.per_page}/>

      </div>

      <ConfirmMenu isOpen={isDeletePopupOpen} toggleModal={toggleDeletePopup} success={deleteUser}/>

      {isHelpMessageOpen && <GenericModal component={NotificationPopup} header="Note"
          message={HelpMessageList.team_activity_role} onSuccess={toggleHelpMessage}
          isOpen={isHelpMessageOpen} toggleModal={toggleHelpMessage}/>
      } 

      {isPermissionsOpen && (
        <GenericModal component={PermissionsList} toggleModal={togglePermissionsModal}
          title="Permissions" editPermission={selectedUser}/>
      )} 
    </Fragment>
  )

}

export default UsersList;