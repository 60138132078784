import React, { useEffect, useState, useCallback, Fragment, useContext } from 'react';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import {OptionsObjList} from '../../../Common/FormInput'
import JourneyTitle from '../JourneyTitle/JourneyTitle';
import {NavIcon} from '../../../Common/MenuItem'
import ReactFlow, {ReactFlowProvider, addEdge, removeElements, isNode, MiniMap, Controls,} from 'react-flow-renderer';
//import dagre from 'dagre';

import {setActions, setOutcome, initialElements, gradeElement, setPlanner, resetElement} from './initialElements';
import {AddActivity, AddDataSource, AddPlanner, AddStepActivity} from './Nodes'
import useModal from '../../../../hooks/useModal';
import './layouting.css';
import querystringify from 'querystringify';
import Cs from '../../../../services/CommonService'
//import ActivityTypeMenu from "../Activities/List/ActivityTypeMenu";
import JourneyProfileService from "../../JourneyProfileService"
import PortalPath from '../PortalPage/PortalPath'
import GenericModal from '../../../Modals/GenericModal'
import useStyle from "../../../../hooks/useStyle";
import CheckAccess from "../../../../components/Roles/CheckAccess";
import CommonService from "../../../../services/CommonService";

const nodeWidth = 200;
const nodeHeight = 60;
let reactFlowInstance = null;
let portalPath = null;

const nodeTypes = {
  AddActivity,
  AddDataSource,
  AddPlanner,
  AddStepActivity,
}

let requestParams = {}
let dataSource = {}
let currentPosition = {}
let futurePositions = {}
let share = {}
let menuDataSourceName = 'path_nodes_oper_jou';

const LayoutFlow = (props) => {
  useStyle("path");

  let params = props.match.params;
  //let journeyProfile = props.location.state;
  let queryParam = querystringify.parse(props.location.search)

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);

  const [journeyProfile, setJourneyProfile] = useState({})
  const [elements, setElements] = useState([])
  const [loading, setLoading] = useState(true)

  const { isOpen:isActivityTypeOpen, toggleModal:toggleActivityTypeModal } = useModal()

  useEffect(() => {
    requestParams = {
      data_source_params: {data_source_id: [3622, queryParam.menu, 3625, 3626]},
      journey_profile_id: params.journey_profile_id,
      page: 1,
      paginate: 1,
      per_page: 15,
      sort_column: "updated_at",
      sort_direction: "desc",
      totalItems: 0,
      search: props.searchString,
    }

    getActivityList()
  }, [])

  let getActivityList = () => {
    setLoading(true)
    JourneyProfileService.getPathNodes(requestParams).then((res) => {
      if (res.status == 200) {
        if(res.data.data_source_list){
          resetElement()
          dataSource = res.data.data_source_list;
          const sector = res.data.journey_profile.data_source.sector
          portalPath = CommonService.findObjById(dataSource, 'id', sector.portal_ds)
          //dataSource.activityColor = dataSource.path_nodes.options.reduce((obj, item) => (obj[item.child_form] = item.header_color, obj) ,{});
          //dataSource.activityIcon = dataSource.path_nodes.options.reduce((obj, item) => (obj[item.child_form] = item.icon, obj) ,{});
          gradeElement(dataSource.path_nodes.options, onGradeClick)
        }
        //setGradeActivities(res.data.activities, dataSource.activityColor, dataSource.activityIcon)
        share = res.data.share_journey
        CheckAccess.userAccess(currentUser, res.data.journey_profile, share)
        setJourneyProfile(res.data.journey_profile);
        //setActivityList([...activityList, ...res.data.activities]); 
        setPlanner(res.data.planners) 
        setActions(res.data.activities)
        setOutcome(res.data.analyze)
        setElements(initialElements)
        setLoading(false);
      }
    })
  }

  /*let getDataSource = () => {
    setIsLoading(true)
    ActivityService.getAll(requestParams).then((res) => {
      res = res.data
      if(res.data_source_list){
        resetElement()
        dataSource = res.data_source_list;
        dataSource.activityColor = dataSource.career_j_activity_cat.options.reduce((obj, item) => (obj[item.child_form] = item.header_color, obj) ,{});
        dataSource.activityIcon = dataSource.career_j_activity_cat.options.reduce((obj, item) => (obj[item.child_form] = item.icon, obj) ,{});
        gradeElement(dataSource.path_nodes_career_journey.options, onGradeClick)
      }
      setGradeActivities(res.activities, dataSource.activityColor, dataSource.activityIcon)
      journeyCategory = res.journey_category
      journeyProfile = journeyCategory.education_journey_profiles
      currentPosition = res.current_position
      futurePositions = res.future_positions
      setFutureCareers(journeyProfile.data.future_careers_array)
      setElements(initialElements)
      setIsLoading(false)
    })
  }*/

  const onLoad = (react_flow_instance) => {
    reactFlowInstance = react_flow_instance;
  }

  let getActivityByFilter = (target) => { 
    //setIsLoading(true)
    requestParams[target.name] = target.value == 'all'?null:target.value
    resetElement()
    //getDataSource()
  }

  const onGradeClick = (ds) =>{
    if(ds.value == 1){
      props.history.push(`/form/goals/${params.journey_profile_id}`)
    }else if(ds.value == 2){
      menuDataSourceName = 'menu_action_nodes'
    }else if(ds.value == 3){
      menuDataSourceName = 'menu_node_outcomes'
    }
    toggleActivityTypeModal()  
  }

  const onConnect = (params) =>
    setElements((els) =>
      addEdge({ ...params, type: 'smoothstep', animated: true }, els)
    )
  
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els))

  let styleFn = (o) => ({backgroundColor: (o.data.icon_color)})

  const routePath = (e, path) => {
    //3354
    e.preventDefault();
    if (path.sid == "prepare") {
      props.history.push(`/baseline/${params.journey_profile_id}?menu=${path.menu_ds}`, journeyProfile)
    }else if (path.sid == "goals") {
      props.history.push(`/goals/${params.journey_profile_id}`, journeyProfile)
    }else if (path.sid == "activities") {
      
    }else if (path.sid == "users") {
      props.history.push(`/invite/users/${journeyProfile.id}?permission_list_id=3475`, {'journeyProfile':journeyProfile, 'share':share})
    }else if (path.sid == "calendar") {
      props.history.push('/calendar/'+journeyProfile.id+'?item_id='+journeyProfile.id+'&item_type=operations', journeyProfile)
    }else if (path.sid == "interviews") {
      
    }else if (path.sid == "data") {
      let form_ids = path.form_templates.map(d => d.id).join(",");
      props.history.push(`/list/data_sources?journey_profile_id=${params.journey_profile_id}&form_ids=${form_ids}`, journeyProfile)
    }else if (path.sid == "roles") {
      props.history.push(`/roles/${params.journey_profile_id}?component_id=${portalPath.id}`, journeyProfile)
    }else if (path.sid == "implement") {
      props.history.push(`/track/${params.journey_profile_id}?form=${path.form_id}`, journeyProfile)
    }else if(path.sid == 'analyze'){
      props.history.push(`/analyze/${params.journey_profile_id}`, journeyProfile)
    }else if(path.sid == 'collections'){
      props.history.push(`/collection/${params.journey_profile_id}`, journeyProfile)
    }else if(path.sid == 'authorize'){
      props.history.push(`/governance/${params.journey_profile_id}`, journeyProfile)
    }else if(path.sid == 'meetings'){
      props.history.push(`/zoom/meetings/${params.journey_profile_id}`, journeyProfile)
    }
  }

  if(loading)return null;

  return (
    <Fragment>
      <div className="content top-10">
        <div className="col-sm-4">
          {journeyProfile && <JourneyTitle title={"Path"} project={journeyProfile}/>}
        </div>
        
        <div className="col-sm-8">
            
          <NavIcon id="home_icon" dataTip="Back to Journey Home" 
            link={`/journey`}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home"/>

          <NavIcon id="home_icon" dataTip="Portal Path" 
            onSelect={e=>{
              menuDataSourceName = 'path_nodes_oper_jou';
              toggleActivityTypeModal(e)
            }}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fa fa-bars"/>

        </div>
        <div className="clear"/>
      </div>

      <div className="page-content m-t-25">
        <div className="row form-group filter p-b-8">
          <div className="col-xs-12 col-sm-4 m-b-5">
            <select onChange={e => getActivityByFilter(e.target)} name="activity_form_id" className="form-control" 
              defaultValue={requestParams.activity_form_id}>
                <option value="all">Activity Type</option>
                <OptionsObjList list={dataSource.path_nodes.options} 
                label_key="label" id_key="child_form"/>
            </select>
          </div>
          <div className="col-xs-12 col-sm-4 m-b-5">
            <select name="grade" className="form-control">
                <option value="all">Career Stage</option>
            </select>
          </div>
          <div className="col-xs-12 col-sm-4 m-b-5">
            <select name="subject_search" className="form-control">
                <option value="all">Target Career</option>
            </select>
          </div>
        </div>

        <div className="layoutflow p-10">
          <ReactFlowProvider>
            <ReactFlow
              elements={elements}
              onConnect={onConnect}
              onElementsRemove={onElementsRemove}
              connectionLineType="smoothstep"
              nodeTypes={nodeTypes}
              onLoad={onLoad}/>
            <Controls />
          </ReactFlowProvider>
        </div>

      </div>
      {isActivityTypeOpen && <GenericModal title="Menu" currentUser={currentUser} 
        portalPath={dataSource[menuDataSourceName]} statics={{}} 
        share={share} journeyProfile={journeyProfile} routePath={routePath}
        component={PortalPath} toggleModal={toggleActivityTypeModal}/> }
    </Fragment>
  )
}

export default LayoutFlow;

/*
  {isActivityTypeOpen && <ActivityTypeMenu journey_category_id={params.journey_category_id} journey_profile_id={params.journey_profile_id} isOpen={isActivityTypeOpen} toggleModal={toggleActivityTypeModal} />}
*/