import React, { useState, Fragment, useEffect, useContext,useRef } from "react";
import ReactTooltip from "react-tooltip";
import querystringify from "querystringify";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import Goals from "../../Goals/List/Goals";
import PlannerService from "../../../PlannerService";
import Pagination from "../../../../Pagination";
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import ProjectMenu from "../../../../Modals/ProjectMenu";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import CommentsModal from "../../../../Modals/CommentsModal";
import useModal from "../../../../../hooks/useModal";
import ChecklistsModal from "../../../../Modals/ChecklistsModal";
import useStyle from "../../../../../hooks/useStyle";
import CheckAccess from "../../../../Roles/CheckAccess";
import PreLoadSpinner from "../../../../PreLoadSpinner";
import JourneyTitle from "../../JourneyTitle/JourneyTitle";
import SideMenuBar from "../../../../Common/SideMenuBar";
import NotificationPopup from "../../../../Common/NotificationPopup";
import {MenuItem,CardMenuInfo,NavIcon,JourneyHome} from '../../../../Common/MenuItem'
import HelpMessageList from '../../../../../constants/HelpMessageList'
import GenericModal from "../../../../Modals/GenericModal";
import ReviewForm from "../../Review/ReviewForm"
import CommonService from "../../../../../services/CommonService";
import {SwiperContainer, SwiperSlide} from "../../../../Common/Swiper"

let requestParams = {};
let selectedPlanner = {};
let data_source = {};
let journey_profile = null;
let cardViewCount = 1;

const GoalsList = (props) => {
  useStyle("card");
  useStyle("card_table");
  useStyle("add_role_dialog");

  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  let cardGridCss = "col-xs-12";
  let cardHeightCss = "ht-400";
  if (queryParam.id) {
    cardGridCss = "col-sm-12 col-sm-6 col-md-8";
    cardHeightCss = "ht-400";
  }

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [goalsList, setGoalsList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pagemeta, setPagemeta] = useState([]);

  const { isOpen: isCommentOpen, toggleModal: toggleComment } = useModal();
  const { isOpen: isDeleteModalOpen, toggleModal: toggleDelete,} = useModal();
  const { isOpen: isPlannerMenuOpen, toggleModal: togglePlannerMenu } = useModal();
  const { isOpen: isChecklistOpen, toggleModal: toggleChecklist,} = useModal();
  const { isOpen: isMenuOpen, toggleModal: toggleMenu } = useModal();
  const { isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage } = useModal();
  const { isOpen:isReviewFormOpen, toggleModal:toggleReviewModal } = useModal();
  const indexEle = useRef(null);

  useEffect(() => {
    requestParams = {
      data_source_params: { data_source_id: 3196, response_type: "object" },
      journey_profile_id: params.journey_profile_id,
      form_id: '444',
      page: currentpage,
      paginate: 1,
      per_page: 10,
      sort_column: "updated_at",
      sort_direction: "desc",
      totalItems: 0,
      search: props.searchString,
      id:queryParam.id
    }

    getPlannerList()
  }, [props.searchString, currentpage, props.location])

  let getPlannerList = () => {
    setLoading(true)
    PlannerService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        //data_source = res.data.data_source_list;
        requestParams.totalItems = res.data.meta.total;
        journey_profile = res.data.journey_profile;
        CheckAccess.userAccess(currentUser, journey_profile, res.data.share_journey);
        cardViewCount = CommonService.getCardCount(screen) 
        requestParams.totalPages = Math.ceil(requestParams.totalItems / cardViewCount);
        setGoalsList(res.data.planners);
        setLoading(false);
        res.data.planners.length == 0 && toggleHelpMessage()
        if(requestParams.search){
          setGoalsList(res.data.planners);
        }else if(queryParam.id){
          cardViewCount = 1
          requestParams.totalPages += 1
          setGoalsList(res.data.planners);
        }
      }
    })
  }

  const addComment = (e, a) => {
    selectedPlanner = a;
    toggleComment(e);
  }

  const addChecklist = (e, a) => {
    selectedPlanner = a;
    toggleChecklist(e);
  }

  const deletePlanner = (e) => {
    e.preventDefault();
    PlannerService.delete(selectedPlanner.id).then((res) => {
      if (res.status == 204) {
        setGoalsList(goalsList.filter((i) => i.id !== selectedPlanner.id))
      }
    })
    toggleDelete(e);
  }

  const showMenu = (e, a) => {
    selectedPlanner = a;
    toggleMenu(e);
  }

  const routeActivity = (planner) => {
    props.history.push({
      pathname:`/goals/${params.journey_profile_id}`,
      search:`id=${planner.id}`,
      state:journey_profile,
    })
  }

  const openReviewModal = (planner) =>{
    selectedPlanner = planner
    toggleReviewModal()
  }

  const menuItems = ({ item }) => {
    const title = item.data && item.data.title.replace(/\s/g, "_");
    const editAccess = CheckAccess.hasEditAccess(currentUser, journey_profile, item);

    return (
      <Fragment>
        <CardMenuInfo formId={item.form_id} recordId={item.record_id} 
          createdAt={item.created_at} userName={item.created_user.name}/>

        {editAccess && (
          <MenuItem id="edit_icon" 
            link={`/form/goals/${item.journey_profile_id}/${item.id}?edit=${editAccess}`}
            icon="far fa-edit"
            action="Edit"
          />
        )}
        {CheckAccess.hasDeleteAccess(currentUser, journey_profile, item) && (
          <MenuItem id="delete_icon" 
            icon="far fa-trash-alt"
            action="Delete"
            onSelect={(e) => {toggleDelete(e);}}
          />
        )}
        {editAccess && 
          <MenuItem id="invite_icon" 
            link={{pathname:`/share/items/${params.journey_profile_id}/planner/${item.id}`, state:{journeyProfile:journey_profile, activity:item} }}
            icon="fas fa-user"
            action="Share Item"
          />
        }
        <MenuItem id="mail_icon" 
          link={`mailto:${title}_planner_${item.record_id}@cuetree.com`}
          icon="far fa-envelope"
          action={`${title}_planner_${item.record_id}@cuetree.com`}
        />
      </Fragment>
    )
  }

  const Menu = ({ planner }) => (
    <i data-tip="Menu" onClick={(e) => showMenu(e, planner)} className="fas fa-bars m-r-15"/>
  )

  const journeyActivityCard = goalsList && goalsList.map((a, i) => (
    <SwiperSlide key={i}>
      <div className={cardGridCss}>
        <Goals queryParam={queryParam} planner={a} openReviewModal={openReviewModal} addComment={addComment} addChecklist={addChecklist} dataSource={data_source} menu={Menu} journeyProfile={journey_profile} cardHeightCss={cardHeightCss} routeActivity={routeActivity} index={i}/>
          {selectedPlanner.id == a.id && (
            <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenu} item={selectedPlanner} type="planner" MenuItems={menuItems} menuHeight="200px"/>
          )}
      </div>
    </SwiperSlide>
    ))
  
    const comment = (
      <Fragment>
        {queryParam.id && goalsList.length > 0 &&
          <SwiperSlide>
          <div className="col-sm-12 col-sm-6 col-md-4">
            <CommentsModal isOpen="true" toggleModal={toggleComment} item={goalsList[0]}
              type="planner" showInline="true"/>
          </div>
          </SwiperSlide>
        }
      </Fragment>
    )

  if (queryParam.id && !loading && goalsList.length == 0) {
    return (
      <NotificationPopup iconClass="fa fa-times-circle lred font-24"
        header="Not Found"
        message={HelpMessageList.new_activity}/>
    )
  }

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-4">
          {journey_profile && <JourneyTitle title={"Goals"} project={journey_profile}/>}
        </div>
        {!queryParam.id && <div className="col-xs-4 text-center">
          <span ref={indexEle}>1</span>/{requestParams.totalPages} Pages
        </div>}
        <div className="col-xs-4 float-right">

          <NavIcon id="add_goal" dataTip="Create New Goal" 
            link={`/form/goals/${params.journey_profile_id}`}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-plus"/>

          <JourneyHome id="home_icon" journey_profile={journey_profile}
            link={`/journey/portal/${params.journey_profile_id}`}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home" dataTip="Back to Journey Home" />

          {queryParam.id  && 
            <NavIcon id="back_icon" dataTip="Back to Activity" 
              onSelect={e=>{props.history.goBack()}}
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fas fa-arrow-left"/>
          }
          <ReactTooltip place="left" />
        </div>
      </div>

      <div className="page-content row">
      { !queryParam.id &&
        <Fragment>
          <SwiperContainer currentpage={currentpage} setCurrentpage={setCurrentpage}
            requestParams={requestParams} indexEle={indexEle} cardViewCount={cardViewCount}>
            {journeyActivityCard}
          </SwiperContainer> 
        </Fragment>
      }

        { queryParam.id &&
          <Fragment>
            {journeyActivityCard}
            <div className="col-sm-12 col-sm-6 col-md-4">
              { goalsList.length > 0 && <CommentsModal isOpen="true" toggleModal={toggleComment} item={goalsList[0]} type="planner" showInline="true"/> }
            </div>
          </Fragment>           
        }
      </div>

      {isCommentOpen && (
        <CommentsModal
          isOpen={isCommentOpen}
          toggleModal={toggleComment}
          item={selectedPlanner}
          type="planner"/>
      )}
      {isChecklistOpen && (
        <ChecklistsModal
          isOpen={isChecklistOpen}
          toggleModal={toggleChecklist}
          item={selectedPlanner}
          type="planner"/>
      )}
     
      <ConfirmMenu
        isOpen={isDeleteModalOpen}
        toggleModal={toggleDelete}
        success={deletePlanner}/>
        
        {isHelpMessageOpen && (
          <NotificationPopup header="Note" message={HelpMessageList.new_activity} 
          onSuccess={toggleHelpMessage} isOpen={isHelpMessageOpen} toggleModal={toggleHelpMessage}/>
        )}

        {isReviewFormOpen && (
          <GenericModal title="Review" component={ReviewForm} isOpen={isReviewFormOpen} toggleModal={toggleReviewModal}
          journey_profile_id={params.journey_profile_id} assessment_id={selectedPlanner.id} assessment_type='planner'/>
        )}
      <SideMenuBar helpId="2"/>
    </Fragment>
  )
}

export default GoalsList;