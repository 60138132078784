import env from '../../env';
import axios from 'axios';

class ServiceProviderService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}service_providers`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(req){
    console.log('req sp axios',req)
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}service_providers/${req.id}`, {params: req}).then((res) => {
        console.log('getid sp',res)
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        console.log('getid sp',e)
        reject(e);
      })
    })
  }

  create(profile){
    return new Promise((resolve, reject) => {
      axios.post(env.ecn_backend_api+'service_providers',profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(profile) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.ecn_backend_api}service_providers/${profile.id}`, profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.ecn_backend_api}service_providers/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  addSkill(skill){
    return new Promise((resolve, reject) => {
      axios.post(env.ecn_backend_api+'service_provider/skills',skill).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  searchServiceProviderBySkill(req){
    return new Promise((resolve, reject) => {
      axios.post(env.ecn_backend_api+'service_provider/skills/search', req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  updateSkill(skill) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.ecn_backend_api}service_provider/skills/${skill.id}`, skill).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  getSkillbyId(skill){
    console.log('req skillbyid axios',skill)
    return new Promise((resolve, reject) => {
      axios.get(`${env.ecn_backend_api}service_provider/skills/${skill.id}`).then((res) => {
        console.log('getid sp',res)
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        console.log('getid sp',e)
        reject(e);
      })
    })
  }

}

export default new ServiceProviderService();