import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import Cs from "../../services/CommonService";
import {UserImage} from '../Common/Image'

const Member = (props) => {
  let {member, member:{data, reports}, journeyProfile, currentUser, share } = props;
  member.updated_at = Cs.formatUpdateDate(member.updated_at);

  console.log(member)

  const RoleList = ({roles}) => roles && roles.map((r, k) =>(
    <Fragment key={k}>
      <p className="pill white">{r.role}</p>
    </Fragment>
  ))

  const Report = () => (
    <div className="m-t-5 font-12">
      <span>Goal</span> <span className="black m-r-15">{reports.goals} || </span> 
      <span>Baseline</span> <span className="black m-r-15">{reports.implement} || </span> 
      <span>Calendar</span> <span className="black m-r-15">{reports.calendar}</span>
    </div>
  )

  /*const PermissionList = ({roles}) => roles && roles.map((r, k) =>(
    <Fragment key={k}>
      <div><a href="#" key={k}>{Object.keys(r.permissions[r.user_type_sid]).join(",")}</a></div>
    </Fragment>
  ))*/

  return (
    <Fragment>

      <div className="card-container shadow-large bg-white role-list-container" >
        <div className="role-list-left">
          <div className="role">
            <UserImage url={member.updated_user.image} className="shadow-small bg-blue2-dark pull-right"/>
           
            <a className="black">{member.email}</a>
           
            <div className="role-list-scroll">
              {journeyProfile.created_by == member.user_id && <p className="pill white">Creator</p>}
              <RoleList roles={member.roles}/>
            </div>

            <div>
              <div className="text-cap role">
                <a className="black">
                  {member.message && member.message.substring(0, 110)}
                </a>
                <span>
                  <strong>Email</strong>
                  <a>
                    <span className={member.is_email_sent ? "far fa-check-circle" : "far fa-circle"}></span>
                  </a>
                </span>
                <span>
                  <strong>Visit</strong>
                  <a>
                    <span className={member.is_invite_link_visited? "far fa-check-circle": "far fa-circle"}></span>
                  </a>
                </span>
                <span>
                  <strong>Join</strong>
                  <a>
                    <span className={member.joined_from_invite? "far fa-check-circle": "far fa-circle"}></span>
                  </a>
                </span>
                {reports && <Report/>}
              </div>
            </div>
          </div>
        </div>

        <div className="col-xs-12 m-t-5"> 
          <div className="pull-left font-11">
            <span className="lred"> Last by {member.updated_user.name} </span>
            <span> {member.updated_at}</span>
          </div>
          {member.user_id == currentUser.id && (journeyProfile.created_by == member.user_id && share?.permissions?.is_primary_admin_removeable) &&
            <div className="pull-right">
              <div className="action-btn bg-highlight" onClick={e=>props.setEdit(e, member)}>Edit</div>
              <div className="action-btn bg-highlight" onClick={e=>props.setDelete(e, member)}>Delete</div>
            </div>
          }
        </div>

      </div>
    </Fragment>
  )

};

export default Member;

/*<p className="color-highlight">
    {member.access_type && member.access_type.includes('full_access') && 
    <a className="p-2 br-groove-black margin-2 bg-blue-white black-light-1">
      Full Access
    </a>
    }
  </p>
*/