import React, { useState, Fragment, useEffect} from "react";
import querystringify from "querystringify";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import Pagination from '../../Pagination'
import { Link } from "react-router-dom";
import useModal from "../../../hooks/useModal";
import useStyle from '../../../hooks/useStyle';
import MilestoneTask from '../../../components/Organizations/MilestoneTasks/MilestoneTask'
import MilestoneTaskService from "../../../components/Organizations/MilestoneTasks/MilestoneTaskService";

let requestParams = {};
let dataSources = {};
let selectedTask = {};
let filter = {};

const MilestoneTasksList = (props) => {
  useStyle('card');  
  useStyle('card_table');
  useStyle('add_role_dialog');

  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  let cardGridCss = 'col-xs-12 col-sm-6 col-md-4'
  let cardHeightCss = 'ht-227'
  if(queryParam.id){
    cardGridCss = 'col-sm-12 col-sm-6 col-md-8'
    cardHeightCss = 'mih-380'
  }

  const { state: authState, dispatch } = React.useContext(AuthContext);
  const [milestoneTaskList, setMilestoneTasksList] = useState([]);
  const [currentpage,setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);

  useEffect(() => {
    requestParams = {
      page: currentpage,
      per_page: 30,
      search: props.searchString || null,
      sort_column: "updated_at",
      sort_direction: "asc",
      accelerator_id: authState.user.accelerator.id,
      milestone_id: params.milestone_id
    }

    getMilestoneTasksList();
  }, [authState.user.accelerator, props.searchString, currentpage]);

  let getMilestoneTasksList = () => {
    MilestoneTaskService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        dataSources = res.data.data_source_list;
        setPagemeta(res.data.meta);
        setMilestoneTasksList(res.data.milestone_tasks);
      }
    })
  }

  const milestoneTasks = milestoneTaskList.map((task, i) =>
    <div className={cardGridCss} key={i}>
      <MilestoneTask task={task} cardHeightCss={cardHeightCss}/>
    </div>
  )

  return (
    <Fragment>
      <div className="content top-10">
        <h5 className="float-left font-500">Milestone Tasks</h5>

        <div className="clear"></div>
      </div>

      <div className="page-content row">
        {milestoneTasks}
        <Pagination pagemeta={pagemeta} currentpage={currentpage} setCurrentpage={setCurrentpage}/>
      </div>
    </Fragment>
  )
};

export default MilestoneTasksList;