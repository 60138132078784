import React, {useState, useEffect, Fragment, useContext} from "react";
import ReactDOM from 'react-dom';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import { Link, useRouteMatch } from "react-router-dom";
import useModal from "../../hooks/useModal";
import DigitalContentService from './../DigitalContent/DigitalContentService';
import useStyle from '../../hooks/useStyle';
import env from '../../env';
import ConfirmMenu from "../Modals/ConfirmMenu";
import CommonService from '../../services/CommonService';

let requestParams = {}
let selectedHelp = null

const HelpSideNav = (props) => {
  useStyle('help');

  const [helpIndex, setHelpIndex] = useState();
  const [helpList, setHelpList] = useState([]);
  const [direction, setDirection] = useState('right');
  const [toggle, setToggle] = useState(true);
  const [hide, setHide] = useState(true);
  const [isSearchOpen, toggleSearch] = useState(false);
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const { isOpen:isDeleteOpen, toggleModal:toggleDeleteModal } = useModal();

  const match = useRouteMatch();
  const pageId = match.path.split("/:")[0]

  useEffect(() => {
    requestParams = {
      'content_type':'help',
      'page': 1,
      'paginate': 1,
      'per_page': 9,
      'sort_column': '',
      'sort_direction': 'desc',
    }

    getHelp();    
  }, [props.helpId, toggle]);

  let getHelp = () => { 
    setToggle(true)
    requestParams.page_url = pageId
    DigitalContentService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        setHelpList(res.data.digital_contents)
        //setHelp(res.data.helps[0])
      }
    })
  }

  const setPosition = (e) =>{
    e.preventDefault()
    let p = direction=='left'?'right':'left'
    setDirection(p)
  }

  const deleteHelp = (e) =>{
    e.preventDefault();
    DigitalContentService.delete(selectedHelp).then((res)=>{
      if(res.status == 204){
       setHelpList(helpList.filter(i => i.id !== selectedHelp.id))
      }
    })
    toggleDeleteModal(e)
  }

  const onDelete = (e, help) =>{
    selectedHelp = help   
    toggleDeleteModal(e)
  }

  const toggleHelp = (i) =>{
    const tabScroll = document.getElementById('tab_'+i);
    window.scrollTo({
      'behavior': 'smooth',
      'left': 0,
      'top': tabScroll.offsetTop + 400
    })
    if(helpIndex == i){
      setHelpIndex(null)
    }else{
      setHelpIndex(i)
    }
  }

  const helpListPanel =  helpList && helpList.map((help, i) => {
    let view = <div id={'tab_'+i} style={{border: helpIndex == i ? '1px solid #00a2ff':''}} key={i}>
        <div className="font-16 pointer br-b-grey p-7" onClick={e=>toggleHelp(i)}>
          <i className={`fas ${i != helpIndex?'fa-angle-down':'fa-angle-up'}`}/>
          <span className="m-l-10">{help.data.title}</span>
        </div>
        {helpIndex == i && <Fragment>
          {env.admin_emails.includes(currentUser.email) &&
            <div className="font-16 ht-25">
              <Link to={`/help/create/${help.id}`} className="m-r-10 pull-right">
                <i className="fas fa-edit"/>
              </Link>
              <a href="#" className="m-r-20 pull-right" onClick={e=>onDelete(e, help)}>
                <i className="fas fa-trash"/>
              </a>
            </div>
          }
          <div id="help_img" dangerouslySetInnerHTML={{ __html: help.data.help_detail }}></div>
        </Fragment>}
      </div>

    /*let user_type = help.data.user_type_array || []
    if(user_type.indexOf(1)>-1 && currentUser.current_admin.role_admin){
      return view
    }else if(user_type.indexOf(2)>-1 && !currentUser.current_admin.role_admin){
      return view
    }*/

    return view
  })

  if(helpList.length < 0)return null;

  return(ReactDOM.createPortal(
    <Fragment>
    <div className={`col-xs-10 col-sm-6 col-md-4 ${hide ? 'help-side-nav':'help-side-nav-hide'}`} style={{'right': direction=='right'?'unset':0,display:toggle? 'block' :'none'}}>
      <div className="help-header bg-highlight clearfix">
        <div className={`col-xs-12 help-icon ${ direction=='right'?'pull-right':'pull-left'}`}> 
          <p className="pull-left white">
            Help
          </p>
          <a href="#" className="m-r-20 pull-right" onClick={e=>{e.preventDefault();props.setIsHelpOpen(false);setToggle(!toggle)}}>
            <i className="fa fa-times white"/>
          </a>
          <a className="input-group m-r-20 pull-right" id="search_box">
            {isSearchOpen && <input type="text" placeholder="Search" className="form-control input-sm"/>}
            <span className="input-group-btn" onClick={e=>toggleSearch(!isSearchOpen)}>
              <p type="button" className="bg-highlight input-sm">
                <i className="fa fa-search white"></i>
              </p>
            </span>
          </a>

          <a href="#" className="m-r-20 pull-right" onClick={e=>setPosition(e)}>
            <i className="fas fa-exchange-alt white"/>
          </a>

          <a href="#" className="m-r-20 pull-right" onClick={e=>{e.preventDefault();setHide(!hide);}}>
            <i className={`white ${hide ? 'fa fa-caret-down' : 'fa fa-caret-up'}`}/>
          </a>

          {env.admin_emails.includes(currentUser.email) &&
            <Link to={`/help/create?page=${pageId}`} className="m-r-20 pull-right">
              <i className="fas fa-plus white"/>
            </Link>
          }
        </div>
      </div> 
      { hide && 
        <div className="row">
          <div className="scroll-auto" style={{height:(screen.xs?'400px':'450px')}}>
            {helpListPanel}
          </div>
        </div>
      }
    </div>
    <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteHelp}/>
  </Fragment>, document.body))
}

export default HelpSideNav;