import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../components/PreLoadSpinner";
import DynamicForm from '../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../constants/FormTemplateList'
import MarketingService from "../../../components/Marketing/MarketingService";
import FormContainer from '../../../components/FormBuilder/FormContainer'

let formMode = null; 
let formId = null; 
let marketing = {}; 
let data = {}; 
let formFn = {};  

function MarketingForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext);
  console.log('marketing forum form',props)
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;
  
  let currentUser = authState.user;

  /*TODO: Move API to Router to load data before view*/
 
  
  useEffect(() => { 
    marketing = {}; 
    data = {}; 
    formFn = {}; 
    formMode = 'create-form-submissions';
    if(props.match.path){
      if(props.match.path.includes('details')){
        console.log('details')
        //get()
        formMode = 'view-form-submissions';
      }else if(!isFormRendered){
        setFormRendered(true);
      }
    }
   
  }, [params.id]);

  const create = (marketing) => {
    marketing.created_by = authState.user.id
    marketing.form_id = 411
    marketing.content_type = "marketing_content"
    console.log(' marketing create',marketing);
    MarketingService.create(marketing).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (marketing) => {
    marketing.accelerator_id = currentUser.accelerator.id;
    MarketingService.update(marketing).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push("/marketing")
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.MarketingForm} form={marketing} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
      </FormContainer> 
    )  
  }else{
    return null
  }
  
}

export default MarketingForm;