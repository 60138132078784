let CheckAccess = {}
let role = {};

CheckAccess.userAccess = (currentUser, journey, invited_journey_member) =>{
  if(currentUser.id == journey.updated_by || currentUser.id == journey.created_by ||
    (invited_journey_member && invited_journey_member.access_type 
      && invited_journey_member.access_type.includes('full_access'))){
      journey.is_admin_user = true;
      journey.is_template_journey = false;
  }else if(journey.data.ai_training_user=='Yes'){
      journey.is_template_journey = true;
  }
}

CheckAccess.hasStepActivityAccess = (currentUser, journey, activity) =>{
  try{
    if(journey.is_admin_user || currentUser.id == activity.created_by){
      return true;
    }else if(activity.shared_item && activity.shared_item.permissions.add_step_activity){
      return true;
    }
  }catch(e){
    return false;  
  }
}

CheckAccess.hasChecklistAccess = (journey, activity) =>{
	return (journey.is_admin_user || (activity.shared_item && activity.shared_item.permissions.add_checklist))
}

CheckAccess.hasDeleteAccess = (currentUser={}, journey={}, activity={}) =>{
	return (journey.is_admin_user || currentUser.id == activity.created_by)
}

CheckAccess.hasEditAccess = (currentUser, journey, activity) => {
	return (journey.is_admin_user || currentUser.id == activity.created_by)
}

CheckAccess.hasRatingAccess = (activity) =>{
	return activity.shared_item && activity.shared_item.permissions.add_review
}

CheckAccess.hasRoleAccess = (currentUser, journey, activity) =>{
	return (journey.is_admin_user || currentUser.id == activity.created_by)
}

CheckAccess.isJourneyAuthor = (currentUser, journey)=>{
  return currentUser.id == journey.updated_by
}

CheckAccess.hasPathAccess = (journey, access_type, access_list, admin_list, component)=>{
  if(admin_list.indexOf(component) > -1 && journey.is_admin_user){
    return true
  }else{
    return (journey.is_admin_user || (access_list && access_list.includes(component)))
  }
  //access_type.includes('partial_access')
  return false
}

export default CheckAccess;