import React, { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import useStyle from "../../../hooks/useStyle";
import CommonService from "../../../services/CommonService";
import useEscape from "../../../hooks/useEscape";
import FormTemplateId from "../../../constants/FormTemplateList";

let requestParams = {};

const CompanyTypeMenu = ({ isOpen, toggleModal ,data_source}) => {
  useStyle("add_role_dialog");
  useStyle("filter_dialog");
  useEscape(toggleModal);
  
  const history = useHistory();
  const {state: { screen, user: currentUser }, dispatch,} = React.useContext(AuthContext);
  //const [journeyCategory, setJourneyCategory] = useState();
  console.log('currentuser',currentUser,data_source)
  let profile = currentUser.domain_info;
  //let data_source = profile.data_source;
  let journeyCategory = data_source.journey_phases.options

  useEffect(() => {
   
  }, []);


  const routeJourney = (e, journey_type) => {
    e.preventDefault();
      history.push(
        `/company_profile?show_form_wizard=1&form_id=${journey_type.parent_form}&form_wizard_id=${journey_type.wizard_id}&fj_type_edu_jou=${journey_type.value}`
      );
  };

  const MenuItem = ({ k }) => (
    <div className="ver-icons" onClick={(e) => routeJourney(e, k)}>
      <a href="/">
        <i
          className={`${k.icon || "fas fa-plus"}`}
          style={{ color: k.icon_color }}
        />
      </a>
      <div className="popup-menu-title">{k.label}</div>
      <i className="fas fa-chevron-right lgrey pull-right" />
    </div>
  );

  const PersonalTypeList = () =>
    journeyCategory.map((k) => (
      <Fragment key={k.value}>
        {k.read_only != "yes" && (
          <MenuItem k={k} />
        )}
      </Fragment>
    ));

  return ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">
          <div className="ml-center bg-black white p-5">
            <h5>TYPE OF EDUCATION JOURNEY</h5>
            <span
              className="ml-button ml-xlarge ml-hover-red ml-display-topright"
              title="Close Modal"
              onClick={(e) => toggleModal(e)}
            >
              ×
            </span>
          </div>

          {journeyCategory && (
            <div className="popup-menu-grid">
              {
                PersonalTypeList()}
            
            </div>
          )}
        </div>
      </div>
    </Fragment>,
    document.body
  );
};

export default CompanyTypeMenu;
