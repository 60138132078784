import React, {useContext, Fragment} from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import Cs from "../../../../../services/CommonService";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import StepActivities from '../../StepActivities/StepActivities'
import {Attachments} from '../../../../Common/Image'
import querystringify from "querystringify";

const Activity = (props) => {
  const { state: authState, dispatch } = useContext(AuthContext);

  let activity = props.activity;
  let queryParam = props.queryParam;

  activity.updated_at = Cs.formatUpdateDate(activity.updated_at);
  activity.created_at = Cs.formatUpdateDate(activity.created_at);

  let data = activity.data;
  let ds = activity.data_source || {};


  const SpanField = ({name, label, styleName}) =>(
    <span className={styleName} style={{ backgroundColor:ds[name]?.bg_color}}>{ds[name] && ds[name][label]}</span>
  )

  const IconField = ({name,icon,icon_color, label, styleName}) => {
    if(ds[name]){
      return(
        <Fragment>
          <span className={styleName}>
          <i className={`${ds[name][icon]} font-18 d-inline m-5`} style={{ color:ds[name][icon_color]}} aria-hidden="true"></i>
          {ds[name] && ds[name][label]}</span>
        </Fragment>
      )
    }else{
      return null
    }
  }

  const SpanList = ({name, label, styleName}) => {
    if(ds[name]){
      return(
        <Fragment>
          {ds[name].map((d, i) => (
            <span className={styleName +' m-l-5'} key={i}>{d.data[label]}</span>
          ))}
        </Fragment>
      )
    }else{
      return null
    }
  }

  const TagList = ({name, label, styleName}) => {
    if(data[name]){
      return(
        <Fragment>
          {data[name].map((d, i) => (
            <span className={styleName +' m-l-5'} key={i}>{d}</span>
          ))}
        </Fragment>
      )
    }else{
      return null
    }
  }
    
  const cardStyle = {backgroundColor: "black"}

  if (!data) return null;

  let incomingMailLink = {
    pathname: `/journey/incoming/emails/activity/${activity.id}`,
    state: { journey: props.journeyCategory, activity: activity },
  }

  const MailTag = () => (
    <a href={`mailto: ${activity.id}-activity@reply.cuetree.com?subject=Activity-${data.name}-${activity.record_id}`}
      target="_blank">
      <i className="far fa-envelope font-18 text-muted" aria-hidden="true"></i>
    </a>
  )

  const Header = () => (
    <div className="white card-header p-5" style={cardStyle}>
      <div className="font-16">
        {data.name}
         <i className="fas fa-expand pull-right white expand p-3" onClick={(e) => props.routeActivity(activity, 'baseline')}></i>
      </div>
      <div className="font-16">
        <span className="font-14">
          {activity.updated_at} by {activity.updated_user.name}
        </span>
      </div>
    </div>
  )

  const Footer = () => (
    <div className="card-footer">
      <Fragment>
        <div className="pull-left lgrey">
          <span id={`comment_${props.index}`} className="m-l-25"
            onClick={(e) => props.addComment(e, activity)}>
            <i className="far fa-comment font-18 text-muted" data-tip="Chat" ></i>
            <span className="badge up bg-lgrey">{activity.comments_count || 0}</span>
          </span>
          <span id={`check_list_${props.index}`} className="m-l-25"
            onClick={(e) => props.addChecklist(e, activity)}>
            <i className="far fa-check-circle font-18 text-muted" data-tip="Checklist"></i>
            <span className="badge up bg-lgrey">{activity.checklists_count || 0}</span>
          </span>
          <span id={`mail_${props.index}`} className="m-l-25">
            <Link onClick={(e) => e.stopPropagation()} to={incomingMailLink}>
              <i className="far fa-envelope font-18 text-muted" data-tip="Email"></i>
              <span className="badge up bg-lgrey">
                {activity.mail_count || 0}
              </span>
            </Link>
          </span>
        </div>
        <div className="pull-right">
          <props.menu activity={activity} />
        </div>
      </Fragment>
    </div>
  )

  const Card = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <Header/>
        <div className={`scroll-auto ${props.cardHeightCss}`}>
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                  <span className="pull-left">
                    Primary Contact : {data.primary_contact}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  Context:<SpanField name="context" label="label" styleName="pill text-left"/>
                </td>
              </tr>
              <tr>
                <td>
                  Dependency: <SpanList name="dependency" label="label" styleName="pill text-right"/>
                </td>
              </tr>
              <tr>
                { data.reference && <td colSpan="2"><Attachments files={data.reference} width="60px"/></td>}
              </tr>
              <tr>
                <td colSpan="2">        
                  Risks: <SpanList name="risks" label="label" styleName="pill text-left"/>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  Tags: <TagList name="tags_array" label="label" styleName="pill text-right"/>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div>Note</div>
                  <div dangerouslySetInnerHTML={{ __html: data.notes }}/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Footer />
        <ReactTooltip place="left" />
      </div>
    )
  }

  return <Card/>;
}

export default Activity;