import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import DynamicForm from '../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../constants/FormTemplateList'
import ZoomMeetingService from "./ZoomMeetingService";
import FormContainer from '../../components/FormBuilder/FormContainer'

let formMode = null
let meeting = {}
let data = {}
let formFn = {}  

function MeetingForm(props) {

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  const get = () => {
    ZoomMeetingService.get_meeting(props.id).then((res) => {
      if(res.status == 200){
        meeting = res.data.meeting
        data = meeting.data || {}
        /*TODO Render Delay Issue*/
        setFormRendered(true)
      }
    })
  }
  
  useEffect(() => { 
    meeting = {}
    data = {}
    formFn = {form_type: "zoom_meeting", journey_profile_id: props.journey_profile_id}
    formMode = 'create-form-submissions'
    if(props.id && props.id != 'null'){
      get()
      formMode = 'edit-form-submissions'
    }else if(!isFormRendered){
      setFormRendered(true)
    }
  }, [props.id])

  const create = (meeting) => {
    meeting.journey_profile_id = props.journey_profile_id
    meeting.form_id = FormTemplateId.ZoomMeeting
    ZoomMeetingService.create_meeting(meeting).then((res)=>{
      if(res.status == 201){
        onSubmitPageRedirect(res.data.meeting, 'create')
      }
    }) 
  }

  const update = (meeting) => {
    ZoomMeetingService.update_meeting(meeting).then((res)=>{
      if(res.status == 204){
        onSubmitPageRedirect(meeting, 'update')
      }
    }) 
  }

  const onSubmitPageRedirect = (meeting, event_type) => {
    props.toggleModal(meeting, event_type)
  }

  if(isFormRendered){
    return (
      <div className="p-5">
        <DynamicForm formMode={formMode} formId={FormTemplateId.ZoomMeeting} form={meeting} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
      </div> 
    )  
  }else{
    return null
  }
  
}

export default MeetingForm;