import React, { useState, Fragment} from 'react';
import ReactDOM from 'react-dom';

const AddScheduleType = ({ newScheduleType, isOpen, toggleModal, addNewScheduleType}) => {

  const [type, setType] = useState(newScheduleType || {data:{}});

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
      <div className="ml-modal-content ml-card-4 ml-animate-zoom">
      
      <div className="ml-center bg-black white p-5">
        <h4>{type.id?'Update':'Add'} Type</h4>
        <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal"
          onClick={e=>toggleModal(false)}>×
        </span>
      </div>
    
      <form className="ml-container">
        <div className="ml-section">

        <label><b>Event Name</b></label>
        <input onChange={e=>{type.data.label = e.target.value}} className="form-control ml-margin-bottom"
          type="text" placeholder="Event name" name="role_name" required defaultValue={type.data.label}/>
        
        <label><b>Color</b></label>
        <input onChange={e=>{type.data.bg_color = e.target.value}} className="form-control ml-margin-bottom"
          type="color" placeholder="Color" name="bg_color" required defaultValue={type.data.bg_color}/>

        <div className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=>{addNewScheduleType(e, type)}}>
          <b>{type.id?'Update':'Create'}</b>
        </div>
        
        </div>
      </form>
    
    </div>
  </div>
  </Fragment>, document.body))
};

export default AddScheduleType;